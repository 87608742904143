import styled from 'styled-components';
import { bootstrapMax, bootstrapMin } from '../../utils/devices';

export const VideoSimple = styled.div`
  width: 331px;
  height: 213px;

  @media ${bootstrapMax.xs} {
    width: 100%;
  }

  @media ${bootstrapMin.lg} {
    width: 737px;
    height: 473px;
  }
`;
