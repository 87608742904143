const { axiosDefault, handleErrors } = require('../utils/defaults');

const documentService = {
  user: {
    getList: async ({ limit = '', name = '', descOrder = '', offset = '', random = '' }) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(
          `/api/documents?limit=${limit}&name=${name}&descOrder=${descOrder}&offset=${offset}&random=${random}`,
        );

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },
};

export default documentService;
