import React, { Component } from 'react';
import { Accordion, Screen, SubHeader, Text, CooperativeCard } from '../../../components';
import { meetOurBackground } from '../../../enums';

import PropTypes from 'prop-types';
const { defaultStates } = require('../../../utils/defaults');

import bannerMobile from '../../../assets/imgs/banner-background-features-mobile.png';
import banner from '../../../assets/imgs/banner-background-features.png';

import {
  MeetOurBackgroundContainer,
  ContainerAssociates,
  BannerAssociates,
  LogoAssociates,
  TextAssociates,
  TitleAssociates,
  RowStyled,
  ColStyled,
} from './styles';
import { Col, Row } from 'react-bootstrap';
class BackgroundFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      innerWidth: window.innerWidth,
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
    this.updateSize = this.updateSize.bind(this);
    this.principles = this.principles.bind(this);
  }

  updateSize = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  header = () => {
    return (
      <>
        <SubHeader
          image
          backgroundImage={banner}
          backgroundImageMobile={bannerMobile}
          minHeightSm={452}
          minHeight={313}
          title={'Características do fundo'}
        />
      </>
    );
  };

  principles = () => {
    window.open('https://www.iadi.org/en/core-principles-and-guidance/core-principles/', '_blank');
  };

  body = () => {
    return (
      <MeetOurBackgroundContainer className={'pt-2 pt-lg-0 mb-lg-5 pb-lg-5'}>
        <Row className={'mb-4 pb-3 mb-lg-5 pb-lg-5'}>
          <Col xs={12} className={'d-flex justify-content-center'}>
            <Text heading2 noWrap textAlign={'center'} title={{ one: 'CONHEÇA NOSSO FUNDO' }} />
          </Col>
          <Col xs={12}>
            {meetOurBackground.map((background, index) => {
              return (
                <Row key={index} className={'meet-our-background'}>
                  <Col>
                    <Accordion
                      id={index.toString()}
                      summary={<Text labelAcordeonFaq text={`${index + 1}. ${background.title}`} />}
                      details={<Text paragraph3 text={background.content} />}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
        <ContainerAssociates>
          <BannerAssociates />
          <RowStyled>
            <ColStyled xl={4} className={'pt-2 pe-lg-4 d-flex flex-column mb-3 mb-lg-0'}>
              <Text
                titleWhite
                fontSizeMobile={25}
                textAlignMobile={'center'}
                text={'ASSOCIADAS DO FGCOOP'}
              />
              <Text
                paragraph5
                lightColor
                text={`Todas as cooperativas singulares de crédito captadoras de depósitos e os bancos cooperativos são instituições associadas ao FGCoop, compulsoriamente.`}
              />
            </ColStyled>
            <Col>
              <Row style={{ gap: '35px 0' }}>
                <CooperativeCard
                  xsSize={6}
                  lgSize={4}
                  title={'SICOOB'}
                  mdClasses={'width: 137px; height: 63px; background-size: 116px 27px;'}
                  associate={'sicoob'}
                  logo={'sicoob-logo.png'}
                />
                <CooperativeCard
                  xsSize={6}
                  lgSize={4}
                  title={'SICREDI'}
                  mdClasses={'width: 137px; height: 63px; background-size: 116px 27px;'}
                  associate={'sicredi'}
                  logo={'sicredi-logo.png'}
                />
                <CooperativeCard
                  lgSize={4}
                  title={'CAPTADORAS DE DEPÓSITOS EM FUNCIONAMENTO NO PAÍS'}
                  associate={'coop'}
                  mdClasses={'width: 137px; height: 83px;'}
                  text={'COOPERATIVAS SINGULARES DE CRÉDITO'}
                />
              </Row>
            </Col>
          </RowStyled>
        </ContainerAssociates>
      </MeetOurBackgroundContainer>
    );
  };

  render = () => {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  };
}

BackgroundFeatures.propTypes = {
  //props
  history: PropTypes.object,
};
export default BackgroundFeatures;
