/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import userService from '../../../services/user';
import {
  Screen,
  Text,
  Input,
  Button,
  Checkbox,
} from '../../../components';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
  baseURL,
} = require('../../../utils/defaults');

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: '',
        email: '',
        password: '',
        isActive: true,
      },
      generatedPassword: '',
      emailError: '',
      changePassword: false,
      ...defaultStates(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });

    const { id } = this.props.match.params;
    if (id !== 'novo') {
      const response = await userService.admin.get(id);
      if (response.ok && response.data && response.data) {
        this.setState({
          user: response.data,
          newRegister: false,
        });
      } else if (response.ok) {
        this.props.history.push('/gestao/users/novo');
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  handleChange(id, event) {
    const { user, changePassword } = this.state;
    if (id === 'changePassword') {
      this.setState({ changePassword: !changePassword });
      if (!changePassword) {
        user.password = '';
        this.setState({ user });
      }
    } else {
      user[id] = event.target.value;
      this.setState({ user });
    }
  }

  async handleSubmit() {
    let { newRegister, user, changePassword } = this.state;
    this.setState({ loadingState: true });

    if (!this.validateEmail(user.email)) {
      const modalState = {
        title: 'Erro',
        text: 'Por favor, insira um e-mail válido.',
        toggle: true,
        onHide: this.modalOnHide,
      };

      this.setState({ modalState, loadingState: false });
      return;
    } else {
      this.setState({ emailError: '' });
    }

    if (user.password.length < 12) {
      const modalState = {
        title: 'Erro',
        text: 'Por favor, insira uma senha com pelo menos 12 caracteres.',
        toggle: true,
        onHide: this.modalOnHide,
      };

      this.setState({ modalState, loadingState: false });
      return;
    }

    const hasNumber = /\d/.test(user.password);
    const hasSpecialChar = /[!@#$%^&*()]/.test(user.password);

    if (!hasNumber) {
      const modalState = {
        title: 'Erro',
        text: 'A senha deve conter pelo menos um número.',
        toggle: true,
        onHide: this.modalOnHide,
      };

      this.setState({ modalState, loadingState: false });
      return;
    }

    if (!hasSpecialChar) {
      const modalState = {
        title: 'Erro',
        text: 'A senha deve conter pelo menos um caracter especial.',
        toggle: true,
        onHide: this.modalOnHide,
      };

      this.setState({ modalState, loadingState: false });
      return;
    }

    if (newRegister) {
      user.isActive = true;

      const response = await userService.admin.save(user);
      if (response.ok) {
        const alertScreenState = {
          title: 'Usuário criado com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/users',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    } else {
      const response = await userService.admin.edit(user);

      if (response.ok) {
        const alertScreenState = {
          title: 'Usuário alterado com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/users',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  handlePassword() {
    const charsetNumbers = '0123456789';
    const charsetSpecial = '!@#$%^&*()';
    const charsetAlpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charsetAll = charsetNumbers + charsetSpecial + charsetAlpha;

    let password = '';
    password += charsetNumbers[Math.floor(Math.random() * charsetNumbers.length)];
    password += charsetSpecial[Math.floor(Math.random() * charsetSpecial.length)];

    const passwordLength = Math.floor(Math.random() * 4) + 12;
    for (let i = 2; i < passwordLength; ++i) {

      let randomNumber = Math.floor(Math.random() * charsetAll.length);
      password += charsetAll.substring(randomNumber, randomNumber + 1);

    }

    password = password.split('').sort(() => 0.5 - Math.random()).join('');

    const { user } = this.state;
    user.password = password;
    this.setState({ user, generatedPassword: password });
  }



  async handleDelete() {
    this.setState({
      modalState: {
        title: `Remoção de Usuário`,
        text: `Tem certeza que deseja excluir esse usuário ?`,
        toggle: true,
        onHide: () => this.modalOnHide(),
        confirm: 'Sim',
        onClickConfirm: async () => await _handleDelete(),
        danger: true,
        cancel: 'Não',
        onClickCancel: () => this.modalOnHide(),
      },
    });

    const _handleDelete = async () => {
      const {
        user: { id },
      } = this.state;
      this.setState({ loadingState: true });

      const response = await userService.admin.delete(id);

      if (response.ok) {
        const alertScreenState = {
          title: 'Usuário Excluído com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/users',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }

      this.setState({ loadingState: false });
    };
  }

  body() {
    const { user, emailError, changePassword } = this.state;

    return (
      <>
        <Row align="center">
          <Col>
            <Text title text={'Usuário'} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Input
              value={user.name}
              label={'Nome completo:'}
              placeholder={'Nome completo'}
              maxLength={200}
              onChange={(e) => this.handleChange('name', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Input
              value={user.email}
              label={'Email:'}
              placeholder={'Email'}
              maxLength={200}
              onChange={(e) => this.handleChange('email', e)}
              isInvalid={!!emailError}
            />
            <div className="invalid-feedback">{emailError}</div>
          </Col>
        </Row>
        {user.id ? (
          <Row className="mt-3">
            <Col>
              <Checkbox
                label={'Trocar senha'}
                checked={changePassword}
                onClick={() => {
                  this.handleChange('changePassword');
                }}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={8} xs={8}>
              <Input
                value={user.password}
                label={'Senha:'}
                placeholder={'Senha'}
                maxLength={200}
                onChange={(e) => this.handleChange('password', e)}
              />
            </Col>
            <Col className="mt-4">
              <Button text={'Gerar senha'} onClick={this.handlePassword} />
            </Col>
          </Row>
        )}
        {changePassword && (
          <Row>
            <Col sm={8} xs={8}>
              <Input
                value={user.password}
                label={'Nova Senha:'}
                placeholder={'Nova Senha'}
                maxLength={200}
                onChange={(e) => this.handleChange('password', e)}
              />
            </Col>
            <Col className="mt-4">
              <Button text={'Gerar senha'} onClick={this.handlePassword} />
            </Col>
          </Row>
        )}
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center" className="mt-5">
          {this.state.user.id ? (
            <Col>
              <Button danger text={'Excluir'} onClick={this.handleDelete} />
            </Col>
          ) : (
            <></>
          )}
          <Col>
            <Button text={'Salvar'} onClick={this.handleSubmit} />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          admin
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

Users.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Users;
