import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { Row, Col, Form } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import infoService from '../../../services/infos';
import {
  Screen,
  Text,
  Input,
  ButtonUpload,
  Image,
  Label,
  Button,
  Checkbox,
  Calendar,
  File as FileDoc,
} from '../../../components';
import fileService from '../../../services/file';
import quote1 from '../../../assets/icons/admin/quote-left-top.png';
import quote2 from '../../../assets/icons/admin/quote-right-bottom.png';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import ImageCompression from '../../../utils/compressImage';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
  baseURL,
} = require('../../../utils/defaults');

class Infos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {
        title: '',
        content: '',
        newContent: '',
        documentDate: '',
        category: 'RELATORIO_GESTAO',
        subcategory: '',
        files: [],
        files_document: [],
        isActive: true,
        url: '',
      },
      ...defaultStates(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });

    const { id } = this.props.match.params;

    if (id !== 'novo') {
      const response = await infoService.admin.get(id);
      if (response.ok && response.data && response.data.info) {
        const formattedDate = moment(response.data.info.documentDate, 'DD/MM/YYYY').toDate();
        response.data.info.documentDate = formattedDate;

        this.setState({
          info: response.data.info,
          newRegister: false,
        });
      } else if (response.ok) {
        this.props.history.push('/gestao/informacoes/novo');
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  handleChange(id, event) {
    const { info } = this.state;

    if (id === 'files') {
      info[id] = [event.target.files && event.target.files[0]];
    } else if (id === 'removeFile') {
      info.files = [];
      info.file_id = null;
    } else if (id === 'newContent') {
      info[id] = event.target.getContent();
    } else if (id === 'files_document') {
      info[id] = [event[0]];
    } else if (id === 'removeFileDocument') {
      info.files_document = [];
      info.file_id_document = null;
    } else if (id === 'category') {
      if (event.target.value !== 'RELATORIO_GESTAO') {
        info.subcategory = '';
      }
      info[id] = event.target.value;
    } else {
      info[id] = event.target.value;
    }
    this.setState({ info });
  }

  async handleSubmit() {
    const { newRegister, info } = this.state;
    this.setState({ loadingState: true });
    info.content = info.newContent || info.content;
    delete info.newContent;

    if (newRegister) {
      if (info.files.length) {
        const compressedFile = await ImageCompression.imageCompress(info.files);
        let file = new File([compressedFile], compressedFile.name, {
          lastModified: compressedFile.lastModified,
          type: compressedFile.type,
        });
        info.files[0] = file;
      }

      const response = await infoService.admin.save(info);

      if (response.ok) {
        const alertScreenState = {
          title: 'Informação criada com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/informacoes',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    } else {
      if (info.files.length && !info.files[0].id) {
        const compressedFile = await ImageCompression.imageCompress(info.files);
        let file = new File([compressedFile], compressedFile.name, {
          lastModified: compressedFile.lastModified,
          type: compressedFile.type,
        });
        info.files[0] = file;
      }

      const response = await infoService.admin.edit(info);

      if (response.ok) {
        const alertScreenState = {
          title: 'Informação alterada com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/informacoes',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  async handleDelete() {
    this.setState({
      modalState: {
        title: `Remoção de ${this.state.info.isActive ? 'informação' : 'rascunho'}`,
        text: `Tem certeza que deseja excluir ess${
          this.state.info.isActive ? 'a informação' : 'e rascunho'
        }?`,
        toggle: true,
        onHide: () => this.modalOnHide(),
        confirm: 'Sim',
        onClickConfirm: async () => await _handleDelete(),
        danger: true,
        cancel: 'Não',
        onClickCancel: () => this.modalOnHide(),
      },
    });

    const _handleDelete = async () => {
      const {
        info: { id },
      } = this.state;
      this.setState({ loadingState: true });

      const response = await infoService.admin.delete(id);

      if (response.ok && response.data) {
        const alertScreenState = {
          title: 'Informação excluída com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/informacoes',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }

      this.setState({ loadingState: false });
    };
  }

  async handleUploadFileTiny(file, cb) {
    const response = await fileService.upload({ files: [file] });
    if (response.ok && response.data) {
      const file = response.data;

      cb(`${baseURL()}/api/file/${file.fileId}/${file.slug}`, {
        title: file.slug,
      });
    } else {
      const modalState = {
        title: 'Erro',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };

      this.setState({ modalState });
    }
  }

  body() {
    const { info, modalFile } = this.state;

    const radios = [
      { name: 'Anual', value: 'ANUAL' },
      { name: 'Semestral', value: 'SEMESTRAL' },
    ];

    const options = [
      { name: 'Relatório de gestão', value: 'RELATORIO_GESTAO' },
      { name: 'Relatório SNCC', value: 'RELATORIO_SNCC' },
      { name: 'Informativo FGCOOP', value: 'INFORMATIVO_FGCOOP' },
      { name: 'Boletim mensal', value: 'BOLETIM_MENSAL' },
      { name: 'Demonstrativo Financeiro', value: 'DEMONSTRATIVO_FINANCEIRO' },
      { name: 'Relatórios Especiais', value: 'RELATORIOS_ESPECIAIS' },
      { name: 'Materiais de Divulgação', value: 'MATERIAIS_DIVULGACAO' },
    ];

    return (
      <>
        <Row align="center">
          <Col>
            <Text title text={'Informações'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Checkbox
              label={'Ativo'}
              checked={info.isActive}
              onClick={() => {
                info.isActive = !info.isActive;
                this.setState({ info });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4} xs={4}>
            <Label text={'Data da Informação:'} />
            <Calendar
              label={'Data'}
              value={info.documentDate}
              onChange={(e) => this.handleChange('documentDate', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Input
              value={info.title}
              label={'Título:'}
              placeholder={'Título da informação'}
              maxLength={200}
              onChange={(e) => this.handleChange('title', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Label text={'Categoria:'} />
            <Form.Select
              size="lg"
              value={info.category}
              onChange={(e) => this.handleChange('category', e)}>
              {options.map((option, idx) => (
                <option key={idx} value={option.value}>
                  {option.name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          {info.category === 'RELATORIO_GESTAO' ? (
            <Col sm={12} xs={12}>
              <Label text={'Subcategoria:'} />
              <br />
              <ButtonGroup>
                {radios.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={'outline-secondary'}
                    name="radio"
                    value={radio.value}
                    checked={radio.value === info.subcategory}
                    onChange={(e) => this.handleChange('subcategory', e)}>
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <Row>
          {info.files && info.files.length ? (
            <Col>
              <Label text={'Imagem do Card:'} />
              <Image
                preview
                img={
                  info.files[0].id
                    ? `${baseURL()}/api/file/${info.files[0].id}/${info.files[0].slug}`
                    : URL.createObjectURL(info.files[0])
                }
                onClick={() => this.handleChange('removeFile')}
              />
            </Col>
          ) : (
            <Col>
              <Label text={'Imagem do Card:'} />
              <ButtonUpload onChange={(e) => this.handleChange('files', e)} image />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Alert severity="info" className={'mt-2'}>
              <span>É recomendado que a imagem do card, tenha o tamanho de:</span>
              <br />
              <span>196px de altura por 248px de largura</span>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label text={'Conteúdo:'} />
            <Editor
              initialValue={info.content}
              apiKey={process.env.REACT_APP_TINY_APIKEY}
              init={{
                height: 500,
                menubar: true,
                language: 'pt_BR',
                content_style: `body { font-size: 20px; font-family: "GT Walsheim Pro", serif !important; }`,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount image importcss',
                ],
                setup: (editor) => {
                  editor.ui.registry.addButton('quote', {
                    text: 'Aspas',
                    tooltip: 'Insere bloco de texto envolvido por aspas customizadas',
                    onAction: () => {
                      editor.insertContent(
                        '<div class="row">' +
                          `<div class="col-1 col-quote1"><img src="${quote1}" class="quote1" style="max-width: 35px; height: fit-content;" alt=""/></div>` +
                          '<div class="col col-text"><p class="news-quote-text" id="myText" style="text-align:left; margin-top: 25px; overflow-wrap: anywhere;">&lt;&lt;Edite Aqui&gt;&gt;</p></div>' +
                          `<div class="col-1 col-quote2 d-flex align-items-end"><img src="${quote2}" class="quote2" style="max-width: 35px; height: fit-content; justify-content: end;" alt=""/></div>` +
                          '</div>',
                      );
                    },
                  });
                },
                toolbar:
                  'undo redo | formatselect | image | bold italic backcolor | ' +
                  'alignleft aligncenter lignright alignjustify | ' +
                  'bullist numlist outdent indent | removeformat | quote | help',
                image_title: true,
                automatic_uploads: true,
                file_picker_types: 'image',
                convert_urls: false,
                image_caption: true,
                a11y_advanced_options: true,
                file_picker_callback: (cb) => {
                  const that = this;
                  const input = document.createElement('input');
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*');
                  input.onchange = async function () {
                    await that.handleUploadFileTiny(this.files[0], cb);
                  };
                  input.click();
                },
              }}
              onChange={(e) => this.handleChange('newContent', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} xs={6}>
            <Input
              value={info.url}
              label={'Url do vídeo:'}
              placeholder={'Url'}
              maxLength={200}
              onChange={(e) => this.handleChange('url', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label text={'Documento:'} />
            <ButtonUpload
              modal
              onClickConfirm={(e) => {
                this.handleChange('files_document', e);
                this.setState({ modalFile: false });
              }}
              modalToggle={modalFile}
              modalTitle={'ADICIONAR DOCUMENTO'}
              modalOnHide={() => this.setState({ modalFile: false })}
              onClick={() => this.setState({ modalFile: true })}
            />
          </Col>
        </Row>
        {info.files_document.length > 0 ? (
          <Row align="center">
            <Col>
              <Text subTitle text="Documento Adicionado" />
            </Col>
          </Row>
        ) : (
          ''
        )}
        <Row>
          {info.files_document.map((doc, index) => {
            return (
              <Col md={4} key={index}>
                <FileDoc
                  fileName={doc.fileName ? doc.fileName : doc.name ? doc.name : 'Arquivo sem nome'}
                  fileTitle={doc.fileName ? doc.fileName : doc.name ? doc.name : 'Arquivo sem nome'}
                  onClick={() => this.handleChange('removeFileDocument')}
                  info={doc}
                />
              </Col>
            );
          })}
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center">
          {this.state.info.id ? (
            <Col>
              <Button danger text={'Excluir'} onClick={this.handleDelete} />
            </Col>
          ) : (
            <></>
          )}
          <Col>
            <Button text={'Salvar'} onClick={this.handleSubmit} />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          admin
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

Infos.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Infos;
