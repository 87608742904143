import React from 'react';

import { Table, TableContainer } from './styles.js';

const ContributionCalendar = () => {
  return (
    <>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th className="fomated-grid">ATIVIDADES 2024</th>
              <th className="fomated-grid">JAN</th>
              <th className="fomated-grid">FEV</th>
              <th className="fomated-grid">MAR</th>
              <th className="fomated-grid">ABR</th>
              <th className="fomated-grid">MAI</th>
              <th className="fomated-grid">JUN</th>
              <th className="fomated-grid">JUL</th>
              <th className="fomated-grid">AGO</th>
              <th className="fomated-grid">SET</th>
              <th className="fomated-grid">OUT</th>
              <th className="fomated-grid">NOV</th>
              <th className="fomated-grid">DEZ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Envio do demonstrativo até o dia</td>
              <td className="fomated-grid">19</td>
              <td className="fomated-grid">20</td>
              <td className="fomated-grid">20</td>
              <td className="fomated-grid">19</td>
              <td className="fomated-grid">20</td>
              <td className="fomated-grid">20</td>
              <td className="fomated-grid">19</td>
              <td className="fomated-grid">20</td>
              <td className="fomated-grid">20</td>
              <td className="fomated-grid">18</td>
              <td className="fomated-grid">20</td>
              <td className="fomated-grid">20</td>
            </tr>
            <tr>
              <td>Recolhimento da contribuição até o dia</td>
              <td className="fomated-grid">25</td>
              <td className="fomated-grid">23</td>
              <td className="fomated-grid">25</td>
              <td className="fomated-grid">25</td>
              <td className="fomated-grid">24</td>
              <td className="fomated-grid">25</td>
              <td className="fomated-grid">25</td>
              <td className="fomated-grid">23</td>
              <td className="fomated-grid">25</td>
              <td className="fomated-grid">25</td>
              <td className="fomated-grid">25</td>
              <td className="fomated-grid">24</td>
            </tr>
          </tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ContributionCalendar;
