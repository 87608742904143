import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin, device } from '../../../utils/devices';
import { Container } from 'react-bootstrap';
import { CooperativeCard, Image, Text } from '../../../components';

import bannerMobile from '../../../assets/imgs/banner-informations-mobile.png';
import banner from '../../../assets/imgs/banner-informations.png';

export const Paragraph = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: ${colors.secondaryTwo};
  border-left: ${({ border }) => (border ? `2px solid ${colors.primaryTwo}` : 'none')};
  padding-left: ${({ border }) => (border ? '10px' : '0')};
  margin-bottom: 30px;

  @media ${bootstrapMin.lg} {
    font-size: 16px;
  }
`;

export const Heading3 = styled.h3`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryTwo};
  font-size: 23px;
  line-height: 32px;

  @media ${bootstrapMin.lg} {
    font-size: 26px;
    line-height: 50px;
  }
`;

export const TextStyled = styled(Text)`
  font-family: 'Trade Gothic LT Bold';
`;

export const ContainerStyled = styled(Container)`
  max-width: 650px !important;
`;

export const CooperativeCardStyled = styled(CooperativeCard)`
  div {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 14px;
  }
  a,
  a:hover {
    color: ${colors.primaryTwo} !important;
  }
`;

export const CooperativeCardTextStyled = styled(CooperativeCardStyled)`
  div {
    display: flex;
    align-items: center;
  }
  p {
    padding: 6px 27px;
  }
`;

export const ContainerLimitValue = styled.div`
  height: 255px;

  @media ${bootstrapMin.lg} {
    height: 273px;
  }
`;

export const BannerLimitValue = styled.div`
  background-image: url(${bannerMobile});
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-size: 100%;
  position: absolute;
  width: 100vw;
  height: 308px;
  margin-left: -50vw;
  left: 49.99%;
  z-index: -1;

  @media ${bootstrapMin.sm} {
    background-image: url(${banner});
    background-size: 1366px 367px;
    height: 273px;
  }
`;

export const ImageStyled = styled(Image)`
  @media ${bootstrapMax.sm} {
    object-fit: contain;
    margin-left: -45px;
  }
`;
