import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import {
  SimpleCard,
  Icon,
  NumberTitle,
  StepNumberTitle,
  Title,
  StepTitle,
  Content,
  StepContent,
  ContentNoBox,
  StepByStepCard,
  DoubleArrowIcon,
  BracketsCard,
  IconBracket,
  StepIconBracket,
  RowStep,
  LeftBracketIcon,
  FullBracketsTitle,
  FullBracketsSubTitle,
  FullBracketsDescription,
  OrdinaryCoverageIconBracket,
  OrdinaryCoverageCard,
  OrdinaryCoverageNumberTitle,
  ColStyled,
  TitleNews,
  NewsHomePageCard,
  ParagraphNews,
  ImageNews,
  LinkContainer,
  LinkNews,
} from './styles';

import { Accordion, Text } from '../../components';

import { Col, Row } from 'react-bootstrap';
import { colors } from '../../utils/colors';

const { baseURL } = require('../../utils/defaults');
class MiniCard extends Component {
  /**
   *
   * Only Example create new component
   *
   */

  render() {
    // props
    const {
      src,
      title,
      subtitle,
      content,
      number,
      data,
      fontSizeContent,
      fontSizeTitle,
      maxWidth,
      maxHeight,
    } = this.props;

    //types
    const {
      isNumber,
      noBox,
      brackets,
      flexDirectionColumn,
      center,
      centerMobile,
      stepByStep,
      noDoubleArrow,
      fullBracketsCard,
      ordinaryCoverage,
      right,
      accordion,
      justifyContentCenter,
      newsHomePage,
      fullColumns,
      styled,
    } = this.props;

    if (newsHomePage) {
      return (
        <NewsHomePageCard>
          <a href={`/noticias/${data.id}`}>
            <ImageNews
              className={'mb-2 mb-lg-3'}
              img={`${baseURL()}/api/file/${data.file.id}/${data.file.slug}`}
            />
          </a>
          <ParagraphNews>
            {data.hashtag.map((hashtag, i) => {
              return `#${hashtag}\n`;
            })}
          </ParagraphNews>
          <TitleNews>{data.title}</TitleNews>
          <ParagraphNews>{data.postingDate}</ParagraphNews>
          <LinkContainer>
            <LinkNews href={`/noticias/${data.id}`}>Ler mais</LinkNews>
          </LinkContainer>
        </NewsHomePageCard>
      );
    }

    if (ordinaryCoverage) {
      return (
        <OrdinaryCoverageCard className={'ordinary-coverage-card'}>
          {!right && (
            <Row>
              <Col>
                <OrdinaryCoverageIconBracket className={'icon-bracket'} />
              </Col>
            </Row>
          )}
          <Row>
            <OrdinaryCoverageNumberTitle>{number}</OrdinaryCoverageNumberTitle>
            <Col xs={11} className={'align-self-center'}>
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
            </Col>

            <Col xs={{ span: 11, offset: 1 }} className={'align-self-center'}>
              {accordion && (
                <Row className={'flex-column'}>
                  {data.map((d, index) => {
                    return (
                      <Col key={index} className={'ordinary-coverage'}>
                        <Accordion
                          expandOnStart
                          textColor={colors.primaryThree}
                          id={index.toString()}
                          summary={<Text labelAcordeonFaq text={d.question} />}
                          details={<Text paragraph3 text={d.answer} />}
                        />
                      </Col>
                    );
                  })}
                </Row>
              )}
            </Col>
          </Row>
          {right && (
            <Row>
              <Col className={'d-flex justify-content-end'}>
                <OrdinaryCoverageIconBracket right className={'icon-bracket__left'} />
              </Col>
            </Row>
          )}
        </OrdinaryCoverageCard>
      );
    }

    if (fullBracketsCard) {
      return (
        <div className={'d-flex'}>
          <LeftBracketIcon />
          <Row>
            <Col className={'pe-0 pe-md-3'}>
              <FullBracketsTitle>{title}</FullBracketsTitle>
              <FullBracketsSubTitle>{subtitle}</FullBracketsSubTitle>
              <FullBracketsDescription
                className={'mt-2'}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
              />
            </Col>
          </Row>
        </div>
      );
    }

    if (brackets) {
      return (
        <div className={'brackets-card'}>
          <Row>
            <Col>
              <IconBracket className={'icon-bracket'} />
            </Col>
          </Row>
          <Row>
            <Col>
              <BracketsCard>
                {isNumber ? (
                  <Row>
                    <Col>
                      <NumberTitle>{number}</NumberTitle>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
                {title ? (
                  <Row>
                    <Col>
                      <Title
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}></Title>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
                <Row>
                  <Col className={'ps-0 px-lg-3 column'}>
                    <Content
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}></Content>
                  </Col>
                </Row>
              </BracketsCard>
            </Col>
          </Row>
          <Row>
            <Col className={'d-flex justify-content-end'}>
              <IconBracket right className={'icon-bracket__left'} />
            </Col>
          </Row>
        </div>
      );
    }

    if (noBox) {
      return (
        <>
          {data.map((item, index) => {
            return (
              <Row
                key={index}
                className={`${item.marginBottom ? item.marginBottom : ''} ${
                  flexDirectionColumn ? 'flex-column' : 'flex-row'
                }`}
                style={{ maxWidth: fullColumns ? '204px' : '100%' }}>
                <ColStyled
                  xs={12}
                  lg={fullColumns ? 12 : 4}
                  xl={fullColumns ? 12 : 3}
                  className={`d-flex mb-3 pb-1 ${
                    justifyContentCenter ? 'justify-content-center' : 'justify-content-start'
                  }`}
                  styled={styled}
                  style={{ minHeight: '80px' }}>
                  <Icon
                    backgroundImage={item.icon}
                    width={item.imgWidth}
                    height={item.imgHeight}
                    fullColumns={fullColumns}></Icon>
                </ColStyled>
                <Col xs={12} lg={fullColumns ? 12 : 8}>
                  <Title
                    className={'mini-card-title'}
                    fontSizeTitle={fontSizeTitle}
                    center={center}
                    centerMobile={centerMobile}>
                    {item.title}
                  </Title>
                  <ContentNoBox
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item.content),
                    }}
                    center={center}
                    centerMobile={centerMobile}
                    fontSizeContent={fontSizeContent}></ContentNoBox>
                </Col>
              </Row>
            );
          })}
        </>
      );
    }

    if (stepByStep) {
      return (
        <StepByStepCard maxWidth={maxWidth} maxHeight={maxHeight}>
          <Row>
            <Col>
              <StepIconBracket />
            </Col>
          </Row>
          <Row className={'justify-content-between mb-2 mb-lg-4'}>
            <Col md={3}>
              <StepNumberTitle>{number}</StepNumberTitle>
            </Col>
            {!noDoubleArrow && (
              <Col md={2} className={'d-none d-lg-flex justify-content-end'}>
                <DoubleArrowIcon />
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <StepTitle
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}></StepTitle>
            </Col>
          </Row>
          <Row>
            <Col>
              <StepContent>{content}</StepContent>
            </Col>
          </Row>
          {!noDoubleArrow && (
            <RowStep>
              <Col className={'d-flex d-lg-none justify-content-end'}>
                <DoubleArrowIcon />
              </Col>
            </RowStep>
          )}
          <Row>
            <Col>
              <StepIconBracket right />
            </Col>
          </Row>
        </StepByStepCard>
      );
    }

    return (
      <SimpleCard>
        <Row>
          <Col md={3}>
            {isNumber ? <NumberTitle>{number}</NumberTitle> : <img src={src} width="61"></img>}
          </Col>
        </Row>
        <Row>
          <Col>
            <Title dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}></Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <Content>{content}</Content>
          </Col>
        </Row>
      </SimpleCard>
    );
  }
}

MiniCard.propTypes = {
  // props
  src: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  number: PropTypes.number,
  data: PropTypes.any,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  fontSizeContent: PropTypes.number,
  fontSizeTitle: PropTypes.number,

  // types
  isNumber: PropTypes.bool,
  noBox: PropTypes.bool,
  stepByStep: PropTypes.bool,
  noDoubleArrow: PropTypes.bool,
  brackets: PropTypes.bool,
  flexDirectionColumn: PropTypes.bool,
  center: PropTypes.bool,
  centerMobile: PropTypes.bool,
  fullBracketsCard: PropTypes.bool,
  ordinaryCoverage: PropTypes.bool,
  right: PropTypes.bool,
  accordion: PropTypes.bool,
  justifyContentCenter: PropTypes.bool,
  newsHomePage: PropTypes.bool,
  fullColumns: PropTypes.bool,
  styled: PropTypes.string,
};

export default MiniCard;
