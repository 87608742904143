import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import {
  CardContainer,
  CardInfoContainer,
  CardPageContainer,
  ImgCard,
  ImgCardPage,
  Title,
  TitlePage,
  SubTitle,
  Hashtag,
  TagDate,
} from './styles';

class Card extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      info,
      searchInfo,
      searchPage,
      id,
      title,
      content,
      hashtag,
      image,
      date,
      less,
      history,
      url,
    } = this.props;

    if (info) {
      return (
        <CardInfoContainer less={less} onClick={() => history.push('/informacoes/' + id)}>
          <ImgCard info={info} img={image} />
          <TagDate info={info}>{date}</TagDate>
          <Title info={info}>{title}</Title>
          <SubTitle dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}></SubTitle>
          <NavLink exact to={'/informacoes/' + id}>
            Acesse
          </NavLink>
        </CardInfoContainer>
      );
    }

    if (searchInfo) {
      return (
        <CardContainer less={less} onClick={() => history.push('/informacoes/' + id)}>
          <ImgCard info={info} img={image} />
          <TagDate info={info}>{date}</TagDate>
          <Title info={searchInfo}>{title}</Title>
          <SubTitle
            info={searchInfo}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}></SubTitle>
          <NavLink exact to={'/informacoes/' + id}>
            Acesse
          </NavLink>
        </CardContainer>
      );
    }

    if (searchPage) {
      return (
        <CardPageContainer less={less} onClick={() => history.push(url)}>
          <ImgCardPage img={image} />
          <TitlePage>{title}</TitlePage>
          <SubTitle dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}></SubTitle>
          <NavLink exact to={url}>
            Acesse
          </NavLink>
        </CardPageContainer>
      );
    }

    return (
      <CardContainer less={less} onClick={() => history.push('/noticias/' + id)}>
        <ImgCard img={image} />
        <TagDate>{date}</TagDate>
        {hashtag.length > 0 ? (
          <Hashtag>
            {hashtag.map((hashtag, i) => {
              return `#${hashtag}\n`;
            })}
          </Hashtag>
        ) : (
          <Hashtag>
            <br />
          </Hashtag>
        )}
        <Title>{title}</Title>
        <NavLink exact to={'/noticias/' + id}>
          Acesse
        </NavLink>
      </CardContainer>
    );
  }
}

Card.propTypes = {
  // props
  id: PropTypes.number,
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  less: PropTypes.bool,
  hashtag: PropTypes.array,
  url: PropTypes.string,
  date: PropTypes.string,
  history: PropTypes.object,

  // types
  info: PropTypes.bool,
  searchInfo: PropTypes.bool,
  searchPage: PropTypes.bool,
};

export default Card;
