const { dataFormParse } = require('../utils/dataFormParse');
const { axiosDefault, handleErrors } = require('../utils/defaults');

const userService = {

  admin: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/user/${id ? id : ''}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async () => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/user/gestao`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    save: async (user) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.post(`/api/user/gestao`, dataFormParse(user));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    edit: async (user) => {
      try {
        const axios = axiosDefault();

        const { data } = await axios.put(`/api/user/gestao/${user.id}`, dataFormParse(user));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    delete: async (id) => {
      try {
        const axios = axiosDefault();

        const { data } = await axios.delete(`/api/user/gestao/${id}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },

  user: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/user/${id ? id : ''}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  
    getUserData: async (id, email) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/user/update-session/${id}/${email}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  }
};

export default userService;
