import styled from 'styled-components';
import { Col as BCol, Row } from 'react-bootstrap';
import { colors } from '../../utils/colors';
import { bootstrapMin } from '../../utils/devices';

import logo from '../../assets/logos/FGCoop-light.svg';
import imgLinkedin from '../../assets/icons/social_media/linkedin-light.svg';
import imgFacebook from '../../assets/icons/social_media/facebook-light.svg';
import imgInstagram from '../../assets/icons/social_media/instagram-light.svg';

export const Footer = styled.div`
  background-color: ${colors.primaryOne};
`;

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 34px 35px 0;
  margin: 0px 0 0;

  > div {
    width: 100%;
    max-width: 1160px;

    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 27px;
  }

  @media ${bootstrapMin.lg} {
    padding: 64px 71px 0;

    > div {
      padding: 0 36px 42px;
    }
  }
`;

export const SecondaryContainer = styled(MainContainer)`
  background-color: #0000001a;
  padding: 0;
  margin: 0;

  > div {
    padding: 23px 0;
  }
`;

export const Col = styled(BCol)`
  display: flex;
  justify-content: center;
`;

export const Logo = styled.a`
  div {
    width: 124px;
    height: 36px;
    background-image: url(${logo});
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
  }
`;

export const ContainerSocialMedia = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 60px;

  @media ${bootstrapMin.lg} {
    margin-top: 35px;
    gap: 30px;
  }
`;

export const IconSocialMedia = styled.div`
  background-image: url(${({ facebook, instagram }) => {
    if (facebook) return imgFacebook;
    else if (instagram) return imgInstagram;
    else return imgLinkedin;
  }});
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;

  @media ${bootstrapMin.lg} {
    width: 19px;
    height: 19px;
  }
`;

export const Paragraph = styled.p`
  font-size: 12px;
  color: ${colors.white};
  line-height: 20px;
  margin: 0;
`;

export const ColStyled = styled(Col)`
  gap: 14px;
  @media ${bootstrapMin.lg} {
    width: 29.9%;
    gap: 19px;
  }
`;

export const DivStyled = styled.div`
  @media ${bootstrapMin.lg} {
    margin-bottom: -8px;
  }
`;

export const RowStyled = styled(Row)`
  @media ${bootstrapMin.xl} {
    gap: 10px;
  }
`;

export const Message = styled.div`
  margin-top: -10px;

  @media ${bootstrapMin.lg} {
    margin-top: -15px;
  }
`;
