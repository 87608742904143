import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './styles.css';
import cooperativeService from '../../services/cooperatives';

class TableCooperatives extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cooperatives: [],
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const response = await cooperativeService.user.getList({});
      if (response.ok) {
        this.setState({ cooperatives: response.data, loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      this.setState({ loading: false });
    }
  }

  render() {
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: 'name',
        text: 'NOME',
      },
      {
        dataField: 'sigla',
        text: 'SIGLA',
        headerClasses: 'notranslate',
      },
    ];

    const { cooperatives, loading } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <ToolkitProvider keyField="id" data={cooperatives} columns={columns} search>
        {(props) => (
          <div>
            <h4>Buscar por nome ou sigla:</h4>
            <SearchBar {...props.searchProps} />
            <hr />
            <BootstrapTable
              pagination={paginationFactory()}
              striped
              bootstrap4
              {...props.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default TableCooperatives;
