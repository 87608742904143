import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';

export const Header = styled.div``;

export const HeaderMobile = styled.div`
  margin-top: 65px;
`;

export const Content = styled.div`
  img:not(.news-quote-left img) {
    max-width: 100%;
    height: 100%;

    @media ${bootstrapMax.xs} {
      &.quote1 {
        max-width: 25px !important;
      }
      &.quote2 {
        margin-left: -35px !important;
        max-width: 25px !important;
      }
    }
  }
  .col-quote1 {
    margin-left: 60px;
    width: 47px;
  }
  .col-quote2 {
    margin-right: 80px;
    width: 47px;
    justify-content: end;
  }
  .col-text {
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .news-quote-text {
    font-size: 24px;
    line-height: 34px;
  }
  p {
    font-size: 20px;
    line-height: 30px;
    white-space: break-spaces;
    overflow-wrap: break-word;
  }
  @media ${bootstrapMax.xs} {
    .col-quote1 {
      margin-left: 0px;
    }
    .col-quote2 {
      margin-right: 0px;
    }
    .col-text {
      margin-top: 0px;
    }
    .news-quote-text {
      font-size: 20px;
      line-height: 27px;
    }
  }
`;

export const ColStyled = styled(Col)`
  gap: 0 20px;

  @media ${bootstrapMin.xl} {
    gap: 0 68px;
  }
`;
