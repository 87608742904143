import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';
import { Col, Row } from 'react-bootstrap';

import bannerAssociates from '../../../assets/imgs/banner-associates.png';
import logoSicoob from '../../../assets/logos/sicoob-logo.png';
import logoSicredi from '../../../assets/logos/sicredi-logo.png';

export const MeetOurBackgroundContainer = styled.div`
  .recipes,
  .guaranteed-deposits {
    @media ${bootstrapMin.xl} {
      max-width: 77%;
    }
  }

  .guaranteed-deposits {
    p {
      @media ${bootstrapMax.lg} {
        margin-bottom: 30px !important;
      }
    }
  }

  & .meet-our-background:first-child {
    & .MuiAccordionSummary-root {
      padding-top: 0 !important;
      margin-top: 5px;

      @media ${bootstrapMax.sm} {
        margin-top: -5px;
      }
    }
  }

  @media ${bootstrapMax.sm} {
    padding: 0 15px;
  }

  @media (max-width: 354px) {
    .adjust-width-mobile {
      width: 100%;
    }
  }
`;

export const ContainerAssociates = styled.div`
  height: 550px;

  @media (max-width: 354px) {
    height: 750px;
  }

  @media ${bootstrapMin.xl} {
    height: 332px;
  }
`;
export const BannerAssociates = styled.div`
  background-color: ${colors.primaryTwo};
  position: absolute;
  width: 100vw;
  height: 550px;
  margin-left: -50vw;
  left: 49.99%;
  z-index: -1;

  @media (max-width: 354px) {
    height: 750px;
  }

  @media ${bootstrapMin.xl} {
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url(${bannerAssociates});
    background-size: 1366px 332px;
    height: 332px;
  }
`;

export const RowStyled = styled(Row)`
  padding-top: 30px;

  @media ${bootstrapMin.lg} and ${bootstrapMax.xl} {
    padding-top: 100px;
  }

  @media ${bootstrapMin.xl} {
    padding-top: 75px;
    margin: 0 -30px;
  }
`;

export const ColStyled = styled(Col)`
  @media ${bootstrapMax.md} {
    gap: 10px;
  }
`;
