import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../utils/devices';
import { Col, Row } from 'react-bootstrap';

import download_file from '../../assets/icons/download-file.png';

export const RowStyled = styled(Row)`
  .green-button {
    height: 39px;

    @media ${bootstrapMax.lg} {
      min-width: 97px;
      height: 33px;
    }
  }
`;

export const DocumentCard = styled.div`
  background-color: ${colors.supportOne};
  border-radius: 4px;
  align-self: center;
  padding: 20px;

  @media ${bootstrapMin.lg} {
    padding: 0 35px;
  }
`;

export const ColStyled = styled(Col)`
  align-self: center;
`;

export const DownloadFileIcon = styled.div`
  background-image: url(${download_file});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 19px;
  height: 65px;
  width: 44px;

  @media ${bootstrapMin.lg} {
    height: 78px;
    width: 53px;
  }
`;

export const Heading3 = styled.h3`
  font-family: 'Trade Gothic LT Bold';
  line-height: 25px;
  font-size: 20px;
  color: ${colors.primaryThree};

  @media ${bootstrapMin.lg} {
    line-height: 40px;
    font-size: 26px;
  }
`;

export const DownloadContent = styled.a`
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: ${colors.primaryThree};

  &:hover {
    color: ${colors.primaryTwo};
  }
`;

export const Paragraph = styled.p`
  color: ${colors.primaryThree};
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 22px;

  @media ${bootstrapMin.lg} {
    font-size: 16px;
    line-height: 24px;
  }
`;
