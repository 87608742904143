import styled from 'styled-components';
import { bootstrapMax, bootstrapMin } from '../../utils/devices';
import arrow from '../../assets/icons/arrow-accordion@2x.png';
import arrowBlue from '../../assets/icons/arrow-blue.png';
import arrow_light from '../../assets/icons/arrow-light.png';
import ReactCarousel from 'react-elastic-carousel';
import { colors } from '../../utils/colors';

export const Carousel = styled(ReactCarousel)`
  .rec-slider-container {
    margin: 0;
  }

  button.rec-dot {
    border: 1px solid ${colors.primaryOne};
    box-shadow: none;
    margin-left: 12px;
    margin-right: 12px;
    width: 11px;
    height: 11px;
  }

  button.rec-dot_active {
    border: 1px solid ${colors.primaryOne};
    background-color: ${colors.primaryOne};
    box-shadow: none;
    width: 11px;
    height: 11px;
  }

  @media (min-width: 1187px) {
    button.rec-dot {
      display: none;
    }

    ${({ showArrows }) =>
      showArrows
        ? ''
        : `.rec-item-wrapper {
    display: flex;
  }
  .rec-carousel-item-0 .rec-item-wrapper {
    justify-content: start;
  }
  .rec-carousel-item-2 .rec-item-wrapper {
    justify-content: end;
  }
  @media ${bootstrapMax.lg} {
    .rec-carousel-item-0 .rec-item-wrapper {
      justify-content: center;
    }
    .rec-carousel-item-2 .rec-item-wrapper {
      justify-content: center;
    }
  }`}
  }
`;

export const Objectives = styled.div`
  max-width: 1000px;

  > p {
    margin: 0 35px;

    @media ${bootstrapMax.sm} {
      margin: unset;
    }
  }
`;

export const Objective = styled.div`
  width: 172px;
  height: 172px;
  background-image: url(${({ img }) => img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media ${bootstrapMax.md} {
    width: 200px;
    height: 200px;
  }
`;

export const ArrowSlider = styled.div`
  width: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  > div {
    width: ${({ showArrows }) => (showArrows ? 15 : 13)}px;
    height: ${({ showArrows }) => (showArrows ? 15 : 8)}px;
    background-image: url(${arrow});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(${({ next }) => (next ? 90 : 270)}deg);

    @media (min-width: 1087px) {
      ${({ showArrows }) => !showArrows && 'display: none;'}
    }

    @media (max-width: 1087px) {
      width: 13px;
      height: 8px;
    }
  }
`;

export const CarouselStyled = styled(ReactCarousel)`
  & .rec-slider-container {
    margin: 0 -21px;
  }
`;

export const ArrowSliderStyled = styled(ArrowSlider)`
  width: 21px;
  position: relative;
  z-index: 1;
  ${({ next }) => (next ? 'right: 18px;' : 'left: 18px;')}

  > div {
    transform: rotate(${({ next }) => (next ? 180 : 0)}deg);
    background-image: url(${({ arrowType }) => (arrowType === 'home' ? arrow_light : arrowBlue)});
    width: 11px;
    height: 20px;

    @media ${bootstrapMin.lg} {
      width: 21px;
      height: 41px;
    }

    @media (min-width: 1087px) {
      display: block !important;
    }
  }

  @media ${bootstrapMin.lg} {
    ${({ next }) => (next ? 'right: 42px;' : 'left: 42px;')}
  }
`;
