import styled from 'styled-components';
import { bootstrapMax } from '../../../utils/devices';

export const Container = styled.div`
  & .row:first-child {
    & .MuiAccordionSummary-root {
      padding-top: 0 !important;
      margin-top: 1px;

      @media ${bootstrapMax.sm} {
        margin-top: -10px;
      }
    }
  }

  @media ${bootstrapMax.sm} {
    padding: 0 15px;
  }
`;
