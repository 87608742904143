import React, { Component } from 'react';
import { ContainerDoubt, MiniCard, Screen, SubHeader, Table, Text } from '../../../components';
import { Col, Row } from 'react-bootstrap';

import PropTypes from 'prop-types';
const { defaultStates } = require('../../../utils/defaults');

import {
  AccompanimentBanner,
  AccompanimentContainer,
  OrdinaryCoverageContainer,
  RowStyled,
} from './styles';

import { coverageLimit, depositCoverage, depositCoverageTotal } from '../../../enums';

import bannerMobile from '../../../assets/imgs/banner-ordinary-coverage-mobile.png';
import banner from '../../../assets/imgs/banner-ordinary-coverage.png';

class OrdinaryCoverage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      innerWidth: window.innerWidth,
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  updateSize = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  header = () => {
    return (
      <>
        <SubHeader
          image
          backgroundImage={banner}
          backgroundImageMobile={bannerMobile}
          minHeightSm={452}
          minHeight={313}
          title={'Cobertura ordinária'}
        />
      </>
    );
  };

  body = () => {
    const columns = [
      {
        headerName: 'Cooperativa liquidada',
        field: 'cooperative',
        flex: 0.3,
        headerAlign: 'center',
      },
      {
        headerName: 'Decretação de Regime Especial',
        field: 'decreeOfSpecialRegime',
        flex: 0.35,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: 'Início dos pagamentos',
        field: 'startOfPayment',
        flex: 0.3,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: 'Valores pagos',
        field: 'totalPayments',
        flex: 0.3,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: 'Saldo a pagar',
        field: 'balanceToPay',
        flex: 0.2,
        headerAlign: 'center',
        align: 'center',
      },
    ];

    return (
      <OrdinaryCoverageContainer className={'mb-lg-5 pb-lg-5'}>
        <Row className={'mb-3 mb-lg-5'}>
          <Col className={'d-flex justify-content-center mb-lg-3'}>
            <Text
              heading2
              noWrap
              textAlign={'center'}
              title={{ one: 'SAIBA MAIS SOBRE O LIMITE DE COBERTURA' }}
            />
          </Col>
        </Row>
        <Row className={'row-styled'}>
          {coverageLimit.map((coverage, index) => {
            return (
              <MiniCard
                ordinaryCoverage
                key={index}
                content={coverage.content}
                data={coverage.examples}
                accordion={coverage.examples ? true : false}
                number={index + 1}
                right={(index + 1) % 2 ? false : true}
              />
            );
          })}
        </Row>
        <AccompanimentContainer>
          <AccompanimentBanner />
          <RowStyled>
            <Col>
              <Text heading2 noWrap textAlign={'center'} title={{ one: 'ACOMPANHAMENTO' }} />
              <div style={{ marginBottom: '-5px' }}>
                <Text
                  paragraph3
                  center
                  text={
                    'Coberturas de depósitos realizadas pelo FGCoop desde a sua fundação, em 2014.'
                  }
                />
              </div>
              <div className={'d-none d-xl-flex'}>
                <Table
                  noIdData
                  onCellClick={() => {}}
                  data={depositCoverage}
                  columns={columns}
                  pageSize={4}
                  totalizerInFooter
                  totalizerData={depositCoverageTotal}
                />
              </div>
              <div className={'d-flex d-xl-none'}>
                <Table
                  noIdData
                  onCellClick={() => {}}
                  data={depositCoverage}
                  columns={columns}
                  pageSize={4}
                  totalizerInFooter
                  totalizerData={depositCoverageTotal}
                />
              </div>
            </Col>
          </RowStyled>
        </AccompanimentContainer>
        <div className={'mt-4 pt-3 mt-lg-5 pt-4 mb-4 pb-2 mb-lg-0 pb-lg-0'}>
          <ContainerDoubt />
        </div>
      </OrdinaryCoverageContainer>
    );
  };

  render = () => {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  };
}

OrdinaryCoverage.propTypes = {
  //props
  history: PropTypes.object,
};
export default OrdinaryCoverage;
