const { axiosDefault, handleErrors } = require('../utils/defaults');

const contactsService = {
  save: async (contacts) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.post(`/api/contacts`, contacts);

      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default contactsService;
