import imageCompression from 'browser-image-compression';
const ImageCompression = {
  imageCompress: async (data) => {
    let imageFile = null;
    if (data) {
      imageFile = data[0];
    }

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    console.log(`originalFile size ${imageFile?.size / 1024 / 1024} MB`);
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);

      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  },
};

export default ImageCompression;
