import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const Title = styled.h2`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryThree};
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0;

  @media ${bootstrapMin.lg} {
    font-size: 26px;
    line-height: 40px;
  }
`;

export const WorkeTypography = styled.p`
   {
    font-family: 'Trade Gothic LT';
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    margin: 20px;
    background-color: #4997d0;
    width: auto;
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 600;
    display: flex;
    justify-content: center;
  }
  @media ${bootstrapMin.lg} {
    font-size: 30px;
  }
`;

export const Textsbody = styled.p`
   {
    font-family: 'Trade Gothic LT';
    text-align: start;
    font-size: 14px;
    margin-top: 15px;
    padding: 10px;
  }
  @media ${bootstrapMin.lg} {
    font-size: 21px;
  }
`;

export const Workeimg = styled.img`
   {
    height: 100%;
  }
`;

export const Certificate = styled.img`
   {
    width: 20%;
    margin-bottom: 20px;
  }
  @media ${bootstrapMax.lg} {
    width: 40%;
  }
`;

export const StyledLink = styled(Link)`
   {
    font-family: 'Trade Gothic LT';
    cursor: pointer;
    margin-top: 10px;
    font-weight: bold;
  }
`;
export const Programs = styled.div`
   {
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
  }

  @media ${bootstrapMax.lg} {
    flex-direction: column-reverse;
  }
`;

export const Programsimg = styled.img`
   {
    width: 10%;
    margin-right: 20px;
  }
  @media ${bootstrapMax.lg} {
    width: 30%;
  }
`;

export const ProgramsText = styled.p`
   {
    font-family: 'Trade Gothic LT';
    font-size: 15px;
    text-align: start;
    line-height: 24px;
    margin: 20px;
  }
  @media ${bootstrapMin.lg} {
    font-size: 20px;
  }
`;

export const RowFinancial = styled(Row)`
  @media ${bootstrapMin.lg} {
    gap: 8%;
  }
`;
export const ColFinancial = styled(Col)`
  @media ${bootstrapMin.lg} {
    width: 28%;
  }
`;

export const WorkContainer = styled.div`
  @media ${bootstrapMax.sm} {
    & .MuiButtonBase-root {
      font-size: 15.5px !important;
    }

    & .container-doubt {
      margin: 0 -20px;

      & .col-doubt {
        padding-right: 0;
      }
    }
  }

  @media ${bootstrapMax.lg} {
    padding: 0 12px;
  }
`;

export const RowStyled = styled(Row)`
  @media ${bootstrapMax.sm} {
    padding: 0 8px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`;
