import React, { Component, useCallback, useRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import contactsService from '../../../services/contacts';
import captchaService from '../../../services/captcha';
import { Button, Input, Screen, SubHeader, Text } from '../../../components';
import { maskPhone } from '../../../utils/helpers';
import ReCAPTCHA from 'react-google-recaptcha';

import PropTypes from 'prop-types';
import {
  ContactContainer,
  ColStyled,
  ContainerSocialMedia,
  IconSocialMedia,
  Map,
  ButtonContainer,
} from './styles';
const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
} = require('../../../utils/defaults');

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      },
      labelButton: 'Enviar',
      success: false,
      error: false,
      recaptcha: false,
      ...defaultStates(),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.header = this.header.bind(this);
    this.body = this.body.bind(this);
  }

  modalOnHide = () => {
    defaultModalOnHide(this);
  };

  alertScreenOnClick = () => {
    defaultAlertScreenOnClick(this);
  };

  handleSubmit = async () => {
    this.setState({ loadingState: true });
    const { contact } = this.state;
    const { recaptcha } = this.state;
    let labelButton = '';
    let success = false;
    let error = false;

    if (recaptcha) {
      const response = await contactsService.save(contact);
      if (response.ok) {
        const alertScreenState = {
          title: 'Mensagem enviada com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/contato',
        };
        labelButton = 'Enviada!';
        success = true;

        this.setState({ alertScreenState, labelButton, success, error });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        labelButton = 'Erro';
        error = true;

        this.setState({ modalState, labelButton, success, error });
      }
    } else {
      const modalState = {
        title: 'Erro',
        text: 'reCAPTCHA não foi verificado!',
        toggle: true,
        onHide: this.modalOnHide,
      };
      labelButton = 'Erro';
      error = true;
      this.setState({ modalState, labelButton, success, error });
    }
    this.setState({ loadingState: false });
  };

  handleChange = (field, event) => {
    const { contact } = this.state;

    const value = event.target
      ? field === 'phone'
        ? maskPhone(event.target.value)
        : event.target.value
      : event;

    contact[field] = value;
    this.setState({ contact });
  };

  handleChangeCaptcha = async (token) => {
    const response = await captchaService.checkCaptcha(token);
    if (response.data.success === true) {
      this.setState({ recaptcha: true });
    } else {
      this.setState({ recaptcha: false });
    }
  };

  header = () => {
    return <SubHeader backgroundColor={'none'} title={'Fale conosco'} />;
  };

  body = () => {
    const { contact, success, error, labelButton, recaptcha } = this.state;

    return (
      <ContactContainer className={'mb-lg-5 pb-lg-5'}>
        <div className={'d-flex flex-column align-items-center d-lg-block'}>
          <Text
            paragraph3
            text={`Envie suas dúvidas ou perguntas. <br> Será um prazer atendê-lo.`}
          />
        </div>
        <Row>
          <ColStyled
            lg={6}
            className={'d-flex flex-column align-items-center mb-4 pb-3 mb-lg-0 pb-lg-0'}>
            <Input
              rounded
              border
              required
              id={'name'}
              value={contact.name}
              placeholder={'NOME'}
              fontSize={14}
              maxLength={255}
              width={460}
              height={48}
              onChange={(e) => {
                this.handleChange('name', e);
              }}
              error={error && contact.name === ''}
            />
            <Input
              rounded
              border
              required
              id={'email'}
              type={'email'}
              value={contact.email}
              placeholder={'E-MAIL'}
              fontSize={14}
              maxLength={255}
              width={460}
              height={48}
              onChange={(e) => {
                this.handleChange('email', e);
              }}
              error={error && contact.email === ''}
            />
            <Input
              rounded
              border
              required
              id={'phone'}
              value={contact.phone}
              placeholder={'TELEFONE'}
              fontSize={14}
              maxLength={15}
              width={460}
              height={48}
              onChange={(e) => {
                this.handleChange('phone', e);
              }}
              error={error && contact.phone === ''}
            />
            <Input
              rounded
              border
              required
              id={'subject'}
              value={contact.subject}
              placeholder={'ASSUNTO'}
              fontSize={14}
              maxLength={255}
              width={460}
              height={48}
              onChange={(e) => {
                this.handleChange('subject', e);
              }}
              error={error && contact.subject === ''}
            />
            <Input
              rounded
              border
              required
              textarea
              id={'message'}
              value={contact.message}
              placeholder={'MENSAGEM'}
              fontSize={14}
              width={460}
              height={48}
              onChange={(e) => {
                this.handleChange('message', e);
              }}
              error={error && contact.message === ''}
            />

            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              onChange={(e) => {
                this.handleChangeCaptcha(e);
              }}
            />

            <ButtonContainer>
              <Button
                greenButton
                width={113}
                widthMobile={150}
                text={labelButton}
                onClick={() => {
                  this.handleSubmit();
                }}
                success={success}
                error={error}
              />
            </ButtonContainer>
          </ColStyled>
          <Col
            lg={{ span: 5, offset: 1 }}
            className={
              'd-flex flex-column align-items-center d-lg-block mb-4 pb-2 mb-lg-4 pb-lg-2'
            }>
            <ContainerSocialMedia className={'mb-4 pb-2'}>
              <a
                href={'https://www.linkedin.com/company/fgcoop/'}
                target={'_blank'}
                rel={'noreferrer noopener'}>
                <IconSocialMedia />
              </a>
              <a
                href={'https://www.facebook.com/pg/FGCoop-114626913731407/posts/'}
                target={'_blank'}
                rel={'noreferrer noopener'}>
                <IconSocialMedia facebook />
              </a>
              <a
                href={'https://www.instagram.com/fgcoop.coop/'}
                target={'_blank'}
                rel={'noreferrer noopener'}>
                <IconSocialMedia instagram />
              </a>
            </ContainerSocialMedia>
            <div className={'mb-4'}>
              <Text
                paragraph3
                text={
                  'Telefone: 55 61 3224-0449 <br> Endereço: Edifício Parque Cidade, SCS <br> Quadra 9, Torre C, Sala 502, CEP 70308-200 <br>'
                }
              />
            </div>
            <a
              href={
                'https://www.google.com.br/maps/place/Edif%C3%ADcio+Parque+Cidade+Corporate/@-15.7904824,-47.9151836,15z/data=!4m6!3m5!1s0x935a3aef14e81067:0xe379c9b6bf767930!8m2!3d-15.7951012!4d-47.8934468!16s%2Fg%2F11bc6zb9kc?entry=ttu'
              }
              target={'_blank'}
              rel="noreferrer">
              <Map />
            </a>
          </Col>
        </Row>
      </ContactContainer>
    );
  };

  render = () => {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  };
}

Contact.propTypes = {
  //props
  history: PropTypes.object,
};
export default Contact;
