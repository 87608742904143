import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Tabs, Screen, SubHeader, Text, MiniCard, ContainerDoubt } from '../../../components';

import PropTypes from 'prop-types';
const { defaultStates } = require('../../../utils/defaults');
import {
  financialInstrumentsContents,
  excludedInstrumentsContents,
  proceduresContents,
} from '../../../enums/index';

import {
  Title,
  TitleWhite,
  TitleProcedures,
  RowFinancial,
  ColFinancial,
  BannerLimitValue,
  ContainerLimitValue,
  GuaranteesContainer,
  RowStyled,
} from './styles';

import banner from '../../../assets/imgs/banner-guarantees.png';
import bannerMobile from '../../../assets/imgs/banner-guarantees-mobile.png';

class Guarantees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      innerWidth: window.innerWidth,
      valueTab: 0,
      contents: [],
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  async componentDidMount() {
    await this.tabsContent();
  }

  async updateSize() {
    this.setState({ innerWidth: window.innerWidth });
  }

  header() {
    return (
      <SubHeader
        image
        backgroundImage={banner}
        backgroundImageMobile={bannerMobile}
        minHeightSm={452}
        minHeight={313}
        title={'Conheça as garantias'}
      />
    );
  }

  financialInstrumentsData = () => {
    return (
      <>
        <Title className={'mb-4 pb-1 pb-lg-3'}>INSTRUMENTOS FINANCEIROS OBJETO DE GARANTIA:</Title>
        <RowFinancial>
          {financialInstrumentsContents.map((financialInstrument, index) => {
            return (
              <ColFinancial key={index} xs={12} lg={3}>
                {financialInstrument.contents.map((content, i) => {
                  return (
                    <div key={i}>
                      <Text paragraph4 borderLeft fontSizeMobile={14} text={content.paragraph} />
                    </div>
                  );
                })}
              </ColFinancial>
            );
          })}
        </RowFinancial>
      </>
    );
  };

  excludedInstrumentsData = () => {
    return (
      <>
        <Title className={'mb-4 pb-1 pb-lg-3'}>INSTRUMENTOS EXCLUÍDOS DA GARANTIA</Title>
        <Row className={'justify-content-lg-between'}>
          {excludedInstrumentsContents.map((excludedInstrument, index) => {
            return (
              <Col key={index} lg={index ? 6 : 5}>
                {excludedInstrument.contents.map((content, i) => {
                  return (
                    <div key={i}>
                      <Text paragraph4 borderLeft text={content.paragraph} />
                    </div>
                  );
                })}
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  tabsContent = () => {
    this.setState({ contents: [this.financialInstrumentsData(), this.excludedInstrumentsData()] });
  };

  body() {
    return (
      <GuaranteesContainer className={'mb-lg-5 pb-lg-5'}>
        <Row className={'mb-lg-4 pb-lg-2'}>
          <Col className={'d-flex flex-column align-items-center'}>
            <Text heading1 noWrap textAlign={'center'} title={{ one: 'GARANTIAS' }} />
          </Col>
        </Row>

        <Row className={'mb-lg-5 pb-lg-5'}>
          <Col className={'mb-lg-2'}>
            <Tabs
              labels={[
                'Instrumentos financeiros objeto de Garantia',
                'Instrumentos excluídos da Garantia',
              ]}
              contents={this.state.contents}
            />
          </Col>
        </Row>

        <ContainerLimitValue>
          <BannerLimitValue />
          <Row className={'pt-lg-5 pt-4'}>
            <Col xs={8} lg={6} className={'pt-2'}>
              <TitleWhite className={'mb-lg-4 pb-2'}>VALOR LIMITE DA GARANTIA</TitleWhite>
              <Text
                paragraph3
                lightColor
                text={`O total de créditos de cada beneficiário contra a mesma instituição associada ao
                FGCoop será garantido até o valor de R$250.000,00.`}
              />
            </Col>
          </Row>
        </ContainerLimitValue>

        <Row className={'pt-4 mt-2 pt-lg-5 mt-lg-5 mb-5 pb-lg-5'}>
          <Col xs={12} className={'pt-lg-4 d-flex flex-column align-items-center'}>
            <TitleProcedures className={'mb-lg-4 pb-lg-1'}>PROCEDIMENTOS</TitleProcedures>
            <Text paragraph3 center text={'Entenda o passo a passo da atuação do FGCoop​​'} />
          </Col>
        </Row>
        <RowStyled className={'mb-lg-3 pb-1 pb-lg-0 px-lg-2'}>
          {proceduresContents.map((procedure, index) => {
            return (
              <Col
                key={procedure.title}
                className={'d-flex justify-content-center mb-3 pb-3 mb-xl-0 pb-xl-0'}
                md={12}
                xl={index > 2 ? 6 : 4}>
                <MiniCard
                  key={index}
                  isNumber
                  stepByStep
                  number={index + 1}
                  title={procedure.title}
                  content={procedure.content}
                  maxWidth={index > 2 ? 523 : 340}
                  maxHeight={index > 2 ? 360 : 382}
                  noDoubleArrow={index + 1 === proceduresContents.length && true}
                />
              </Col>
            );
          })}
        </RowStyled>

        <ContainerDoubt />
      </GuaranteesContainer>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  }
}

Guarantees.propTypes = {
  //props
  history: PropTypes.object,
};
export default Guarantees;
