import styled from 'styled-components';
import { bootstrapMax, bootstrapMin } from '../../utils/devices';
import { colors } from '../../utils/colors';

export const CardContainer = styled.div`
  position: relative;
  padding: 325px 18px 38px;
  border-radius: 24px;
  margin: 0 0 ${({ less }) => (less ? '0 !important;' : '60px')};
  width: 318px;
  height: 502px;
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 10px 10px 0px 0px;

  @media ${bootstrapMax.md} {
    height: 448px;
    margin: 0 0 ${({ less }) => (less ? 0 : 30)}px !important;
    &:nth-of-type(2n + 1) {
      margin-right: unset;
    }
  }

  @media ${bootstrapMax.sm} {
    height: 448px;
    margin: 0 0 ${({ less }) => (less ? 0 : 30)}px !important;
  }

  a {
    font-family: 'Trade Gothic LT';
    color: ${colors.primaryThree};
    font-size: 14px;
    line-height: 16px;
    display: flex;

    &:hover {
      color: ${colors.primaryTwo};
    }

    @media ${bootstrapMax.md} {
      position: absolute;
      bottom: 25px;
      right: 20px;
    }

    @media ${bootstrapMin.md} {
      justify-content: center;
    }
  }
`;

export const Img = styled.img`
  ${({ img }) =>
    img
      ? `
        background-image: url(${img});
        background-repeat: no-repeat;
        background-size: cover;
  `
      : ''}

  ${({ stayIn }) =>
    stayIn
      ? `
        max-width: inherit;
        border-radius: 20px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 20px;
    `
      : `
      height: 60px;
      max-width: 100%;
    `}
`;

export const ImgCard = styled.div`
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  max-height: ${({ info }) => (info ? 196 : 313)}px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export const ImgCardPage = styled(ImgCard)`
  max-height: 105px;
  background-image: url(${({ img }) => img});
  border-radius: 0px;
`;

export const Title = styled.span`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryThree};
  font-size: ${({ info }) => (info ? 18 : 20)}px;
  line-height: ${({ info }) => (info ? 25 : 30)}px;
  min-height: ${({ info }) => (info ? '50px' : '60px')};
  text-transform: ${({ info }) => (info ? 'uppercase' : '')};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: ${({ info }) => (info ? 'unset' : '25px')};
  margin-top: ${({ info }) => (info ? '10px' : '')};

  @media ${bootstrapMax.md} {
    max-width: 220px;
  }
`;

export const TitlePage = styled(Title)`
  color: ${colors.primaryOne};
  font-size: 24px;
  margin-bottom: 12px;
`;

export const SubTitle = styled.span`
  font-family: 'Trade Gothic LT';
  color: ${colors.secondaryTwo};
  font-size: 16px;
  line-height: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: ${({ info }) => (info ? 10 : 25)}px !important;
  margin-top: 10px;
  min-height: 44px;

  @media ${bootstrapMax.md} {
    max-width: 220px;
  }

  & > * {
    margin-bottom: 0;
  }
`;

export const Hashtag = styled.span`
  font-family: 'Trade Gothic LT';
  color: ${colors.gray};
  font-size: 16px;
  line-height: ${({ desc }) => (desc ? 20 : 30)}px;
  min-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 10px !important;
`;

export const TagDate = styled.span`
  background-color: ${colors.secondaryThree};
  font-family: 'Trade Gothic LT';
  color: ${colors.white};
  position: absolute;
  top: 12px;
  left: 0px;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  max-width: 53px;
  min-height: ${({ info }) => (info ? 19 : 30)}px;
  min-width: ${({ info }) => (info ? 76 : 100)}px;
  font-size: ${({ info }) => (info ? 12 : 13)}px;
  padding-top: ${({ info }) => (info ? 2 : 6)}px;
`;

export const CardInfoContainer = styled.div`
  position: relative;
  padding: 200px 18px 20px;
  border-radius: 24px;
  margin: 0 0 ${({ less }) => (less ? '0 !important;' : '60px')};
  width: 248px;
  height: 392px;
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 10px 10px 0px 0px;

  @media ${bootstrapMax.md} {
    width: 328px;
    height: 342px;
    margin: 0 0 ${({ less }) => (less ? 0 : 30)}px !important;
    &:nth-of-type(2n + 1) {
      margin-right: unset;
    }
  }

  @media ${bootstrapMax.sm} {
    width: 328px;
    height: 342px;
    margin: 0 0 ${({ less }) => (less ? 0 : 30)}px !important;
  }

  a {
    font-family: 'Trade Gothic LT';
    color: ${colors.primaryThree};
    font-size: 14px;
    line-height: 16px;
    display: flex;

    &:hover {
      color: ${colors.primaryTwo};
    }

    @media ${bootstrapMax.md} {
      position: absolute;
      bottom: 25px;
      right: 20px;
    }

    @media ${bootstrapMin.md} {
      justify-content: center;
    }
  }
`;

export const CardPageContainer = styled.div`
  position: relative;
  padding: 120px 18px 38px;
  border-radius: 24px;
  margin: 0 0 ${({ less }) => (less ? '0 !important;' : '80px')};
  width: 318px;
  height: 314px;
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 10px 10px 0px 0px;

  @media ${bootstrapMax.md} {
    height: 314px;
    margin: 0 0 ${({ less }) => (less ? 0 : 30)}px !important;
    &:nth-of-type(2n + 1) {
      margin-right: unset;
    }
  }

  @media ${bootstrapMax.sm} {
    height: 314px;
    margin: 0 0 ${({ less }) => (less ? 0 : 30)}px !important;
  }

  a {
    font-family: 'Trade Gothic LT';
    color: ${colors.primaryThree};
    font-size: 14px;
    line-height: 16px;
    display: flex;

    &:hover {
      color: ${colors.primaryTwo};
    }

    @media ${bootstrapMax.md} {
      position: absolute;
      bottom: 25px;
      right: 43%;
    }

    @media ${bootstrapMax.sm} {
      position: absolute;
      bottom: 25px;
      right: 43%;
    }

    @media ${bootstrapMin.md} {
      justify-content: center;
    }
  }
`;
