import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Input, Label } from '../index';
import {
  Nav,
  Header,
  HeaderMobile,
  Logo,
  LogoMenu,
  Ul,
  MenuHeader,
  ScrollList,
  StyledBurger,
  Accordion as StyledAccordion,
  HeaderAccordion,
  Body,
  Item,
  ContainerMenuMobile,
  ContainerTranslate,
} from './styles';
import { Link, NavLink } from 'react-router-dom';
import { Col, Row, Modal, NavDropdown, Accordion } from 'react-bootstrap';
import ReactCountryFlag from 'react-country-flag';
import { defaultStates } from '../../utils/defaults';

import logo from '../../assets/logos/FGCoop.png';
import logoMobile from '../../assets/logos/FGCoop-mobile.png';
import hamburger from '../../assets/icons/mobile/hamburger.png';

class NavbarApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbar: false,
      alerts: null,
      search: '',
      ...defaultStates(),
    };
  }

  componentDidMount() {}

  handleChange(search) {
    this.setState({ search });
  }

  async handleSearch(value) {
    if (value.trim().length !== 0) {
      this.props.history.push({
        pathname: '/busca',
        search: `?search=${value}`,
      });
    }
  }

  render() {
    const options = [
      {
        header: (
          <>
            <NavLink exact to={'/'}>
              <Label header text={'home'} />
            </NavLink>
          </>
        ),
      },
      {
        header: <>{'O FGCOOP'}</>,
        body: (
          <>
            <NavLink
              exact
              to={'/quem-somos'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              Quem Somos
            </NavLink>
            <NavLink
              exact
              to={'/caracteristicas-fundo'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              Características do Fundo
            </NavLink>
            <NavLink
              exact
              to={'/governanca'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              Governança
            </NavLink>
            <NavLink
              exact
              to={'/plano-estrategico'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              Plano Estratégico
            </NavLink>
            <NavLink
              exact
              to={'/normas'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              Normas
            </NavLink>
            <NavLink
              exact
              to={'/sncc'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              SNCC
            </NavLink>
            <NavLink
              exact
              to={'/iadi'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              IADI
            </NavLink>
            <NavLink
              exact
              to={'/cooperativas-associadas'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              Cooperativas Associadas
            </NavLink>
            <NavLink
              exact
              to={'/bancos-cooperativos'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              Bancos Cooperativos
            </NavLink>
          </>
        ),
      },
      {
        header: <>{'GARANTIAS'}</>,
        body: (
          <>
            <NavLink
              exact
              to={'/garantias'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              Garantias
            </NavLink>
            <NavLink
              exact
              to={'/cobertura-ordinaria'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              Cobertura ordinária
            </NavLink>
            <NavLink
              exact
              to={'/perguntas-frequentes'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              Perguntas frequentes
            </NavLink>
          </>
        ),
      },
      {
        header: <>{'PUBLICAÇÕES'}</>,
        body: (
          <>
            <NavLink
              exact
              to={'/informacoes'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              Informações FGCOOP
            </NavLink>
            <NavLink
              exact
              to={'/noticias'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              Notícia
            </NavLink>
          </>
        ),
      },
      {
        header: (
          <>
            <NavLink exact to={'/calendario-contribuicao'}>
              <Label headerCustom text={'calendário'} />
            </NavLink>
          </>
        ),
      },
      {
        header: <>{'Contato'}</>,
        body: (
          <>
            <NavLink
              exact
              to={'/contato'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              Fale Conosco
            </NavLink>
            <NavLink
              exact
              to={'/trabalheconosco'}
              activeClassName="is-active"
              className="d-flex flex-column align-items-end">
              Trabalhe Conosco
            </NavLink>
          </>
        ),
      },
    ];

    return (
      <>
        <Nav>
          <Header className={'header d-none d-xl-block d-xxl-block '}>
            <Row className={'align-items-center'}>
              <Col
                lg={{ span: 5, order: 1 }}
                className={
                  'd-flex justify-content-start justify-content-lg-start justify-content-xl-start'
                }>
                <Logo img={logo} href={'/'}>
                  <div />
                </Logo>
              </Col>
              <Col
                lg={{ span: 7, order: 2 }}
                className={
                  'd-flex justify-content-end justify-content-lg-end justify-content-xl-end'
                }>
                <div id="google_translate_element"></div>
                <ContainerTranslate>
                  <ReactCountryFlag
                    onClick={() => {
                      window['translateLanguage']('Inglês');
                    }}
                    className="emojiFlag notranslate"
                    countryCode="US"
                    style={{
                      fontSize: '1.5em',
                      lineHeight: '1.5em',
                    }}
                    aria-label="United States"
                  />
                  <ReactCountryFlag
                    onClick={() => {
                      window['translateLanguage']('Espanhol');
                    }}
                    className="emojiFlag notranslate"
                    countryCode="ES"
                    style={{
                      fontSize: '1.5em',
                      lineHeight: '1.5em',
                    }}
                    aria-label="Spain"
                  />
                  <ReactCountryFlag
                    onClick={() => {
                      window['translateLanguage']('Português');
                    }}
                    className="emojiFlag notranslate"
                    countryCode="BR"
                    style={{
                      fontSize: '1.5em',
                      lineHeight: '1.5em',
                    }}
                    aria-label="Brasil"
                  />
                </ContainerTranslate>
                <Input
                  searchWhite
                  value={this.state.search}
                  label={'PESQUISAR'}
                  placeholder={'PESQUISAR'}
                  maxLength={100}
                  onChange={(e) => this.handleChange(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && this.handleSearch(e.target.value)}
                />
              </Col>
            </Row>
            <hr></hr>
            <Row className={'align-items-center'}>
              <Col
                lg={{ span: 1, order: 1 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavDropdown
                  title="HOME"
                  onClick={() => this.props.history.push('/')}
                  className={'custom-dropdown-hide-menu notranslate'}
                  id="nav-dropdown"></NavDropdown>
              </Col>
              <Col
                lg={{ span: 2, order: 2 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavDropdown href="#fgcop" title="O FGCOOP" id="nav-dropdown">
                  <NavDropdown.Item
                    as={Link}
                    to="/quem-somos"
                    className={
                      this.props.history.location.pathname === '/quem-somos' ? 'is-active' : ''
                    }>
                    Quem somos
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/caracteristicas-fundo"
                    className={
                      this.props.history.location.pathname === '/caracteristicas-fundo'
                        ? 'is-active'
                        : ''
                    }>
                    Características do Fundo
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/governanca"
                    className={
                      this.props.history.location.pathname === '/governanca' ? 'is-active' : ''
                    }>
                    Governança
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/plano-estrategico"
                    className={
                      this.props.history.location.pathname === '/plano-estrategico'
                        ? 'is-active'
                        : ''
                    }>
                    Plano Estratégico
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/normas"
                    className={
                      this.props.history.location.pathname === '/normas' ? 'is-active' : ''
                    }>
                    Normas
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/sncc"
                    className={this.props.history.location.pathname === '/sncc' ? 'is-active' : ''}>
                    SNCC
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/iadi"
                    className={this.props.history.location.pathname === '/iadi' ? 'is-active' : ''}>
                    IADI
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/cooperativas-associadas"
                    className={
                      this.props.history.location.pathname === '/cooperativas-associadas'
                        ? 'is-active'
                        : ''
                    }>
                    Cooperativas Associadas
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/bancos-cooperativos"
                    className={
                      this.props.history.location.pathname === '/bancos-cooperativos'
                        ? 'is-active'
                        : ''
                    }>
                    Bancos Cooperativos
                  </NavDropdown.Item>
                </NavDropdown>
              </Col>
              <Col
                lg={{ span: 2, order: 3 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavDropdown title="GARANTIAS" id="nav-dropdown">
                  <NavDropdown.Item
                    as={Link}
                    to="/garantias"
                    className={
                      this.props.history.location.pathname === '/garantias' ? 'is-active' : ''
                    }>
                    Garantias
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/cobertura-ordinaria"
                    className={
                      this.props.history.location.pathname === '/cobertura-ordinaria'
                        ? 'is-active'
                        : ''
                    }>
                    Cobertura ordinária
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/perguntas-frequentes"
                    className={
                      this.props.history.location.pathname === '/perguntas-frequentes'
                        ? 'is-active'
                        : ''
                    }>
                    Perguntas frequentes
                  </NavDropdown.Item>
                </NavDropdown>
              </Col>
              <Col
                lg={{ span: 2, order: 4 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavDropdown title="PUBLICAÇÕES" id="nav-dropdown">
                  <NavDropdown.Item
                    as={Link}
                    to="/informacoes"
                    className={
                      this.props.history.location.pathname === '/informacoes' ? 'is-active' : ''
                    }>
                    Informações FGCOOP
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/noticias"
                    className={
                      this.props.history.location.pathname === '/noticias' ? 'is-active' : ''
                    }>
                    Notícias
                  </NavDropdown.Item>
                </NavDropdown>
              </Col>
              <Col
                lg={{ span: 3, order: 5 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavDropdown
                  title="CALENDÁRIO DE CONTRIBUIÇÃO"
                  onClick={() => this.props.history.push('/calendario-contribuicao')}
                  className={'custom-dropdown-hide-menu'}
                  id="nav-dropdown"></NavDropdown>
              </Col>
              <Col
                lg={{ span: 2, order: 5 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavDropdown title="CONTATO" id="nav-dropdown">
                  <NavDropdown.Item
                    as={Link}
                    to="/contato"
                    className={
                      this.props.history.location.pathname === '/contato' ? 'is-active' : ''
                    }>
                    Fale Conosco
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/trabalheconosco"
                    className={
                      this.props.history.location.pathname === '/trabalheconosco' ? 'is-active' : ''
                    }>
                    Trabalhe Conosco
                  </NavDropdown.Item>
                </NavDropdown>
              </Col>
            </Row>
          </Header>
          <HeaderMobile className={'header d-xl-none d-xxl-none'}>
            <Row className={'align-items-center mx-sm-0'}>
              <Logo img={logoMobile} href={'/'}>
                <div />
              </Logo>
              <StyledBurger
                open={this.state.navbar}
                img={hamburger}
                onClick={() => {
                  this.setState({ navbar: !this.state.navbar });
                }}>
                <div />
              </StyledBurger>
            </Row>
          </HeaderMobile>
        </Nav>
        <Ul
          show={this.state.navbar}
          placement={'end'}
          onHide={() => this.setState({ navbar: false })}
          className="d-flex justify-content-end">
          <MenuHeader key={0} closeButton className="d-flex align-items-center justify-content-end">
            <LogoMenu src={logoMobile} alt="fgcoop logotipo" />
          </MenuHeader>
          <ScrollList>
            <ContainerMenuMobile>
              <StyledAccordion>
                {options.map(({ header, body }, i) => (
                  <Item eventKey={i} key={i} className="d-flex flex-column align-items-end">
                    <HeaderAccordion>{header}</HeaderAccordion>
                    {body && <Body>{body}</Body>}
                  </Item>
                ))}
                <br />
              </StyledAccordion>
              <Col className={'search'}>
                <Input
                  searchWhite
                  value={this.state.search}
                  label={'PESQUISAR'}
                  placeholder={'PESQUISAR'}
                  maxLength={100}
                  onChange={(e) => this.handleChange(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && this.handleSearch(e.target.value)}
                />
              </Col>
              <Col className={'d-flex justify-content-end'}>
                <ContainerTranslate>
                  <ReactCountryFlag
                    onClick={() => {
                      window['translateLanguage']('Inglês');
                    }}
                    className="emojiFlag notranslate"
                    countryCode="US"
                    style={{
                      fontSize: '1.5em',
                      lineHeight: '1.5em',
                    }}
                    aria-label="United States"
                  />
                  <ReactCountryFlag
                    onClick={() => {
                      window['translateLanguage']('Espanhol');
                    }}
                    className="emojiFlag notranslate"
                    countryCode="ES"
                    style={{
                      fontSize: '1.5em',
                      lineHeight: '1.5em',
                    }}
                    aria-label="Spain"
                  />
                  <ReactCountryFlag
                    onClick={() => {
                      window['translateLanguage']('Português');
                    }}
                    className="emojiFlag notranslate"
                    countryCode="BR"
                    style={{
                      fontSize: '1.5em',
                      lineHeight: '1.5em',
                    }}
                    aria-label="Brasil"
                  />
                </ContainerTranslate>
              </Col>
              <NavLink
                exact
                to={'/perguntas-frequentes'}
                className="d-flex flex-column align-items-end common-questions">
                <Label menu text={'Perguntas frequentes'} />
              </NavLink>
              <NavLink
                exact
                to={'/politica-de-privacidade'}
                className="d-flex flex-column align-items-end privacy-policy">
                <Label menu text={'Política de privacidade'} />
              </NavLink>
            </ContainerMenuMobile>
          </ScrollList>
        </Ul>
      </>
    );
  }
}

NavbarApp.propTypes = { back: PropTypes.bool, history: PropTypes.object };

export default NavbarApp;
