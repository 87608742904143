import React, { Component } from 'react';
import { Screen, SubHeader, Label, Text, CarouselComponent, Card, Video } from '../../../components';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import newsService from '../../../services/news';
const { defaultStates, defaultModalOnHide, baseURL } = require('../../../utils/defaults');
import { ColStyled, Content, Header, HeaderMobile } from './styles';

class NewsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: {
        title: '',
        postingDate: '',
        content: '',
        url: ''
      },
      list: [],
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  async componentDidMount() {
    await this.loadNews();
  }

  async componentDidUpdate() {
    const { id } = this.props.match.params;
    const { loadingState } = this.state;
    const { id: newsId } = this.state.news;

    if (!loadingState && newsId && newsId !== Number(id)) {
      await this.loadNews();
    }
  }

  async loadNews() {
    this.setState({ loadingState: true });

    const { id } = this.props.match.params;

    const response = await newsService.user.get(id);
    if (response.ok && response.data && response.data) {
      this.setState({
        news: response.data,
      });
    } else if (response.ok) {
      this.props.history.push('/');
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }

    const list = await newsService.user.getList({ limit: 3, random: true });
    if (list.ok && list.data && list.data) {
      this.setState({
        list: list.data,
      });
    }

    this.setState({ loadingState: false });
  }

  goBack = () => {
    this.props.history.push('/noticias');
  };

  header() {
    return (
      <>
        <SubHeader back onClick={this.goBack} />
      </>
    );
  }

  body() {
    const { news, list } = this.state;

    const breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 600, itemsToShow: 2, itemsToScroll: 2 },
      { width: 910, itemsToShow: 3, itemsToScroll: 2 },
    ];

    const partnerItems = list.map((n) => (
      <Card
        key={n.id}
        id={n.id}
        {...this.props}
        date={n.postingDate}
        title={n.title}
        hashtag={n.hashtag}
        image={n.file && n.file.id ? `${baseURL()}/api/file/${n.file.id}/${n.file.slug}` : ''}
      />
    ));

    return (
      <>
        <Header className={'d-none d-xl-block d-xxl-block'}>
          <Row className={'mt-4'}>
            <Col>
              <Label newsDate text={`postado em ` + news.postingDate} />
            </Col>
          </Row>
          <Row className={'mt-4'}>
            <Col>
              <Label newsTitle text={news.title} />
            </Col>
          </Row>
        </Header>
        <HeaderMobile className={'d-xl-none d-xxl-none'}>
          <Row className={'mt-4'}>
            <Col>
              <Label newsTitle text={news.title} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label newsDate text={`postado em ` + news.postingDate} />
            </Col>
          </Row>
        </HeaderMobile>

        <Row className={'mt-2'}>
          <Col>
            <Content
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(news.content, {
                  ADD_TAGS: ['iframe'],
                  ADD_ATTR: [
                    'allow',
                    'allowfullscreen',
                    'frameborder',
                    'scrolling',
                    'src',
                    'width',
                    'height',
                    'style',
                  ],
                }),
              }}
            />
          </Col>
        </Row>

        {news.url ? (<Row className={'mt-4 mb-4 mb-lg-5 pb-4'}>
          <Col className={'mb-lg-3 pb-lg-1 d-flex justify-content-center'}>
            <Video url={news.url} />
          </Col>
        </Row>) : ('')}

        <Row className={'mt-5 mb-lg-4 pb-lg-3'}>
          <Col xs={12} className={'mb-lg-1'}>
            <Text heading2 noWrap textAlign={'center'} title={{ one: 'MAIS NOTÍCIAS' }} />
          </Col>
        </Row>

        <Row className={'mb-5 pb-1 pb-md-5 mt-3 mt-lg-5 d-flex d-lg-none'}>
          <CarouselComponent breakPoints={breakPoints} items={partnerItems} />
        </Row>

        <Row className={'mb-5 pb-1 pb-md-5 mt-3 mt-lg-5 d-none d-lg-flex'}>
          <ColStyled className={'d-flex'}>{partnerItems}</ColStyled>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        loadingState={loadingState}
      />
    );
  }
}

NewsDetail.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};
export default NewsDetail;
