import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AccordionDetails, AccordionSummary, Plus, AccordionPlus } from './styles';

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandOnStart: true,
    };
  }

  render() {
    //props
    const { id, summary, details, textColor } = this.props;

    //types
    const { expandOnStart } = this.props;

    const handleChange = () => {
      this.setState({ expandOnStart: !this.state.expandOnStart });
    };

    if (expandOnStart) {
      return (
        <AccordionPlus
          disableGutters
          elevation={0}
          expanded={this.state.expandOnStart}
          onChange={handleChange}>
          <AccordionSummary expandIcon={<Plus />} aria-controls={id} id={id}>
            {summary}
          </AccordionSummary>
          <AccordionDetails text_color={textColor}>{details}</AccordionDetails>
        </AccordionPlus>
      );
    }

    return (
      <AccordionPlus disableGutters elevation={0} onChange={handleChange}>
        <AccordionSummary expandIcon={<Plus />} aria-controls={id} id={id}>
          {summary}
        </AccordionSummary>
        <AccordionDetails text_color={textColor}>{details}</AccordionDetails>
      </AccordionPlus>
    );
  }
}

Accordion.propTypes = {
  //props
  id: PropTypes.string,
  summary: PropTypes.any,
  details: PropTypes.any,
  textColor: PropTypes.string,

  //types
  expandOnStart: PropTypes.bool,
};

export default Accordion;
