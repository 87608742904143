import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Text } from '../../components';
import { BannerDoubt, DivDoubt, RowDoubt, TitleDoubt } from './styles';

class ContainerDoubt extends Component {
  render() {
    return (
      <Row className={'justify-content-end mb-5 container-doubt'}>
        <BannerDoubt />
        <Col xs={11} md={6} className={'col-doubt'}>
          <DivDoubt>
            <RowDoubt>
              <Col className={'p-0'}>
                <TitleDoubt className={'mb-lg-4 pb-2'}>FICOU COM ALGUMA DÚVIDA?</TitleDoubt>
                <Text
                  paragraph4
                  lightColor
                  fontSizeMobile={14}
                  lineHeight={20}
                  text={`Acesse nosso FAQ ou entre em contato conosco, vamos adorar atendê-lo.`}
                />
                <Text
                  link
                  fontSize={14}
                  text={'Perguntas frequentes'}
                  href={'/perguntas-frequentes'}
                />
              </Col>
            </RowDoubt>
          </DivDoubt>
        </Col>
      </Row>
    );
  }
}

export default ContainerDoubt;
