const { axiosDefault, handleErrors } = require('../utils/defaults');

const searchedpageService = {
  user: {
    getList: async ({ limit = '', content = '', offset = '', random = '' }) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(
          `/api/searchedpage?limit=${limit}&content=${content}&offset=${offset}&random=${random}`,
        );

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },
};

export default searchedpageService;
