import React, { Component } from 'react';
import { Screen, SubHeader, MiniCard, Text, CooperativeCard } from '../../../components';

import PropTypes from 'prop-types';
const { defaultStates } = require('../../../utils/defaults');

import bannerMobile from '../../../assets/imgs/banner-page-cooperative-banks-mobile.png';
import banner from '../../../assets/imgs/banner-page-cooperative-banks.png';

import {
  ColStyled,
  Container,
  TitleSubText,
  SubtitleText,
  Paragraph,
  BannerCooperativeBank,
  Heading3,
  Background,
  ColFontStyled,
  LinkStyled,
} from './styles';

import { Col, Row } from 'react-bootstrap';
class CooperativeBanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      innerWidth: window.innerWidth,
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  updateSize = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  header = () => {
    return (
      <>
        <SubHeader
          image
          backgroundImage={banner}
          backgroundImageMobile={bannerMobile}
          minHeightSm={452}
          minHeight={313}
          title={'BANCOS COOPERATIVOS'}
        />
      </>
    );
  };

  body = () => {
    return (
      <Container className={'pt-2 pt-lg-0'}>
        <Row
          className={'mb-5 pb-5 mb-lg-5 pb-lg-5 justify-content-center ps-3 ms-1 ms-lg-0 ps-lg-0'}>
          <Col lg={4} className={'mb-3 pb-1 mb-lg-0 pb-lg-0 px-0'}>
            <Text
              heading1
              noWrap
              lineHeight={50}
              title={{ one: 'SISTEMA NACIONAL DE CRÉDITO COOPERATIVO' }}
            />
            <TitleSubText className={'mb-0 mb-lg-3'}>Novas possibilidades de negócios</TitleSubText>
          </Col>
          <ColStyled xs={11} lg={8} className={'px-0 px-lg-5'}>
            <MiniCard
              brackets
              content={`<p>O Banco Cooperativo é um banco comercial ou banco múltiplo constituído, 
              obrigatoriamente, com carteira comercial. Diferencia-se dos demais por ter como 
              acionistas-controladores cooperativas centrais de crédito, as quais devem deter no mínimo 51% 
              das ações com direito a voto. O Banco Cooperativo oferece produtos e serviços financeiros às 
              cooperativas, ampliando e criando novas possibilidades de negócios e gestão centralizada dos recursos 
              financeiros do sistema.</p>
              <p>Desta forma, as cooperativas de crédito contam com uma linha completa de cartões de crédito, poupança, 
              cobrança bancária, linhas de créditos de recursos repassados por instituições governamentais, 
              fundos de investimentos, entre outros, em condições significativamente competitivas.</p>`}
            />
          </ColStyled>
        </Row>

        <Row
          className={
            'mb-5 pb-2 mt-lg-5 pt-lg-5 mb-lg-4 pb-lg-5 justify-content-center align-items-center'
          }
          style={{ gap: '35px 0' }}>
          <Background className={'mt-5'} />
          <Col lg={3} className={'mb-3 pb-1 mb-lg-0 pb-lg-0 px-0'}>
            <SubtitleText className={'text-lg-start text-center'}>
              NO BRASIL EXISTEM DOIS BANCOS COOPERATIVOS
            </SubtitleText>
          </Col>
          <CooperativeCard
            xsSize={12}
            lgSize={4}
            title={'SICOOB'}
            associate={'sicoob'}
            link={'https://www.sicoob.com.br/'}
            logo={'sicoob-logo.png'}
          />
          <CooperativeCard
            xsSize={12}
            lgSize={4}
            title={'SICREDI'}
            associate={'sicredi'}
            link={'https://www.sicredi.com.br/home/'}
            logo={'sicredi-logo.png'}
          />
        </Row>
        <Row className={'mb-5 pb-5'}>
          <ColFontStyled xs={12} lg={8} className={'px-lg-5 px-0 text-center text-lg-start'}>
            Fontes:
            <LinkStyled target={'_blank'} href={'https://www.bcb.gov.br/'}>
              Banco Central do Brasil
            </LinkStyled>
            <LinkStyled target={'_blank'} href={'https://www.sicoob.com.br/'}>
              Sicoob
            </LinkStyled>
            <LinkStyled target={'_blank'} href={'https://www.sicredi.com.br/home/'}>
              Sicredi
            </LinkStyled>
          </ColFontStyled>
        </Row>
      </Container>
    );
  };

  render = () => {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  };
}

CooperativeBanks.propTypes = {
  //props
  history: PropTypes.object,
};
export default CooperativeBanks;
