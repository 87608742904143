import React, { useState, useEffect } from 'react';
import { VideoContainer, VideoSimple } from './styles';
import { PropTypes } from 'prop-types';
import { bootstrapMax } from '../../utils/devices';

const Video = ({ tenYears, whoWeAre, purpose, url }) => {
  const [lang, setLang] = useState(document.documentElement.lang);
  let srcVideoWhoWeAre = 'https://www.youtube.com/embed/-Tlhare1-Qg?autoplay=1';

  if (url) {
    const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;

    const r = url.match(rx);

    if (r && r[1]) {
      const videoId = r[1];
      url = videoId;
    }
  }
  useEffect(() => {
    const targetNode = document.documentElement;
    const config = { attributes: true, attributeFilter: ['lang'] };

    const callback = (mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
          setLang(targetNode.lang);
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);

    return () => {
      observer.disconnect();
    };
  }, []);

  if (lang === 'en') {
    // US-Eng
    srcVideoWhoWeAre = 'https://www.youtube.com/embed/oeDXLa7wdt4?autoplay=1';
  } else if (lang === 'es') {
    // ES-Spain
    srcVideoWhoWeAre = 'https://www.youtube.com/embed/b4oWHXI9r7A?autoplay=1';
  } else {
    //PT-Br
    srcVideoWhoWeAre = 'https://www.youtube.com/embed/-Tlhare1-Qg?autoplay=1';
  }

  if (whoWeAre) {
    return (
      <VideoSimple className={'text-lg-center'}>
        <iframe
          style={{ borderRadius: '8px' }}
          width="100%"
          height="100%"
          src={srcVideoWhoWeAre}
          title="FGCoop - Mais segurança para as cooperativas de crédito"
          frameBorder="0"
          allow="fullscreen"></iframe>
      </VideoSimple>
    );
  }

  if (tenYears) {
    return (
      <VideoSimple className={'text-lg-center'}>
        <iframe
          style={{ borderRadius: '8px' }}
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/SuDKgJbfkFI?autoplay=1"  
          title="FGCoop - Mais segurança para as cooperativas de crédito"
          frameBorder="0"
          allow="fullscreen"></iframe>
      </VideoSimple>
    );
  }

  if (url) {
    return (
      <VideoSimple className={'text-lg-center'}>
        <iframe
          style={{ borderRadius: '8px' }}
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${url}?autoplay=1`}
          title="FGCoop - Mais segurança para as cooperativas de crédito"
          frameBorder="0"
          allow="fullscreen"></iframe>
      </VideoSimple>
    );
  }

  if (purpose) {
    return (
      <VideoSimple className={'text-lg-center'}>
        <iframe
          style={{ borderRadius: '8px' }}
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/0mvgVMSiDVc?autoplay=1"
          title="FGCoop - Mais segurança para as cooperativas de crédito"
          frameBorder="0"
          allow="fullscreen"></iframe>
      </VideoSimple>
    );
  }
};

Video.propTypes = {
  tenYears: PropTypes.bool,
  whoWeAre: PropTypes.bool,
  purpose: PropTypes.bool,
  url: PropTypes.string,
};

export default Video;
