import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tab, Box } from '@mui/material';
import { MUITabs, BorderTabs } from './styles';
import TabPanel from '../TabPanel';

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  a11yProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  };

  render() {
    const { labels, contents } = this.props;

    const handleChange = (event, newValue) => {
      this.setState({ value: newValue });
    };

    return (
      <Box sx={{ width: '100%' }}>
        <Box lg={{ borderBottom: 1, borderColor: 'divider' }}>
          <BorderTabs />
          <MUITabs value={this.state.value} onChange={handleChange} aria-label="tabs">
            {labels.map((label, index) => {
              return <Tab key={index} label={label} {...this.a11yProps(index)} />;
            })}
          </MUITabs>
        </Box>
        {contents.map((content, index) => {
          return (
            <TabPanel key={index} value={this.state.value} index={index}>
              {content}
            </TabPanel>
          );
        })}
      </Box>
    );
  }
}

Tabs.propTypes = {
  labels: PropTypes.array.isRequired,
  contents: PropTypes.array.isRequired,
};

export default Tabs;
