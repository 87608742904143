import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Screen, SubHeader, Text, TableCooperatives } from '../../../components';
import { Container } from './styles';
import { Col, Row } from 'react-bootstrap';

const { defaultStates } = require('../../../utils/defaults');

import bannerMobile from '../../../assets/imgs/banner-governance-mobile.png';
import banner from '../../../assets/imgs/banner-governance.png';

class AssociateCooperatives extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
  }

  header = () => {
    return (
      <>
        <SubHeader
          image
          backgroundImage={banner}
          backgroundImageMobile={bannerMobile}
          minHeightSm={452}
          minHeight={313}
          title={'Cooperativas Associadas'}
        />
      </>
    );
  };

  body() {
    return (
      <Container className={'pt-2 pt-lg-0 mb-lg-5 pb-lg-5'}>
        <Row className={'justify-content-center pt-lg-3'}>
          <Col xs={12} className={'mb-lg-4'}>
            <Text
              heading2
              noWrap
              textAlign={'center'}
              title={{ one: 'Lista de Cooperativas associadas ao FGCOOP' }}
            />
          </Col>
          <Col lg={12} className={'pe-lg-12'}>
            <TableCooperatives />
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    const { loadingState } = this.state;

    return <Screen body={this.body} header={this.header} loadingState={loadingState} />;
  }
}

AssociateCooperatives.propTypes = {
  history: PropTypes.object,
};

export default AssociateCooperatives;
