import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../utils/devices';

import bracket_left_top from '../../assets/icons/bracket-left-top.png';
import bracket_right_bottom from '../../assets/icons/bracket-right-bottom.png';
import left_bracket from '../../assets/icons/left-bracket.png';

import double_arrow from '../../assets/icons/double-arrow.png';
import { Col, Row } from 'react-bootstrap';

export const SimpleCard = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  max-width: 305px;
  min-height: 325px;
  border-radius: 2px;
  padding: 26px 38px;

  @media ${bootstrapMax.lg} {
    min-height: 272px;
  }
`;

export const NumberTitle = styled.span`
  font-family: 'Trade Gothic LT Bold';
  font-size: 40px;
  line-height: 28px;
  color: ${colors.primaryTwo};
`;

export const Title = styled.h3`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryThree};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  ${({ fontSizeTitle }) =>
    fontSizeTitle
      ? `font-size: ${fontSizeTitle}px; line-height: 40px;`
      : `font-size: 20px; line-height: 28px;`}

  @media ${bootstrapMax.lg} {
    text-align: ${({ centerMobile }) => (centerMobile ? 'center' : 'left')};
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Content = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${colors.secondaryTwo};
`;

export const ContentNoBox = styled(Content)`
  ${({ fontSizeContent }) => fontSizeContent !== 14 && 'font-size: 16px; line-height: 24px;'}
  text-align: ${({ centerMobile }) => (centerMobile ? 'center' : 'left')};

  ol {
    text-align: left;
    padding-left: 20px;
    display: flex;
    justify-content: center;

    & ul {
      padding-left: 0px;

      li {
        &::marker {
          color: ${colors.primaryTwo};
          font-size: 16px;
        }
      }
    }

    @media ${bootstrapMin.lg} {
      justify-content: normal;
    }
  }

  @media ${bootstrapMin.lg} {
    text-align: ${({ center }) => (center ? 'center' : 'left')};
  }
`;

export const Icon = styled.div`
  padding: 17px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background-repeat: no-repeat;
  background-image: url(${({ backgroundImage }) => (backgroundImage ? backgroundImage : 'none')});
  background-size: contain;
  align-self: ${({ fullColumns }) => (fullColumns ? 'end' : 'start')};
`;

export const StepByStepCard = styled(SimpleCard)`
  border-left: 1px solid ${colors.primaryTwo};
  border-bottom: 1px solid ${colors.primaryTwo};
  border-right: 1px solid ${colors.primaryTwo};
  border-radius: 0px 0px 15px 15px;
  padding: 0px 10px 26px 30px;
  box-shadow: none;
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth && maxWidth}px;
  max-height: ${({ maxHeight }) => maxHeight && maxHeight}px;
  margin-bottom: 87px;

  &::after {
    content: '';
    height: 60%;
    width: 3px;
    position: absolute;
    right: -2px;
    top: 0px;
    background-color: white;
  }

  @media ${bootstrapMax.lg} {
    max-height: 100%;
    min-height: 282px;
    margin-bottom: 37px;
    padding-right: 20px;
  }

  @media ${bootstrapMax.xl} {
    max-width: 523px;
  }
`;

export const StepNumberTitle = styled(NumberTitle)`
  font-size: 40px;
  line-height: 40px;
  color: ${colors.primaryOne};

  @media ${bootstrapMin.lg} {
    font-size: 55px;
  }
`;

export const StepTitle = styled(Title)`
  font-size: 18px;
  line-height: 40px;
  text-align: left;

  @media ${bootstrapMin.lg} {
    font-size: 22px;
  }
`;

export const StepContent = styled(Content)`
  font-size: 14px;
  line-height: 20px;

  @media ${bootstrapMin.lg} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const DoubleArrowIcon = styled.div`
  background-image: url(${double_arrow});
  background-repeat: no-repeat;
  background-size: 20px;
  transform: rotate(90deg);
  align-self: center;
  height: 20px;
  width: 20px;

  @media ${bootstrapMin.lg} {
    background-image: url(${double_arrow});
    background-size: 26px;
    transform: rotate(0);
    height: 26px;
    width: 26px;
  }
`;

export const IconBracket = styled.div`
  width: 36px;
  height: 42px;
  position: absolute;
  background-size: 36px 42px;
  background-repeat: no-repeat;
  background-image: url(${({ right }) => (right ? bracket_right_bottom : bracket_left_top)});
  ${({ right }) =>
    right ? 'margin-right: 14px; margin-top: -32px;' : 'margin-top: -30px; margin-left: -35px;'}

  @media ${bootstrapMax.md} {
    width: 27px;
    height: 32px;
    background-size: 27px 32px;
    ${({ right }) =>
      right ? 'margin-right: 0px; margin-top: -30px;' : 'margin-top: -20px; margin-left: -40px;'}
  }
`;

export const BracketsCard = styled.div`
  & p {
    font-size: 16px;
    line-height: 24px;

    @media ${bootstrapMax.md} {
      font-size: 14px;
    }
  }
`;

export const StepIconBracket = styled(IconBracket)`
  background-size: 23px 27px;
  margin-left: -37px;
  margin-top: -25px;
  width: 23px;
  height: 27px;

  ${({ right }) => right && 'bottom: -8px; right: -6px; margin-right: 0px; margin-top: 0px;'}
`;

export const RowStep = styled(Row)`
  @media ${bootstrapMax.lg} {
    margin-top: -10px;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
`;

export const LeftBracketIcon = styled.span`
  background-image: url(${left_bracket});
  background-size: 17px 90px;
  height: 90px;
  align-self: center;
  background-repeat: no-repeat;
  padding: 20px;
`;

export const FullBracketsTitle = styled.p`
  font-size: 20px !important;
  line-height: 28px !important;
  color: ${colors.primaryOne} !important;
  font-family: 'Trade Gothic LT Bold' !important;
  margin: 0 !important;
`;
export const FullBracketsSubTitle = styled.p`
  color: ${colors.primaryOne} !important;
  margin: 0 !important;
`;
export const FullBracketsDescription = styled.div`
  & p {
    font-size: 14px !important;
    line-height: 22px !important;
    color: ${colors.secondaryTwo} !important;
    margin: 0 !important;
  }
`;

export const OrdinaryCoverageIconBracket = styled(IconBracket)`
  width: 23px;
  height: 27px;
  background-size: 23px 27px;
  ${({ right }) =>
    right ? 'margin-right: -35px; margin-top: 10px;' : 'margin-top: -35px; margin-left: -46px;'}

  @media ${bootstrapMax.lg} {
    ${({ right }) =>
      right ? 'margin-right: -45px; margin-top: 6px;' : 'margin-top: -24px; margin-left: -46px;'}
  }
`;

export const OrdinaryCoverageCard = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  padding: 20px 40px 25px;
  margin-bottom: 30px;

  & p {
    font-size: 14px;
    line-height: 20px;
    color: ${colors.secondaryTwo};

    b {
      font-family: 'Trade Gothic LT Bold';
      color: ${colors.primaryTwo};
    }

    &.examples {
      font-size: 20px;
      line-height: 40px;
      font-family: 'Trade Gothic LT Bold';
      color: ${colors.primaryTwo};
      margin-bottom: 0;

      @media ${bootstrapMin.lg} {
        font-size: 23px;
      }
    }

    &.color-black {
      color: ${colors.primaryThree};
    }

    @media ${bootstrapMin.lg} {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  & .custom {
    @media ${bootstrapMin.lg} {
      position: relative;
      margin-left: 380px;
      top: -240px;
    }
  }

  & .ordinary-coverage {
    &:first-child {
      & .MuiAccordionSummary-root {
        padding-top: 0 !important;
      }
    }

    & .MuiAccordionSummary-root {
      @media ${bootstrapMax.lg} {
        padding-top: 0 !important;
      }
    }
  }

  & .MuiAccordionDetails-root {
    p {
      margin-bottom: 30px !important;
      @media ${bootstrapMin.lg} {
        padding-left: 16px;
        margin-bottom: 0 !important;
      }
    }
  }

  @media ${bootstrapMin.lg} {
    padding: 30px 30px 30px 40px;
  }
`;

export const OrdinaryCoverageNumberTitle = styled.span`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryOne};
  font-size: 40px;
  display: flex;
  flex: 0;
  padding: 0;

  @media ${bootstrapMin.lg} {
    font-size: 55px;
    padding-right: 31px;
  }
`;

export const ColStyled = styled(Col)`
  @media ${bootstrapMax.lg} {
    justify-content: center !important;
  }
`;

export const NewsHomePageCard = styled.div`
  max-width: 312px;
  width: 100%;

  @media ${bootstrapMax.lg} {
    max-width: 284px;
    padding: 0 5px;
  }
`;

export const ImageNews = styled.div`
  background-image: url(${({ img }) => img});
  background-size: 283px 284px;
  background-repeat: no-repeat;
  border-radius: 8px;
  max-width: 312px;
  height: 284px;

  @media ${bootstrapMin.lg} {
    background-size: 312px 313px;
    height: 313px;
  }
`;

export const TitleNews = styled.h4`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryThree};
  line-height: 30px;
  font-size: 20px;
  height: 60px;
`;

export const ParagraphNews = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: ${colors.gray};
  line-height: 30px;
  font-size: 16px;
  height: 30px;
`;

export const LinkNews = styled.a`
  color: ${colors.primaryThree};
  padding-top: 10px;
  font-size: 14px;
  line-height: 16px;

  &:hover {
    color: ${colors.primaryTwo};
  }
`;

export const LinkContainer = styled.div`
  border-top: 1px solid ${colors.lightGreyTwo};
  padding-top: 8px;
`;
