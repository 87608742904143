const { axiosDefault, handleErrors } = require('../utils/defaults');

const migrateService = {
  admin: {
    migrate: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/migrate/gestao/${id}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },
};

export default migrateService;
