const { dataFormParse } = require('../utils/dataFormParse');
const { axiosDefault, handleErrors } = require('../utils/defaults');

const cooperativesService = {
  admin: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/cooperatives/gestao/${id ? id : ''}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async () => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/cooperatives/gestao`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    save: async (cooperative) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.post(`/api/cooperatives/gestao`, dataFormParse(cooperative));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    saveBatch: async (cooperatives) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.post(`/api/cooperatives/batch`, cooperatives);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    readCsv: async (csv) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.post(`/api/cooperatives/csv`, dataFormParse(csv));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    edit: async (cooperative) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.put(
          `/api/cooperatives/gestao/${cooperative.id}`,
          dataFormParse(cooperative),
        );

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    editBatch: async (cooperatives) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.put(`/api/cooperatives/batch`, (cooperatives));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    delete: async (id) => {
      try {
        const axios = axiosDefault();

        const { data } = await axios.delete(`/api/cooperatives/gestao/${id}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },

  user: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/cooperatives/${id}`);

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async ({ limit = '', search = '', offset = '', random = '' }) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(
          `/api/cooperatives?limit=${limit}&title=${search}&offset=${offset}&random=${random}`,
        );

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },
};

export default cooperativesService;
