import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Text, Button } from '..';
import PropTypes from 'prop-types';
import check_maskavo_min from '../../assets/imgs/admin/check_maskavo-min.png';
import check_maskavo_black from '../../assets/imgs/admin/check_maskavo_black-min.png';

class AlertScreen extends Component {
  render() {
    // props

    const { title, text, onClick } = this.props;

    if (process.env.REACT_APP_WEB === 'false') {
      return (
        <>
          <Row align="center">
            <Col>
              <img src={check_maskavo_black} />{' '}
            </Col>
          </Row>
          <Row align="center">
            <Col>
              <Text titleMiddleWhite text={title} />
            </Col>
          </Row>
          <Row align="center">
            <Col>
              <Text textMiddleSwhite text={text} />
            </Col>
          </Row>
          <Row align="center">
            <Col>
              <Button underlineWhite text={'VOLTAR'} onClick={onClick} />
            </Col>
          </Row>
        </>
      );
    }

    return (
      <>
        <Row align="center">
          <Col>
            {' '}
            <img src={check_maskavo_min} />
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <Text titleMiddle text={title} />
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <Text textMiddleS text={text} />
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <Button underlineSec text={'VOLTAR'} onClick={onClick} />
          </Col>
        </Row>
      </>
    );
  }
}

AlertScreen.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  pagePath: PropTypes.string,
};

export default AlertScreen;
