import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../utils/devices';

import banner_doubt from '../../assets/imgs/banner-doubt.png';
import banner_doubt_mobile from '../../assets/imgs/banner-doubt-mobile.png';

export const DivDoubt = styled.div`
  background-color: ${colors.primaryTwo};
  border-radius: 8px;
  width: 100%;
  height: auto;

  @media ${bootstrapMin.xl} {
    width: 533px;
    height: 305px;
  }
`;

export const RowDoubt = styled(Row)`
  padding: 20px 33px 30px 33px;
  margin: 0;
  margin-top: 150px;

  @media ${bootstrapMin.lg} {
    padding: 31px 110px 78px 45px;
    margin-top: 30px;
  }
`;

export const TitleDoubt = styled.h2`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.white};
  font-size: 25px;
  line-height: 30px;

  @media ${bootstrapMin.lg} {
    font-size: 42px;
    line-height: 46px;
  }
`;

export const BannerDoubt = styled.div`
  background-image: url(${banner_doubt_mobile});
  background-size: 375px 201px;
  height: 201px;
  width: 100vw;
  left: 49.99%;
  z-index: -1;
  position: absolute;
  margin-left: -50vw;
  background-repeat: no-repeat;
  background-color: transparent;
  border-radius: 8px;

  @media ${bootstrapMax.lg} {
    background-position: center;
  }

  @media ${bootstrapMin.lg} {
    background-image: url(${banner_doubt});
    background-size: 965px 350px;
    height: 350px;
  }

  @media ${bootstrapMin.xxl} {
    width: 965px;
    position: relative;
    left: 24.99%;
  }
`;
