import React, { Component } from 'react';
import { Screen, SubHeader, Button, Text, Card, SearchInfo } from '../../../components';
import { Col, Row, NavDropdown } from 'react-bootstrap';
import {
  SelectOrder,
  Title,
  Content,
  ContentMobile,
  ContentSearch,
  ColStyled,
  InfoOrdinationContainer,
} from './styles';
import PropTypes from 'prop-types';
import infosService from '../../../services/infos';
const { defaultStates, defaultModalOnHide, baseURL } = require('../../../utils/defaults');

import bannerMobile from '../../../assets/imgs/banner-informations-mobile.png';
import banner from '../../../assets/imgs/banner-informations.png';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

class Informations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descOrder: true,
      offset: 0,
      countOffSet: window.innerWidth < 768 ? 6 : 9,
      limit: window.innerWidth < 768 ? 6 : 9,
      qtdResultNews: 0,
      infos: [],
      category: '',
      ...defaultStates(),
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleOffset = this.handleOffset.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  async componentDidMount() {
    this.handleSearch();
  }

  handleChangeOrder(descOrder) {
    this.setState({ offset: 0 });
    this.setState({ descOrder });
    setTimeout(() => {
      this.handleSearch();
    }, 0);
  }

  handleChangeSearch(value) {
    this.setState({ category: value });
    this.setState({ offset: 0 });
    setTimeout(() => {
      this.handleSearch();
    }, 0);
  }

  handleOffset(offset) {
    let count = offset;
    count += this.state.countOffSet;
    this.setState({ offset: count });
    return count;
  }

  async ViewMore() {
    const response = await infosService.user.getList({
      category: this.state.category,
      descOrder: this.state.descOrder,
      limit: this.state.limit,
      offset: this.handleOffset(this.state.offset),
    });
    if (response && response.ok && response.data) {
      this.setState({ qtdResultNews: response.data.length });
      this.setState({ infos: this.state.infos.concat(response.data) });
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  async handleSearch() {
    this.setState({ offset: 0 });
    this.setState({ loadingState: true });
    const response = await infosService.user.getList({
      category: this.state.category,
      descOrder: this.state.descOrder,
      limit: this.state.limit,
    });

    if (response && response.ok && response.data) {
      this.setState({ infos: response.data });
      this.setState({ qtdResultNews: response.data.length });
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }

    this.setState({ loadingState: false });
  }

  infos = (infos) => (
    <ColStyled
      xs={12}
      md={10}
      xl={12}
      className={`no-gutters d-flex flex-row flex-wrap justify-content-center`}>
      {infos.map(({ id, title, content, file, documentDate }) => (
        <Card
          info={true}
          key={id}
          id={id}
          date={documentDate}
          title={title}
          content={content}
          image={file && file.id ? `${baseURL()}/api/file/${file.id}/${file.slug}` : ''}
          {...this.props}
        />
      ))}
    </ColStyled>
  );

  header() {
    return (
      <>
        <SubHeader
          image
          backgroundImage={banner}
          backgroundImageMobile={bannerMobile}
          minHeightSm={452}
          minHeight={313}
          title={'INFORMAÇÕES'}
        />
      </>
    );
  }

  body() {
    const { infos } = this.state;

    return (
      <>
        <ContentSearch>
          <Row className={'align-items-center'}>
            {/* Content */}
            <Col
              lg={{ span: 6, order: 1 }}
              className={
                'align-items-center d-flex justify-content-start justify-content-lg-start justify-content-xl-start d-none d-xl-block d-xxl-block'
              }>
              <Title>PESQUISAR POR</Title>
            </Col>
            {/* End Content */}
            {/* Content Mobile */}
            <Col
              lg={{ span: 6, order: 1 }}
              className={
                'align-items-center d-flex justify-content-start justify-content-lg-start justify-content-xl-start d-xl-none d-xxl-none'
              }>
              <NavDropdown
                className="shadow bg-white rounded"
                id="basic-nav-dropdown"
                title="PESQUISAR POR">
                <NavDropdown.Item>
                  <SearchInfo mobile onClick={this.handleChangeSearch}></SearchInfo>
                </NavDropdown.Item>
              </NavDropdown>
            </Col>
            {/* End Mobile */}
            <Col
              xs={12}
              lg={{ span: 6, order: 2 }}
              className={
                'align-items-center d-flex justify-content-end justify-content-lg-end justify-content-xl-end'
              }>
              <InfoOrdinationContainer>
                <Text newsOrdination center text={`Ordenar por:`} />
                <SelectOrder>
                  <Select
                    value={this.state.descOrder}
                    onChange={(e) => this.handleChangeOrder(e.target.value)}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value={true}>Mais recente</MenuItem>
                    <MenuItem value={false}>Mais antigo</MenuItem>
                  </Select>
                </SelectOrder>
              </InfoOrdinationContainer>
            </Col>
          </Row>
        </ContentSearch>

        <Content className={'d-none d-xl-block d-xxl-block mb-lg-5 pb-lg-5'}>
          <Row>
            <Col xs={3}>
              <SearchInfo onClick={this.handleChangeSearch}></SearchInfo>
            </Col>
            <Col xs={9}>
              <Row className={'mt-3 pt-3 mb-n3 mb-lg-0 justify-content-center'}>
                {this.infos(infos)}
              </Row>
              <Col xs={12} className={'mb-lg-3 pb-lg-1 d-flex justify-content-center'}>
                {this.state.qtdResultNews < this.state.limit ? (
                  <></>
                ) : (
                  <Button
                    greenButton
                    width={156}
                    widthMobile={150}
                    height={39}
                    text={'CARREGAR MAIS'}
                    onClick={() => this.ViewMore()}></Button>
                )}
              </Col>
            </Col>
          </Row>
        </Content>

        <ContentMobile className={'d-xl-none d-xxl-none'}>
          <Row>
            <Col xs={12}>
              <Row className={'mt-3 pt-3 mb-n3 mb-lg-0 justify-content-center'}>
                {this.infos(infos)}
              </Row>
              <Col xs={12} className={'mb-lg-3 pb-lg-1 d-flex justify-content-center'}>
                {this.state.qtdResultNews < this.state.limit ? (
                  <></>
                ) : (
                  <Button
                    greenButton
                    width={156}
                    widthMobile={150}
                    height={39}
                    text={'CARREGAR MAIS'}
                    onClick={() => this.ViewMore()}></Button>
                )}
              </Col>
            </Col>
          </Row>
        </ContentMobile>
      </>
    );
  }

  render() {
    const { modalState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        loadingState={loadingState}
      />
    );
  }
}

Informations.propTypes = {
  //props
  history: PropTypes.object,
};
export default Informations;
