import styled from 'styled-components';
import { bootstrapMax, bootstrapMin } from '../../utils/devices';
import { colors } from '../../utils/colors';

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  @media ${bootstrapMax.lg} {
    max-width: 100%;
  }
`;
export const Table = styled.table`
  font-size: 1rem;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 0.75rem;
  width: 100%;
  @media ${bootstrapMax.lg} {
    font-size: 14px;
  }
  thead {
    tr {
      border-top: none;
      background-color: unset;
      font-weight: bold;
      padding: 0rem 0rem;
      font-weight: bold;
      border-bottom: 2px solid ${colors.lightGrey};
      white-space: nowrap;
      font-family: 'Trade Gothic LT Bold', serif !important;
      min-width: 200px;
      th {
        border-bottom: 2px solid ${colors.lightGrey};
        background-color: ${colors.secondaryOne};
        color: ${colors.primaryTwo};
        font-weight: bold;
      }
      th:first-child {
        @media ${bootstrapMax.lg} {
          padding-right: 2rem;
          padding-top: 1.5rem;
        }
      }
    }
  }
  tr {
    font-family: 'Trade Gothic LT', serif !important;
    background-color: ${colors.supportOne};
    td {
      color: ${colors.gray};
      padding: 0rem 0rem 0rem 1rem;
    }
    td:first-child {
      color: ${colors.secondaryThree};
      font-weight: bold;
      text-align: left;
    }
    th:first-child,
    td:first-child {
      position: sticky;
      left: 0;
      padding: 0.5rem 0rem 0.5rem 1rem;
      min-width: 180px;
    }
    td:first-child {
      background-color: ${colors.supportOne};
    }
  }
  .fomated-grid {
    padding: 0rem 1rem 0rem 1rem;
  }
`;
