import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMin, width } from '../../utils/devices';
import { Row } from 'react-bootstrap';

import arrow_left_green from '../../assets/icons/arrow-left-green.png';

export const PreviewImg = styled.div`
  width: 125px;
  height: 125px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid ${colors.ligthBrown};
`;

export const PreviewImgBanner = styled.div`
  width: 1060px;
  height: 503px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid ${colors.gray};

  @media ${bootstrapMin.xs} {
    max-width: 250px;
    max-height: 125px;
  }
`;

export const PreviewDoc = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

export const CustomImg = styled.img`
  max-width: 110px;
  max-height: 110px;
`;

export const CustomImgBanner = styled.img`
  max-width: 1060px;
  max-height: 503px;

  @media ${bootstrapMin.xs} {
    max-width: 250px;
    max-height: 125px;
  }
`;

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid ${colors.primary};
  border-radius: 50%;
  position: absolute;
  top: -16px;
  right: -16px;

  background-color: ${colors.white} !important;
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px 26px;
`;

export const LogoImg = styled.img`
  width: 100%;
  max-width: 360px;
  margin-bottom: 50px;
`;

export const PinImg = styled.img`
  width: 100%;
  max-width: 16px;
`;

export const Circle = styled.div`
  width: 55px;
  height: 55px;
  border-color: ${({ selected }) =>
    selected ? colors.primaryTwo : colors.secondaryTwo} !important;
  border-width: ${({ selected }) => (selected ? '2px' : '1px')} !important;
  border: 1px solid;
  border-radius: 50%;
`;

export const Banner = styled.img`
  width: 100%;
  height: 100%;

  @media ${bootstrapMin.xs} {
    width: 100vw;
    height: 275px;
    margin-left: -35px;
    object-fit: cover;
  }
`;

export const ImageGovernanceHierarchy = styled.img`
  width: 100%;
  padding: 0 ${({ zoom, innerWidth }) => innerWidth >= width.lg && !zoom && '18%'};

  @media ${bootstrapMin.sm} {
    padding: 0 18% !important;
  }
`;

export const IconArrowLeft = styled.div`
  background-image: url(${arrow_left_green});
  background-repeat: no-repeat;
  height: 11px;
  width: 16px;
`;

export const IconArrowRight = styled(IconArrowLeft)`
  transform: rotate(180deg);
`;

export const RowStyled = styled(Row)`
  justify-content: space-between;
  top: 45%;
  position: relative;
`;
