import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';

export const GovernanceContainer = styled.div`
  & .brackets-card {
    & .icon-bracket__left {
      margin-top: -25px;
      margin-right: -15px;
    }

    @media ${bootstrapMin.lg} {
      & .column {
        padding-right: 0 !important;
      }
    }
  }

  & .know-our-structure:first-child {
    & .MuiAccordionSummary-root {
      @media ${bootstrapMax.sm} {
        padding-top: 0 !important;
      }
    }
  }

  & .rec-carousel-wrapper {
    width: 100%;
    min-width: 240px;
    margin: 0 -15px;

    & .rec-item-wrapper {
      justify-content: flex-start;
    }

    & .rec-carousel {
      width: 100%;
      min-width: 200px;
    }

    @media ${bootstrapMin.md} {
      display: none;
      margin: 0;
    }
  }
`;

export const ColStyled = styled(Col)`
  @media ${bootstrapMin.lg} {
    padding: 30px 40px 0 35px;
  }
`;

export const GovernanceHierarchyRow = styled(Row)`
  min-height: 225px;
  position: relative;
  width: 100vw;
  margin-left: -50vw;
  left: 49.99%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${colors.supportOne};
`;

export const KnowOurStructureTitle = styled.h3`
  font-size: 20px;
  line-height: 29px;
  margin: 0;

  @media ${bootstrapMin.lg} {
    font-size: 23px;
    line-height: 40px;
  }
`;

export const RowStyled = styled(Row)`
  gap: 40px 0;

  @media ${bootstrapMin.lg} {
    gap: 60px 0;
  }
`;

export const JobTitle = styled.span`
  font-family: 'Trade Gothic LT';
  font-size: 18px;
  line-height: 24px;
  color: ${colors.primaryTwo};
  text-align: center;
  border-bottom: 1px solid ${colors.lightGreyTwo};
  padding-bottom: 5px;
  margin-bottom: 20px;
`;
