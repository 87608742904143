import React, { Component } from 'react';
import { Button, MiniCard, Screen, SubHeader, Text } from '../../../components';

import PropTypes from 'prop-types';
const { defaultStates } = require('../../../utils/defaults');
import {
  stageOfElaboration,
  axesOfActingBlockOne,
  axesOfActingBlockTwo,
} from '../../../enums/index';

import bannerMobile from '../../../assets/imgs/banner-strategic-plan-mobile.png';
import banner from '../../../assets/imgs/banner-strategic-plan.png';
import { Col, Row } from 'react-bootstrap';

import { StrategicPlanContainer } from './styles';

class StrategicPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      innerWidth: window.innerWidth,
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  async updateSize() {
    this.setState({ innerWidth: window.innerWidth });
  }

  folder = () => {
    //folder strategic plan - pdf
    window.open(
      'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/anexo-i-revisao-do-planejamento-estrategico-apresentacao-1-2.pdf',
      '_blank',
    );
  };

  primer = () => {
    //prime strategic plan - pdf
    window.open(
      'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/relatorio-de-revisao-do-planejamento-estrategico.pdf',
      '_blank',
    );
  };

  header() {
    return (
      <>
        <SubHeader
          image
          backgroundImage={banner}
          backgroundImageMobile={bannerMobile}
          minHeightSm={452}
          minHeight={313}
          title={'Plano estratégico'}
        />
      </>
    );
  }

  body() {
    return (
      <StrategicPlanContainer className={'mb-lg-5 pb-lg-5'}>
        <Row className={'mb-lg-3 pb-lg-1 mt-3 mt-lg-0'}>
          <Col xs={12} className={'d-flex justify-content-center mb-lg-3'}>
            <Text
              heading1
              noWrap
              textAlign={'center'}
              title={{ one: 'CONHEÇA NOSSO PLANO ESTRATÉGICO' }}
            />
          </Col>
          <Col xs={12} className={'d-flex justify-content-center'}>
            <Text
              paragraph3
              text={`Bem-vindos(as) a um novo momento do Fundo Garantidor do Cooperativismo de Crédito. Alinhado às diretrizes 
              estratégicas traçadas para o SNCC, em 2023, começaremos a implementar o planejamento estratégico que guiará os 
              trabalhos do FGCoop nos próximos três anos (de 2023 a 2025) e constituirão base para os projetos com os quais o 
              FGCoop quer consolidar o seu papel de instrumento para fortalecimento, confiança e crescimento do SNCC, por meio 
              de sua atuação proativa na detecção de problemas e contribuição para a busca de soluções sistêmicas.<p>
              O novo Planejamento Estratégico traz algumas novidades, que são frutos dos aprendizados acumulados nos últimos anos, 
              confira!</p>`}></Text>
          </Col>
          <Col xs={12} className={'d-flex justify-content-center mb-5 pb-2 pb-lg-0'}>
            <Button
              greenButton
              width={213}
              widthMobile={150}
              text={'BAIXE O FOLDER'}
              onClick={this.folder}></Button>
          </Col>
          <Col className={'d-flex flex-column align-items-center '}></Col>
        </Row>
        <Row>
          <Col className={'d-flex flex-column align-items-center'}>
            <Text heading2 noWrap textAlign={'center'} title={{ one: 'A ELABORAÇÃO' }} />
            <div className={'mt-lg-2 mb-3 pb-1 mb-lg-5'}>
              <Text
                paragraph3
                center
                text={'A elaboração do Plano Estratégico envolveu as seguintes etapas'}
              />
            </div>
          </Col>
        </Row>
        <Row className={'mb-4 pb-1 mb-lg-5 pb-lg-0'}>
          {stageOfElaboration.map((stage, index) => {
            return (
              <Col
                key={stage.title}
                className={'d-flex justify-content-center mb-3 pb-3 mb-xl-0 pb-xl-0'}
                md={12}
                xl={4}>
                <MiniCard
                  key={index}
                  isNumber
                  number={index + 1}
                  title={stage.title}
                  content={stage.content}
                />
              </Col>
            );
          })}
        </Row>
        <Row className={'mb-5 pb-lg-3'}>
          <Col className={'d-flex justify-content-center mb-lg-4'}>
            <Button
              greenButton
              width={321}
              widthMobile={283}
              text={'SAIBA MAIS SOBRE O PLANO ESTRATÉGICO'}
              onClick={this.primer}
            />
          </Col>
        </Row>
        <Row>
          <Col className={'d-flex flex-column align-items-center'}>
            <Text heading2 noWrap textAlign={'center'} title={{ one: 'OBJETIVOS ESTRATÉGICOS' }} />
            <div className={'mt-lg-2 mb-3 pb-1 mb-lg-5'}>
              <Text
                paragraph3
                center
                text={`Nosso trabalho será orientado por 8 Key Results (Resultados Chave), divididos em dois eixos, sendo 3 finalísticos e 5 de gestão.`}
              />
            </div>
          </Col>
        </Row>
        <Row className={'mb-3'}>
          <Col xs={12} lg={6}>
            <MiniCard noBox data={axesOfActingBlockOne} />
          </Col>
          <Col xs={12} lg={6}>
            <MiniCard noBox data={axesOfActingBlockTwo} />
          </Col>
        </Row>
      </StrategicPlanContainer>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  }
}

StrategicPlan.propTypes = {
  //props
  history: PropTypes.object,
};
export default StrategicPlan;
