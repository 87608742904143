import styled from 'styled-components';
import { Accordion as BSAccordion, Offcanvas } from 'react-bootstrap';
import { colors } from '../../utils/colors';

const { Header: BSHeader, Body: BSBody, Item: BSItem } = BSAccordion;

export const Accordion = styled(BSAccordion)`
  .accordion-body {
    border-radius: 4px;
    padding: 0;
    min-width: 150px;
    border: none;
    font-size: ${({ mobile }) => (mobile ? 13 : 16)}px;
  }
`;

export const Body = styled(BSBody)`
  a {
    font-family: 'Trade Gothic LT', serif !important;
    font-size: ${({ mobile }) => (mobile ? 13 : 16)}px;
    color: ${colors.secondaryTwo};
    padding: 4px 0px 4px 20px;
    white-space: nowrap;
    text-decoration: none;
  }

  .is-active {
    font-family: 'Trade Gothic LT Bold', serif !important;
    color: ${colors.primaryThree};
  }
`;

export const Item = styled(BSItem)`
  background-color: transparent;
  border: none;

  .search-info {
    text-decoration: none;
  }

  .is-active {
    text-decoration: none;

    span {
      font-family: 'Trade Gothic LT Bold', serif !important;
      color: ${colors.primaryThree};
    }
  }
`;

export const HeaderAccordion = styled(BSHeader)`
  button[aria-expanded='true'] {
    font-family: 'Trade Gothic LT Bold', serif !important;
    color: ${colors.primaryThree} !important;
  }

  .accordion-button {
    color: ${colors.secondaryTwo} !important;
    background-color: transparent;
    font-size: ${({ mobile }) => (mobile ? 14 : 16)}px;
    outline: none !important;
    box-shadow: none;
    border: none;
    padding: ${({ mobile }) => (mobile ? '10px 0px 5px 0px' : '10px 20px 10px 0px')};

    span {
      font-size: ${({ mobile }) => (mobile ? 14 : 16)}px;
    }

    ::after {
      display: none;
    }
  }
`;
