import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import bannersService from '../../../services/banners';
import {
  Screen,
  Text,
  Input,
  ButtonUpload,
  Image,
  Label,
  Button,
  Checkbox,
} from '../../../components';
import fileService from '../../../services/file';
import Alert from '@mui/material/Alert';
import ImageCompression from '../../../utils/compressImage';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
  baseURL,
} = require('../../../utils/defaults');

class Banners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: {
        title: '',
        subtitle: '',
        link: '',
        files: [],
        filesMobile: [],
        isActive: true,
        isVisible: true,
      },
      ...defaultStates(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });

    const { id } = this.props.match.params;
    if (id !== 'novo') {
      const response = await bannersService.admin.get(id);
      if (response.ok && response.data && response.data.banner) {
        this.setState({
          banner: response.data.banner,
          newRegister: false,
        });
      } else if (response.ok) {
        this.props.history.push('/gestao/banners/novo');
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  handleChange(id, event) {
    const { banner } = this.state;

    if (id === 'files' || id === 'filesMobile') {
      banner[id] = [event.target.files && event.target.files[0]];
    } else if (id === 'removeFile') {
      banner.files = [];
      banner.file_id = null;
    } else if (id === 'removeFileMobile') {
      banner.filesMobile = [];
      banner.file_mobile_id = null;
    } else {
      banner[id] = event.target.value;
    }

    this.setState({ banner });
  }

  async handleSubmit() {
    let { newRegister, banner } = this.state;
    this.setState({ loadingState: true });

    if (newRegister) {
      if (banner.files.length) {
        const compressedFile = await ImageCompression.imageCompress(banner.files);
        const file = new File([compressedFile], compressedFile.name, {
          lastModified: compressedFile.lastModified,
          type: compressedFile.type,
        });
        banner.files[0] = file;
      }

      if (banner.filesMobile.length) {
        const compressedFileMobile = await ImageCompression.imageCompress(banner.filesMobile);

        if (!isNaN(compressedFileMobile)) {
          banner.filesMobile = [];
          const fileMobile = new File([compressedFileMobile], compressedFileMobile.name, {
            lastModified: compressedFileMobile.lastModified,
            type: compressedFileMobile.type,
          });
          banner.filesMobile[0] = fileMobile;
        }
      }

      const response = await bannersService.admin.save(banner);
      if (response.ok) {
        const alertScreenState = {
          title: 'Banner criado com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/banners',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    } else {
      if (banner.files.length && !banner.files[0].id) {
        const compressedFile = await ImageCompression.imageCompress(banner.files);
        let file = new File([compressedFile], compressedFile.name, {
          lastModified: compressedFile.lastModified,
          type: compressedFile.type,
        });
        banner.files[0] = file;
      }
      if (!banner.filesMobile[0]?.id && banner.filesMobile[0] != null) {
        const compressedFile = await ImageCompression.imageCompress(banner.filesMobile);
        let file = new File([compressedFile], compressedFile.name, {
          lastModified: compressedFile.lastModified,
          type: compressedFile.type,
        });
        banner.filesMobile[0] = file;
      }

      const response = await bannersService.admin.edit(banner);

      if (response.ok) {
        const alertScreenState = {
          title: 'Banner alterado com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/banners',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  async handleDelete() {
    this.setState({
      modalState: {
        title: `Remoção de ${this.state.banner.isActive ? 'banner' : 'rascunho'}`,
        text: `Tem certeza que deseja excluir ess${
          this.state.banner.isActive ? 'e banner' : 'e rascunho'
        }?`,
        toggle: true,
        onHide: () => this.modalOnHide(),
        confirm: 'Sim',
        onClickConfirm: async () => await _handleDelete(),
        danger: true,
        cancel: 'Não',
        onClickCancel: () => this.modalOnHide(),
      },
    });

    const _handleDelete = async () => {
      const {
        banner: { id },
      } = this.state;
      this.setState({ loadingState: true });

      const response = await bannersService.admin.delete(id);

      if (response.ok && response.data) {
        const alertScreenState = {
          title: 'Banner excluído com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/banners',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }

      this.setState({ loadingState: false });
    };
  }

  async handleUploadFileTiny(file, cb) {
    const response = await fileService.upload({ files: [file] });

    if (response.ok && response.data && response.data[0]) {
      const file = response.data[0];

      cb(`${baseURL()}/api/file/${file.fileId}/${file.slug}`, {
        title: file.name,
      });
    } else {
      const modalState = {
        title: 'Erro',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };

      this.setState({ modalState });
    }
  }

  body() {
    const { banner } = this.state;

    return (
      <>
        <Row align="center">
          <Col>
            <Text title text={'Banner'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Checkbox
              label={'Ativo'}
              checked={banner.isActive}
              onClick={() => {
                banner.isActive = !banner.isActive;
                this.setState({ banner });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={10} xs={10}>
            <Input
              value={banner.title}
              label={'Título:'}
              placeholder={'Título do banner'}
              maxLength={200}
              onChange={(e) => this.handleChange('title', e)}
            />
          </Col>
          <Col className="d-flex align-items-center mt-5">
            <Checkbox
              label={'Visível'}
              checked={banner.isVisible}
              onClick={() => {
                banner.isVisible = !banner.isVisible;
                this.setState({ banner });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Input
              value={banner.subtitle}
              label={'Subtítulo:'}
              placeholder={'Subtítulo do banner'}
              maxLength={200}
              onChange={(e) => this.handleChange('subtitle', e)}
            />
          </Col>
        </Row>
        <Row>
          {banner.files && banner.files.length ? (
            <Col>
              <Label text={'Imagem do Card:'} />
              <Image
                previewBanner
                img={
                  banner.files[0].id
                    ? `${baseURL()}/api/file/${banner.files[0].id}/${banner.files[0].slug}`
                    : URL.createObjectURL(banner.files[0])
                }
                onClick={() => this.handleChange('removeFile')}
              />
            </Col>
          ) : (
            <Col>
              <Label text={'Imagem do Banner:'} />
              <ButtonUpload onChange={(e) => this.handleChange('files', e)} image />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Alert severity="info" className={'mt-2'}>
              <span>É recomendado que a imagem do banner, tenha o tamanho de:</span>
              <br />
              <span>503px de altura por 1366px de largura</span>
            </Alert>
          </Col>
        </Row>
        <Row>
          {banner.filesMobile && banner.filesMobile[0] != null ? (
            <Col>
              <Label text={'Imagem do Card mobile:'} />
              <Image
                previewBanner
                img={
                  banner.filesMobile[0].id
                    ? `${baseURL()}/api/file/${banner.filesMobile[0].id}/${
                        banner.filesMobile[0].slug
                      }`
                    : URL.createObjectURL(banner.filesMobile[0])
                }
                onClick={() => this.handleChange('removeFileMobile')}
              />
            </Col>
          ) : (
            <Col>
              <Label text={'Imagem do Banner mobile:'} />
              <ButtonUpload onChange={(e) => this.handleChange('filesMobile', e)} image />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Alert severity="info" className={'mt-2'}>
              <span>É recomendado que a imagem para mobile, tenha o tamanho de:</span>
              <br />
              <span>314px de altura por 375px de largura</span>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Input
              value={banner.link}
              label={'Link:'}
              placeholder={'Link'}
              maxLength={200}
              onChange={(e) => this.handleChange('link', e)}
            />
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center">
          {this.state.banner.id ? (
            <Col>
              <Button danger text={'Excluir'} onClick={this.handleDelete} />
            </Col>
          ) : (
            <></>
          )}
          <Col>
            <Button text={'Salvar'} onClick={this.handleSubmit} />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          admin
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

Banners.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Banners;
