import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Screen, SubHeader, Card, DownloadCard, Text, Button } from '../../../components';
import newsService from '../../../services/news';
import infosService from '../../../services/infos';
import documentService from '../../../services/documents';
import searchedpageService from '../../../services/searchedpage';

import PropTypes from 'prop-types';
import { ColStyled } from './styles';
const {
  defaultStates,
  defaultModalOnHide,
  defaultPageImg,
  baseURL,
} = require('../../../utils/defaults');

class SearchedResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      descOrder: true,
      offSetNews: 0,
      offSetInfos: 0,
      offSetDocs: 0,
      offSetPages: 0,
      countOffSet: 3,
      countOffSetDoc: 4,
      limit: 3,
      limitDoc: 4,
      news: [],
      qtdResultNews: 0,
      infos: [],
      qtdResultInfos: 0,
      docs: [],
      qtdResultDocs: 0,
      pages: [],
      qtdResultPages: 0,
      viewMoreNews: false,
      viewMoreInfos: false,
      viewMoreDocs: false,
      viewMorePages: false,
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.handleOffsetNews = this.handleOffsetNews.bind(this);
    this.handleOffsetInfos = this.handleOffsetInfos.bind(this);
    this.handleOffsetDocs = this.handleOffsetDocs.bind(this);
    this.handleOffsetPages = this.handleOffsetPages.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  async componentDidMount() {
    let searchParams = new URLSearchParams(location.search).get('search');
    this.setState({ search: searchParams });
    this.handleSearchAll(searchParams);
  }

  async componentDidUpdate() {
    let searchParams = new URLSearchParams(location.search).get('search');
    if (searchParams !== this.state.search) {
      this.setState({ search: searchParams });
      this.handleSearchAll(searchParams);
    }
  }

  visibilityViewMoreNews(result, limit) {
    if (result === limit) this.setState({ viewMoreNews: true });
    else this.setState({ viewMoreNews: false });
  }

  visibilityViewMoreInfos(result, limit) {
    if (result === limit) this.setState({ viewMoreInfos: true });
    else this.setState({ viewMoreInfos: false });
  }

  visibilityViewMoreDocs(result, limit) {
    if (result === limit) this.setState({ viewMoreDocs: true });
    else this.setState({ viewMoreDocs: false });
  }

  visibilityViewMorePages(result, limit) {
    if (result === limit) this.setState({ viewMorePages: true });
    else this.setState({ viewMorePages: false });
  }

  async handleSearchAll(search) {
    this.setState({ offSetNews: 0, offSetInfos: 0, offSetDocs: 0, offSetPages: 0 });
    this.setState({ loadingState: true });
    this.handleSearchNews(search);
    this.handleSearchInformations(search);
    this.handleSearchDocuments(search);
    this.handleSearchPages(search);
    this.setState({ loadingState: false });
  }

  async handleSearchNews(search) {
    const response = await newsService.user.getList({
      search: search,
      descOrder: this.state.descOrder,
      limit: this.state.limit,
    });
    if (response && response.ok && response.data) {
      this.visibilityViewMoreNews(response.data.length, this.state.limit);
      this.setState({ news: response.data });
      this.setState({ qtdResultNews: response.data.length });
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  handleOffsetNews(offset) {
    let count = offset;
    count += this.state.countOffSet;
    this.setState({ offSetNews: count });
    return count;
  }

  async ViewMoreNews() {
    const response = await newsService.user.getList({
      search: this.state.search,
      descOrder: this.state.descOrder,
      limit: this.state.limit,
      offset: this.handleOffsetNews(this.state.offSetNews),
    });
    if (response && response.ok && response.data) {
      this.visibilityViewMoreNews(response.data.length, this.state.limit);
      if (response.data.length > 0) {
        this.setState({ qtdResultNews: response.data.length });
        this.setState({ news: this.state.news.concat(response.data) });
      }
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  async handleSearchInformations(search) {
    const response = await infosService.user.getList({
      search: search,
      descOrder: this.state.descOrder,
      limit: this.state.limit,
    });
    if (response && response.ok && response.data) {
      this.visibilityViewMoreInfos(response.data.length, this.state.limit);
      this.setState({ infos: response.data });
      this.setState({ qtdResultInfos: response.data.length });
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  handleOffsetInfos(offset) {
    let count = offset;
    count += this.state.countOffSet;
    this.setState({ offSetInfos: count });
    return count;
  }

  async ViewMoreInfos() {
    const response = await infosService.user.getList({
      search: this.state.search,
      descOrder: this.state.descOrder,
      limit: this.state.limit,
      offset: this.handleOffsetInfos(this.state.offSetInfos),
    });
    if (response && response.ok && response.data) {
      this.visibilityViewMoreInfos(response.data.length, this.state.limit);
      if (response.data.length > 0) {
        this.setState({ qtdResultInfos: response.data.length });
        this.setState({ infos: this.state.infos.concat(response.data) });
      }
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  async handleSearchDocuments(search) {
    const response = await documentService.user.getList({
      name: search,
      descOrder: this.state.descOrder,
      limit: this.state.limitDoc,
    });
    if (response && response.ok && response.data) {
      this.visibilityViewMoreDocs(response.data.length, this.state.limitDoc);
      this.setState({ docs: response.data });
      this.setState({ qtdResultDocs: response.data.length });
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  handleOffsetDocs(offset) {
    let count = offset;
    count += this.state.countOffSetDoc;
    this.setState({ offSetDocs: count });
    return count;
  }

  async ViewMoreDocs() {
    const response = await documentService.user.getList({
      name: this.state.search,
      descOrder: this.state.descOrder,
      limit: this.state.limitDoc,
      offset: this.handleOffsetDocs(this.state.offSetDocs),
    });
    if (response && response.ok && response.data) {
      this.visibilityViewMoreDocs(response.data.length, this.state.limitDoc);
      if (response.data.length > 0) {
        this.setState({ qtdResultDocs: response.data.length });
        this.setState({ docs: this.state.docs.concat(response.data) });
      }
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  async handleSearchPages(search) {
    const response = await searchedpageService.user.getList({
      content: search,
      limit: this.state.limit,
    });
    if (response && response.ok && response.data) {
      this.visibilityViewMorePages(response.data.length, this.state.limit);
      this.setState({ pages: response.data });
      this.setState({ qtdResultPages: response.data.length });
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  handleOffsetPages(offset) {
    let count = offset;
    count += this.state.countOffSet;
    this.setState({ offSetPages: count });
    return count;
  }

  async ViewMorePages() {
    const response = await searchedpageService.user.getList({
      content: this.state.search,
      descOrder: this.state.descOrder,
      limit: this.state.limit,
      offset: this.handleOffsetPages(this.state.offSetPages),
    });
    if (response && response.ok && response.data) {
      this.visibilityViewMorePages(response.data.length, this.state.limit);
      if (response.data.length > 0) {
        this.setState({ qtdResultPages: response.data.length });
        this.setState({ pages: this.state.pages.concat(response.data) });
      }
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  news = (news) => (
    <ColStyled
      xs={12}
      lg={9}
      xl={12}
      className={`no-gutters d-flex flex-row flex-wrap justify-content-center justify-content-md-between justify-content-lg-start`}>
      {news.map(({ id, title, hashtag, file, postingDate }) => (
        <Card
          key={id}
          id={id}
          date={postingDate}
          title={title}
          hashtag={hashtag}
          image={file && file.id ? `${baseURL()}/api/file/${file.id}/${file.slug}` : ''}
          {...this.props}
        />
      ))}
    </ColStyled>
  );

  infos = (infos) => (
    <ColStyled
      xs={12}
      lg={9}
      xl={12}
      className={`no-gutters d-flex flex-row flex-wrap justify-content-center justify-content-md-between justify-content-lg-start`}>
      {infos.map(({ id, title, content, file, documentDate }) => (
        <Card
          searchInfo={true}
          key={id}
          id={id}
          date={documentDate}
          title={title}
          content={content}
          image={file && file.id ? `${baseURL()}/api/file/${file.id}/${file.slug}` : ''}
          {...this.props}
        />
      ))}
    </ColStyled>
  );

  docs = (docs) => (
    <Col className={`no-gutters d-flex flex-row flex-wrap`}>
      {docs.map(({ id, name, urlS3 }) => (
        <Col key={id} lg={6} className={'mb-4 mb-lg-5 pb-3'}>
          <DownloadCard title={name} href={urlS3} />
        </Col>
      ))}
    </Col>
  );

  pages = (pages) => (
    <ColStyled
      xs={12}
      lg={9}
      xl={12}
      className={`no-gutters d-flex flex-row flex-wrap justify-content-center justify-content-md-between justify-content-lg-start`}>
      {pages.map(({ id, name, content, url }) => (
        <Card
          searchPage={true}
          key={id}
          id={id}
          title={name}
          content={content}
          image={defaultPageImg(name)}
          url={url}
          {...this.props}
        />
      ))}
    </ColStyled>
  );

  header() {
    return (
      <>
        <SubHeader
          back
          onClick={() => {
            history.back();
          }}
        />
      </>
    );
  }

  body() {
    const { news, infos, docs, pages, search } = this.state;

    return (
      <>
        <Row className="mt-5 pt-4 mb-n3 mb-lg-5 pb-lg-5">
          <Col className={'d-flex flex-column align-items-center'}>
            <Text
              heading2
              noWrap
              textAlign={'center'}
              title={{ one: `RESULTADOS PARA "${search}"` }}
            />
          </Col>
        </Row>
        {/* News */}
        {this.state.qtdResultNews > 0 && (
          <Row className="mt-3 pt-3 mb-n3 mb-lg-0">
            <Col className={'d-flex flex-column align-items-center'}>
              <Text heading4 noWrap textAlign={'center'} title="NOTÍCIAS" />
            </Col>
          </Row>
        )}
        {this.state.qtdResultNews > 0 && (
          <Row className={'mt-4 pt-3 mb-n3 mb-lg-0 justify-content-center'}>{this.news(news)}</Row>
        )}
        <Row className="mb-3 pb-3 mb-n3 mb-lg-0">
          <Col xs={12} className={'mb-lg-3 pb-lg-1 d-flex justify-content-center'}>
            {!this.state.viewMoreNews ? (
              <></>
            ) : (
              <Button
                greenButton
                width={156}
                widthMobile={150}
                height={39}
                text={'CARREGAR MAIS'}
                onClick={() => this.ViewMoreNews()}></Button>
            )}
          </Col>
        </Row>
        {/* Infos */}
        {this.state.qtdResultInfos > 0 && (
          <Row className="mt-3 pt-3 mb-n3 mb-lg-0">
            <Col className={'d-flex flex-column align-items-center'}>
              <Text heading4 noWrap textAlign={'center'} title="INFORMAÇÕES" />
            </Col>
          </Row>
        )}
        {this.state.qtdResultInfos > 0 && (
          <Row className={'mt-3 pt-3 mb-n3 mb-lg-0 justify-content-center'}>
            {this.infos(infos)}
          </Row>
        )}
        <Row className="mb-3 pb-3 mb-n3 mb-lg-0">
          <Col xs={12} className={'mb-lg-3 pb-lg-1 d-flex justify-content-center'}>
            {!this.state.viewMoreInfos ? (
              <></>
            ) : (
              <Button
                greenButton
                width={156}
                widthMobile={150}
                height={39}
                text={'CARREGAR MAIS'}
                onClick={() => this.ViewMoreInfos()}></Button>
            )}
          </Col>
        </Row>
        {/* Documents */}
        {this.state.qtdResultDocs > 0 && (
          <Row className="mt-3 pt-3 mb-n3 mb-lg-0">
            <Col className={'d-flex flex-column align-items-center'}>
              <Text heading4 noWrap textAlign={'center'} title="DOCUMENTOS" />
            </Col>
          </Row>
        )}
        {this.state.qtdResultDocs > 0 && (
          <Row className={'mt-3 pt-3 mb-n3 mb-lg-0'}>{this.docs(docs)}</Row>
        )}
        <Row className="mb-3 pb-3 mb-n3 mb-lg-0">
          <Col xs={12} className={'mb-lg-3 pb-lg-1 d-flex justify-content-center'}>
            {!this.state.viewMoreDocs ? (
              <></>
            ) : (
              <Button
                greenButton
                width={156}
                widthMobile={150}
                height={39}
                text={'CARREGAR MAIS'}
                onClick={() => this.ViewMoreDocs()}></Button>
            )}
          </Col>
        </Row>
        {/* Pages */}
        {this.state.qtdResultPages > 0 && (
          <Row className="mt-3 pt-3 mb-n3 mb-lg-0">
            <Col className={'d-flex flex-column align-items-center'}>
              <Text heading4 noWrap textAlign={'center'} title="PÁGINAS" />
            </Col>
          </Row>
        )}
        {this.state.qtdResultPages > 0 && (
          <Row className={'mt-3 pt-3 mb-n3 mb-lg-0 justify-content-center'}>
            {this.pages(pages)}
          </Row>
        )}
        <Row className="mb-3 pb-3 mb-n3 mb-lg-0">
          <Col xs={12} className={'mb-lg-3 pb-lg-1 d-flex justify-content-center'}>
            {!this.state.viewMorePages ? (
              <></>
            ) : (
              <Button
                greenButton
                width={156}
                widthMobile={150}
                height={39}
                text={'CARREGAR MAIS'}
                onClick={() => this.ViewMorePages()}></Button>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  }
}

SearchedResult.propTypes = {
  //props
  history: PropTypes.object,
};
export default SearchedResult;
