import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';
import { Screen, Text, Input, Label, Button, Checkbox } from '../../../components';
import { Editor } from '@tinymce/tinymce-react';
import governancesService from '../../../services/governances';
import quote1 from '../../../assets/icons/admin/quote-left-top.png';
import quote2 from '../../../assets/icons/admin/quote-right-bottom.png';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
} = require('../../../utils/defaults');

class Governances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      governance: {
        governance: '',
        office: '',
        name: '',
        description: '',
        newDescription: '',
        isActive: true,
      },
      ...defaultStates(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });

    const { id } = this.props.match.params;

    if (id !== 'novo') {
      const response = await governancesService.admin.get(id);
      if (response.ok && response.data && response.data.governance) {
        this.setState({
          governance: response.data.governance,
          newRegister: false,
        });
      } else if (response.ok) {
        this.props.history.push('/gestao/governances/novo');
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  handleChange(id, event) {
    const { governance } = this.state;

    if (id === 'newDescription') {
      governance[id] = event.target.getContent();
    } else if (id !== 'governance') {
      let value = event.target.value;
      governance[id] = value.toUpperCase();
    } else {
      governance[id] = event.target.value;
    }
    this.setState({ governance });
  }

  async handleSubmit() {
    const { newRegister, governance } = this.state;
    governance.description = governance.newDescription || governance.description;
    delete governance.newDescription;
    this.setState({ loadingState: true });
    if (newRegister) {
      const response = await governancesService.admin.save(governance);

      if (response.ok) {
        const alertScreenState = {
          title: 'Governança criada com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/governances',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    } else {
      const response = await governancesService.admin.edit(governance);

      if (response.ok) {
        const alertScreenState = {
          title: 'Governança alterada com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/governances',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  async handleDelete() {
    this.setState({
      modalState: {
        title: `Remoção de ${this.state.governance.isActive ? 'Governança' : 'Rascunho'}`,
        text: `Tem certeza que deseja excluir ess${
          this.state.governance.isActive ? 'a governança' : 'e rascunho'
        }?`,
        toggle: true,
        onHide: () => this.modalOnHide(),
        confirm: 'Sim',
        onClickConfirm: async () => await _handleDelete(),
        danger: true,
        cancel: 'Não',
        onClickCancel: () => this.modalOnHide(),
      },
    });

    const _handleDelete = async () => {
      const {
        governance: { id },
      } = this.state;
      this.setState({ loadingState: true });

      const response = await governancesService.admin.delete(id);

      if (response.ok && response.data) {
        const alertScreenState = {
          title: 'Governança Excluida com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/governances',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }

      this.setState({ loadingState: false });
    };
  }

  body() {
    const { governance } = this.state;
    const options = [
      { name: 'Selecione uma opção'},
      { name: 'CONSELHO DE ADMINISTRAÇÃO' },
      { name: 'CONSELHO FISCAL - MANDATO 2023 A 2026' },
      { name: 'DIRETORIA EXECUTIVA' },
      { name: 'COMITÊ TÉCNICO DE ASSESSORAMENTO' },
    ];

    return (
      <>
        <Row align="center">
          <Col>
            <Text title text={'Governança'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Checkbox
              label={'Ativo'}
              checked={governance.isActive}
              onClick={() => {
                governance.isActive = !governance.isActive;
                this.setState({ governance });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Label text={'Selecione a Governança'} />
            <Form.Select
              size="lg"
              value={governance.governance}
              onChange={(e) => this.handleChange('governance', e)}>
              {options.map((option, idx) => (
                <option key={idx} value={option.name}>
                  {option.name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Input
              value={governance.office}
              label={'Cargo:'}
              placeholder={'Cargo na governança'}
              maxLength={200}
              onChange={(e) => this.handleChange('office', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Input
              value={governance.name}
              label={'Nome'}
              placeholder={'Nome'}
              maxLength={200}
              onChange={(e) => this.handleChange('name', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label text={'Descrição da governança:'} />
            <Editor
              initialValue={governance.description}
              apiKey={process.env.REACT_APP_TINY_APIKEY}
              init={{
                height: 500,
                menubar: false,
                language: 'pt_BR',
                content_style: `body { font-size: 20px; font-family: "GT Walsheim Pro", serif !important; }`,
                plugins: [
                  'advlist autolink lists link charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount image importcss',
                ],
                setup: (editor) => {
                  editor.ui.registry.addButton('quote', {
                    text: 'Aspas',
                    tooltip: 'Insere bloco de texto envolvido por aspas customizadas',
                    onAction: () => {
                      editor.insertContent(
                        '<div class="row">' +
                          `<div class="col-1 col-quote1"><img src="${quote1}" class="quote1" style="max-width: 35px; height: fit-content;" alt=""/></div>` +
                          '<div class="col col-text"><p class="governance-quote-text" id="myText" style="text-align:left; margin-top: 25px; overflow-wrap: anywhere;">&lt;&lt;Edite aqui seu conteúdo... As aspas devem ocupar toda linha como um bloco de texto.&gt;&gt;</p></div>' +
                          `<div class="col-1 col-quote2 d-flex align-items-end"><img src="${quote2}" class="quote2" style="max-width: 35px; height: fit-content; justify-content: end;" alt=""/></div>` +
                          '</div>',
                      );
                    },
                  });
                },
                toolbar:
                  'undo redo | formatselect  | bold italic backcolor | ' +
                  'alignleft aligncenter lignright alignjustify | ' +
                  'bullist numlist outdent indent | removeformat | quote | help',
              }}
              onChange={(e) => this.handleChange('newDescription', e)}
            />
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center">
          {this.state.governance.id ? (
            <Col>
              <Button danger text={'Excluir'} onClick={this.handleDelete} />
            </Col>
          ) : (
            <></>
          )}
          <Col>
            <Button text={'Salvar'} onClick={this.handleSubmit} />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          admin
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

Governances.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Governances;
