import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataGrid, GridOverlay, GridPagination, ptBR } from '@mui/x-data-grid';
import './styles.css';
import { getLabelFromEnum } from '../../utils/helpers';
import { Col, Row } from 'react-bootstrap';

class TableList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: window.innerWidth < 768,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ mobile: window.innerWidth < 768 });
    });
  }

  render() {
    const {
      data,
      onCellClick,
      loading,
      pageSize,
      checkboxSelection,
      onSelectionModelChange,
      isRowSelectable,
      noIdData,
      totalizerInFooter,
      totalizerData,
    } = this.props;
    const { columns = [] } = this.props;
    const columnsMobile = [{ table: [] }, { table: [] }, { table: [] }];
    const columnsWeb = noIdData ? [] : [{ field: 'id', headerName: 'ID', minWidth: 10 }];

    if (!columns.length) {
      columns.push({ field: 'name', headerName: 'Name' });
      if ((data[0] && data[0].description) || !data) {
        columns.push({ field: 'description', headerName: 'Descrição' });
      }
    }

    columns.map((e) => {
      columnsWeb.push({
        field: e.field,
        headerName: e.headerName,
        flex: e.flex ? e.flex : 1,
        minWidth: e.width && e.width,
        align: e.align ? e.align : 'left',
        headerAlign: e.headerAlign ? e.headerAlign : 'left',
        ...(e.typeEnum
          ? {
              renderCell: ({ value }) => {
                return <>{getLabelFromEnum(value, e.typeEnum)}</>;
              },
            }
          : {
              renderCell: (cellValues) => <span title={cellValues.value}>{cellValues.value}</span>,
            }),
      });
    });

    const rows = [
      { id: 1, name: 'Snow', description: 'Jon' },
      {
        id: 2,
        name: 'Lannister',
        description:
          'Cersei Cersei Cersei Cersei Cersei Cersei Cersei Cersei Cersei Cersei Cersei Cersei Cersei ',
        age: 42,
      },
      { id: 3, name: 'Lannister', description: 'Jaime', title: 'Lannister', link: 'Jaime' },
      { id: 4, name: 'Stark', description: 'Arya', title: 'Stark', link: 'Arya' },
      { id: 5, name: 'Targaryen', description: 'Daenerys', title: 'Targaryen', link: 'Daenerys' },
      { id: 6, name: 'Melisandre', description: null },
      { id: 7, name: 'Clifford', description: 'Ferrara' },
      { id: 8, name: 'Frances', description: 'Rossini' },
      { id: 9, name: 'Roxie', description: 'Harvey' },
    ];

    columns.forEach((column) => {
      if (column.field === 'cooperative' || column.field === 'decreeOfSpecialRegime') {
        columnsMobile[0].table.push(column);
      }
      if (column.field === 'cooperative' || column.field === 'startOfPayment') {
        columnsMobile[1].table.push(column);
      }
      if (
        column.field === 'cooperative' ||
        column.field === 'totalPayments' ||
        column.field === 'balanceToPay'
      ) {
        columnsMobile[2].table.push(column);
      }
    });

    const totalizerComponent = () => {
      if (totalizerInFooter) {
        return (
          <Row className={'totalizer-in-footer'}>
            <Col style={{ textAlign: 'left' }}>
              <div>Total</div>
            </Col>
            {totalizerData.map((data, index) => {
              return (
                <Col key={index} style={{ flex: '0 auto', width: `${data.width}px` }}>
                  <p>{data.total}</p>
                </Col>
              );
            })}
          </Row>
        );
      }
    };

    const totalizerComponentMobile = (table) => {
      const totalizer = [];
      switch (table) {
        case 1:
          totalizer.push(totalizerData[0]);
          break;
        case 2:
          totalizer.push(totalizerData[1]);
          totalizer.push(totalizerData[2]);
          break;
        case 3:
          totalizer.push(totalizerData[3]);
          totalizer.push(totalizerData[4]);
          break;
      }

      if (totalizerInFooter) {
        return (
          <Row className={'totalizer-in-footer'}>
            <Col style={{ textAlign: 'left' }}>
              <div>Total</div>
            </Col>
            {totalizer.map((t, index) => {
              return (
                <Col key={index} style={{ flex: '0 auto', width: `${t.widthMobile}%` }}>
                  <p>{t.total}</p>
                </Col>
              );
            })}
          </Row>
        );
      }
    };

    const components = {
      NoRowsOverlay: () => <GridOverlay height="100%">Nenhum registro encontrado</GridOverlay>,
      Footer: () => (totalizerInFooter ? totalizerComponent() : <GridPagination />),
    };

    return (
      <div style={{ width: '100%' }}>
        <div
          className={'d-none d-xl-flex'}
          style={{ height: 350, width: '100%', marginTop: '25px' }}>
          <DataGrid
            className={'is-no-admin'}
            onCellClick={onCellClick}
            onSelectionModelChange={onSelectionModelChange}
            isRowSelectable={isRowSelectable}
            rows={data || rows}
            columns={columnsWeb}
            pageSize={pageSize || 10}
            rowsPerPageOptions={[pageSize || 10]}
            rowHeight={52}
            isCellEditable={() => false}
            headerHeight={50}
            checkboxSelection={checkboxSelection}
            disableSelectionOnClick={false}
            loading={loading}
            disableExtendRowFullWidth={false}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            components={components}
            showCellRightBorder
          />
        </div>
        {columnsMobile.map((columnMobile, index) => {
          return (
            <div
              className={'d-flex d-xl-none'}
              key={index}
              style={{ height: 260, width: '100%', marginTop: '20px' }}>
              <DataGrid
                className={'is-no-admin d-flex d-xl-none'}
                onCellClick={onCellClick}
                onSelectionModelChange={onSelectionModelChange}
                isRowSelectable={isRowSelectable}
                rows={data || rows}
                columns={columnMobile.table}
                pageSize={pageSize || 10}
                rowsPerPageOptions={[pageSize || 10]}
                rowHeight={31}
                isCellEditable={() => false}
                headerHeight={74}
                checkboxSelection={checkboxSelection}
                disableSelectionOnClick={false}
                loading={loading}
                disableExtendRowFullWidth={false}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                components={{
                  NoRowsOverlay: () => (
                    <GridOverlay height="100%">Nenhum registro encontrado</GridOverlay>
                  ),
                  Footer: () =>
                    totalizerInFooter ? totalizerComponentMobile(index + 1) : <GridPagination />,
                }}
                showCellRightBorder
              />
            </div>
          );
        })}
      </div>
    );
  }
}

TableList.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  onCellClick: PropTypes.func,
  loading: PropTypes.bool,
  checkboxSelection: PropTypes.bool,
  onSelectionModelChange: PropTypes.func,
  isRowSelectable: PropTypes.func,
  pageSize: PropTypes.number,
  typeEnum: PropTypes.array,
  noIdData: PropTypes.bool,
  totalizerInFooter: PropTypes.bool,
  totalizerData: PropTypes.array,
};

export default TableList;
