import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax, bootstrapMin, device } from '../../utils/devices';

import newsletter from '../../assets/icons/newsletter.png';

export const Input = styled.input`
  height: 40px;
  padding: 0 10px;
  border-style: solid;
  border-width: 1px;
  margin-top: 5px;
  :focus {
    outline: 0;
  }
  color: ${colors.black};

  ::placeholder {
    color: ${colors.primaryTwo};
    opacity: 0.4;
    font-size: 12px;
    font-weight: bold;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.primaryTwo};
  }
`;

export const InputDisabled = styled(Input)`
  background: transparent;
  height: 34px;
  border-style: solid;
  border-color: ${colors.white};
  color: ${colors.white};
`;

export const InputTransparent = styled(Input)`
  background: transparent;
  height: 34px;
  border-style: solid;
  border-color: ${colors.white};
  color: ${colors.white};
`;

export const InputBrown = styled(Input)`
  border-width: 1.5px;
  border-color: ${colors.primary};
`;

export const BigInputBrown = styled(Input)`
  border-width: 1.5px;
  border-color: ${colors.primary};
  height: 55px;
`;

export const InputSearch = styled(InputBrown)`
  padding-left: 35px;
`;

export const InputCloseImg = styled.img`
  width: 18px;
  display: flex;
  position: absolute;
  top: 15px;
  right: 25px;
  &:hover {
    cursor: pointer;
  }
`;

export const InputSearchImg = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 16px;
  right: 16px;

  background-image: url(${({ img }) => img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;
`;

export const InputSearchBlack = styled(Input)`
  border-width: 1.5px;
  background-color: ${colors.almostBlack};
  color: ${colors.white};
  border: unset !important;
  padding-left: 37px;
  padding-right: 37px;
`;

export const InputSearchWhite = styled(Input)`
  padding-left: 20px;
  padding-right: 37px;
  font-size: 14px;
  max-width: 277px;
  width: 100%;
  height: 36px;
  border: 1px solid ${colors.primaryTwo};
  border-radius: 8px;
  white-space: nowrap;
`;

export const InputSearchNews = styled(Input)`
  padding-left: 20px;
  margin-top: 0;
  font-size: 14px;
  width: 100%;
  min-width: 352px;
  height: 45px;
  border: 1px solid ${colors.primaryTwo};
  border-radius: 8px;
  white-space: nowrap;

  @media ${bootstrapMax.md} {
    width: 200px;
    min-width: 200px;
  }
`;

export const InputMargin = styled(InputBrown)`
  margin-bottom: 45px;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Search = styled.div`
  position: relative;
  max-width: 277px;
  width: 100%;
`;

export const InputBlack = styled(Input)`
  border-width: 1.5px;
  background-color: ${colors.almostBlack};
  color: ${colors.white};
  border: unset !important;
`;

export const InputBlackPassword = styled(Input)`
  height: 40px;
  padding: 0 10px;
  border-style: solid;
  border-width: 1px;
  margin: 5px 0 5px 0;
  :focus {
    outline: 0;
  }
  background-color: ${colors.almostBlack};
  color: ${colors.white};
  border: unset !important;
`;

export const InputRounded = styled(Input)`
  border-radius: 8px !important;
  width: 100%;
  height: ${({ height }) => height}px !important;
  padding: 0 ${({ required }) => (required ? 45 : 60)}px 0
    ${({ footer }) => (footer ? '15' : '28')}px;
  border: ${({ border }) => (border ? `1px solid ${colors.primaryTwo}` : 'none')};
  font-size: ${({ fontSize }) => fontSize}px;
  margin: 0;

  ${({ error }) => error && `border: 1px solid ${colors.red};`}

  @media ${bootstrapMin.lg} {
    width: ${({ width }) => width}px !important;
  }
`;

export const Rounded = styled(Search)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${({ width }) => width}px;
`;

export const NewsletterIcon = styled.div`
  width: 29px;
  height: 29px;
  position: absolute;
  right: 22px;
  top: 8px;

  background-image: url(${newsletter});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

export const RequiredIcon = styled.div`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryOne};
  position: absolute;
  right: 35px;
  font-size: 16px;
  line-height: 18px;
  top: 17px;

  &:: after {
    content: '*';
  }
`;

export const Textarea = styled.textarea`
  border-radius: 8px !important;
  width: 100%;
  padding: 33px 43px 10px 28px;
  border: 1px solid #0079ba;
  font-size: 14px;
  margin: 0;
  height: 272px !important;
  resize: none;
  color: ${colors.black};

  ${({ error }) => error && `border: 1px solid ${colors.red};`}

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: ${colors.primaryTwo};
    opacity: 0.4;
    font-size: 12px;
    font-weight: bold;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.primaryTwo};
  }
`;

export const SpanError = styled.span`
  font-size: 12px;
  position: absolute;
  color: ${colors.red};
`;
