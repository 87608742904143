const axios = require('axios');
const jwt = require('jsonwebtoken');

const bannerWhoWeAre = require('../assets/imgs/banner-who-we-are-mobile.png');
const strategicPlan = require('../assets/imgs/banner-strategic-plan-mobile.png');
const standards = require('../assets/imgs/banner-standards-mobile.png');
const guarantees = require('../assets/imgs/banner-guarantees-mobile.png');
const backgroundFeatures = require('../assets/imgs/banner-background-features-mobile.png');

const governance = require('../assets/imgs/cards/search/governance.png');
const information = require('../assets/imgs/cards/search/information.png');
const ordinaryCoverage = require('../assets/imgs/cards/search/ordinaryCoverage.png');

const userDefault = {
  id: 0,
  email: '',
  isAdmin: false,
  name: '',
};

exports.defaultStates = () => {
  const token = localStorage.getItem('token');
  const dataToken = token && jwt.decode(token);
  const decoded = dataToken ? dataToken.data && dataToken.data.user : userDefault;

  return {
    newRegister: true,
    modalState: {
      title: null,
      text: null,
      warning: null,
      toggle: false,
      onHide: null,
    },
    alertScreenState: {
      open: false,
      title: null,
      text: null,
      onClick: null,
      pagePath: null,
      forceReload: null,
    },
    loadingState: false,
    userLogged: decoded,
    app: process.env.REACT_APP_WEB !== 'true',
  };
};

exports.defaultAlertScreenOnClick = (that) => {
  const { alertScreenState } = that.state;
  const { pagePath, forceReload } = that.state.alertScreenState;
  alertScreenState.open = false;
  that.setState(alertScreenState);

  that.props.history.push(pagePath || '/gestao');
  if (forceReload) location.reload();
};

exports.defaultModalOnHide = (that) => {
  const { modalState } = that.state;
  modalState.toggle = false;
  that.setState(modalState);
};

exports.Header = (type) => {
  let headers = new Headers();
  const token = localStorage.getItem('token');
  if (type !== 'file') headers.append('Content-Type', 'application/json');
  headers.append('Authorization', token ? `Bearer ${token}` : undefined);
  return headers;
};

exports.axiosDefault = (type, contentType) => {
  const token = localStorage.getItem('token');
  const baseURL = this.baseURL();

  const configs = {
    headers: {
      'Strict-Transport-Security': 'max-age=15768000;includeSubdomains',
      'Referrer-Policy': 'strict-origin',
      'X-Frame-Options': 'SAMEORIGIN',
      'X-Content-Type-Options': 'nosniff',
      'Content-Security-Policy': "frame-ancestors 'none'",
      Authorization: token ? `Bearer ${token}` : undefined,
      withCredentials: true,
      fromAdmin: process.env.REACT_APP_WEB,
      baseURL: baseURL,
    },
    withCredentials: true,
  };

  configs.baseURL = baseURL;

  if (type !== 'file') configs.headers['Content-Type'] = 'application/json';

  if (type === 'download') configs.headers['Content-Type'] = contentType;

  return axios.create(configs);
};

exports.baseURL = () => {
  return process.env.REACT_APP_DEV_SERVER_URL;
};

exports.clientURL = () => {
  return process.env.REACT_APP_DEV_CLIENT_URL;
};

exports.downloadFile = (id, slug) => {
  const link = document.createElement('a');
  link.download = slug;
  link.href = `${this.baseURL()}/api/file/${id}/${slug}/download`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

exports.openDownloadPage = (id, slug) => {
  window.open(`${this.baseURL()}/download/${id}/${slug}`);
};

exports.handleErrors = (error) => {
  if (error.response && error.response.data && error.response.data.log) {
    console.error('Erro:', error.response.data.log);
  }

  if (error.response && error.response.data && error.response.data.logout) {
    window.location.href = '/gestao/logout';
  }

  const statusCode = (error && error.response && error.response.status) || null;

  let data;
  if (error.response && error.response.data) {
    data = {};
    if (error.response.data.message) {
      data = error.response.data;
    } else {
      data.message =
        statusCode && statusCode === 504
          ? 'Falha ao conectar com o servidor! Tente novamente mais tarde.'
          : 'Erro inesperado';
    }
    data.statusCode = statusCode;
  }

  if (!data) console.info(error);

  return data || { ok: false, message: 'Erro inesperado! Falha na conexão.', statusCode };
};

exports.defaultSearchInfos = (category) => {
  switch (category) {
    case 'rel_manager':
      return 'RELATORIO_GESTAO';
    case 'rel_sncc':
      return 'RELATORIO_SNCC';
    case 'bo_mensal':
      return 'BOLETIM_MENSAL';
    case 'de_financeiro':
      return 'DEMONSTRATIVO_FINANCEIRO';
    case 'ma_divulgacao':
      return 'MATERIAIS_DIVULGACAO';
    case 'info_fgcoop':
      return 'INFORMATIVO_FGCOOP';
    case 'rel_especiais':
      return 'RELATORIOS_ESPECIAIS';
    case 'anual':
      return 'ANUAL';
    case 'semestral':
      return 'SEMESTRAL';
    default:
      return '';
  }
};

exports.defaultPageImg = (page) => {
  switch (page) {
    case 'QUEM SOMOS':
      return bannerWhoWeAre.default;
    case 'CARACTERÍSTICAS DO FUNDO':
      return backgroundFeatures.default;
    case 'GOVERNANÇA DO FGCOOP':
      return governance.default;
    case 'PLANO ESTRATÉGICO':
      return strategicPlan.default;
    case 'NORMAS':
      return standards.default;
    case 'GARANTIAS':
      return guarantees.default;
    case 'PERGUNTAS FREQUENTES':
      return bannerWhoWeAre.default;
    case 'INFORMAÇÕES':
      return information.default;
    case 'COBERTURA ORDINÁRIA':
      return ordinaryCoverage.default;
    default:
      return bannerWhoWeAre.default;
  }
};
