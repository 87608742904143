import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../utils/devices';
import { Col, Row } from 'react-bootstrap';

export const RowStyled = styled(Row)`
  width: 100%;
  min-height: ${({ min_height }) => (min_height ? min_height : 58)}px;

  @media ${bootstrapMin.sm} {
    min-height: ${({ min_height_sm }) => (min_height_sm ? min_height_sm : 86)}px;
  }

  @media (max-width: 370px) {
    ${({ image }) => image && 'min-height: 269px;'}
  }
`;

export const RowBackStyled = styled(Row)`
  width: 100%;
  min-height: ${({ min_height }) => (min_height ? min_height : 58)}px;

  @media ${bootstrapMin.sm} {
    min-height: ${({ min_height_sm }) => (min_height_sm ? min_height_sm : 86)}px;
  }

  @media (max-width: 575px) {
    z-index: 100;
  }
`;

export const SubHeaderDefault = styled.div`
  z-index: -1;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : 58)}px !important;
  position: absolute;
  width: 100vw;
  margin-left: -50vw;
  left: 49.99%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : colors.supportOne};
  background-image: ${({ backgroundImageMobile }) =>
    backgroundImageMobile ? `url(${backgroundImageMobile})` : 'none'};

  @media ${bootstrapMin.sm} {
    min-height: ${({ minHeightSm }) => (minHeightSm ? minHeightSm : 86)}px !important;
    background-image: ${({ backgroundImage }) =>
      backgroundImage ? `url(${backgroundImage})` : 'none'};
    background-size: 1366px ${({ minHeightSm }) => (minHeightSm ? minHeightSm : 86)}px !important;
  }

  @media ${bootstrapMax.sm} {
    background-size: 100%;
  }

  @media (max-width: 370px) {
    min-height: 269px !important;
  }
`;

export const SubHeaderBack = styled(SubHeaderDefault)`
  @media (max-width: 575px) {
    position: fixed;
    z-index: 50;
    border-radius: 15px;
    min-height: 58px !important;
  }
`;

export const TitleCarousel = styled.h1`
  font-family: 'Trade Gothic LT Bold';
  font-size: 25px;
  line-height: 30px;
  color: ${colors.white};

  @media ${bootstrapMin.lg} {
    font-size: 42px;
    line-height: 50px;
  }
`;
export const ContentCarousel = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: ${colors.white};

  @media ${bootstrapMin.lg} {
    line-height: 22px;
  }
`;

export const LinkCarousel = styled.a`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.white};

  &:hover {
    color: ${colors.white};
  }
`;

export const CarouselContainer = styled.div`
  & .rec-carousel {
    position: absolute;
    width: 100vw;
    margin-left: -50vw;
    left: 49.99%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
  }
`;

export const SubHeaderCarousel = styled(SubHeaderDefault)`
  min-height: ${({ minHeight }) => (minHeight ? minHeight : 58)}px !important;
  margin-left: -100%;
  position: initial;
  background-size: 100% 312px !important;
  width: 100% !important;

  @media ${bootstrapMin.lg} {
    min-height: ${({ minHeightSm }) => (minHeightSm ? minHeightSm : 86)}px !important;
    background-size: 100% 503px !important;
  }
`;

export const RowCarousel = styled(RowStyled)`
  margin: 0;
  padding: 0 35px;

  @media ${bootstrapMin.sm} {
    min-height: ${({ min_height }) => (min_height ? min_height : 58)}px;
  }

  @media ${bootstrapMin.lg} {
    min-height: ${({ min_height_sm }) => (min_height_sm ? min_height_sm : 86)}px;
    padding: 0 130px;
  }
`;

export const ColStyled = styled(Col)`
  @media (max-width: 575px) {
    position: fixed;
  }
`;
