import React, { Component } from 'react';
import { Button, Image, MiniCard, Screen, SubHeader, Text, Video } from '../../../components';
import { ColStyled, PurposeRow, Background, KnowOurGovernanceRow } from './styles';
import { Col, Row } from 'react-bootstrap';

import PropTypes from 'prop-types';
const { defaultStates } = require('../../../utils/defaults');
import { companyPurpose } from '../../../enums';

import bannerMobile from '../../../assets/imgs/banner-who-we-are-mobile.png';
import banner from '../../../assets/imgs/banner-who-we-are.png';

class WhoWeAre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      innerWidth: window.innerWidth,
    };

    this.body = this.body.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  goToGovernance = () => {
    window.location.href = '/governanca';
  };

  async updateSize() {
    this.setState({ innerWidth: window.innerWidth });
  }

  header() {
    return (
      <>
        <SubHeader
          image
          backgroundImage={banner}
          backgroundImageMobile={bannerMobile}
          minHeightSm={452}
          minHeight={313}
          title={'Prazer, somos o FGCOOP'}
        />
      </>
    );
  }

  body() {
    return (
      <>
        <Row
          className={'mb-4 pb-2 mb-lg-5 pb-lg-1 justify-content-center ps-3 ms-1 ms-lg-0 ps-lg-0'}>
          <Col lg={6} className={'mb-3 pb-1 mb-lg-0 pb-lg-0 px-0'}>
            <Text
              heading1
              noWrap
              lineHeight={50}
              title={{ one: 'GARANTIA DE DEPÓSITOS, SEGURANÇA PARA BONS NEGÓCIOS' }}
            />
          </Col>
          <ColStyled xs={11} lg={6} className={'px-0 px-lg-3'}>
            <MiniCard
              brackets
              content={`<p>O Fundo Garantidor do Cooperativismo de Crédito (FGCoop) nasceu em 2014 para
              contribuir com a solidez e confiança do Sistema Nacional de Crédito Cooperativo
              (SNCC), tornando-se parte de uma ampla rede de proteção ao Sistema Financeiro
              Nacional.</p> <p>O FGCoop foi criado para proteger pessoas que confiam suas economias às
              cooperativas de crédito e aos bancos cooperativos associados ao Fundo, nas condições
              do seu Regulamento.</p> <p>Proteger os depositantes não significa somente garantir o
              reembolso dos seus depósitos, mas também contribuir para que as cooperativas de
              crédito e os bancos cooperativos mantenham-se íntegros, em plena saúde financeira,
              evitando a ocorrência de situações extremas, que possam ocasionar a intervenção ou
              liquidação extrajudicial dessas. Prevenir é o melhor negócio!</p> <p>No mundo das
              instituições financeiras cooperativas, confiança é uma palavra-chave.</p> <p>Melhorar e
              merecer a confiança dos associados é a base sobre a qual poderemos continuar
              construindo um Sistema Nacional de Crédito Cooperativo sólido e em contínuo
              crescimento, resultado final esperado por todos os componentes desse Sistema.</p>`}
            />
          </ColStyled>
        </Row>
        <Row className={'mb-3 mb-lg-5 pb-4'}>
          <Background />
          <Col xs={12} className={'mb-4 pt-4 mt-1'}>
            <Text
              heading2
              noWrap
              textAlign={'center'}
              title={{ one: 'Conheça o propósito do FGCOOP' }}
            />
          </Col>
          <Col className={'mb-lg-3 pb-lg-1 d-flex justify-content-center'}>
            <Video purpose={true} />
          </Col>
        </Row>
        <Row className={'mb-3 mb-lg-5 pb-4'}>
          <Background />
          <Col xs={12} className={'mb-4 pt-4 mt-1'}>
            <Text
              heading2
              noWrap
              textAlign={'center'}
              title={{ one: 'CONHEÇA MAIS SOBRE O FGCOOP' }}
            />
          </Col>
          <Col className={'mb-lg-3 pb-lg-1 d-flex justify-content-center'}>
            <Video whoWeAre={true} />
          </Col>
        </Row>
        <Row className={'mb-lg-4 pb-lg-3'}>
          <Col xs={12} className={'mb-lg-1'}>
            <Text heading2 noWrap textAlign={'center'} title={{ one: 'NO QUE ACREDITAMOS' }} />
          </Col>
        </Row>
        <PurposeRow className={'mb-3'}>
          {companyPurpose.map((purpose, index) => {
            return (
              <Col
                xs={12}
                lg={4}
                key={index}
                className={
                  'mb-5 px-5 px-lg-0 d-flex justify-content-center justify-content-lg-start'
                }>
                <MiniCard
                  noBox
                  fullColumns
                  centerMobile
                  styled={'true'}
                  flexDirectionColumn
                  data={[purpose]}
                  fontSizeContent={14}
                  fontSizeTitle={26}
                />
              </Col>
            );
          })}
        </PurposeRow>
        <KnowOurGovernanceRow className={'mb-3 mb-lg-0'}>
          <Col xs={12} className={'mb-4 pb-lg-4 pt-3 pt-lg-5 mt-3 px-5 px-lg-0'}>
            <Text
              heading2
              noWrap
              textAlign={'center'}
              title={{ one: 'CONHEÇA NOSSA GOVERNANÇA' }}
            />
          </Col>
          <Col xs={12} className={'mt-lg-2 mb-4 pb-2 mb-lg-5 pb-lg-5'}>
            <Image governanceHierarchy />
          </Col>
          <Col xs={12} className={'mb-lg-5 pb-lg-5 d-flex justify-content-center'}>
            <Button
              greenButton
              width={213}
              widthMobile={150}
              text={'CONHEÇA MAIS'}
              onClick={this.goToGovernance}></Button>
          </Col>
        </KnowOurGovernanceRow>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  }
}

WhoWeAre.propTypes = {
  //props
  history: PropTypes.object,
};
export default WhoWeAre;
