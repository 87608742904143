/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { Row, Col } from 'react-bootstrap';
import newsService from '../../../services/news';
import {
  Screen,
  Text,
  Input,
  ButtonUpload,
  Image,
  Label,
  Button,
  Checkbox,
  Calendar,
  InputHashTags,
} from '../../../components';
import fileService from '../../../services/file';
import quote1 from '../../../assets/icons/admin/quote-left-top.png';
import quote2 from '../../../assets/icons/admin/quote-right-bottom.png';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import ImageCompression from '../../../utils/compressImage';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
  baseURL,
} = require('../../../utils/defaults');

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: {
        title: '',
        hashtag: [],
        content: '',
        newContent: '',
        files: [],
        isActive: true,
        postingDate: '',
        url: ''
      },
      ...defaultStates(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });

    const { id } = this.props.match.params;

    if (id !== 'novo') {
      const response = await newsService.admin.get(id);
      if (response.ok && response.data && response.data.news) {
        const formattedDate = moment(response.data.news.postingDate, 'DD/MM/YYYY').toDate();
        response.data.news.postingDate = formattedDate;

        this.setState({
          news: response.data.news,
          newRegister: false,
        });
      } else if (response.ok) {
        this.props.history.push('/gestao/noticias/novo');
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  handleChange(id, event) {
    const { news } = this.state;

    if (id === 'files') {
      news[id] = [event.target.files && event.target.files[0]];
    } else if (id === 'removeFile') {
      news.files = [];
      news.file_id = null;
    } else if (id === 'newContent') {
      news[id] = event.target.getContent();
    } else if (id === 'hashtag') {
      news[id] = event.values;
    } else {
      news[id] = event.target.value;
    }

    this.setState({ news });
  }

  handleRemove(id) {
    const { news } = this.state;
    if (id === 'hashtag') {
      news[id] = [];
    }
    this.setState({ news });
  }

  async handleSubmit() {
    const { newRegister, news } = this.state;
    this.setState({ loadingState: true });
    news.content = news.newContent || news.content;
    delete news.newContent;

    if (newRegister) {
      if (news.files.length) {
        const compressedFile = await ImageCompression.imageCompress(news.files);
        let file = new File([compressedFile], compressedFile.name, {
          lastModified: compressedFile.lastModified,
          type: compressedFile.type,
        });
        news.files[0] = file;
      }

      const response = await newsService.admin.save(news);

      if (response.ok) {
        const alertScreenState = {
          title: 'Notícia criada com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/noticias',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    } else {
      if (news.files.length && !news.files[0].id) {
        const compressedFile = await ImageCompression.imageCompress(news.files);
        let file = new File([compressedFile], compressedFile.name, {
          lastModified: compressedFile.lastModified,
          type: compressedFile.type,
        });
        news.files[0] = file;
      }

      const response = await newsService.admin.edit(news);

      if (response.ok) {
        const alertScreenState = {
          title: 'Notícia alterada com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/noticias',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  async handleDelete() {
    this.setState({
      modalState: {
        title: `Remoção de ${this.state.news.isActive ? 'notícia' : 'rascunho'}`,
        text: `Tem certeza que deseja excluir ess${this.state.news.isActive ? 'a notícia' : 'e rascunho'
          }?`,
        toggle: true,
        onHide: () => this.modalOnHide(),
        confirm: 'Sim',
        onClickConfirm: async () => await _handleDelete(),
        danger: true,
        cancel: 'Não',
        onClickCancel: () => this.modalOnHide(),
      },
    });

    const _handleDelete = async () => {
      const {
        news: { id },
      } = this.state;
      this.setState({ loadingState: true });

      const response = await newsService.admin.delete(id);

      if (response.ok && response.data) {
        const alertScreenState = {
          title: 'Notícia excluída com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/noticias',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }

      this.setState({ loadingState: false });
    };
  }

  async handleUploadFileTiny(file, cb) {
    const response = await fileService.upload({ files: [file] });
    if (response.ok && response.data) {
      const file = response.data;

      cb(`${baseURL()}/api/file/${file.fileId}/${file.slug}`, {
        title: file.slug,
      });
    } else {
      const modalState = {
        title: 'Erro',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };

      this.setState({ modalState });
    }
  }

  body() {
    const { news } = this.state;

    return (
      <>
        <Row align="center">
          <Col>
            <Text title text={'Notícia'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Checkbox
              label={'Ativo'}
              checked={news.isActive}
              onClick={() => {
                news.isActive = !news.isActive;
                this.setState({ news });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4} xs={4}>
            <Label text={'Data da Postagem:'} />
            <Calendar
              label={'Data'}
              value={news.postingDate}
              onChange={(e) => this.handleChange('postingDate', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Input
              value={news.title}
              label={'Título:'}
              placeholder={'Título da notícia'}
              maxLength={200}
              onChange={(e) => this.handleChange('title', e)}
            />
          </Col>
        </Row>
        <Row>
          {news.files && news.files.length ? (
            <Col>
              <Label text={'Imagem do Card:'} />
              <Image
                preview
                img={
                  news.files[0].id
                    ? `${baseURL()}/api/file/${news.files[0].id}/${news.files[0].slug}`
                    : URL.createObjectURL(news.files[0])
                }
                onClick={() => this.handleChange('removeFile')}
              />
            </Col>
          ) : (
            <Col>
              <Label text={'Imagem do Card:'} />
              <ButtonUpload onChange={(e) => this.handleChange('files', e)} image />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Alert severity="info" className={'mt-2'}>
              <span>É recomendado que a imagem do card, tenha o tamanho de:</span>
              <br />
              <span>313px de altura por 318px de largura</span>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Label text={'Hashtags:'} />
            <Alert severity="info" className={'my-2'}>
              <span>Após digitar a palavra da hashtag aperte Enter ou Space.</span>
            </Alert>
            <InputHashTags
              value={news.hashtag}
              onTags={(e) => this.handleChange('hashtag', e)}
              onClick={() => {
                this.handleRemove('hashtag');
              }}></InputHashTags>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label text={'Conteúdo:'} />
            <Editor
              initialValue={news.content}
              apiKey={process.env.REACT_APP_TINY_APIKEY}
              init={{
                height: 500,
                menubar: true,
                language: 'pt_BR',
                content_style: `body { font-size: 20px; font-family: "GT Walsheim Pro", serif !important; }`,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount image importcss',
                ],
                setup: (editor) => {
                  editor.ui.registry.addButton('quote', {
                    text: 'Aspas',
                    tooltip: 'Insere bloco de texto envolvido por aspas customizadas',
                    onAction: () => {
                      editor.insertContent(
                        '<div class="row">' +
                        `<div class="col-1 col-quote1"><img src="${quote1}" class="quote1" style="max-width: 35px; height: fit-content;" alt=""/></div>` +
                        '<div class="col col-text"><p class="news-quote-text" id="myText" style="text-align:left; margin-top: 25px; overflow-wrap: anywhere;">&lt;&lt;Edite aqui seu conteúdo... As aspas deve ocupar toda linha como um bloco de texto.&gt;&gt;</p></div>' +
                        `<div class="col-1 col-quote2 d-flex align-items-end"><img src="${quote2}" class="quote2" style="max-width: 35px; height: fit-content; justify-content: end;" alt=""/></div>` +
                        '</div>',
                      );
                    },
                  });
                },
                toolbar:
                  'undo redo | formatselect | image | bold italic backcolor | ' +
                  'alignleft aligncenter lignright alignjustify | ' +
                  'bullist numlist outdent indent | removeformat | quote | help',
                image_title: true,
                automatic_uploads: true,
                file_picker_types: 'image',
                convert_urls: false,
                image_caption: true,
                a11y_advanced_options: true,
                file_picker_callback: (cb) => {
                  const that = this;
                  const input = document.createElement('input');
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*');
                  input.onchange = async function () {
                    await that.handleUploadFileTiny(this.files[0], cb);
                  };
                  input.click();
                },
              }}
              onChange={(e) => this.handleChange('newContent', e)}
            />
            <Alert severity="info" className={'my-2'}>
              <span>
                É recomendado que as imagens no corpo da notícia, tenham a largura maxima de 1088px
              </span>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col sm={6} xs={6}>
            <Input
              value={news.url}
              label={'Url do vídeo:'}
              placeholder={'Url'}
              maxLength={200}
              onChange={(e) => this.handleChange('url', e)}
            />
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center">
          {this.state.news.id ? (
            <Col>
              <Button danger text={'Excluir'} onClick={this.handleDelete} />
            </Col>
          ) : (
            <></>
          )}
          <Col>
            <Button text={'Salvar'} onClick={this.handleSubmit} />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          admin
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

News.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default News;
