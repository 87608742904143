const { dataFormParse } = require('../utils/dataFormParse');
const { axiosDefault, handleErrors } = require('../utils/defaults');

const governancesService = {
  admin: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/governances/gestao/${id ? id : ''}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async () => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/governances/gestao`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    save: async (governance) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.post(`/api/governances/gestao`, dataFormParse(governance));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    edit: async (governance) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.put(
          `/api/governances/gestao/${governance.id}`,
          dataFormParse(governance),
        );

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    delete: async (id) => {
      try {
        const axios = axiosDefault();

        const { data } = await axios.delete(`/api/governances/gestao/${id}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },

  user: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/governances/${id}`);

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async ({ limit = '', search = '', offset = '', random = '' }) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(
          `/api/governances?limit=${limit}&title=${search}&offset=${offset}&random=${random}`,
        );

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },
};

export default governancesService;
