import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Label as LabelComponent,
  LabelHeader,
  LabelHeaderCustom,
  LabelMarginBottom,
  LabelMenu,
  LabelNewsDate,
  LabelNewsTitle,
  LabelLinkInfo,
  WhiteLabelComponent,
} from './styles';

class Label extends Component {
  render() {
    // props
    const { text } = this.props;

    //types
    const { marginBottom, whiteLabel, header, headerCustom, menu, newsDate, newsTitle, linkInfo } =
      this.props;

    if (header) {
      return <LabelHeader className="notranslate">{text}</LabelHeader>;
    }

    if (headerCustom) {
      return <LabelHeaderCustom>{text}</LabelHeaderCustom>;
    }

    if (menu) {
      return <LabelMenu>{text}</LabelMenu>;
    }

    if (newsDate) {
      return <LabelNewsDate>{text}</LabelNewsDate>;
    }

    if (newsTitle) {
      return <LabelNewsTitle>{text}</LabelNewsTitle>;
    }

    if (linkInfo) {
      return <LabelLinkInfo>{text}</LabelLinkInfo>;
    }

    if (marginBottom) {
      return <LabelMarginBottom>{text}</LabelMarginBottom>;
    }

    if (whiteLabel) {
      return <WhiteLabelComponent>{text}</WhiteLabelComponent>;
    }

    return (
      <>
        <LabelComponent>{text}</LabelComponent>
      </>
    );
  }
}

Label.propTypes = {
  // props
  text: PropTypes.string,

  // types
  header: PropTypes.bool,
  headerCustom: PropTypes.bool,
  menu: PropTypes.bool,
  newsDate: PropTypes.bool,
  newsTitle: PropTypes.bool,
  linkInfo: PropTypes.bool,
  marginBottom: PropTypes.bool,
  whiteLabel: PropTypes.bool,
};

export default Label;
