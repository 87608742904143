import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  // AlertScreenContainer,
  Container,
  ContainerBody,
  ContainerBodyLogin,
  ContainerFooter,
  ContainerFooterLogin,
  ContainerHeader,
  ContainerHeaderStyled,
  ContainerLogin,
  RowStyled,
} from './styles';
import { Modal, AlertScreen, Loading, Button } from '../index';
import { Col } from 'react-bootstrap';

class Screen extends Component {
  moveUp() {
    window.location.href = '#';
  }

  render() {
    // props
    const { back, onClickBack, body, footer, modalState, loadingState, header } = this.props;

    //types
    const { appPage, login, alertScreenState, admin, errorPage, home, infoDetailPage } = this.props;

    if (login) {
      return (
        <ContainerLogin>
          <Modal {...modalState} />
          <Loading loadingState={loadingState} />
          <ContainerHeader />
          <ContainerBodyLogin>{body ? body() : ''}</ContainerBodyLogin>
          <ContainerFooterLogin>{footer ? footer() : ''}</ContainerFooterLogin>
        </ContainerLogin>
      );
    }

    if (alertScreenState && alertScreenState.open) {
      return (
        <Container>
          <ContainerHeader />
          <ContainerBody>
            <AlertScreen {...alertScreenState} />
          </ContainerBody>
          <ContainerFooter />
        </Container>
      );
    }

    if (appPage) {
      return (
        <Container>
          <ContainerHeader />
          <Loading loadingState={loadingState} />
          <Modal {...modalState} />
          <ContainerBody>{body && body()}</ContainerBody>
          <ContainerFooter>{footer && footer()}</ContainerFooter>
        </Container>
      );
    }

    if (admin) {
      return (
        <Container>
          <ContainerHeader />
          <Loading loadingState={loadingState} />
          <Modal {...modalState} />
          <ContainerBody>{body && body()}</ContainerBody>
          <ContainerFooter>{footer ? footer() : ''}</ContainerFooter>
        </Container>
      );
    }

    return (
      <Container>
        <ContainerHeaderStyled home={home}>{header ? header() : ''}</ContainerHeaderStyled>
        <Loading loadingState={loadingState} />
        <Modal {...modalState} />
        <ContainerBody home={home}>
          {body()}
          {!admin && !errorPage && !infoDetailPage ? (
            <RowStyled>
              <Col className={'d-flex justify-content-end p-0'}>
                <Button up onClick={this.moveUp} />
              </Col>
            </RowStyled>
          ) : (
            ''
          )}
        </ContainerBody>
        <ContainerFooter>{footer ? footer() : ''}</ContainerFooter>
      </Container>
    );
  }
}

Screen.propTypes = {
  // props
  body: PropTypes.func.isRequired,
  footer: PropTypes.func,
  back: PropTypes.bool,
  modalState: PropTypes.object,
  loadingState: PropTypes.bool,
  noData: PropTypes.bool,
  onClickBack: PropTypes.func,
  header: PropTypes.func,

  // types
  alertScreenState: PropTypes.object,
  appPage: PropTypes.bool,
  login: PropTypes.bool,
  backgroundLogo: PropTypes.bool,
  admin: PropTypes.bool,
  errorPage: PropTypes.bool,
  infoDetailPage: PropTypes.bool,
  home: PropTypes.string,
};

export default Screen;
