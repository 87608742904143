import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import {
  SubHeaderDefault,
  SubHeaderBack,
  RowStyled as Row,
  RowBackStyled,
  CarouselContainer,
  TitleCarousel,
  ContentCarousel,
  SubHeaderCarousel,
  RowCarousel,
  ColStyled,
} from './styles';
import { Text } from '../../components';
import Button from '../Button';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

class SubHeader extends Component {
  render() {
    // props
    const {
      onClick,
      minHeight,
      minHeightSm,
      backgroundColor,
      backgroundImage,
      backgroundImageMobile,
      title,
      content,
      href,
      isVisible,
      length
    } = this.props;

    // types
    const { back, alignCenter, image, carousel } = this.props;

    if (carousel) {
      return (
        <>
          {href ? (<a
            href={href}
            style={{
              top: '10%',
              left: '5%',
              right: '5%',
              width: '100%',
              height: '80%',
              textDecoration: 'none'
            }}
          >
            <RowCarousel
              min_height={minHeight}
              min_height_sm={minHeightSm}
              image={'true'}
              className={'ms-0 mb-4 mb-lg-4'}
            >
              <Col className={'d-flex flex-column pe-4 px-lg-2 align-self-center'} lg={5} style={{ zIndex: 2 }}>
                <CarouselContainer className="d-flex flex-column justify-content-between">
                  <div className="d-flex flex-column justify-content-center flex-grow-1 position-relative" style={{ zIndex: 2 }}>
                    {isVisible || !length ? (
                      <>
                        <TitleCarousel
                          className={'mb-3 mb-lg-4'}
                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
                        />
                        <ContentCarousel
                          className={'mb-lg-5'}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(content),
                          }}
                        />
                      </>
                    ) : ('')}
                  </div>
                </CarouselContainer>
              </Col>
            </RowCarousel>
          </a>) : (<RowCarousel
            min_height={minHeight}
            min_height_sm={minHeightSm}
            image={'true'}
            className={'ms-0 mb-4 mb-lg-4'}
          >
            <Col className={'d-flex flex-column pe-4 px-lg-2 align-self-center'} lg={5} style={{ zIndex: 2 }}>
              <CarouselContainer className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column justify-content-center flex-grow-1 position-relative" style={{ zIndex: 2 }}>
                  {isVisible || !length ? (
                    <>
                      <TitleCarousel
                        className={'mb-3 mb-lg-4'}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
                      />
                      <ContentCarousel
                        className={'mb-lg-5'}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(content),
                        }}
                      />
                    </>
                  ) : ('')}
                </div>
              </CarouselContainer>
            </Col>
          </RowCarousel>)}

          <SubHeaderCarousel
            minHeight={minHeight}
            minHeightSm={minHeightSm}
            backgroundImage={backgroundImage}
            backgroundImageMobile={backgroundImageMobile}
            backgroundColor={'transparent'}
          />
        </>
      );
    }

    if (back) {
      return (
        <>
          <RowBackStyled>
            <ColStyled
              className={'align-self-center px-4 px-lg-2 back'}
              sm={{ offset: 2 }}
              lg={{ offset: 1 }}
              xl={{ offset: 0 }}>
              <Button back onClick={onClick} />
            </ColStyled>
          </RowBackStyled>
          <SubHeaderBack
            minHeight={minHeight}
            minHeightSm={minHeightSm}
            backgroundColor={backgroundColor}
          />
        </>
      );
    }

    if (image) {
      return (
        <>
          {title ? (
            <Row
              min_height={minHeight}
              min_height_sm={minHeightSm}
              image={'true'}
              className={'mb-4 ms-0 mt-md-5 pt-md-4 mb-md-3 pb-md-3'}>
              <Col className={'d-flex align-self-center pl-sm-5 px-4 px-lg-2'} xs={9}>
                <div>
                  <Text heading1 lightColor noWrap title={{ one: title }} />
                </div>
              </Col>
            </Row>
          ) : (
            ''
          )}
          <SubHeaderDefault
            minHeight={minHeight}
            minHeightSm={minHeightSm}
            backgroundImage={backgroundImage}
            backgroundImageMobile={backgroundImageMobile}
            backgroundColor={'transparent'}
          />
        </>
      );
    }

    return (
      <>
        {title ? (
          <Row className={'mt-4 mt-md-5 pt-md-4 mb-md-3 pb-md-3 m-0'}>
            <Col className={'justify-content-center d-flex mt-1'}>
              <Text
                heading1
                className={`${alignCenter ? 'align-items-center' : ''}`}
                title={{ one: title }}
              />
            </Col>
          </Row>
        ) : (
          ''
        )}
      </>
    );
  }
}

SubHeader.propTypes = {
  // props
  onClick: PropTypes.func,
  minHeight: PropTypes.number,
  minHeightSm: PropTypes.number,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundImageMobile: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  href: PropTypes.string,
  isVisible: PropTypes.bool,
  length: PropTypes.number,

  // types
  back: PropTypes.bool,
  alignCenter: PropTypes.bool,
  image: PropTypes.bool,
  carousel: PropTypes.bool,
};

export default SubHeader;
