import React, { Component } from 'react';
import { Text } from '../index';
import { Row } from 'react-bootstrap';
import { defaultStates, defaultModalOnHide } from '../../utils/defaults';
import Input from '../Input';
import newslettersService from '../../services/newsletters';
import { validateEmail } from '../../utils/helpers';
import {
  Col,
  ColStyled,
  RowStyled,
  DivStyled,
  Logo,
  ContainerSocialMedia,
  IconSocialMedia,
  Paragraph,
  MainContainer,
  SecondaryContainer,
  Footer,
  Message,
} from './styles';
class FooterApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isActive: true,
      navbar: false,
      alerts: null,
      label: '',
      emails: [],
      ...defaultStates(),
    };

    this.getEmail = this.getEmail.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  handleChange = (event) => {
    this.setState({ email: event.target.value, label: '' });
  };

  getEmail = async () => {
    this.setState({ loadingState: true, newRegister: false });
    const { email } = this.state;

    const isEmailValid = validateEmail(email);

    if (isEmailValid) {
      const response = await newslettersService.get(email);

      if (response.ok && response.data) {
        this.setState({ newRegister: response.data.length ? false : true });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }
    this.setState({ loadingState: false });
  };

  handleSubmit = async () => {
    await this.getEmail();

    const { newRegister, email } = this.state;
    this.setState({ loadingState: true, label: '' });

    if (newRegister) {
      const response = await newslettersService.save({ email });
      if (response.ok) {
        const alertScreenState = {
          title: 'E-mail cadastrado com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
        };
        this.setState({ alertScreenState, label: alertScreenState.title });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    }
    this.setState({ loadingState: false });
  };

  render() {
    const { email, label } = this.state;

    return (
      <Footer>
        <MainContainer>
          <div>
            <RowStyled>
              <Col className={'d-none d-lg-flex justify-content-start flex-column'} lg={2}>
                <Logo href={'/'} className={'d-none d-lg-flex'}>
                  <div />
                </Logo>
                <ContainerSocialMedia>
                  <a
                    href={'https://www.linkedin.com/company/fgcoop/'}
                    target={'_blank'}
                    rel={'noreferrer noopener'}>
                    <IconSocialMedia />
                  </a>
                  <a
                    href={'https://www.facebook.com/pg/FGCoop-114626913731407/posts/'}
                    target={'_blank'}
                    rel={'noreferrer noopener'}>
                    <IconSocialMedia facebook />
                  </a>
                  <a
                    href={'https://www.instagram.com/fgcoop.coop/'}
                    target={'_blank'}
                    rel={'noreferrer noopener'}>
                    <IconSocialMedia instagram />
                  </a>
                </ContainerSocialMedia>
              </Col>
              <Col
                className={'d-none d-lg-flex justify-content-start flex-column'}
                style={{ gap: '20px' }}
                lg={3}>
                <Text
                  link
                  fontSize={14}
                  href={'/perguntas-frequentes'}
                  footer
                  text={'Perguntas frequentes'}
                />
                <Text
                  link
                  fontSize={14}
                  href={'/politica-de-privacidade'}
                  footer
                  text={'Política de privacidade'}
                />
                <Paragraph>
                  Resp. Tratamento de Dados Pessoais (DPO) <br />
                  Taíse Ribeiro Montijo - Consultora Jurídica. <br />
                  e-mail: dpo@fgcoop.coop.br <br />
                </Paragraph>
              </Col>
              <Col
                className={
                  'd-flex justify-content-start flex-column pe-lg-0 ps-xl-4 ms-xl-1 text-center text-lg-start'
                }
                style={{ gap: '5px' }}
                xs={12}
                lg={3}>
                <Paragraph className={'mb-2 mb-lg-0'}>Tel. + 55 61 3224-0449</Paragraph>
                <Paragraph>
                  Edifício Parque Cidade, SCS <br />
                  Quadra 9, Torre C, Sala 502 <br />
                  CEP 70308-200, Brasilia DF
                </Paragraph>
              </Col>
              <Col xs={12} className={'d-flex d-lg-none mb-3 pb-1'}>
                <ContainerSocialMedia>
                  <a
                    href={'https://www.linkedin.com/company/fgcoop/'}
                    target={'_blank'}
                    rel={'noreferrer noopener'}>
                    <IconSocialMedia />
                  </a>
                  <a
                    href={'https://www.facebook.com/pg/FGCoop-114626913731407/posts/'}
                    target={'_blank'}
                    rel={'noreferrer noopener'}>
                    <IconSocialMedia facebook />
                  </a>
                  <a
                    href={'https://www.instagram.com/fgcoop.coop/'}
                    target={'_blank'}
                    rel={'noreferrer noopener'}>
                    <IconSocialMedia instagram />
                  </a>
                </ContainerSocialMedia>
              </Col>
              <Col xs={12} className={'d-flex d-lg-none mb-3 pb-1 text-center'}>
                <Paragraph>
                  Resp. Tratamento de Dados Pessoais (DPO) <br />
                  Taíse Ribeiro Montijo - Consultora Jurídica. <br />
                  e-mail: dpo@fgcoop.coop.br <br />
                </Paragraph>
              </Col>
              <ColStyled
                className={
                  'd-flex align-items-center align-items-lg-start justify-content-start flex-column'
                }
                xs={12}
                lg={{ span: 3, offset: 0 }}>
                <Paragraph className={'text-center text-lg-start'}>
                  Cadastre-se em nossa lista <br /> de e-mails para receber notícias.
                </Paragraph>
                <Input
                  rounded
                  newsletter
                  id={'emailNewsletter'}
                  type="email"
                  value={email}
                  placeholder={'SEU E-MAIL'}
                  maxLength={50}
                  onClick={async () => await this.handleSubmit()}
                  onKeyPress={async (e) => {
                    if (['NumpadEnter', 'Enter'].includes(e.key)) await this.handleSubmit();
                  }}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                />
                <Message>
                  <Text messageSuccess text={label} />
                </Message>
              </ColStyled>
            </RowStyled>
            <Row className={'text-center mt-3 mt-lg-4 pt-lg-3'}>
              <Paragraph style={{ fontFamily: 'Trade Gothic LT Light' }} className={'px-2 px-lg-3'}>
                O Fundo Garantidor do Cooperativismo de Crédito - FGCoop alerta que NÃO é uma
                instituição financeira, não atua na concessão ou intermediação de
                empréstimos/financiamentos a pessoas físicas ou jurídicas em qualquer modalidade.
                Portanto, ninguém é ou está autorizado a, em seu nome, oferecer tal tipo de serviço
                ou operação.
              </Paragraph>
            </Row>
          </div>
        </MainContainer>
        <SecondaryContainer>
          <div>
            <Row className={'m-0'}>
              <Col className={'d-flex align-items-center flex-column px-4 mx-2 px-lg-3 mx-lg-0'}>
                <DivStyled>
                  <Text
                    paragraph1
                    lightColor
                    lightFont
                    center
                    text={'GARANTIA DE DEPÓSITOS, SEGURANÇA PARA BONS NEGÓCIOS'}
                  />
                </DivStyled>
                <Paragraph style={{ fontFamily: 'Trade Gothic LT Light' }} className={'mb-3'}>
                  FGCOOP © TODOS OS DIREITOS RESERVADOS.
                </Paragraph>
                <Paragraph style={{ fontFamily: 'Trade Gothic LT Light' }}>
                  Powered by CodeBit
                </Paragraph>
              </Col>
            </Row>
          </div>
        </SecondaryContainer>
      </Footer>
    );
  }
}
export default FooterApp;
