import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { LogoAssociates, TitleAssociates, LinkAssociates, TextAssociates } from './styles';

class CooperativeCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      xsSize,
      lgSize,
      mdClasses,
      title = '',
      associate,
      text = null,
      link = null,
      logo,
      logoSize,
      className,
    } = this.props;

    return (
      <Col
        xs={xsSize}
        lg={lgSize}
        className={`d-flex flex-column align-items-center pe-lg-0 mt-lg-3 pt-lg-1 adjust-width-mobile ${className}`}>
        <LogoAssociates logo={logo} logoSize={logoSize} mdClasses={mdClasses} associate={associate}>
          {text ? <TextAssociates>{text}</TextAssociates> : ''}
        </LogoAssociates>
        {link ? (
          <LinkAssociates target={'_blank'} href={link}>
            {title}
          </LinkAssociates>
        ) : (
          <TitleAssociates associate={associate}>{title}</TitleAssociates>
        )}
      </Col>
    );
  }
}

CooperativeCard.propTypes = {
  xsSize: PropTypes.number,
  lgSize: PropTypes.number,
  mdClasses: PropTypes.string,
  title: PropTypes.string,
  associate: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  logo: PropTypes.string,
  logoSize: PropTypes.string,
  className: PropTypes.string,
};

export default CooperativeCard;
