import React, { Component } from 'react';
import PropTypes from 'prop-types';
import migrateService from '../../../services/migrate';
import { Screen } from '../../../components';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
} = require('../../../utils/defaults');

class Migrate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
    };

    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });

    const { id } = this.props.match.params;

    const response = await migrateService.admin.migrate(id);
    console.log(response, 'response');
    if (response.ok && response.data) {
      const modalState = {
        title: 'Sucesso!',
        text: response.data,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    } else {
      const modalState = {
        title: 'Erro',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
    this.setState({ loadingState: false });
  }

  body() {}

  footer() {}

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          admin
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

Migrate.propTypes = {
  //props
  match: PropTypes.object,
};

export default Migrate;
