import React, { Component } from 'react';
import { Screen, SubHeader, Label, Button, Video } from '../../../components';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import infosService from '../../../services/infos';
const { defaultStates, defaultModalOnHide, baseURL } = require('../../../utils/defaults');
import { Content, Header, Paragraph } from './styles';

class InformationsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {
        title: '',
        documentDate: '',
        content: '',
        url: ''
      },
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  async componentDidMount() {
    await this.loadInfos();
  }

  async componentDidUpdate() {
    const { id } = this.props.match.params;
    const { loadingState } = this.state;
    const { id: newsId } = this.state.info;

    if (!loadingState && newsId && newsId !== Number(id)) {
      await this.loadInfos();
    }
  }

  async loadInfos() {
    this.setState({ loadingState: true });

    const { id } = this.props.match.params;

    const response = await infosService.user.get(id);
    if (response.ok && response.data && response.data) {
      this.setState({
        info: response.data,
      });
    } else if (response.ok) {
      this.props.history.push('/');
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }

    this.setState({ loadingState: false });
  }

  goBack = () => {
    this.props.history.push('/informacoes');
  };

  openFile = (document) => {
    const mountedLink =
      document && document.id ? `${baseURL()}/api/file/${document.id}/${document.slug}` : '';
    window.open(mountedLink, '_blank', 'noopener,noreferrer');
  };

  header() {
    return (
      <>
        <SubHeader back onClick={this.goBack} />
      </>
    );
  }

  body() {
    const { info } = this.state;

    return (
      <>
        <Header>
          <Row className={'mt-4'}>
            <Col>
              <Label newsTitle text={info.title} />
            </Col>
          </Row>
          <Row className={'mt-2'}>
            <Col>
              <Label newsDate text={info.documentDate} />
            </Col>
          </Row>
        </Header>

        <Row className={'mt-4'}>
          <Col>
            <Content
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(info.content, {
                  ADD_TAGS: ['iframe'],
                  ADD_ATTR: [
                    'allow',
                    'allowfullscreen',
                    'frameborder',
                    'scrolling',
                    'src',
                    'width',
                    'height',
                    'style',
                  ],
                }),
              }}
            />
          </Col>
        </Row>

        {info.url ? (<Row className={'mt-4 mb-4 mb-lg-5 pb-4'}>
          <Col className={'mb-lg-3 pb-lg-1 d-flex justify-content-center'}>
            <Video url={info.url} />
          </Col>
        </Row>) : ('')}

        <Row className={'mt-5 pb-lg-1'}>
          <Col xs={12} className={'pb-lg-1 d-flex justify-content-center'}>
            <Paragraph style={{ fontFamily: 'Trade Gothic LT Bold' }} className={'px-2 px-lg-3'}>
              Baixe agora para conferir o documento
            </Paragraph>
          </Col>
          <Col xs={12} className={'mb-lg-3 pb-lg-1 d-flex justify-content-center'}>
            <Paragraph style={{ fontFamily: 'Trade Gothic LT Bold' }} className={'px-2 px-lg-3'}>
              completo
            </Paragraph>
          </Col>
        </Row>
        <Col xs={12} className={'mb-lg-3 pb-lg-1 d-flex justify-content-center'}>
          <Button
            greenButton
            width={113}
            widthMobile={113}
            height={39}
            text={'ABRIR'}
            onClick={() => this.openFile(info.file_document)}></Button>
        </Col>
      </>
    );
  }

  render() {
    const { modalState, loadingState } = this.state;

    return (
      <Screen
        infoDetailPage
        header={this.header}
        body={this.body}
        modalState={modalState}
        loadingState={loadingState}
      />
    );
  }
}

InformationsDetail.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};
export default InformationsDetail;
