import React, { Component, Fragment } from 'react';
import {
  Accordion,
  CarouselComponent,
  Image,
  MiniCard,
  Screen,
  SubHeader,
  Text,
} from '../../../components';

import PropTypes from 'prop-types';
const { defaultStates } = require('../../../utils/defaults');

import bannerMobile from '../../../assets/imgs/banner-governance-mobile.png';
import banner from '../../../assets/imgs/banner-governance.png';

import {
  ColStyled,
  GovernanceContainer,
  GovernanceHierarchyRow,
  KnowOurStructureTitle,
  JobTitle,
  RowStyled,
} from './styles';
import { Col, Row } from 'react-bootstrap';
import governancesService from '../../../services/governances';
class Governance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      governances: [],
      innerWidth: window.innerWidth,
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
    this.updateSize = this.updateSize.bind(this);
    this.handleComponent = this.handleComponent.bind(this);
  }

  updateSize = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  header = () => {
    return (
      <>
        <SubHeader
          image
          backgroundImage={banner}
          backgroundImageMobile={bannerMobile}
          minHeightSm={452}
          minHeight={313}
          title={'Governança do FGCOOP'}
        />
      </>
    );
  };

  handleMiniCardsForCarousel = (position) => {
    return position.people.map((p, i) => {
      return (
        <div key={i} className={'d-flex d-md-none'}>
          <MiniCard
            fullBracketsCard
            title={p.name}
            subtitle={position.title}
            content={p.description}
          />
        </div>
      );
    });
  };

  handleComponent = (ourStructure) => {
    const breakPoints = [{ width: 1, itemsToShow: 1 }];

    return ourStructure.positions.map((position, index) => {
      return position.carousel ? (
        <Fragment key={index}>
          <Col
            className={'d-flex d-md-none pe-0 flex-column'}
            sm={{ span: 9, offset: 2 }}
            md={{ span: 6, offset: 0 }}
            lg={{ span: 4, offset: 0 }}>
            <JobTitle>{position.titleMobile}</JobTitle>
            <CarouselComponent
              breakPoints={breakPoints}
              items={this.handleMiniCardsForCarousel(position)}
            />
          </Col>
          {position.people.map((p, i) => {
            return (
              <Col
                className={'d-none d-md-flex pe-0 pe-md-3'}
                key={i}
                sm={{ span: 9, offset: 2 }}
                md={{ span: 6, offset: 0 }}
                lg={{ span: 4, offset: 0 }}>
                <MiniCard
                  fullBracketsCard
                  title={p.name}
                  subtitle={position.title}
                  content={p.description}
                />
              </Col>
            );
          })}
        </Fragment>
      ) : (
        position.people.map((p, i) => {
          return (
            <Col
              key={i}
              sm={{ span: 9, offset: 2 }}
              md={{ span: 6, offset: 0 }}
              lg={{ span: 4, offset: 0 }}
              className={'pe-0 pe-md-3'}>
              <div className={'d-flex d-md-none'}>
                <MiniCard
                  fullBracketsCard
                  title={p.name}
                  subtitle={position.title}
                  content={p.description}
                />
              </div>
              <div className={'d-none d-md-flex'}>
                <MiniCard
                  fullBracketsCard
                  title={p.name}
                  subtitle={position.title}
                  content={p.description}
                />
              </div>
            </Col>
          );
        })
      );
    });
  };

  async componentDidMount() {
    const response = await governancesService.user.getList({});

    if (response.ok) {
      const newResponse = this.transformArray(response.data);
      this.setState({
        governances: newResponse,
      });
    }
  }

  transformArray = (data) => {
    const transformedArray = [];

    const groupedByGovernance = data.reduce((acc, curr) => {
      if (!acc[curr.governance]) {
        acc[curr.governance] = [];
      }
      acc[curr.governance].push(curr);
      return acc;
    }, {});

    for (const governance in groupedByGovernance) {
      const positions = [];
      const group = groupedByGovernance[governance];

      const groupedByOffice = group.reduce((acc, curr) => {
        if (!acc[curr.office]) {
          acc[curr.office] = [];
        }
        acc[curr.office].push(curr);
        return acc;
      }, {});

      for (const office in groupedByOffice) {
        const people = groupedByOffice[office].map(({ office, name, description }) => ({
          name,
          description,
        }));

        const positionObj = {
          title: office,
          people,
        };

        if (people.length > 1 && governance !== 'Diretoria Executiva') {
          let words = office.split(' ');
          let job = [];

          words.map((word) => {
            if (word.slice(-1) !== 'S') {
              job.push(word + 'S');
            } else {
              job.push(word);
            }
          });
          positionObj.titleMobile = office === 'CONSELHEIRO' ? 'CONSELHEIROS' : job.join(' ');
          positionObj.carousel = true;
        }

        positions.push(positionObj);
      }

      transformedArray.push({
        title: governance,
        positions,
      });
    }
    return transformedArray;
  };

  body = () => {
    const { governances } = this.state;
    const options = [
      { title: 'Conselho de Administração' },
      { title: 'Conselho Fiscal - Mandato 2023 a 2026' },
      { title: 'Diretoria Executiva' },
      { title: 'Comitê Técnico de Assessoramento' },
    ];

    return (
      <GovernanceContainer>
        <Row
          className={'mb-5 pb-2 mb-lg-4 pb-lg-5 justify-content-center ps-3 ms-1 ms-lg-0 ps-lg-0'}>
          <Col lg={4} className={'mb-3 pb-1 mb-lg-0 pb-lg-0 px-0'}>
            <Text heading1 noWrap lineHeight={50} title={{ one: 'GOVERNANÇA' }} />
          </Col>
          <ColStyled xs={11} lg={8} className={'px-0 px-lg-3'}>
            <MiniCard
              brackets
              content={`<p>O FGCoop é uma associação civil sem fins lucrativos, com valores e princípios
              cooperativistas. A governança corporativa do Fundo está em constante aprimoramento
              para aliar as melhores práticas internacionais de gestão, recomendadas aos fundos
              garantidores com as melhores práticas e valores estruturantes do cooperativismo.</p> <p>O
              FGCoop conta com uma gestão sólida, eficiente e confiável, que promove a diversidade e
              prioriza a tomada de decisões de forma colegiada, dentro de instâncias bem definidas,
              fortalecendo as suas estratégias de atuação. Todos os sistemas do cooperativismo de
              crédito estão representados na Governança do FGCoop.</p> <p>A administração do Fundo é
              realizada pela Diretoria Executiva e pelo Conselho de Administração, enquanto a
              instância máxima de decisão fica a cargo da Assembleia Geral.</p>`}
            />
          </ColStyled>
        </Row>
        <GovernanceHierarchyRow className={'mb-3 mb-lg-0 pt-3 pt-lg-5 pb-lg-3'}>
          <Col xs={12} className={'mt-lg-2 mb-4 pb-2 mb-lg-5 pb-lg-5 pt-lg-4'}>
            <Image governanceHierarchy />
          </Col>
        </GovernanceHierarchyRow>
        <Row className={'mt-4 pt-3 mt-lg-5 pt-lg-5'}>
          <Col xs={12} className={'mb-lg-1'}>
            <Text
              heading2
              noWrap
              textAlign={'center'}
              title={{ one: 'CONHEÇA MAIS A NOSSA ESTRUTURA' }}
            />
          </Col>
        </Row>
        <div className={'mb-4 pb-2 px-3 px-lg-0 mb-lg-5 pb-lg-5'}>
          {governances.map((ourStructure, index) => {
            return (
              <Row key={index} className={'know-our-structure'}>
                <Col>
                  <Accordion
                    id={index.toString()}
                    summary={<KnowOurStructureTitle>{ourStructure.title}</KnowOurStructureTitle>}
                    details={
                      <RowStyled
                        className={`mt-2 mt-lg-5 ${
                          governances.length - 1 !== index ? 'mb-lg-5 pb-lg-5' : ''
                        }`}>
                        {this.handleComponent(ourStructure)}
                      </RowStyled>
                    }
                  />
                </Col>
              </Row>
            );
          })}
        </div>
        <div></div>
      </GovernanceContainer>
    );
  };

  render = () => {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  };
}

Governance.propTypes = {
  //props
  history: PropTypes.object,
};
export default Governance;
