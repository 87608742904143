import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Screen, Text, Input, TableAdmin } from '../../../components';
import { Row, Col } from 'react-bootstrap';

import newsService from '../../../services/news';

const { defaultStates, defaultAlertScreenOnClick } = require('../../../utils/defaults');

class NewsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsList: [],
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
    this.fillTable = this.fillTable.bind(this);
  }

  async componentDidMount() {
    await this.fillTable();
  }

  async fillTable(error = true) {
    this.setState({ loadingState: true });

    const response = await newsService.admin.getList();
    if (response.ok && response.data) {
      const data = response.data.map((i) => {
        i.news = i.news ? i.news.title : '';
        i.isActive = i.isActive ? 'Ativo' : 'Rascunho';
        return i;
      });

      this.setState({ newsList: data });
    } else {
      if (error) {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  modalOnHide() {
    const { modalState } = this.state;
    modalState.toggle = false;
    this.setState(modalState);
    this.fillTable(false);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  body() {
    const onCellClick = ({ id }) => this.props.history.push(`/gestao/noticias/${id}`);

    const columns = [
      { headerName: 'ID', field: 'id', hide: true, flex: 0.05 },
      { headerName: 'Título', field: 'title', flex: 0.5 },
      { headerName: 'Data de postagem', field: 'postingDate', flex: 0.2, align: 'center' },
      { headerName: 'Status', field: 'isActive', flex: 0.2 },
    ];

    return (
      <>
        <Row align={'center'}>
          <Col>
            <Text title text={'Notícias'} />
          </Col>
        </Row>
        <Row align="right">
          <Col>
            <Button
              underlinePlus
              text={'adicionar'}
              onClick={() => this.props.history.push('/gestao/noticias/novo')}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="mt-3">
            <TableAdmin onCellClick={onCellClick} data={this.state.newsList} columns={columns} />
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center"></Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        admin
        body={this.body}
        footer={this.footer}
        modalState={modalState}
        loadingState={loadingState}
        alertScreenState={alertScreenState}
      />
    );
  }
}

NewsList.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default NewsList;
