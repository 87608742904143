exports.dataFormParse = (data) => {
  const formData = new FormData();
  if (data.files && data.files.length > 0) {
    data.files.map((file) => {
      if (!file.id) {
        formData.append('image', file);
      }
    });
  }
  if (data.filesMobile && data.filesMobile[0] != null) {
    data.filesMobile.map((file) => {
      if (!file.id) {
        formData.append('image_mobile', file);
      }
    });
  }

  if (data.files_document && data.files_document.length > 0) {
    data.files_document.map((file) => {
      if (!file.id) {
        formData.append('document', file);
      }
    });
  }

  const dataCount = Object.keys(data).length;
  for (let index = 0; index < dataCount; index++) {
    const itemIndex = Object.keys(data)[index];
    const item =
      typeof data[itemIndex] === 'object' && data[itemIndex] !== null
        ? JSON.stringify(data[itemIndex])
        : data[itemIndex];

    if (item || item === '') {
      formData.append(itemIndex, item);
    }
  }

  return formData;
};
