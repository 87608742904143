import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';

import imgLinkedin from '../../../assets/icons/social_media/linkedin.png';
import imgFacebook from '../../../assets/icons/social_media/facebook.png';
import imgInstagram from '../../../assets/icons/social_media/instagram.png';
import map from '../../../assets/imgs/map.png';
import mapMobile from '../../../assets/imgs/map-mobile.png';
import { Col } from 'react-bootstrap';

export const ContactContainer = styled.div``;

export const ColStyled = styled(Col)`
  gap: 22px 0;

  @media ${bootstrapMin.lg} {
    align-items: flex-start !important;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  max-width: 460px;
  display: flex;
  justify-content: end;

  & .green-button {
    height: 47px !important;

    @media ${bootstrapMin.lg} {
      height: 39px !important;
    }
  }
`;

export const ContainerSocialMedia = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;

  @media ${bootstrapMin.lg} {
    justify-content: flex-start;
    gap: 0 50px;
  }
`;

export const IconSocialMedia = styled.div`
  background-image: url(${({ facebook, instagram }) => {
    if (facebook) return imgFacebook;
    else if (instagram) return imgInstagram;
    else return imgLinkedin;
  }});
  background-size: contain;
  background-repeat: no-repeat;
  width: 27px;
  height: 27px;
`;

export const Map = styled.div`
  background-image: url(${mapMobile});
  background-repeat: no-repeat;
  height: 280px;
  width: 280px;

  @media ${bootstrapMin.lg} {
    background-image: url(${map});
    height: 455px;
    width: 100%;
  }
`;
