import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';

export const Content = styled.div``;

export const ContentMobile = styled.div``;

export const ContentSearch = styled.div`
  .dropdown-menu {
    border-left-width: 0px;
  }

  .dropdown-item {
    height: 280px;
  }

  #basic-nav-dropdown {
    font-family: 'Trade Gothic LT Bold';
    color: ${colors.primaryThree};
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0;
    padding: 5px 16px 5px 16px;

    @media (max-width: 320px) {
      padding: 5px 5px 5px 10px;
    }
  }
`;

export const SelectOrder = styled.div`
  fieldset {
    display: none;
  }

  .MuiOutlinedInput-root {
    & div {
      padding: 0 32px !important;
    }
  }

  .MuiSelect-select {
    opacity: 1;
    border: none;
    color: ${colors.secondaryTwo};

    @media ${bootstrapMax.md} {
      font-size: 12px;
    }
  }
`;

export const Title = styled.h2`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryThree};
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0;

  @media ${bootstrapMin.lg} {
    font-size: 26px;
    line-height: 40px;
  }
`;

export const ColStyled = styled(Col)`
  gap: 0 60px;

  @media ${bootstrapMin.xl} {
    gap: 0 33px;
  }

  @media (min-width: 769px) {
    justify-content: space-between !important;
  }

  @media (min-width: 992px) {
    justify-content: start !important;
    gap: 0 16px;
  }
`;

export const InfoOrdinationContainer = styled.div`
  @media ${bootstrapMax.sm} {
    margin-right: -15px;
  }

  @media (max-width: 374px) {
    margin-top: 16px;
  }

  @media (min-width: 375px) {
    position: absolute;
    margin-top: -44px;
  }

  @media (min-width: 992px) {
    position: initial;
    display: flex;
    margin-top: -5px;
  }
`;
