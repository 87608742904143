import React, { Component, Fragment } from 'react';
import {
  Button,
  Screen,
  SubHeader,
  Text,
  CarouselComponent,
  Image,
  CooperativeCard,
} from '../../../components';
import { Container } from 'react-bootstrap';

import PropTypes from 'prop-types';
const { defaultStates } = require('../../../utils/defaults');

import bannerMobile from '../../../assets/imgs/banner-page-sncc-mobile.png';
import banner from '../../../assets/imgs/banner-page-sncc.png';

import imgCarrouselSNCCGrau1 from '../../../assets/imgs/carrousel-sncc-1grau.png';
import imgCarrouselSNCCGrau2 from '../../../assets/imgs/carrousel-sncc-2grau.png';
import imgCarrouselSNCCGrau3 from '../../../assets/imgs/carrousel-sncc-3grau.png';

import {
  Paragraph,
  Heading3,
  ContainerStyled,
  CooperativeCardStyled,
  TextStyled,
  CooperativeCardTextStyled,
  ContainerLimitValue,
  BannerLimitValue,
  ImageStyled,
} from './styles';
import { Col, Row } from 'react-bootstrap';
class SNCC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      innerWidth: window.innerWidth,
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  updateSize = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  header = () => {
    return (
      <>
        <SubHeader
          image
          backgroundImage={banner}
          backgroundImageMobile={bannerMobile}
          minHeightSm={452}
          minHeight={313}
          title={'SNCC - SISTEMA NACIONAL DE CRÉDITO COOPERATIVO'}
        />
      </>
    );
  };

  handleImagesForSection = (items) => {
    return items.map((item, i) => {
      return (
        <ContainerStyled key={i}>
          <Row className={'pt-5 pt-md-0'}>
            <Col>
              <ImageStyled img={item.src} alt={item.title} />
            </Col>
          </Row>
          <Row className={'pt-5 pb-lg-3'}>
            <Text heading5 noWrap textAlign={'center'} title={item.title}></Text>
          </Row>
          <Row>
            <Text paragraph3 center textAlign={'center'} text={item.text}></Text>
          </Row>
        </ContainerStyled>
      );
    });
  };

  body = () => {
    const breakPoints = [{ width: 1, itemsToShow: 1 }];

    const items = [
      {
        src: imgCarrouselSNCCGrau3,
        title: 'COOPERATIVA SINGULAR OU DE 1º GRAU',
        text: 'Tem objetivo de prestar serviços diretos ao associado. É constituída por um mínimo de vinte cooperados.',
      },
      {
        src: imgCarrouselSNCCGrau2,
        title: 'CENTRAL OU COOPERATIVA DE 2º GRAU',
        text: `Seu objetivo é organizar, em maior escala, os serviços das filiadas, facilitando a utilização recíproca dos serviços. 
                É constituída por, no mínimo, três cooperativas singulares.`,
      },
      {
        src: imgCarrouselSNCCGrau1,
        title: 'CONFEDERAÇÃO OU COOPERATIVA DE 3º GRAU',
        text: `Possui personalidade jurídica própria e reúne no mínimo três centrais, com o objetivo de defender seus interesses,
                promover a padronização, supervisão e integração operacional, financeira, normativa e tecnológica.`,
      },
    ];

    return (
      <Container className={'pt-2 pt-lg-0'}>
        <Row className={'mb-3 mb-lg-5 flex-column'}>
          <Col className={'d-flex justify-content-center mb-lg-3'}>
            <Text
              heading2
              noWrap
              textAlign={'center'}
              title={{ one: 'UMA VISÃO SOBRE A ORGANIZAÇÃO DAS COOPERATIVAS' }}
            />
          </Col>
          <Col>
            <Text
              paragraph3
              text={`As cooperativas de crédito podem se organizar em sistemas de segundo (centrais) 
              ou terceiro (confederações) nível. Esses sistemas propiciam economia de escala sob uma estrutura piramidal.<br><br> 
              As cooperativas singulares (primeiro grau) ocupam a base, as cooperativas centrais (segundo grau) 
              ocupam a zona intermediária e a confederação (terceiro grau) fica no topo:`}
            />
          </Col>
        </Row>
        <CarouselComponent
          className={'mb-3 mb-lg-5 flex-column d-none d-md-block'}
          showArrows
          breakPoints={breakPoints}
          snccArrow
          items={this.handleImagesForSection(items)}
        />
        {/* Mobile Content */}
        <Row className={'d-block d-md-none mb-5'}>{this.handleImagesForSection(items)}</Row>
        {/* End Mobile Content */}
        <Row className={'mb-3 my-lg-5 justify-content-center'}>
          <Col className={'d-flex justify-content-center mb-lg-3 mt-lg-5'} lg={9}>
            <Text
              heading2
              noWrap
              textAlign={'center'}
              title={{ one: 'SISTEMA NACIONAL DE CRÉDITO COOPERATIVO BRASILEIRO (SNCC)' }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextStyled
              className={'mt-5'}
              heading4
              noWrap
              textAlign={'center'}
              title={`4 SISTEMAS DE COOPERATIVAS DE CRÉDITO DE TERCEIRO NÍVEL (CONFEDERAÇÃO)`}
            />
          </Col>
        </Row>
        <Row className={'my-lg-4'}>
          <CooperativeCardStyled
            xsSize={12}
            lgSize={3}
            title={'Saiba mais'}
            associate={'sicoob'}
            link={'https://www.sicoob.com.br/'}
            logo={'sicoob-logo.png'}
            className={'mt-5'}
          />
          <CooperativeCardStyled
            xsSize={12}
            lgSize={3}
            title={'Saiba mais'}
            associate={'sicredi'}
            link={'https://www.sicredi.com.br/home/'}
            logo={'sicredi-logo.png'}
            className={'mt-5'}
          />
          <CooperativeCardStyled
            xsSize={12}
            lgSize={3}
            title={'Saiba mais'}
            associate={'unicred'}
            link={'https://unicred.com.br/'}
            logo={'unicred-logo.png'}
            className={'mt-5'}
          />
          <CooperativeCardStyled
            xsSize={12}
            lgSize={3}
            title={'Saiba mais'}
            associate={'cresol'}
            link={'https://cresol.com.br/'}
            logo={'cresol-logo.png'}
            className={'mt-5'}
          />
        </Row>
        <Row className={'pt-5 mt-5'}>
          <Col>
            <TextStyled
              heading4
              noWrap
              textAlign={'center'}
              title={`4 SISTEMAS ORGANIZADOS EM SEGUNDO NÍVEL (CENTRAIS)`}
            />
          </Col>
        </Row>
        <Row className={'my-lg-4 justify-content-between'}>
          <CooperativeCardStyled
            xsSize={12}
            lgSize={2}
            title={'Saiba mais'}
            associate={'cecoop'}
            link={'https://cecoop.com.br/central/'}
            logo={'cecoop-logo.png'}
            className={'mt-5'}
          />
          <CooperativeCardStyled
            xsSize={12}
            lgSize={2}
            title={'Saiba mais'}
            associate={'ailos'}
            link={'https://www.ailos.coop.br/'}
            logo={'ailos-logo.png'}
            logoSize={'97px 63px'}
            className={'mt-5'}
          />
          <CooperativeCardStyled
            xsSize={12}
            lgSize={2}
            title={'Saiba mais'}
            associate={'credisis'}
            link={'https://credisis.com.br/'}
            logo={'credisis-logo.png'}
            logoSize={'154px 47px'}
            className={'mt-5'}
          />
          <CooperativeCardStyled
            xsSize={12}
            lgSize={2}
            title={'Saiba mais'}
            associate={'uniprime'}
            link={'https://www.uniprime.com.br/singular/central'}
            logo={'uniprime-logo.png'}
            className={'mt-5'}
          />
        </Row>
        <Row className={'my-5 py-5'}>
          <Col className={'my-lg-4'}>
            <TextStyled
              heading4
              noWrap
              textAlign={'center'}
              title={`COOPERATIVAS SINGULARES DE CRÉDITO NÃO FILIADAS A CENTRAIS`}
            />
          </Col>
          <CooperativeCardTextStyled
            xsSize={12}
            lgSize={12}
            className={'mt-5'}
            text={'COOPERATIVAS NÃO FILIADAS'}
          />
        </Row>
        <Row className={'mb-5 mb-lg-4'}>
          <ContainerLimitValue>
            <BannerLimitValue />
            <Row className={'pt-lg-5 pt-3'}>
              <Col xs={9} lg={6} className={'pt-2 ps-0'}>
                <Text
                  paragraph3
                  lightColor
                  text={`O SNCC possui, ainda, um quarto elemento: os chamados "bancos cooperativos", que devem ter controle acionário 
                    de cooperativas centrais de crédito, e fornecem produtos e serviços financeiros especialmente para os membros 
                    do sistema, tais como poupança e fundo de investimento.`}
                />
                <Text linkApp href={'bancos-cooperativos'} lightColor text={`Saiba mais`} />
              </Col>
            </Row>
          </ContainerLimitValue>
        </Row>
      </Container>
    );
  };

  render = () => {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  };
}

SNCC.propTypes = {
  //props
  history: PropTypes.object,
};
export default SNCC;
