import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField as MUITextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '../../utils/colors';

export const DatePicker = styled(MUIDatePicker)`
  .MuiOutlinedInput-root:hover {
    border: 1px solid red !important;
  }
`;

export const TextField = styled(MUITextField)(({ error }) => ({
  label: {
    color: `${colors.primaryTwo}`,
    opacity: 0.4,
    fontSize: 'small',
  },
  'label.Mui-focused': {
    color: `${!error ? `${colors.secondaryTwo} !important` : ''}`,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.secondaryTwo,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `${!error ? colors.secondaryTwo : ''}`,
    },
    '&:hover fieldset': {
      borderColor: `${!error ? colors.secondaryTwo : ''}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${!error ? colors.secondaryTwo : ''}`,
    },
  },
  width: '100%',
}));
