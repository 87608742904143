import styled from 'styled-components';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';

export const StrategicPlanContainer = styled.div`
  & .mini-card-title {
    text-align: center;
    @media${bootstrapMin.lg} {
      text-align: left !important;
    }
  }
`;
