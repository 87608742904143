import React, { Component } from 'react';
import { Screen, SubHeader, Input, Button, Text, Card } from '../../../components';
import { Col, Row } from 'react-bootstrap';
import { ColStyled, NewsOrdinationContainer, SelectOrder, Span } from './styles';
import PropTypes from 'prop-types';
import newsService from '../../../services/news';
const { defaultStates, defaultModalOnHide, baseURL } = require('../../../utils/defaults');

import bannerMobile from '../../../assets/imgs/banner-news-mobile.png';
import banner from '../../../assets/imgs/banner-news.png';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descOrder: true,
      offset: 0,
      countOffSet: window.innerWidth < 768 ? 6 : 9,
      limit: window.innerWidth < 768 ? 6 : 9,
      qtdResultNews: 0,
      news: [],
      search: '',
      searched: '',
      ...defaultStates(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.handleOffset = this.handleOffset.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  async componentDidMount() {
    this.handleSearch();
  }

  handleChange(search) {
    this.setState({ search });
  }

  handleChangeOrder(descOrder) {
    this.setState({ offset: 0 });
    this.setState({ descOrder });
    setTimeout(() => {
      this.handleSearch();
    }, 0);
  }

  handleOffset(offset) {
    let count = offset;
    count += this.state.countOffSet;
    this.setState({ offset: count });
    return count;
  }

  async ViewMore() {
    const response = await newsService.user.getList({
      search: this.state.search,
      descOrder: this.state.descOrder,
      limit: this.state.limit,
      offset: this.handleOffset(this.state.offset),
    });
    if (response && response.ok && response.data) {
      this.setState({ qtdResultNews: response.data.length });
      this.setState({ news: this.state.news.concat(response.data) });
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  async handleSearch() {
    this.setState({ offset: 0 });
    this.setState({ loadingState: true, searched: this.state.search });
    const response = await newsService.user.getList({
      search: this.state.search,
      descOrder: this.state.descOrder,
      limit: this.state.limit,
    });

    if (response && response.ok && response.data) {
      this.setState({ news: response.data });
      this.setState({ qtdResultNews: response.data.length });
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }

    this.setState({ loadingState: false });
  }

  news = (news) => (
    <ColStyled
      xs={12}
      lg={9}
      xl={12}
      className={`no-gutters d-flex flex-row flex-wrap justify-content-center justify-content-md-between justify-content-lg-start`}>
      {news.map(({ id, title, hashtag, file, postingDate }) => (
        <Card
          key={id}
          id={id}
          date={postingDate}
          title={title}
          hashtag={hashtag}
          image={file && file.id ? `${baseURL()}/api/file/${file.id}/${file.slug}` : ''}
          {...this.props}
        />
      ))}
    </ColStyled>
  );

  header() {
    return (
      <>
        <SubHeader
          image
          backgroundImage={banner}
          backgroundImageMobile={bannerMobile}
          minHeightSm={452}
          minHeight={313}
          title={'AS NOVIDADES DO FGCOOP'}
        />
      </>
    );
  }

  body() {
    const { news, searched } = this.state;

    return (
      <>
        <Row className={'align-items-center'}>
          <Col
            lg={{ span: 6, order: 1 }}
            className={
              'align-items-center d-flex justify-content-start justify-content-lg-start justify-content-xl-start'
            }>
            <Input
              searchNews
              value={this.state.search}
              label={'DIGITE SUA BUSCA'}
              placeholder={'DIGITE SUA BUSCA'}
              maxLength={100}
              onChange={(e) => this.handleChange(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && this.handleSearch()}
            />
            <Button
              greenButtonSearch
              width={170}
              text={'BUSCAR'}
              onClick={() => this.handleSearch()}></Button>
          </Col>
          <Col
            xs={12}
            lg={{ span: 6, order: 2 }}
            className={
              'align-items-center d-flex justify-content-end justify-content-lg-end justify-content-xl-end'
            }>
            <NewsOrdinationContainer>
              <Text newsOrdination center text={`Ordenar por:`} />
              <SelectOrder>
                <Select
                  value={this.state.descOrder}
                  onChange={(e) => this.handleChangeOrder(e.target.value)}
                  inputProps={{ 'aria-label': 'Without label' }}>
                  <MenuItem value={true}>Mais recente</MenuItem>
                  <MenuItem value={false}>Mais antigo</MenuItem>
                </Select>
              </SelectOrder>
            </NewsOrdinationContainer>
          </Col>
        </Row>

        {searched ? (
          <Row>
            <Col xs={12} sm={12} className={'d-flex mt-2 justify-content-center'}>
              <Text
                titleSearch
                text={`RESULTADO${news.length > 1 ? 'S' : ''} PARA `}
                searched={searched}
              />
            </Col>

            <Col className={'d-flex justify-content-center'}>
              {news.length ? (
                <Span>{`Aproximadamente ${news.length} resultado${
                  news.length > 1 ? 's' : ''
                }`}</Span>
              ) : (
                <Span noFound>{`Não encontramos nenhum resultado para "${searched}".`}</Span>
              )}
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <Row className={'mt-4 pt-3 mb-n3 mb-lg-0 justify-content-center'}>{this.news(news)}</Row>

        <Col xs={12} className={'mb-lg-5 pb-lg-5 d-flex justify-content-center'}>
          {this.state.qtdResultNews < this.state.limit ? (
            <></>
          ) : (
            <Button
              greenButton
              width={156}
              widthMobile={150}
              height={39}
              text={'CARREGAR MAIS'}
              onClick={() => this.ViewMore()}></Button>
          )}
        </Col>
      </>
    );
  }

  render() {
    const { modalState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        loadingState={loadingState}
      />
    );
  }
}

News.propTypes = {
  //props
  history: PropTypes.object,
};
export default News;
