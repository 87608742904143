import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import {
  ColStyled,
  DocumentCard,
  DownloadContent,
  DownloadFileIcon,
  Heading3,
  Paragraph,
  RowStyled,
} from './styles';
import { Button } from '../../components';

class DownloadCard extends Component {
  render() {
    //props
    const { title, href } = this.props;

    //types
    const { documents } = this.props;

    if (documents) {
      return (
        <DocumentCard className={'d-flex flex-column mb-3'}>
          <RowStyled>
            <ColStyled xs={6} lg={10}>
              <Paragraph>{title}</Paragraph>
            </ColStyled>
            <Col xs={6} lg={2} className={'d-flex justify-content-end'}>
              <Button
                greenButton
                width={113}
                widthMobile={97}
                text={'ABRIR'}
                onClick={() => {
                  window.open(`${href}`, '_blank');
                }}
              />
            </Col>
          </RowStyled>
        </DocumentCard>
      );
    }

    return (
      <div className={'d-flex'}>
        <div>
          <DownloadFileIcon />
        </div>
        <Row>
          <Col>
            <Heading3>{title}</Heading3>
            <DownloadContent href={href} target={'_blank'}>
              Baixar
            </DownloadContent>
          </Col>
        </Row>
      </div>
    );
  }
}

DownloadCard.propTypes = {
  //props
  title: PropTypes.string,
  href: PropTypes.string,

  //types
  documents: PropTypes.bool,
};

export default DownloadCard;
