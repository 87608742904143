import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Label } from '../index';
import { Accordion as StyledAccordion, HeaderAccordion, Body, Item } from './styles';
import { NavLink } from 'react-router-dom';
import { defaultStates } from '../../utils/defaults';

class SearchInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLinkHeader: '',
      activeLinkBody: '',
      alerts: null,
      ...defaultStates(),
    };

    this.handleChangeBody = this.handleChangeBody.bind(this);
  }

  handleChangeBody(valueBody, valueHeader) {
    this.setState({ activeLinkBody: valueBody, activeLinkHeader: valueHeader });
    this.props.onClick(valueBody);
  }

  componentDidMount() {}

  render() {
    // types
    const { mobile, onClick } = this.props;

    const options = [
      {
        header: (
          <>
            <NavLink
              to={this}
              onClick={() => onClick('rel_manager')}
              className={
                this.state.activeLinkHeader === 'rel_manager' ? 'is-active' : 'search-info'
              }>
              <Label linkInfo text={'Relatório de gestão'} />
            </NavLink>
          </>
        ),
        id: 'rel_manager',
        body: (
          <>
            <NavLink
              exact
              to={this}
              activeClassName="is-active"
              onClick={() => this.handleChangeBody('anual', 'rel_manager')}
              className={
                this.state.activeLinkBody === 'anual'
                  ? 'd-flex flex-column align-items-start is-active'
                  : 'd-flex flex-column align-items-start'
              }
              id="anual">
              Anual
            </NavLink>
            <NavLink
              exact
              to={this}
              activeClassName="is-active"
              onClick={() => this.handleChangeBody('semestral', 'rel_manager')}
              className={
                this.state.activeLinkBody === 'semestral'
                  ? 'd-flex flex-column align-items-start is-active'
                  : 'd-flex flex-column align-items-start'
              }
              id="semestral">
              Semestral
            </NavLink>
          </>
        ),
      },
      {
        header: (
          <>
            <NavLink
              to={this}
              onClick={() => onClick('rel_sncc')}
              className={this.state.activeLinkHeader === 'rel_sncc' ? 'is-active' : 'search-info'}>
              <Label linkInfo text={'Relatório SNCC'} />
            </NavLink>
          </>
        ),
        id: 'rel_sncc',
      },
      {
        header: (
          <>
            <NavLink
              to={this}
              onClick={() => onClick('info_fgcoop')}
              className={
                this.state.activeLinkHeader === 'info_fgcoop' ? 'is-active' : 'search-info'
              }>
              <Label linkInfo text={'Informativo FGCOOP'} />
            </NavLink>
          </>
        ),
        id: 'info_fgcoop',
      },
      {
        header: (
          <>
            <NavLink
              to={this}
              onClick={() => onClick('bo_mensal')}
              className={this.state.activeLinkHeader === 'bo_mensal' ? 'is-active' : 'search-info'}>
              <Label linkInfo text={'Boletim mensal'} />
            </NavLink>
          </>
        ),
        id: 'bo_mensal',
      },
      {
        header: (
          <>
            <NavLink
              to={this}
              onClick={() => onClick('de_financeiro')}
              className={
                this.state.activeLinkHeader === 'de_financeiro' ? 'is-active' : 'search-info'
              }>
              <Label linkInfo text={'Demonstrativo Financeiro'} />
            </NavLink>
          </>
        ),
        id: 'de_financeiro',
      },
      {
        header: (
          <>
            <NavLink
              to={this}
              onClick={() => onClick('rel_especiais')}
              className={
                this.state.activeLinkHeader === 'rel_especiais' ? 'is-active' : 'search-info'
              }>
              <Label linkInfo text={'Relatórios Especiais'} />
            </NavLink>
          </>
        ),
        id: 'rel_especiais',
      },
      {
        header: (
          <>
            <NavLink
              to={this}
              onClick={() => onClick('ma_divulgacao')}
              className={
                this.state.activeLinkHeader === 'ma_divulgacao' ? 'is-active' : 'search-info'
              }>
              <Label linkInfo text={'Materiais de Divulgação'} />
            </NavLink>
          </>
        ),
        id: 'ma_divulgacao',
      },
    ];

    if (mobile) {
      return (
        <>
          <StyledAccordion defaultActiveKey={[0]} alwaysOpen>
            {options.map(({ header, body, id }, i) => (
              <Item eventKey={i} key={i} className="d-flex flex-column align-items-start">
                <HeaderAccordion
                  mobile="true"
                  onClick={() => this.setState({ activeLinkHeader: id, activeLinkBody: '' })}>
                  {header}
                </HeaderAccordion>
                <Body mobile="true">{body}</Body>
              </Item>
            ))}
            <br />
          </StyledAccordion>
        </>
      );
    }

    return (
      <>
        <StyledAccordion>
          {options.map(({ header, body, id }, i) => (
            <Item eventKey={i} key={i} className="d-flex flex-column align-items-start">
              <HeaderAccordion
                onClick={() => this.setState({ activeLinkHeader: id, activeLinkBody: '' })}>
                {header}
              </HeaderAccordion>
              <Body>{body}</Body>
            </Item>
          ))}
          <br />
        </StyledAccordion>
      </>
    );
  }
}

SearchInfo.propTypes = {
  history: PropTypes.object,
  mobile: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SearchInfo;
