const { dataFormParse } = require('../utils/dataFormParse');
const { axiosDefault, handleErrors, defaultSearchInfos } = require('../utils/defaults');

const infoService = {
  admin: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/infos/gestao/${id ? id : ''}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async () => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/infos/gestao`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    save: async (info) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.post(`/api/infos/gestao`, dataFormParse(info));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    edit: async (info) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.put(`/api/infos/gestao/${info.id}`, dataFormParse(info));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    delete: async (id) => {
      try {
        const axios = axiosDefault();

        const { data } = await axios.delete(`/api/infos/gestao/${id}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },

  user: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/infos/${id}`);

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async ({ limit = '', category = '', descOrder = '', offset = '', search = '' }) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(
          `/api/infos?limit=${limit}&category=${defaultSearchInfos(
            category,
          )}&descOrder=${descOrder}&offset=${offset}&search=${search}`,
        );

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },
};

export default infoService;
