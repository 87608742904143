import PropTypes from 'prop-types';
import { useState } from 'react';
import * as React from 'react';
import { DatePicker, TextField } from './styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { startOfDay } from 'date-fns';

const Calendar = ({ label, readOnly, disabled, minDate, maxDate, value, onChange, autoFocus }) => {
  const [hasError, setHasError] = useState(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <DatePicker
        mask={'__/__/____'}
        label={label}
        value={value ? startOfDay(value) : null}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(value) => onChange({ target: { value: startOfDay(value) } })}
        renderInput={(params) => (
          <TextField
            autoFocus={autoFocus}
            onBlur={(e) => {
              if (hasError) e.target.focus();
            }}
            {...params}
            size={'small'}
          />
        )}
        readOnly={readOnly}
        disabled={disabled}
        onError={(e) => setHasError(e)}
      />
    </LocalizationProvider>
  );
};

Calendar.propTypes = {
  label: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default Calendar;
