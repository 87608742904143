import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FileCard } from './styles';
import { Col, Row } from 'react-bootstrap';
import { Button } from '../index';

import x_icon from '../../assets/icons/x_maskavo-min.png';
import pdf_icon from '../../assets/icons/pdf_maskavo-min.png';

class File extends Component {
  render() {
    // props
    const { fileName, fileTitle, onClick } = this.props;

    return (
      <FileCard>
        <Row>
          <Col className="d-flex justify-content-end">
            <div>
              <img
                className={'p-2'}
                style={{ cursor: 'pointer' }}
                src={x_icon}
                alt=""
                onClick={onClick}
              />
            </div>
          </Col>
        </Row>
        <Row align="center">
          <Col className="p-1">
            <img src={pdf_icon} alt="" />
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <div className="file-name">{fileName}</div>
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <div className="file-title">{fileTitle}</div>
          </Col>
        </Row>
      </FileCard>
    );
  }
}

File.propTypes = {
  // props
  fileName: PropTypes.string,
  fileTitle: PropTypes.string,
  onClick: PropTypes.func,
};

export default File;
