const { dataFormParse } = require('../utils/dataFormParse');
const { axiosDefault, handleErrors } = require('../utils/defaults');

const newsService = {
  admin: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/news/gestao/${id ? id : ''}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async () => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/news/gestao`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    save: async (news) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.post(`/api/news/gestao`, dataFormParse(news));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    edit: async (news) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.put(`/api/news/gestao/${news.id}`, dataFormParse(news));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    delete: async (id) => {
      try {
        const axios = axiosDefault();

        const { data } = await axios.delete(`/api/news/gestao/${id}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },

  user: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/news/${id}`);

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async ({ limit = '', search = '', descOrder = '', offset = '', random = '',home='false' }) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(
          `/api/news?limit=${limit}&title=${search}&descOrder=${descOrder}&offset=${offset}&random=${random}&home=${home}`,
        );

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },
};

export default newsService;
