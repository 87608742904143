import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin, device } from '../../../utils/devices';

export const Container = styled.div`
  .recipes,
  .guaranteed-deposits {
    @media ${bootstrapMin.xl} {
      max-width: 77%;
    }
  }

  .guaranteed-deposits {
    p {
      @media ${bootstrapMax.lg} {
        margin-bottom: 30px !important;
      }
    }
  }

  & .meet-our-background:first-child {
    & .MuiAccordionSummary-root {
      padding-top: 0 !important;
      margin-top: 5px;

      @media ${bootstrapMax.sm} {
        margin-top: -5px;
      }
    }
  }

  @media ${bootstrapMax.sm} {
    padding: 0 15px;
  }

  @media (max-width: 354px) {
    .adjust-width-mobile {
      width: 100%;
    }
  }

  .btn-iadi {
    @media ${device.tablet} {
      justify-content: center !important;
    }
  }
  hr {
    border-bottom: 2px solid;
  }
`;

export const Paragraph = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.secondaryTwo};
  border-left: ${({ border }) => (border ? `2px solid ${colors.primaryTwo}` : 'none')};
  padding-left: ${({ border }) => (border ? '10px' : '0')};
  margin-bottom: 30px;

  @media ${bootstrapMin.lg} {
    font-size: 16px;
  }
`;
