/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Screen, Text, Input, Label, Button, ButtonUpload, File as FileDoc, Modal } from '../../../components';
import cooperativeService from '../../../services/cooperatives';

import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormControlLabel from '@mui/material/FormControlLabel';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
} = require('../../../utils/defaults');

class CooperativeCsv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cooperative: {
        files_document: [],
      },
      response: {},
      selectAllNovos: false,
      selectAllAtualizados: false,
      csv: false,
      ignore: false,
      errorExtension: '',
      showModal: false,
      ...defaultStates(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReadCsv = this.handleReadCsv.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  handleConfirm = () => {
    this.setState({ showModal: false });
  };

  handleToggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  handleToggleExistenteNovos = (index) => () => {
    const { response } = this.state;
    const novos = [...response.novos];
    novos[index].aprovado = !novos[index].aprovado;

    this.setState({ response: { ...response, novos } });
  };

  handleToggleExistenteAtualizados = (index) => () => {
    const { response } = this.state;
    const atualizados = [...response.atualizados];
    atualizados[index].aprovado = !atualizados[index].aprovado;

    this.setState({ response: { ...response, atualizados } });
  };

  handleToggleSelectAllNovos = () => {
    const { response, selectAllNovos } = this.state;

    const novos = response.novos.map(item => ({
      ...item,
      aprovado: !selectAllNovos
    }));

    this.setState({
      response: { ...response, novos },
      selectAllNovos: !selectAllNovos
    });
  };

  handleToggleSelectAllAtualizados = () => {
    const { response, selectAllAtualizados } = this.state;

    const atualizados = response.atualizados.map(item => ({
      ...item,
      aprovado: !selectAllAtualizados
    }));

    this.setState({
      response: { ...response, atualizados },
      selectAllAtualizados: !selectAllAtualizados
    });
  };

  handleToggleIgnore = () => {
    const { ignore } = this.state;

    this.setState({ ignore: !ignore });
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() { }

  handleChange(id, event) {
    const { cooperative } = this.state;

    if (id === 'files_document') {
      cooperative[id] = [event[0]];
    } else if (id === 'removeFileDocument') {
      this.setState({ errorExtension: '' })
      cooperative.files_document = [];
    } else {
      cooperative[id] = event.target.value;
    }

    this.setState({ cooperative });
  }

  async handleReadCsv() {
    const { cooperative } = this.state;

    const formData = new FormData();
    formData.append('cooperative', JSON.stringify(cooperative));

    if (cooperative.files_document.length) {

      formData.append('file', cooperative.files_document[0]);
    }

    try {
      const response = await cooperativeService.admin.readCsv(cooperative);

      response.statusCode === 400 ? this.setState({ errorExtension: response.message }) : this.setState({ errorExtension: '' });

      response.data.novos.length ? response.data.novos = response.data.novos.map(item => ({ ...item, aprovado: false })) : response.data.novos = [];
      response.data.atualizados.length ? response.data.atualizados = response.data.atualizados.map(item => ({ ...item, aprovado: false })) : response.data.atualizados = [];

      this.setState({
        response: response.data,
        csv: true,
        selectAllNovos: false,
        selectAllAtualizados: false
      });
    } catch (error) {
      console.error('Erro ao ler o CSV:', error);
    }
  }

  async handleSubmit() {
    const { response, ignore } = this.state;

    if (!ignore && response.erros?.length) {
      this.setState({
        showModal: true
      });
      return;
    }

    let newResponse = {};
    let updatedResponse = {};

    if (response.novos?.length) {
      const cooperatives = response.novos.filter(item => item.aprovado);
      newResponse = await cooperativeService.admin.saveBatch(cooperatives);
    }

    if (response.atualizados?.length) {
      const cooperatives = response.atualizados.filter(item => item.aprovado);
      updatedResponse = await cooperativeService.admin.editBatch(cooperatives);
    }

    if ((newResponse?.ok || updatedResponse?.ok)) {
      const alertScreenState = {
        title: 'Cooperativas criadas e atualizadas com sucesso!',
        open: true,
        onClick: this.alertScreenOnClick,
        pagePath: '/gestao/cooperatives',
      };

      this.setState({ alertScreenState });
    }
  }


  body() {
    const { cooperative, modalFile, response, selectAllNovos, selectAllAtualizados, ignore, errorExtension, showModal, csv } = this.state;

    return (
      <>
        <Row align="center">
          <Col>
            <Text title className="font-weight-bolder" text={'Cooperativa'} />
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <Label text={'Documento CSV:'} />
            <ButtonUpload
              modal
              onClickConfirm={(e) => {
                this.handleChange('files_document', e);
                this.setState({ modalFile: false });
              }}
              modalToggle={modalFile}
              modalTitle={'ADICIONAR DOCUMENTO'}
              modalOnHide={() => this.setState({ modalFile: false })}
              onClick={() => this.setState({ modalFile: true })}
            />
          </Col>
        </Row>
        <Row align="center" className="d-flex justify-content-center mt-4">
          {cooperative.files_document.map((doc, index) => {
            return (
              <Col md={4} key={index}>
                {cooperative.files_document.length ? (
                  <Label text={'Documento adicionado:'} />
                ) : (
                  ''
                )}
                <FileDoc
                  fileName={doc.fileName ? doc.fileName : doc.name ? doc.name : 'Arquivo sem nome'}
                  fileTitle={doc.fileName ? doc.fileName : doc.name ? doc.name : 'Arquivo sem nome'}
                  onClick={() => this.handleChange('removeFileDocument')}
                  info={doc}
                />
                <Text danger text={errorExtension ? `${errorExtension}` : ''} />
              </Col>
            );
          })}
        </Row>
        {response.novos?.length ? (
          <>
            <hr style={{ borderTop: '1px solid black' }} />
            <Row align="center" className="mt-5">
              <Col>
                <Text title className="font-weight-bolder mb-1" text={'Novas cooperativas'} />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center ml-1">
              <Col>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAllNovos}
                      onChange={this.handleToggleSelectAllNovos}
                    />
                  }
                  label="Selecionar todos"
                />
              </Col>
            </Row>
            <Row align="center">
              <Col>
                <List>
                  {response.novos.map((item, index) => (
                    <ListItem key={index} dense onClick={this.handleToggleExistenteNovos(index)}>
                      <Checkbox
                        edge="start"
                        checked={item.aprovado}
                        tabIndex={-1}
                        disableRipple
                      />
                      <ListItemText primary={`${item.name} - ${item.sigla}` || `Item ${index + 1}`} />
                    </ListItem>
                  ))}
                </List>
              </Col>
            </Row>
          </>
        ) : ('')}
        {response.atualizados?.length ? (
          <>
            <hr style={{ borderTop: '1px solid black' }} />
            <Row align="center" className="mt-5">
              <Col>
                <Text title className="font-weight-bolder mb-1" text={'Cooperativa a serem atualizadas'} />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center ml-1">
              <Col>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAllAtualizados}
                      onChange={this.handleToggleSelectAllAtualizados}
                    />
                  }
                  label="Selecionar todos"
                />
              </Col>
            </Row>
            <Row align="center">
              <Col>
                <List>
                  {response.atualizados.map((item, index) => (
                    <ListItem key={index} dense onClick={this.handleToggleExistenteAtualizados(index)}>
                      <Checkbox
                        edge="start"
                        checked={item.aprovado}
                        tabIndex={-1}
                        disableRipple
                      />
                      <ListItemText primary={`${item.name} - ${item.sigla}` || `Item ${index + 1}`} />
                    </ListItem>
                  ))}
                </List>
              </Col>
            </Row>
          </>
        ) : ('')}
        {response.erros?.length ? (
          <>
            <hr style={{ borderTop: '1px solid black' }} />
            <Row align="center" className="mt-5">
              <Col>
                <Text className="font-weight-bolder mb-1 d-inline" title text={'Linhas não identificadas'} />
                <Text className="font-weight-bolder mb-1 d-inline text-danger ms-1" title text={'*'} />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center ml-1">
              <Col>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ignore}
                      onChange={this.handleToggleIgnore}
                    />
                  }
                  label="Ignorar erros"
                />
              </Col>
            </Row>
            <Row align="center">
              <Col>
                <List>
                  {response.erros.map((item, index) => (
                    <ListItem key={index} dense onClick={this.handleToggleExistenteAtualizados(index)}>
                      <ListItemText primary={`- linha ${item.linha} do csv está com  ${!item.name ? 'o nome vazio' : ''} 
                      ${!item.sigla ? 'a sigla vazia' : ''} 
                      ${item.name && item.sigla ? 'nome duplicado' : ''}` || `Item ${index + 1}`} />
                    </ListItem>
                  ))}
                </List>
              </Col>
            </Row>
            <Modal
              confirm={'Fechar'}
              onClickConfirm={this.handleConfirm}
              title={'Campo obrigatório'}
              text={'É necessário marcar a caixa "Ignorar erros" para prosseguir'}
              toggle={showModal}
              onHide={this.handleToggleModal}
            />
          </>
        ) : ('')}
        {csv && (!response.novos?.length && !response.atualizados?.length && !response.erros?.length) ? (
          <>
            <hr style={{ borderTop: '1px solid black' }} />
            <Row align="center" className="mt-1">
              <Col>
                <Text title className="font-weight-bolder mb-1" text={'não foram identificados novos registros'} />
              </Col>
            </Row>
          </>
        ) : ('')}
      </>
    );
  }

  footer() {
    const { csv } = this.state

    if (csv) {
      return (
        <>
          <hr style={{ borderTop: '1px solid black' }} />
          <Row align="center" className="mt-5 mb-5">
            <Col>
              <Button text={'salvar'} onClick={this.handleSubmit} />
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <Row align="center" className="mt-5">
          <Col>
            <Button text={'Enviar'} onClick={this.handleReadCsv} />
          </Col>
        </Row>
      );
    }
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          admin
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

CooperativeCsv.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default CooperativeCsv;
