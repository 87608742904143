import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';

export const CookiesContainer = styled.div`
  padding: 0 50px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1190px !important;

  & .cookies {
    max-width: 1088px !important;
    max-height: 89px;
    background-color: transparent !important;
    left: auto !important;
    top: auto !important;
    bottom: 30px;

    .CookieConsent {
      align-items: center !important;
      background-color: ${colors.white} !important;
      color: ${colors.primaryThree} !important;
      box-shadow: 8px 10px 20px #0000001a;
      padding: 10px 40px 10px 45px;
      position: absolute !important;
      border-radius: 10px;
      max-width: 1088px;
      flex: unset;
      bottom: 0px;
      display: flex;
      text-align: center;
      font-size: 14px;
      line-height: 22px;
      max-height: 89px;
      flex-wrap: initial !important;

      & a,
      a:hover {
        color: ${colors.primaryThree};
      }

      button {
        width: 170px;
        height: 45px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        border-radius: 8px !important;
        background-color: ${colors.secondaryThree} !important;
        color: ${colors.white} !important;
        transition: 0.3s;

        @media ${bootstrapMax.lg} {
          margin: 0 !important;
          font-size: 14px;
          line-height: 17px;
          max-height: 36px;
        }
      }

      @media ${bootstrapMax.lg} {
        flex-wrap: wrap !important;
        padding: 15px 28px;
        display: flex;
        max-height: 180px;

        & .mediaQuery {
          flex-shrink: 0;
          width: 100%;
          max-width: 100%;
          margin: 0 !important;
        }
      }

      @media ${bootstrapMax.xl} {
        width: auto !important;
        max-width: 100%;
        margin: 0 15px;
      }
    }

    @media ${bootstrapMax.lg} {
      max-height: 100% !important;
      & div {
        width: 100%;
      }
    }

    @media ${bootstrapMax.xl} {
      max-width: 100% !important;
      left: 0px !important;
      max-height: 170px !important;
      bottom: 18px !important;
    }
  }
`;
