import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

class TabPanel extends Component {
  render() {
    // props
    const { children, value, index, ...other } = this.props;

    return (
      <div
        className={'tab-panel'}
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}>
        {value === index && <Box className={'mt-lg-4 pt-4'}>{children}</Box>}
      </div>
    );
  }
}

TabPanel.propTypes = {
  // props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel;
