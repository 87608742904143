import styled from 'styled-components';
import { Accordion as BSAccordion, Offcanvas } from 'react-bootstrap';
import { colors } from '../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../utils/devices';

const { Header: BSHeader, Body: BSBody, Item: BSItem } = BSAccordion;

export const Accordion = styled(BSAccordion)`
  .accordion-body {
    box-shadow: 5px 5px 20px #7070701f;
    border-radius: 4px;
    padding: 0;
    min-width: 150px;
    opacity: 1;
    border: none;
    padding: 0 20px;
    margin-right: -20px;
  }
`;

export const Body = styled(BSBody)`
  a {
    font-family: 'Trade Gothic LT', serif !important;
    font-size: 12px;
    color: ${colors.primaryThree};
    padding: 7px 0px 7px 0px;
    white-space: nowrap;

    @media (max-width: 374px) {
      font-size: 11px;
    }
  }

  .is-active {
    color: ${colors.primaryTwo};
    font-family: 'Trade Gothic LT Bold', serif !important;
  }
`;

export const Item = styled(BSItem)`
  background-color: transparent;
  border: none;
`;

export const HeaderAccordion = styled(BSHeader)`
  button[aria-expanded='true'] {
    font-family: 'Trade Gothic LT Bold', serif !important;
    text-decoration: underline;
    text-decoration-color: ${colors.primaryTwo};
    text-decoration-thickness: 3px;
    text-underline-offset: 8px;
  }

  .accordion-button {
    color: ${colors.primaryThree} !important;
    background-color: transparent;
    font-size: 14px;
    outline: none !important;
    box-shadow: none;
    border: none;
    padding-right: 0;

    a:hover {
      font-family: 'Trade Gothic LT Bold', serif !important;
      text-decoration: underline;
      text-decoration-color: ${colors.primaryTwo};
      text-decoration-thickness: 3px;
      text-underline-offset: 8px;
    }

    ::after {
      display: none;
    }
  }
`;

// Hamburger CSS
export const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  padding: 0;

  > div {
    width: 40px;
    height: 27px;
    background-image: url(${({ img }) => img});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media ${bootstrapMax.lg} {
    > div {
      width: 28px;
      height: 19px;
    }
  }
`;

// Navbar CSS
export const Nav = styled.nav`
  top: 0px;
  left: 0px;
  width: auto;
  background: ${colors.white} 0% 0% no-repeat padding-box;
  opacity: 1;
  display: block;
  margin: auto;
  z-index: 50;

  @media ${bootstrapMax.md} {
    width: 100%;
    position: fixed;
    height: 55px;
  }

  @media ${bootstrapMin.xxl} {
    width: 1366px;
  }
`;

export const Header = styled.div`
  margin-top: 28px;
  margin-left: 139px;
  margin-right: 139px;
  margin-bottom: 10px;
  width: auto;
  height: 116px;
  opacity: 1;
  z-index: 50;

  .nav-link {
    color: ${colors.primaryThree};
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  a[aria-expanded='true'] {
    font-family: 'Trade Gothic LT Bold', serif !important;
    text-decoration: underline;
    text-decoration-color: ${colors.primaryTwo};
    text-decoration-thickness: 3px;
    text-underline-offset: 8px;
  }

  .dropdown-menu {
    background-color: ${colors.supportOne};
    box-shadow: 5px 5px 20px #7070701f;
    border-radius: 4px;
    opacity: 1;
    border: none;
  }

  .dropdown-item {
    background-color: ${colors.supportOne};
    font-family: 'Trade Gothic LT', serif !important;
    font-size: 13px;
    opacity: 1;
    padding: 5px 30px 7px 10px;
  }

  .dropdown-item:hover {
    background-color: none;
  }

  .custom-dropdown-hide-menu {
    .dropdown-menu {
      visibility: hidden;
    }
  }

  .is-active {
    color: ${colors.primaryTwo};
    font-family: 'Trade Gothic LT Bold', serif !important;
  }

  @media ${bootstrapMin.xxl} {
    width: 1088px;
  }

  @media ${bootstrapMax.sm} {
    margin-left: 30px;
    margin-right: 30px;
  }
`;

export const HeaderMobile = styled.div`
  width: auto;
  opacity: 1;
  z-index: 50;
  margin: 10px;

  .nav-link {
    color: ${colors.primaryThree};
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  a[aria-expanded='true'] {
    font-family: 'Trade Gothic LT Bold', serif !important;
    text-decoration: underline;
    text-decoration-color: ${colors.primaryTwo};
    text-decoration-thickness: 3px;
    text-underline-offset: 8px;
  }

  .dropdown-menu {
    background-color: ${colors.supportOne};
    box-shadow: 5px 5px 20px #7070701f;
    border-radius: 4px;
    opacity: 1;
    border: none;
  }

  .dropdown-item {
    background-color: ${colors.supportOne};
    font-family: 'Trade Gothic LT', serif !important;
    font-size: 13px;
    opacity: 1;
    padding: 5px 30px 7px 10px;
  }

  .dropdown-item:hover {
    background-color: none;
  }

  .custom-dropdown-hide-menu {
    .dropdown-menu {
      visibility: hidden;
    }
  }

  @media ${bootstrapMin.xxl} {
    width: 1088px;
  }

  @media ${bootstrapMax.sm} {
    margin-left: 30px;
    margin-right: 30px;

`;

export const Logo = styled.a`
  flex: 1;
  width: 110px;
  height: 32px;
  display: flex;
  justify-content: start;

  div {
    width: 110px;
    height: 32px;
    background-image: url(${({ img }) => img});
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  @media ${bootstrapMax.md} {
    padding: 0;
  }

  @media ${bootstrapMax.lg} {
    width: 83px;
    height: 24px;
    justify-content: start;
    margin-left 20px;
    div {
      width: 83px;
      height: 24px;
      margin: 0;
    }
  }
`;

// Links CSS
export const Ul = styled(Offcanvas)`
  flex-flow: column nowrap;
  background-color: ${colors.white};
  position: fixed;
  transform: ${({ open }) => (open ? 'translateX(0)' : `translateX(100%)`)};
  top: 0;
  right: 0;
  height: 100%;
  max-width: 375px;
  transition: transform 0.3s ease-in-out;
  z-index: 1050;
  justify-content: normal;
  font-size: 16px;
  list-style-type: none;
  padding: 0 9px 0 0;

  a {
    text-transform: none;
    text-decoration: none;
  }

  div {
    &:hover {
      color: ${colors.secondaryOne};
    }
  }

  @media ${bootstrapMax.lg} {
    width: 50% !important;
    font-size: 10px;
    padding: 0 3px 0 0;
  }

  .btn-close {
    position: absolute;
    top: 30px;
    left: 30px;
    padding: 0;
    opacity: 0.8;
  }
`;

export const ScrollList = styled.div`
  overflow-y: auto;
  height: inherit;
  padding-bottom: 100px;

  .nav-link {
    color: ${colors.primaryThree};
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  a[aria-expanded='true'] {
    font-family: 'Trade Gothic LT Bold', serif !important;
    text-decoration: underline;
    text-decoration-color: ${colors.primaryTwo};
    text-decoration-thickness: 3px;
    text-underline-offset: 8px;
  }

  .dropdown-menu {
    position: relative;
    text-align: right;
    background-color: ${colors.supportOne};
    box-shadow: 5px 5px 20px #7070701f;
    border-radius: 4px;
    opacity: 1;
    border: none;
  }

  .dropdown-item {
    background-color: ${colors.supportOne};
    font-family: 'Trade Gothic LT', serif !important;
    font-size: 13px;
    opacity: 1;
    padding: 5px 10px 7px 10px;
  }

  .dropdown-item:hover {
    background-color: none;
  }

  .custom-dropdown-hide-menu {
    .dropdown-menu {
      visibility: hidden;
    }
  }

  .search {
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 0px;
    padding-bottom: 20px;
  }
`;

export const LogoMenu = styled.img`
  width: 83px;

  @media ${bootstrapMax.lg} {
    padding-top: 20px;
    padding-bottom: 30px;
    width: 83px;
  }
`;

const MenuDefault = styled(Offcanvas.Header)`
  width: 100%;
  padding: 35px 45px;
  color: ${colors.primaryThree};

  @media ${bootstrapMax.lg} {
    padding: 25px 35px;
  }
`;

export const MenuHeader = styled(MenuDefault)`
  margin: 10px 0 0;
`;

export const MenuItem = styled(MenuDefault)`
  border-bottom: 1px solid ${colors.secondaryOne};
  align-items: center;
  justify-content: flex-start;
`;

export const Icon = styled.div`
  min-width: 20px;
  max-width: 21px;
  width: 100%;
  height: 29px;
  margin: 0 16px 0 0;
  background-image: url(${({ img }) => img});
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;

  @media ${bootstrapMax.lg} {
    max-width: 33px;
    margin: 0 19px 0 0;
  }
`;

export const Close = styled.div`
  width: 7px;
  height: 7px;
  margin-top: 30px;
  margin-left: 30px;
  cursor: pointer;
  background-image: url(${({ img }) => img});
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;

  @media ${bootstrapMax.lg} {
    width: 7px;
    height: 7px;
  }
`;

export const MenuFooter = styled(MenuDefault)`
  flex-direction: column;
  padding: 19px 35px;

  > a:last-child {
    margin: 15px 0 0;
  }
`;

export const ContainerMenuMobile = styled.div`
  margin-right: 30px;

  @media (max-width: 374px) {
    .common-questions,
    .privacy-policy {
      & span {
        font-size: 12px;
      }
    }
  }
`;

export const ContainerTranslate = styled.div`
  span {
    cursor: pointer;
    padding-top: 4px;
    padding-right: 6px;
  }
`;
