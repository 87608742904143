import * as FileSaver from 'file-saver';
import * as XLSX from 'sheetjs-style';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import Button from '../../../components/Button'; // Assuming you have a custom Button component

const ExportExcel = ({ datas, fileName }) => {
  const [loading, setLoading] = useState(false);

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = () => {
    if (!Array.isArray(datas) || datas.length === 0) {
      console.error('ExportExcel: datas is not a valid array or it is empty');
      return;
    }

    setLoading(true);

    try {
      const newdata = new Date().toISOString().slice(0, 10);
      const filteredData = datas.map(({ id, email, createdAt }) => ({
        id,
        email,
        createdAt,
      }));

      const sheetData = [
        ['ID', 'Email', 'Data de Cadastro'],
        ...filteredData.map((item) => [item.id, item.email, item.createdAt]),
      ];
      const ws = XLSX.utils.aoa_to_sheet(sheetData);

      const titleStyle = {
        font: { bold: true, color: { rgb: '#3F4B66' } },
        fill: { fgColor: { rgb: '#4F81BD' } },
      };

      const range = XLSX.utils.decode_range(ws['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = XLSX.utils.encode_cell({ c: C, r: 0 });
        if (!ws[cell_address]) continue;
        ws[cell_address].s = titleStyle;
      }

      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + newdata + fileExtension);
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip
      title="Extração de usuários que se cadastraram para recebimento de notícias"
      placement="top">
      <span style={{ display: 'inline-block', marginLeft: '0' }}>
        <Button
          underlinePlus
          text={loading ? 'exportando...' : 'exportar relatório newsletters'}
          onClick={exportToExcel}
          disabled={loading}
        />
      </span>
    </Tooltip>
  );
};

ExportExcel.propTypes = {
  datas: PropTypes.array.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default ExportExcel;
