/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Label } from '../index';
import {
  Nav,
  StyledBurger,
  Ul,
  ScrollList,
  Logo,
  LogoMenu,
  Close,
  MenuItem,
  MenuHeader,
  Header,
} from './styles';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { defaultStates } from '../../utils/defaults';

import hamburger from '../../assets/icons/menu/admin/menu-icon.png';
import logo from '../../assets/logos/FGCoop@2x.png';
import logoWhite from '../../assets/logos/FGCoop.png';
import close from '../../assets/icons/mobile/close-mobile.png';

class NavbarAdminApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbar: false,
      alerts: null,
      ...defaultStates(),
    };
  }

  componentDidMount() {}

  render() {
    const { back } = this.props;

    return (
      <>
        <Nav>
          <Header className={'header'}>
            <Row className={'navbar-expand-lg align-items-center'}>
              <Col
                lg={{ order: 0 }}
                className={
                  'd-flex justify-content-center justify-content-lg-start justify-content-xl-start'
                }>
                <Logo img={logo} href={'/gestao'}>
                  <div />
                </Logo>
              </Col>

              <Col
                lg={{ span: 2, order: 1 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/gestao/noticias'}>
                  <Label header text={'Notícias'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 2, order: 2 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/gestao/informacoes'}>
                  <Label header text={'Informações'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 2, order: 3 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/gestao/banners'}>
                  <Label header text={'Banner'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 2, order: 5 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/gestao/cooperatives'}>
                  <Label header text={'Cooperativas'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 2, order: 4 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/gestao/governances'}>
                  <Label header text={'Governanças'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 2, order: 5 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/gestao/users'}>
                  <Label header text={'Usuários'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 1, order: 5 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/gestao/logout'}>
                  <Label header text={'Sair'} />
                </NavLink>
              </Col>

              <Col
                xs={{ span: 3, order: 1 }}
                sm={{ span: 3, order: 1 }}
                lg={{ span: 1, offset: 0, order: 5 }}
                className={`d-${
                  back ? 'none' : 'flex'
                } d-sm-flex justify-content-start justify-content-lg-end justify-content-xl-end`}>
                <StyledBurger
                  className="d-flex d-xl-none d-lg-none"
                  open={this.state.navbar}
                  img={hamburger}
                  onClick={() => {
                    this.setState({ navbar: !this.state.navbar });
                  }}>
                  <div />
                </StyledBurger>
              </Col>
            </Row>
          </Header>
        </Nav>

        <Ul open={this.state.navbar}>
          <MenuHeader key={0} className="d-flex align-items-center justify-content-between">
            <LogoMenu src={logoWhite} alt="fgcoop logotipo" />
            <Close img={close} onClick={() => this.setState({ navbar: false })} />
          </MenuHeader>
          <ScrollList>
            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={1}
              to="/gestao/home">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Home'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={2}
              to="/gestao/noticias">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Notícias'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={3}
              to="/gestao/informacoes">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Informações'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={4}
              to="/gestao/banners">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Banner'} />
              </MenuItem>
            </NavLink>
            
            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={5}
              to="/gestao/governances">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Governanças'} />
              </MenuItem>
            </NavLink>
            
            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={6}
              to="/gestao/cooperatives">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Cooperativas'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={7}
              to="/gestao/users">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Usuários'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={8}
              to="/gestao/logout">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Sair'} />
              </MenuItem>
            </NavLink>
          </ScrollList>
        </Ul>
      </>
    );
  }
}

NavbarAdminApp.propTypes = { back: PropTypes.bool };

export default NavbarAdminApp;
