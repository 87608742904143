import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Screen, Text, Input, TableAdmin } from '../../../components';
import { Row, Col } from 'react-bootstrap';
import newslettersService from '../../../services/newsletters';
import userService from '../../../services/user';
import ExportExcel from './excel';

const { defaultStates, defaultAlertScreenOnClick } = require('../../../utils/defaults');

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersList: [],
      newsletters: [],
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
    this.fillTable = this.fillTable.bind(this);
  }

  async componentDidMount() {
    await this.fillTable();
    await this.loadNewsletters();
  }

  async loadNewsletters() {
    const response = await newslettersService.getall();

    if (response.ok && response.data) {
      const data = response.data.map((i) => {
        i.isActive = i.isActive ? 'Ativo' : 'Rascunho';
        return i;
      });

      this.setState({ newsletters: data });
    } else {
      const modalState = {
        title: 'Erro',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  async fillTable(error = true) {
    this.setState({ loadingState: true });

    const response = await userService.admin.getList();
    if (response.ok && response.data) {
      const data = response.data.map((i) => {
        i.isActive = i.isActive ? 'Ativo' : 'Rascunho';
        return i;
      });

      this.setState({ usersList: data });
    } else {
      if (error) {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  modalOnHide() {
    const { modalState } = this.state;
    modalState.toggle = false;
    this.setState(modalState);
    this.fillTable(false);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  body() {
    const onCellClick = ({ id }) => this.props.history.push(`/gestao/users/${id}`);

    const columns = [
      { headerName: 'ID', field: 'id', hide: true, flex: 0.05 },
      { headerName: 'Nome', field: 'name', flex: 0.3 },
      { headerName: 'Email', field: 'email', flex: 0.4 },
      { headerName: 'Status', field: 'isActive', flex: 0.2 },
    ];

    return (
      <>
        <Row align={'center'}>
          <Col>
            <Text title text={'Usuários'} />
          </Col>
        </Row>
        <Row align="right">
          <Col align="left">
            <ExportExcel datas={this.state.newsletters} fileName={'newsletters'} />
          </Col>
          <Col align="right">
            <Button
              underlinePlus
              text={'adicionar'}
              onClick={() => this.props.history.push('/gestao/users/novo')}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="mt-3">
            <TableAdmin onCellClick={onCellClick} data={this.state.usersList} columns={columns} />
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center"></Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        admin
        body={this.body}
        footer={this.footer}
        modalState={modalState}
        loadingState={loadingState}
        alertScreenState={alertScreenState}
      />
    );
  }
}

UsersList.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default UsersList;
