const { axiosDefault, handleErrors } = require('../utils/defaults');

const captchaService = {
  checkCaptcha: async (token) => {
    try {
      const axios = axiosDefault();

      const { data } = await axios.post(`/api/captcha`, { token });

      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default captchaService;
