import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Text } from '../../../components';
import { bootstrapMax, width } from '../../../utils/devices';

export const TextStyled = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  text-align: left !important;

  @media ${bootstrapMax.lg} {
    text-align: center !important;
    font-size: 14px;
  }
`;

export const ColStyled = styled(Col)`
  @media ${bootstrapMax.md} {
    padding: 0 30px;
  }
`;
