import React, { Component } from 'react';
import { DownloadCard, Screen, SubHeader, Tabs, Text } from '../../../components';

import PropTypes from 'prop-types';
const { defaultStates } = require('../../../utils/defaults');

import bannerMobile from '../../../assets/imgs/banner-standards-mobile.png';
import banner from '../../../assets/imgs/banner-standards.png';

import {
  standards,
  resolutions,
  circulars,
  circularLetter,
  legislation,
  policiesAndNorms,
  announcements,
} from '../../../enums';
import { StandardsContainer } from './styles';
import { Col, Row } from 'react-bootstrap';

class Standards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      innerWidth: window.innerWidth,
      contents: [],
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  async componentDidMount() {
    await this.tabsContent();
  }

  updateSize = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  header = () => {
    return (
      <>
        <SubHeader
          image
          backgroundImage={banner}
          backgroundImageMobile={bannerMobile}
          minHeightSm={452}
          minHeight={313}
          title={'Conheça as normas'}
        />
      </>
    );
  };

  handleComponent = (data) => {
    return (
      <Col className={'mb-4 mb-lg-5 pb-3'}>
        {data.map((data, index) => {
          return <DownloadCard documents key={index} title={data.title} href={data.href} />;
        })}
      </Col>
    );
  };

  tabsContent = () => {
    this.setState({
      contents: [
        this.handleComponent(resolutions),
        this.handleComponent(circulars),
        this.handleComponent(circularLetter),
        this.handleComponent(legislation),
        this.handleComponent(policiesAndNorms),
        this.handleComponent(announcements),
      ],
    });
  };

  body = () => {
    return (
      <StandardsContainer>
        <Row className={'mb-3 mb-lg-5 flex-column'}>
          <Col className={'d-flex justify-content-center mb-lg-3'}>
            <Text heading2 noWrap textAlign={'center'} title={{ one: 'NORMAS' }} />
          </Col>
          <Col>
            <Text
              paragraph3
              text={`O FGCoop tem sua atuação pautada no sistema legal vigente para garantir a segurança
              jurídica dos seus atos e a observância dos direitos de todos.<br><br> Para um regular
              funcionamento e visando as boas práticas de gestão, o FGCoop conta ainda com normas
              específicas, devidamente aprovadas pelos órgãos competentes.`}
            />
          </Col>
        </Row>
        <Row className={'mb-3 mb-lg-5'}>
          {standards.map((standard, index) => {
            return (
              <Col key={index} lg={6} className={'mb-4 mb-lg-5 pb-3'}>
                <DownloadCard title={standard.title} href={standard.href} />
              </Col>
            );
          })}
        </Row>

        <Row className={'mb-lg-4 pb-lg-2'}>
          <Col className={'d-flex flex-column align-items-center'}>
            <Text heading1 noWrap textAlign={'center'} title={{ one: 'DOCUMENTOS' }} />
          </Col>
        </Row>

        <Row className={'mb-lg-5 pb-lg-5'}>
          <Col style={{ marginBottom: '230px' }}>
            <Tabs
              labels={[
                'Resoluções',
                'Circulares',
                'Carta-circulares',
                'Legislação',
                'Políticas e normas',
                'Comunicados do FGCOOP',
              ]}
              contents={this.state.contents}
            />
          </Col>
        </Row>
      </StandardsContainer>
    );
  };

  render = () => {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  };
}

Standards.propTypes = {
  //props
  history: PropTypes.object,
};
export default Standards;
