import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../utils/devices';

export const LogoAssociates = styled.div`
  background-image: ${({ logo }) => {
    if (logo) return `url(${require(`../../assets/logos/${logo}`).default})`;
    else return 'none';
  }};
  background-size: ${({ logoSize }) => {
    if (logoSize) return logoSize;
    else return '169px 40px';
  }};
  background-position: center;
  background-color: ${colors.white};
  background-repeat: no-repeat;
  width: 200px;
  height: 92px;
  border-radius: 20px;

  @media ${bootstrapMax.md} {
    border-radius: 10px;
    ${({ mdClasses }) => {
      if (mdClasses) {
        return `${mdClasses}`;
      }
    }}
  }
`;

export const TitleAssociates = styled.p`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.white};
  text-align: center;
  margin-bottom: 0;
  margin-top: 15px;

  @media ${bootstrapMin.xl} {
    ${({ associate }) =>
      associate === 'coop'
        ? `padding: 0px 20px;
            text-align: left;`
        : ''}
  }
`;

export const TextAssociates = styled.p`
  font-size: 18px;
  color: ${colors.primaryOne};
  font-family: 'Trade Gothic LT Bold';
  text-align: center;
  padding: 6px 15px;
  margin: 0px;

  @media ${bootstrapMax.md} {
    padding: 9px 15px;
    line-height: 22px;
    font-size: 16px;
  }
`;

export const LinkAssociates = styled.a`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryOne}!important;
  text-align: center;
  margin-bottom: 0;
  margin-top: 15px;

  &:hover {
    color: ${colors.secondaryThree} !important;
  }

  @media ${bootstrapMin.xl} {
    ${(associate) =>
      associate === 'coop'
        ? `padding: 0px 20px;
            text-align: left;`
        : ''}
  }
`;
