import React, { Component } from 'react';
import CC from 'react-cookie-consent';
import { buttonStyle, contentStyle, style, CookiesContainer } from './styles';

class CookieConsent extends Component {
  render() {
    return (
      <CookiesContainer>
        <CC
          overlayClasses={'cookies'}
          location="bottom"
          buttonText="CONTINUAR E FECHAR"
          cookieName="cookieConsent"
          contentClasses={'mediaQuery'}
          buttonClasses={'bold'}
          overlay
          expires={180}>
          <p>
            Utilizamos cookies para melhorar a sua experiência em nosso site. Ao continuar navegando
            você concorda com a nossa&nbsp;
            <a href="/politica-de-privacidade">política de privacidade.</a>
          </p>
        </CC>
      </CookiesContainer>
    );
  }
}

export default CookieConsent;
