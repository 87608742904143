import intelligence from '../assets/icons/intelligence.png';
import prevention from '../assets/icons/prevention.png';
import sanitation from '../assets/icons/sanitation.png';
import depositors_protection from '../assets/icons/depositors-protection.png';
import financial_management from '../assets/icons/financial-management.png';
import governance from '../assets/icons/governance.png';
import people from '../assets/icons/people.png';
import ti from '../assets/icons/ti.png';
import mission from '../assets/icons/mission.png';
import vision from '../assets/icons/vision.png';
import values from '../assets/icons/values.png';
import safety from '../assets/icons/safety.png';
import solidity from '../assets/icons/solidity.png';
import warranty from '../assets/icons/warranty.png';

export const stageOfElaboration = [
  {
    title: 'REUNIÃO DE PARTIDA ',
    content: `Primeira reunião realizada virtualmente, com dois representantes do FGCoop (um diretor e um analista),
    para o alinhamento das expectativas e a revisão do conteúdo e das etapas de trabalho.`,
  },
  {
    title: 'GRUPOS FOCAIS',
    content: `Foram formados dois grupos focais virtuais com todos os colaboradores do FGCoop, com o objetivo de
    captar a percepção dos envolvidos sobre as possíveis mudanças na missão, visão de futuro, valores
    organizacionais e objetivos estratégicos.`,
  },
  {
    title: 'ENTREVISTAS EM PROFUNDIDADE',
    content: `Foram realizadas 12 entrevistas estratégicas com os stakeholders, dentre eles, membros dos Conselhos
    de Administração e Fiscal do FGCoop, representantes de instituições cooperativistas como a OCB, a Confebras e
    outros cooperativistas que atuam ou atuaram diretamente com o FGCoop.
    O resultado deste trabalho, fruto da cooperação e apoio de todos envolvidos foi transformado no novo
    Plano Estratégico 2023-2025.`,
  },
];

export const axesOfActingBlockOne = [
  {
    imgWidth: 84,
    imgHeight: 94,
    icon: intelligence,
    marginBottom: 'mb-4 pb-3 mb-lg-5 pb-lg-5',
    title: '1. INTELIGÊNCIA',
    content: 'Aprimorar o processo de monitoramento e geração de informações.',
  },
  {
    imgWidth: 81,
    imgHeight: 81,
    icon: prevention,
    marginBottom: 'mb-4 pb-3 mb-lg-5 pb-lg-5',
    title: '2. PREVENÇÃO',
    content: `Aprimorar a atuação preventiva junto às instituições associadas.`,
  },
  {
    imgWidth: 107,
    imgHeight: 74,
    icon: sanitation,
    marginBottom: 'mb-4 pb-3 mb-lg-5 pb-lg-5',
    title: '3. ASSITÊNCIA FINANCEIRA',
    content: 'Estruturar o acompanhamento das operações de assistências financeira.',
  },
  {
    imgWidth: 95,
    imgHeight: 95,
    icon: financial_management,
    marginBottom: 'mb-4 pb-3 mb-lg-5 pb-lg-2',
    title: '4. GESTÃO ADMINISTRATIVA E FINANCEIRA',
    content: 'Aperfeiçoar a gestão administrativo-financeira.',
  },
];

export const axesOfActingBlockTwo = [
  {
    imgWidth: 109,
    imgHeight: 63,
    icon: governance,
    marginBottom: 'mb-4 pb-3 mb-lg-5 pb-lg-5',
    title: '5. GOVERNANÇA',
    content: 'Aperfeiçoar o modelo de Governança Corporativa do FGCoop.',
  },
  {
    imgWidth: 104,
    imgHeight: 70,
    icon: people,
    marginBottom: 'mb-4 pb-3 mb-lg-5 pb-lg-5',
    title: '6. PESSOAS',
    content: 'Promover a performance, o desenvolvimento e a satisfação dos colaboradores.',
  },
  {
    imgWidth: 114,
    imgHeight: 74,
    icon: ti,
    marginBottom: 'mb-4 pb-3 mb-lg-5 pb-lg-5',
    title: '7. TECNOLOGIA DA INFORMAÇÃO',
    content:
      'Entregar soluções tecnológicas que viabilizem segurança, integração, resiliência e Escalabilidade.',
  },
  {
    imgWidth: 104,
    imgHeight: 70,
    icon: people,
    title: '8. COMUNICAÇÃO',
    content: 'Tornar conhecido o propósito do FGCoop.',
  },
];

export const financialInstrumentsContents = [
  {
    contents: [
      { paragraph: 'Depósitos à vista ou sacáveis mediante aviso prévio;' },
      { paragraph: 'Depósitos de poupança;' },
      {
        paragraph:
          'Depósitos a prazo, com ou sem emissão de certificado - RDC (Recibo de Depósito Cooperativo), RDB (Recibo de Depósito Bancário) e CDB (Certificado de Depósito Bancário);',
      },
    ],
  },
  {
    contents: [
      {
        paragraph:
          'Depósitos mantidos em contas não movimentáveis por cheques destinadas ao registro e controle do fluxo de recursos referentes à prestação de serviços de pagamento de salários, vencimentos, aposentadorias, pensões e similares;',
      },
      {
        paragraph: 'Letras de câmbio - LC;',
      },
      {
        paragraph: 'Letras Hipotecárias - LH;',
      },
    ],
  },
  {
    contents: [
      {
        paragraph: 'Letras de Crédito Imobiliário - LCI;',
      },
      {
        paragraph: 'Letras de Crédito do Agronegócio - LCA;',
      },
      {
        paragraph:
          'Operações compromissadas que têm como objeto títulos emitidos após 8 de março de 2012 por empresa ligada.',
      },
    ],
  },
];

export const excludedInstrumentsContents = [
  {
    contents: [
      {
        paragraph:
          'Saldos de quotas-partes de capital de titularidade de associados de cooperativas singulares de crédito filiadas ao Fundo;',
      },
      {
        paragraph:
          'Depósitos, empréstimos ou quaisquer outros recursos captados ou levantados no exterior;',
      },
      {
        paragraph:
          'Operações relacionadas a programas de interesse governamental instituídos por lei;',
      },
      {
        paragraph: 'Depósitos judiciais;',
      },
      {
        paragraph:
          'Qualquer instrumento financeiro que contenha cláusula de subordinação, autorizado ou não pelo Banco Central do Brasil (BCB) a integrar o patrimônio de referência das cooperativas singulares de crédito captadoras de depósitos e bancos cooperativos integrantes do Sistema Nacional de Crédito Cooperativo (SNCC);',
      },
      {
        paragraph:
          'Os depósitos e quaisquer outros créditos de titularidade de: <br><br> <b>a)</b> associadas representantes, nos termos do parágrafo único do art. 10, § 1º do Estatuto do FGCoop, exceto os depósitos à vista e a prazo mantidos nos bancos cooperativos, até o limite previsto no art. 3º do Regulamento; <br><br> <b>b)</b> cooperados e clientes de instituições que não sejam associadas ao FGCoop;',
      },
    ],
  },
  {
    contents: [
      {
        paragraph:
          'Os créditos: <br><br> <b>a)</b> de titularidade de instituições financeiras e demais instituições autorizadas a funcionar pelo BCB, de entidades de previdência complementar, de sociedades seguradoras, de sociedades de capitalização, de clubes de investimento e de fundos de investimento; <br><br> <b>b)</b> representados por cotas de fundos de investimento ou que representem quaisquer participações nas entidades acima referidas ou nos instrumentos financeiros de sua titularidade; <br><br> Os créditos de titularidade dos membros dos órgãos de administração da instituição associada que estiverem no exercício da função à data da decretação da intervenção ou da liquidação extrajudicial, tenham-na exercido nos 24 (vinte e quatro) meses anteriores à decretação ou estejam com os seus bens indisponíveis em razão da decretação da intervenção ou da liquidação extrajudicial, respeitado o disposto na legislação em vigor; <br><br> Os créditos de titularidade dos membros do Conselho Fiscal que estiverem no exercício da função à data da decretação da intervenção ou da liquidação extrajudicial ou a tenham exercido nos 24 (vinte e quatro) meses anteriores à decretação, até que seja apurada a sua responsabilidade pela ocorrência da situação motivadora da prestação de garantia; <br><br> Os créditos de titularidade de sociedades de cujo capital participem os membros dos órgãos de administração ou do Conselho Fiscal referidos anteriormente.',
      },
    ],
  },
];

export const proceduresContents = [
  {
    title: 'LIQUIDAÇÃO EXTRAJUDICIAL',
    content:
      'Quando o Banco Central do Brasil (BCB) decreta a intervenção ou liquidação extrajudicial de uma cooperativa de crédito, o FGCoop inicia o processo de organização do pagamento de garantias.',
  },
  {
    title: 'CRÉDITOS CONSOLIDADOS',
    content:
      'O interventor ou liquidante nomeado pelo Banco Central realiza o levantamento de associados com direito ao recebimento da garantia, utilizando-se como base o CPF ou CNPJ para a identificação e consolidação dos créditos. Em seguida, encaminha ao FGCoop a relação de pessoas e seus respectivos créditos a receber, com as devidas retenções tributárias, quando aplicáveis.',
  },
  {
    title: 'AGILIDADE',
    content:
      'O FGCoop seleciona a cooperativa de crédito mais próxima dos pontos de atendimento da cooperativa sob intervenção ou liquidação extrajudicial, de modo a dar maior comodidade aos beneficiários e rapidez no pagamento da garantia.',
  },
  {
    title: 'EDITAL',
    content:
      'O FGCoop publica, em seu site e em jornais da região, um edital informando o período, locais e documentação necessária para recebimento dos valores. O recebimento pode ser em espécie - no valor limite definido em cada caso - ou transferência, sem custo para o recebedor, para sua conta em outra instituição financeira.​​',
  },
  {
    title: 'PAGAMENTO',
    content:
      'O associado ou representante legal da pessoa jurídica deverá comparecer à instituição pagadora munido da documentação solicitada, descrita no edital publicado. Se necessário, designar procurador com poderes específicos. Para pessoa física, será permitido o instrumento particular com firma reconhecida. Para pessoa jurídica, deverá ser procuração pública. O associado deverá datar e assinar, no ato do recebimento de seu crédito, um termo de cessão de créditos ao FGCoop.​​',
  },
];

export const companyPurpose = [
  {
    imgWidth: 49,
    imgHeight: 61,
    icon: mission,
    title: 'MISSÃO',
    content:
      'Proteger os depositantes do Sistema Nacional de Crédito Cooperativo nos limites da regulamentação, contribuindo​ para sua solidez, perenidade e imagem.',
  },
  {
    imgWidth: 63,
    imgHeight: 61,
    icon: vision,
    title: 'VISÃO',
    content:
      'Ser reconhecido pela atuação preventiva para fortalecimento, confiança e crescimento do Sistema Nacional de Crédito Cooperativo.',
  },
  {
    imgWidth: 65,
    imgHeight: 61,
    icon: values,
    title: 'VALORES',
    content: [
      `<ol>
        <ul>
          <li>Transparência</li>
          <li>Equidade</li>
          <li>Proatividade</li>
          <li>Cooperação</li>
          <li>Comprometimento</li>
          <li>Excelência</li>
        </ul>
      </ol>`,
    ],
  },
];

export const commonQuestions = [
  {
    question: `O QUE É O FGCOOP?`,
    answer: `O FGCoop é uma associação civil sem fins lucrativos, com personalidade jurídica própria, de direito privado, de abrangência nacional, tendo como associadas todas as cooperativas singulares de crédito captadoras de depósitos e os dois bancos cooperativos: Banco Sicoob e Banco Sicredi.`,
  },
  {
    question: `O QUE É UM FUNDO GARANTIDOR?`,
    answer: `Conceitualmente, um fundo garantidor é parte de uma ampla rede de proteção aos sistemas financeiros. Essa rede envolve, também, regulação prudencial, supervisão eficiente, legislação, práticas adequadas de gestão e metodologias adequadas de contabilidade e de transparência na divulgação de informações à população. <br><br>

    A experiência internacional mostra que os fundos garantidores em vários países, no passado, assumiram a função de garantidor de depósitos (paybox), na eventualidade da liquidação de instituições (a chamada atuação ex-post). Mais recentemente, agregaram ao seu escopo de atuação outras funções, como prover assistência financeira às entidades associadas e o suporte nas chamadas soluções de mercado (a chamada atuação ex-ante). Em alguns países, recentemente, foram criados os “fundos de estabilização” com esse mesmo propósito. No Brasil, alinhado a essas melhores práticas internacionais, o FGC (dos bancos comerciais) vem desempenhando papel relevante no sentido de contribuir com a estabilidade do Sistema Financeiro Nacional, garantindo depósitos e viabilizando a assistência financeira a instituições com eventuais dificuldades de liquidez e ainda viabilizando soluções de mercado. Com esses mesmos objetivos, foi criado o Fundo Garantidor do Cooperativismo de Crédito (FGCoop).`,
  },
  {
    question: `POR QUE FOI CRIADO O FGCOOP?`,
    answer: `O FGCoop foi criado para igualar as condições de competitividade com os bancos comerciais, protegendo depositantes e investidores das instituições associadas, com a garantia de depósitos até R$ 250.000,00 e para contribuir com a manutenção da estabilidade do Sistema Nacional de Crédito Cooperativo (SNCC), ampliando a credibilidade do sistema.`,
  },
  {
    question: `TODAS AS COOPERATIVAS SÃO FILIADAS AO FGCOOP?`,
    answer: `Segundo a Resolução CMN nº 4.150/2012 todas as cooperativas singulares de crédito captadoras de depósitos e os bancos cooperativos devem associar-se ao FGCoop.`,
  },
  {
    question: `COMO SÃO AS CONTRIBUIÇÕES AO FGCOOP?`,
    answer: `Segundo a Resolução CMN Nº 4.933/2021, a contribuição mensal ordinária das instituições associadas ao FGCoop é de 0,0125% (cento e vinte e cinco décimos de milésimos por cento) do montante dos saldos das contas referentes aos instrumentos financeiros relacionados no art. 2º do Regulamento, ainda que os créditos correspondentes não sejam cobertos pela garantia ordinária.`,
  },
  {
    question: `COMO É EFETUADO O RECOLHIMENTO DAS CONTRIBUIÇÕES PELAS COOPERATIVAS AO FGCOOP?`,
    answer: `O FGCoop tem contas no Banco Sicoob e Banco Sicredi para receber as contribuições. As centrais fazem o recolhimento em nome de suas filiadas, podendo também ser feito pela confederação ou banco cooperativo. As cooperativas não filiadas a centrais fazem diretamente o recolhimento em uma das duas contas.`,
  },
  {
    question: `O FGCOOP TEM OUTROS RECURSOS ALÉM DAS CONTRIBUIÇÕES ORDINÁRIAS DAS COOPERATIVAS DE CRÉDITO E BANCOS COOPERATIVOS?`,
    answer: `Sim, compõem também a receita do FGCoop as taxas de serviços decorrentes da emissão de cheques sem fundos, recolhidas direta ou indiretamente pelas instituições associadas, o rendimento de aplicações e outros.`,
  },
  {
    question: `QUAIS CRÉDITOS SÃO GARANTIDOS PELO FGCOOP?`,
    answer: `<p>São garantidos:</p>`,
    answerArray: [
      {
        answer: `<ul><li>Depósitos à vista ou sacáveis mediante aviso prévio;</li><br>
      <li>Depósitos de poupança;</li><br>
      <li>Depósitos a prazo, com ou sem emissão de certificado<br>(CDB/RDB/RDC);</li><br>
      <li>Depósitos mantidos em contas não movimentáveis por cheques, destinadas ao registro e controle do fluxo de recursos referentes à prestação de serviços de pagamento de salários, vencimentos, aposentadorias, pensões e similares;</li></ul>`,
      },
      {
        answer: `<ul><li>Letras de câmbio;</li><br>
      <li>Letras hipotecárias;</li><br>
      <li>Letras de crédito imobiliário;</li><br>
      <li>Letras de crédito do agronegócio;</li><br>
      <li>Operações compromissadas que têm como objeto títulos emitidos após 08.03.2012 por empresa ligada.</li></ul>`,
      },
    ],
  },
  {
    question: `COMO SERÁ A GARANTIA DE CRÉDITOS, POR ASSOCIADO, EM CASO DE INTERVENÇÃO OU LIQUIDAÇÃO?`,
    answer: `O total de créditos de cada pessoa contra a mesma cooperativa será garantido até o valor de R$ 250.000,00 (duzentos e cinquenta mil Reais). Devem ser somados todos os créditos de cada credor identificado pelo CPF ou CNPJ na mesma cooperativa. Os cônjuges são considerados pessoas distintas, seja qual for o regime de casamento. Os créditos de dependentes são computados separadamente. Nas contas conjuntas, o valor de garantia é limitado a R$ 250.000,00 ou ao saldo da conta, quando inferior a esse limite, dividido pelo número de titulares, sendo o crédito do valor garantido feito de forma individual`,
  },
  {
    question: `O MONTANTE DOS CRÉDITOS GARANTIDOS EM CASOS DE PESSOAS JURÍDICAS É APURADO CONSIDERANDO CADA CNPJ (AINDA QUE DIFERENTE APENAS A SEQUÊNCIA FINAL) OU APENAS O CNPJ BASE?`,
    answer: `Para apuração do montante dos créditos objeto de garantia pelo FGCoop, conforme art. 2º, § 3º do Regulamento do Fundo, considera-se o número base do CNPJ (oito primeiros dígitos).`,
  },
  {
    question: `COMO SERÁ A GARANTIA DOS RECURSOS MUNICIPAIS?`,
    answer: `O limite será calculado considerando que cada Munícipio, em conjunto com seus órgãos ou entidades e empresas por ele controladas, deve ser considerado como uma única pessoa, independentemente da existência de múltiplas inscrições no Cadastro Nacional da Pessoa Jurídica (CNPJ).`,
  },
  {
    question: 'QUAL O PRAZO PARA PAGAMENTO DOS CRÉDITOS EM CASO DE INTERVENÇÃO OU LIQUIDAÇÃO?',
    answer: `O pagamento dos créditos relativos aos instrumentos financeiros garantidos será iniciado em até 30 (trinta) dias após o recebimento, pelo FGCoop, da informação sobre os valores.`,
  },
  {
    question: `O QUE NÃO É COBERTO PELO FGCOOP?`,
    answer: `Não são cobertos pelo FGCoop:`,
    answerArray: [
      {
        answer: `
        <ul><li>Os saldos de quotas-partes de capital de titularidade de associados de cooperativas singulares de crédito filiadas ao Fundo;</li><br>
        <li>Os depósitos, empréstimos ou quaisquer outros recursos captados ou levantados no exterior;</li><br>
        <li>As operações relacionadas a programas de interesse governamental instituídos por lei;</li><br>
        <li>Os depósitos judiciais;</li><br>
        <li>Qualquer instrumento financeiro que contenha cláusula de subordinação, autorizado ou não pelo Banco Central do Brasil (BC) a integrar o patrimônio de referência das cooperativas singulares de crédito captadoras de depósitos e bancos cooperativos integrantes do Sistema Nacional de Crédito Cooperativo (SNCC);</li></ul>`,
      },
      {
        answer: `
        <ul><li>Os depósitos e quaisquer outros créditos de titularidade de: <br><br><b>a)</b> associadas representantes, nos termos do parágrafo único do art. 10 do Estatuto do FGCoop, exceto os depósitos à vista e a prazo mantidos nos bancos cooperativos; e <br><br><b>b)</b> cooperados e clientes de instituições que não sejam associadas ao FGCoop;</li><br>
        <li>Os créditos: <br><br><b>a)</b> de titularidade de instituições financeiras e demais instituições autorizadas a funcionar pelo BC, de entidades de previdência complementar, de sociedades seguradoras, de sociedades de capitalização, de clubes de investimento e de fundos de investimento; e <br><br><b>b)</b> representados por cotas de fundos de investimento ou que representem quaisquer participações nas entidades acima referidas ou nos instrumentos financeiros de sua titularidade.</li></ul>
        `,
      },
    ],
  },
  {
    question: `O FGCOOP COBRE CRÉDITOS DE COOPERATIVAS DE CRÉDITO INGRESSADAS EM LIQUIDAÇÃO EXTRAJUDICIAL ANTES DA CRIAÇÃO DO FUNDO?`,
    answer: `Não. Conforme art. 1º do Regulamento, o direito à garantia ordinária assiste aos beneficiários nas situações de intervenção ou liquidação extrajudicial decretada a partir de 12 de fevereiro de 2014, data do registro dos atos constitutivos do FGCoop no cartório de registro de pessoas jurídicas.`,
  },
];

export const meetOurBackground = [
  {
    title: `Limite de Cobertura Ordinária`,
    content: `O total de créditos de cada beneficiário contra a mesma instituição associada ao FGCoop será garantido até o valor de R$250.000,00 (duzentos e cinquenta mil reais).`,
  },
  {
    title: `Contribuição Fixa`,
    content: `Fixada em 0,0125% ao mês do montante dos saldos das contas referentes aos instrumentos financeiros relacionados no art. 2º, incisos I a IX, do Regulamento do FGCoop, ainda que os créditos correspondentes não sejam cobertos pela garantia ordinária.`,
  },
  {
    title: `Adesão compulsória`,
    content: `A adesão de todas as cooperativas singulares de crédito captadoras de depósitos e dos bancos cooperativos (Banco Sicoob e Banco Sicredi) ocorre de forma compulsória, nos termos da Resolução CMN nº 4.150/2012.`,
  },
  {
    title: `Proteção Explícita`,
    content: `O FGCoop possui norma legal que define os critérios e limites de proteção: Regulamento do FGCoop – Anexo II à Resolução CMN nº 4.933/2021.`,
  },
  {
    title: `Sistema Privado`,
    content: `O FGCoop é uma entidade privada de abrangência nacional, sem fins lucrativos. <br>A personalidade jurídica de direito privado do FGCoop foi estabelecida por meio da Resolução CMN nº 4.150/2012.`,
  },
  {
    title: `Receitas`,
    content: `<div class="recipes"><ul><li>Contribuições ordinárias e extraordinárias das instituições associadas;</li><br>
    <li>Taxas de serviços decorrentes da emissão de cheques sem provisão de fundos recolhidos de forma direta ou indireta pelas instituições associadas;</li><br>
    <li>Recuperações de direitos creditórios nas quais o FGCoop houver se sub-rogado, em virtude de pagamento de dívidas de instituições associadas relativas a créditos garantidos;</li><br>
    <li>Resultado líquido dos serviços prestados pelo FGCoop e rendimentos de aplicação de seus recursos;</li><br>
    <li>Remuneração e encargos correspondentes ao recebimento dos valores devidos em função da realização das assistências ou de suporte financeiros;</li><br>
    <li>Receitas de outras origens.</li></ul></div>`,
  },
  {
    title: `Depósitos Garantidos`,
    content: `<div class="guaranteed-deposits"><p>Os instrumentos financeiros garantidos pelo FGCoop, nos termos da regulamentação, são:</p>

    <ul><li>Depósitos à vista ou sacáveis mediante aviso prévio;</li><br>
    <li>Depósitos de poupança;</li><br>
    <li>Depósitos a prazo, com ou sem emissão de certificado;</li><br>
    <li>Depósitos mantidos em contas não movimentáveis por cheques, destinadas ao registro e controle do fluxo de recursos referentes à prestação de serviços de pagamento de salários, vencimentos, aposentadorias, pensões e similares;</li><br> <li>Letras de câmbio;</li><br>
    <li>Letras hipotecárias;</li><br>
    <li>Letras de crédito imobiliário;</li><br>
    <li>Letras de crédito do agronegócio;</li><br>
    <li>Operações compromissadas que têm como objetivo títulos emitidos após 8 de março de 2012 por empresa ligada.</li></ul></div>`,
  },
];

export const knowOurStructure = [
  {
    title: 'CONSELHO DE ADMINISTRAÇÃO',
    positions: [
      {
        titleMobile: 'CONSELHEIROS',
        title: 'CONSELHEIRO',
        carousel: true,
        people: [
          {
            title: 'PRESIDENTE',
            name: 'LUIZ ANTÔNIO FERREIRA DE ARAÚJO',
            description: `<p>Representante do Sistema Sicoob</p> <p>Sistema de três níveis</p><p>Mandato de 2022 a 2025</p>`,
          },
          {
            name: 'CELSO RONALDO RAGUZZONI FIGUEIRA',
            description: `<p>Representante do Sistema Sicredi</p> <p>Sistema de três níveis</p><p>Mandato de 2022 a 2025</p>`,
          },
          {
            name: 'ADRIANO MICHELON',
            description: `<p>Representante do Sistema Cresol</p> <p>Sistema de três níveis</p><p>Mandato de 2022 a 2025</p>`,
          },
          {
            name: 'ALCIR JOSÉ GOLDONI (Credicoamo/OCB)',
            description: `<p>Representante das cooperativas singulares não filiadas a Centrais</p> <p>Mandato de 2022 a 2025</p>`,
          },
          {
            name: 'DONIZETTI JOSÉ (CrediSIS)',
            description: `<p>Representante dos sistemas organizados em dois níveis</p> <p>Mandato de 2022 a 2025</p>`,
          },
          {
            name: 'REMACLO FISCHER JUNIOR',
            description: `<p>Representante do Sistema Unicred</p> <p>Sistema de três níveis</p><p>Mandato de 2022 a 2025</p>`,
          },
          {
            title: 'CONSELHEIRO',
            name: 'JOÃO FRANCISCO SANCHEZ TAVARES',
            description: `<p>Independente</p> <p>Mandato de 2023 a 2026</p>`,
          },
        ],
      },
    ],
  },
  {
    title: 'CONSELHO FISCAL – MANDATO 2023 a 2026',
    positions: [
      {
        title: 'COORDENADOR',
        people: [
          {
            name: 'MOACIR KRAMBECK',
            description: `<p>Sistema AILOS</p> <p>Sistema de dois níveis</p>`,
          },
        ],
      },
      {
        titleMobile: 'CONSELHEIROS EFETIVOS',
        title: 'CONSELHEIRO EFETIVO',
        carousel: true,
        people: [
          {
            name: 'ALZIMIRO THOMÉ',
            description: `<p>Representante do Sistema Cresol</p> <p>Sistema de três níveis</p>`,
          },
          {
            name: 'NILTON REIS',
            description: `<p>Sistema UNICRED</p> <p>Sistema de três níveis</p>`,
          },
        ],
      },
      {
        titleMobile: 'CONSELHEIROS SUPLENTES',
        title: 'CONSELHEIRO SUPLENTE',
        carousel: true,
        people: [
          {
            name: 'JOÃO BEZERRA',
            description: `<p>Sistema SICREDI</p> <p>Sistema de três níveis</p>`,
          },
          {
            name: 'AIFA NAOMI',
            description: `<p>Sistema SICOOB</p> <p>Sistema de três níveis</p>`,
          },
          {
            name: 'KEDSON PEREIRA MACEDO',
            description: `<p>Cooperforte</p> <p>Sistema OCB</p> <p>Associadas não filiadas a centrais</p>`,
          },
        ],
      },
    ],
  },
  {
    title: 'DIRETORIA EXECUTIVA',
    positions: [
      {
        title: 'DIRETOR EXECUTIVO',
        people: [
          {
            name: 'ADRIANO MEIRA RICCI',
            description: `<p>Com mandato até 2026</p>`,
          },
        ],
      },
      {
        title: 'DIRETOR',
        people: [
          {
            name: 'CARLOS DE MEDEIROS ROLIM',
            description: `<p>Com mandato até 2024</p>`,
          },
          {
            name: 'CLÁUDIO LUIS MEDEIROS WEBER',
            description: `<p>Com mandato até 2024</p>`,
          },
        ],
      },
    ],
  },
  {
    title: 'COMITÊ TÉCNICO DE ASSESSORAMENTO',
    positions: [
      {
        title: 'COORDENADOR',
        people: [
          {
            name: 'VINICIUS GASPARINO REZENDE DE SOUZA',
            description: `<p>Representante do Sistema Sicoob</p> <p>-</p>`,
          },
        ],
      },
      {
        titleMobile: 'MEMBROS',
        title: 'MEMBRO',
        carousel: true,
        people: [
          {
            name: 'ANDRESSA CASTRO',
            description: `<p>Representante do Sistema Cresol</p> <p>-</p>`,
          },
          {
            name: 'ALEXANDRE EUZÉBIO SILVA',
            description: `<p>Representante do Sistema Unicred</p> <p>-</p>`,
          },
          {
            name: 'CLAIRTON WALTER',
            description: `<p>Representante do Sistema Sicredi</p> <p>-</p>`,
          },
          {
            name: 'JOSÉ LUIZ CONRADO (Credicoamo)',
            description: `<p>Representante das cooperativas singulares não filiadas a centrais</p> <p>-</p>`,
          },
          {
            name: 'LEONEL PEDRO CERUTTI (Cecresul)',
            description: `<p>Representante dos sistemas organizados em dois níveis</p> <p>-</p>`,
          },
        ],
      },
    ],
  },
];

export const standards = [
  {
    title: 'ESTATUTO',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-estatuto.pdf',
  },
  {
    title: 'REGIMENTO INTERNO DO FGCOOP',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/regimento-interno-fgcoop.pdf',
  },
  {
    title: 'REGULAMENTO',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-regulamento.pdf',
  },
  {
    title: 'CÓDIGO DE CONDUTA E ÉTICA DO FGCOOP',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-codigo-de-conduta-e-etica.pdf',
  },
];

export const resolutions = [ 
  {
    title: 'Resolução nº 4.933 de 29 de julho de 2021.',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-resolucao-4933-2021.pdf',
  },
  {
    title: 'Resolução nº4.659/2018',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/Resolu%C3%A7%C3%A3o-n4.659-2018.pdf',
  },
  {
    title: 'Resolução nº 4.518, de 24 de agosto de 2016.',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-resolucao-4518-2016.pdf',
  },
  {
    title: 'Resolução nº 4.454/2015',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/Resolu%C3%A7%C3%A3o-n4.454-2015.pdf',
  },
  {
    title: 'Resolução nº 4.434/2015',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/Resolu%C3%A7%C3%A3o-n4.434-2015.pdf',
  },
  {
    title: 'Resolução nº 4.284, de 5 de novembro de 2013.',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-resolucao-4284-2013.pdf',
  },
  {
    title: 'Resolução nº 4.150, de 30 de outubro de 2012.',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-resolucao-4150-2012.pdf',
  },
];

export const circulars = [
  {
    title: 'Circular FGCoop Nº 006/2021',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-circular-006-2021.pdf',
  },
  {
    title: 'Circular FGCoop Nº 001/2014',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/circular-fgcoop-001-10042014.pdf',
  },
  {
    title: 'Circular FGCoop nº 005/2021',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-circular-005-2021.pdf',
  },
  {
    title: 'Anexo da Circular FGCoop nº 005/2021',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-anexo-circular-005-2021.xlsx',
  },
  {
    title: 'Anexo da Circular FGCOOP nº 006/2021',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-anexo-circular-006-2021.xlsx',
  },
  {
    title: 'Circular nº 3.790/2016',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/Circular-n3.790-2016.pdf',
  },
  {
    title: 'Circular n° 3.771/2015',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/Circular-n3.771-2015.pdf',
  },
  {
    title: 'Circular nº 3.700/2014',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-circular-3700-2014.pdf',
  },
];

export const circularLetter = [
  {
    title: 'Carta-Circular nº 3.636, de 6 de março de 2014',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-carta-circular-3636-2014.pdf',
  },
  {
    title: 'Carta-Circular nº 3.660, de 20 de março de 2014',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-carta-circular-3660-2014.pdf',
  },
  {
    title: 'Carta-Circular nº 3.888/2018',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-carta-circular-3888-2018.pdf',
  },
];

export const legislation = [
  {
    title: 'Lei Complementar nº 161/2018',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/Lei-Complementar-n161-2018.pdf',
  },
  {
    title: 'Lei Complementar nº 130/2009',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/Lei-Complementar-n130-2009.pdf',
  },
  {
    title: 'Lei nº 5.764/1971',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/Lei-n5.764-1971.pdf',
  },
];

export const policiesAndNorms = [
  {
    title: 'Instrução Normativa BCB nº 145, 23 de Agosto de 2021.',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-instrucao-normativa-bcb-145-2021.pdf',
  },
];

export const announcements = [
  {
    title: 'Comunicado 09',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-comunicado-009-2021.pdf',
  },
  {
    title: 'Comunicado Prescrição Crediserv',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-comunicado-prescricao-crediserv.pdf',
  },
  {
    title: 'Comunicado nº 006/2019',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-comunicado-006-2019.pdf',
  },
  {
    title: 'Comunicado 005, de 05 de setembro de 2018.',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-comunicado-005-2018.pdf',
  },
  {
    title: 'Comunicado 004, de 22 de agosto de 2018.',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-comunicado-004-2018.pdf',
  },
  {
    title: 'Comunicado 003, de 18 de janeiro de 2018.',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-comunicado-003-2018.pdf',
  },
  {
    title: 'Comunicado 002, de 24 de março de 2017.',
    href: 'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/documentos-fgcoop/fgcoop-comunicado-002-2017.pdf',
  },
];

export const coverageLimit = [
  {
    content: `<p class='mb-0'><b>O total de créditos de cada pessoa contra a mesma instituição</b> associada ao Fundo <b>será garantido até o valor de R$ 250.000,00,</b> limitado ao saldo existente.</p>`,
  },
  {
    content: `<p><b>Para efeito da determinação do valor garantido dos créditos</b> de cada pessoa, devem ser observados os seguintes critérios:</p>
    <p>a) <b>titular do crédito é aquele em cujo nome o crédito estiver registrado na escrituração da instituição</b> associada ou aquele designado em título por ela emitido ou aceito;</p> <p>b) <b>devem ser somados os créditos de cada beneficiário, contra a mesma instituição associada ao FGCoop,</b> considerando, no caso de pessoas físicas, o respectivo número de registro no Cadastro de Pessoas Físicas (CPF) e, no caso de pessoas jurídicas, os 8 (oito) primeiros dígitos do número de registro no Cadastro Nacional da Pessoa Jurídica (CNPJ), ressalvados os Municípios, na forma seguinte;</p> <p class='mb-0'>c) <b>cada Município,</b> em conjunto com seus órgãos, entidades ou empresas por ele controladas, <b>será considerado como um único beneficiário,</b> independentemente da existência de múltiplas inscrições no CNPJ;</p>`,
  },
  {
    content: `<p class='mb-0'><b>Na hipótese de aplicação em instrumento financeiro relacionado no art. 2º do Regulamento,</b> cuja negociação seja intermediada por instituição integrante do Sistema Financeiro Nacional, <b>a titularidade dos créditos contra as instituições associadas ao FGCoop deve ser comprovada, pelo cliente da instituição intermediária na operação,</b> mediante a apresentação da nota de negociação do título na forma da legislação em vigor;</p>`,
  },
  {
    content: `<p class='mb-0'><b>Os Cônjuges são considerados pessoas distintas,</b> seja qual for o regime de bens do casamento;</p>`,
  },
  {
    content: `<p class='mb-0'><b>Os instrumentos financeiros titulados por associações, condomínios e entidades assemelhadas,</b> sem personalidade jurídica, <b>serão garantidos até o valor limite de R$ 250.000,00,</b> na totalidade de seus haveres na instituição associada;</p>`,
  },
  {
    content: `<p class='mb-0'><b>Nas contas conjuntas, a garantia está limitada ao valor de R$ 250.000,00 ou ao saldo da conta,</b> quando inferior a esse limite, dividido pelo número de titulares, sendo o crédito do valor garantido feito de forma individual;</p>`,
  },
  {
    content: `<p><b>Nas contas em moeda estrangeira, o valor deverá ser convertido em real com base na média da cotação oficial de compra e venda da moeda estrangeira,</b> da data da decretação do regime de resolução, conforme divulgada no sítio do Banco Central do Brasil.</p>

    <p class='examples'>EXEMPLOS</p>`,
    examples: [
      {
        question: `a) Conta conjunta de 2 (dois) titulares`,
        answer: `<p>A B = saldo de R$ 280.000,00 <br> Valor Garantido = R$ 250.000,00/2 = R$ 125.000,00 para cada titular.</p>`,
      },
      {
        question: `b) Conta conjunta de 3 (três) titulares`,
        answer: `<p>A B C = saldo de R$ 280.000,00 <br> Valor Garantido = R$ 250.000,00/3 = R$ 83.333,33 para cada titular.</p>`,
      },
      {
        question: `c) Conta conjunta de 4 (quatro) titulares`,
        answer: `<p>A B C D = saldo de R$ 280.000,00 <br> Valor Garantido = R$ 250.000,00/4 = R$ 62.500,00 para cada titular.</p>`,
      },
      {
        question: `d) um cliente (A) com 4 (quatro) contas conjuntas (com B, C, D e E) cada uma com saldo de R$ 280.000,00`,
        answer: `<p>Conta AB = R$ 280.000,00 <br> Conta AC = R$ 280.000,00 <br> Conta AD = R$ 280.000,00 <br> Conta AE = R$ 280.000,00 <br><br> Cálculo do valor da garantia por conta: <br> AB = R$ 250.000,00/2 = R$ 125.000,00 <br> AC = R$ 250.000,00/2 = R$ 125.000,00 <br> AD = R$ 250.000,00/2 = R$ 125.000,00 <br> AE = R$ 250.000,00/2 = R$ 125.000,00</p>
        <p class='custom'>A cada um deles caberá: <br> A = R$ 250.000,00 <br> B = R$ 125.000,00 <br> C = R$ 125.000,00 <br> D = R$ 125.000,00 <br> E = R$ 125.000,00</p>`,
      },
    ],
  },
  {
    content: `<p class='mb-lg-5'><b>No caso de pessoas jurídicas, para apuração do montante dos créditos objeto de garantia, considera-se o número base do CNPJ</b> (oito primeiros dígitos).</p>

    <p class='examples mb-2'>EXEMPLO</p>
    <p class='color-black'>Matriz: CNPJ 12345678/0001-XX = saldo de R$ 150.000,00 <br> Filial 1: CNPJ 12345678/0001-XX = saldo de R$ 50.000,00 <br> Filial 2: CNPJ 12345678/0001-XX = saldo de R$ 300.000,00</p>
    <p class='color-black'>Montante dos Créditos Garantido = R$ 500.000,00 <br> Valor Garantido = R$ 250.000,00 A SER PAGO AO CNPJ 12345678/0001-XX (Matriz).</p>
    <p class='color-black'>Detectada a ocorrência de procedimentos que possam propiciar, mediante utilização de artifícios, o pagamento de valor superior ao limite de R$ 250.000,00 (duzentos e cinquenta mil reais), com o intuito de beneficiar uma mesma pessoa, ou de operações cujas condições pactuadas revelem o indício de fraude, o FGCoop, mediante decisão fundamentada referente ao específico depositante ou investidor, poderá suspender o pagamento até o esclarecimento do fato, cabendo ao interessado demonstrar a lisura dos procedimentos adotados, ficando a critério do FGCoop atacar ou não os argumentos e provas apresentadas.</p>`,
  },
];

export const depositCoverage = [
  {
    id: '1',
    cooperative: 'Crehnor Sarandi',
    decreeOfSpecialRegime: '24/03/2017',
    startOfPayment: '09/05/2017',
    totalPayments: 'R$18.796,00',
    balanceToPay: 'R$-',
  },
  {
    id: '2',
    cooperative: 'Crediserv',
    decreeOfSpecialRegime: '18/01/2018',
    startOfPayment: '19/03/2018',
    totalPayments: 'R$858,00',
    balanceToPay: 'R$-',
  },
  {
    id: '3',
    cooperative: 'Credicazola',
    decreeOfSpecialRegime: '05/09/2018',
    startOfPayment: '12/11/2018',
    totalPayments: 'R$21.112,00',
    balanceToPay: 'R$-',
  },
  {
    id: '4',
    cooperative: 'Municred',
    decreeOfSpecialRegime: '15/02/2023',
    startOfPayment: '25/04/2023',
    totalPayments: 'R$1.230,79',
    balanceToPay: 'R$-',
  },
];

export const depositCoverageTotal = [
  {
    total: '',
    width: 150,
    widthMobile: 150,
  },
  {
    total: '',
    width: 272,
    widthMobile: 33,
  },
  {
    total: '',
    width: 232,
    widthMobile: 150,
  },
  {
    total: 'R$41.997',
    width: 232,
    widthMobile: 36,
  },
  {
    total: 'R$ -',
    width: 155,
    widthMobile: 26,
  },
];

export const differentials = [
  {
    imgWidth: 54,
    imgHeight: 69,
    icon: safety,
    title: `Segurança`,
    content: `Fundo Garantidor único para todas as cooperativas de crédito captadoras de depósito do País.`,
  },
  {
    imgWidth: 64,
    imgHeight: 69,
    icon: warranty,
    title: `Garantia`,
    content: `Proteção dos depósitos e investimentos nas cooperativas singulares de crédito e nos bancos cooperativos.`,
  },
  {
    imgWidth: 86,
    imgHeight: 52,
    icon: solidity,
    title: `Solidez`,
    content: `Contribuições do FGCoop ao Sistema Nacional de Crédito Cooperativo (SNCC).`,
  },
  {
    imgWidth: 93,
    imgHeight: 54,
    icon: governance,
    title: `Competitividade`,
    content: `Igualdade das condições de competitividade com os bancos comerciais na cobertura de depósitos.`,
  },
];
