import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';

export const OrdinaryCoverageContainer = styled.div`
  & .row-styled {
    @media ${bootstrapMin.lg} {
      margin-bottom: 82px;
      gap: 15px;
    }
  }

  & .container-doubt {
    margin-bottom: 0 !important;
  }

  @media ${bootstrapMin.lg} {
    & .MuiAccordionDetails-root p {
      max-height: 250px;
    }
  }
`;

export const AccompanimentContainer = styled.div`
  height: 920px;

  @media ${bootstrapMin.lg} and ${bootstrapMax.xl} {
    height: 990px;
  }

  @media ${bootstrapMin.xl} {
    height: 527px;
  }
`;

export const AccompanimentBanner = styled.div`
  background-color: ${colors.supportOne};
  position: absolute;
  width: 100vw;
  left: 49.99%;
  height: 920px;
  z-index: -1;
  margin-left: -50vw;

  @media ${bootstrapMin.lg} and ${bootstrapMax.xl} {
    height: 990px;
  }

  @media ${bootstrapMin.xl} {
    height: 527px;
  }
`;

export const RowStyled = styled(Row)`
  padding-top: 30px;

  @media ${bootstrapMin.lg} and ${bootstrapMax.xl} {
    padding-top: 100px;
  }

  @media ${bootstrapMin.xl} {
    padding-top: 48px;
    margin: 0 -30px;
  }
`;
