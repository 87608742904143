import styled from 'styled-components';
import { bootstrapMax, bootstrapMin, width } from '../../utils/devices';
import { Container as ContainerComponent, Row } from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;

  @media ${bootstrapMin.xs} {
    padding: 0 20px;
  }
`;

export const ContainerLogin = styled.div`
  &::before {
    height: 100vh;
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    z-index: -1;
  }
`;

export const ContainerBodyLogin = styled(ContainerComponent)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px 35px 50px;
  z-index: 1;
  max-width: 1160px;

  @media ${bootstrapMin.xl} {
    padding: 0 50px;
  }
`;

export const ContainerFooterLogin = styled(ContainerComponent)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20vh;

  @media ${bootstrapMax.lg} {
    margin-bottom: 35px;
  }

  @media ${bootstrapMin.xl} {
    padding: 0 50px;
  }
`;

export const ContainerBody = styled(ContainerComponent)`
  ${({ home }) =>
    home
      ? `
  padding: 0 27px;
  @media ${bootstrapMin.lg} {
    padding: 0 50px;
  }`
      : `@media ${bootstrapMin.xl} {
    padding: 0 50px;
  }`}
`;

export const ContainerHeaderStyled = styled(ContainerComponent)`
  ${({ home }) => home && 'max-width: 1366px !important;'}
  ${({ home }) => home && 'padding: 0;'}
  display: flex;

  @media ${bootstrapMin.xl} {
    padding: ${({ home }) => (home ? 0 : ' 0 50px')};
  }
`;

export const ContainerHeader = styled(ContainerComponent)`
  min-height: 160px !important;

  @media ${bootstrapMax.sm} {
    min-height: 110px;
  }

  @media ${bootstrapMin.xl} {
    padding: 0 50px;
  }
`;

export const ContainerFooter = styled(ContainerComponent)`
  @media ${bootstrapMin.xl} {
    padding: 0 50px;
  }
`;

export const RowStyled = styled(Row)`
  @media (min-width: ${width.xxl}px) {
    margin-right: -100px;
  }
`;
