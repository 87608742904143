import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Screen, SubHeader, Text } from '../../../components';

import PropTypes from 'prop-types';

const { defaultStates } = require('../../../utils/defaults');
import { financialInstrumentsContents, excludedInstrumentsContents } from '../../../enums/index';

import {
  WorkeTypography,
  Title,
  RowFinancial,
  ColFinancial,
  Textsbody,
  Workeimg,
  Certificate,
  StyledLink,
  Programs,
  Programsimg,
  ProgramsText,
  WorkContainer,
  RowStyled,
} from './styles';

import banner from '../../../assets/imgs/banner-desktop-trabalhe-conosco-v1.png';
import bannerMobile from '../../../assets/imgs/banner-mobile-trabalhe-conosco-v1.png';
import grup from '../../../assets/imgs/grupo.jpeg';
import certificate from '../../../assets/imgs/selo-pdf.png';
import program01 from '../../../assets/logos/logo-bem-voce.png';
import program02 from '../../../assets/logos/logo-lidera-fgcoop.png';
import program03 from '../../../assets/logos/logo-perfoma-mais.png';

class Work extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      innerWidth: window.innerWidth,
      valueTab: 0,
      contents: [],
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  async componentDidMount() {
    await this.tabsContent();
  }

  async updateSize() {
    this.setState({ innerWidth: window.innerWidth });
  }

  header() {
    return (
      <SubHeader
        image
        backgroundImage={banner}
        backgroundImageMobile={bannerMobile}
        minHeightSm={452}
        minHeight={313}
        title={'Trabalhe Conosco'}
      />
    );
  }

  financialInstrumentsData = () => {
    return (
      <>
        <Title className={'mb-4 pb-1 pb-lg-3'}>INSTRUMENTOS FINANCEIROS OBJETO DE GARANTIA:</Title>
        <RowFinancial>
          {financialInstrumentsContents.map((financialInstrument, index) => {
            return (
              <ColFinancial key={index} xs={12} lg={3}>
                {financialInstrument.contents.map((content, i) => {
                  return (
                    <div key={i}>
                      <Text paragraph4 borderLeft fontSizeMobile={14} text={content.paragraph} />
                    </div>
                  );
                })}
              </ColFinancial>
            );
          })}
        </RowFinancial>
      </>
    );
  };

  excludedInstrumentsData = () => {
    return (
      <>
        <Row className={'justify-content-lg-between '}>
          {excludedInstrumentsContents.map((excludedInstrument, index) => {
            return (
              <Col key={index} lg={index ? 6 : 5}>
                {excludedInstrument.contents.map((content, i) => {
                  return (
                    <div key={i}>
                      <Text paragraph4 borderLeft text={content.paragraph} />
                    </div>
                  );
                })}
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  tabsContent = () => {
    this.setState({ contents: [this.financialInstrumentsData(), this.excludedInstrumentsData()] });
  };

  body() {
    return (
      <WorkContainer className={'mb-lg-5 pb-lg-5'}>
        <Row className={'mb-lg-4 pb-lg-2'}>
          <Col className={'d-flex flex-column align-items-center'}>
            <Text heading1 noWrap textAlign={'center'} title={{ one: 'Trabalhe conosco' }} />
          </Col>
        </Row>

        <RowStyled className={'mb-lg-5 pb-lg-5'}>
          <WorkeTypography>Quem somos?</WorkeTypography>

          <Textsbody>
            Somos uma empresa que acredita que a confiança é a base de todo relacionamento sólido e
            duradouro na vida pessoal, profissional ou financeira. Por isso temos a confiança como
            um direcionador para tudo que fazemos. Acreditamos que nossos colaboradores são o
            alicerce de nossa instituição. Nos dedicamos a preservar a confiança de todos os
            brasileiros no cooperativismo de crédito e por isso construímos um time forte e
            confiável, capaz de fazer a diferença no equilíbrio de todo Sistema Financeiro Nacional.{' '}
            <br /> Venha fazer parte dessa história! <br /> <b>#VemSerFGCoop</b>
          </Textsbody>

          <Workeimg src={grup}></Workeimg>
        </RowStyled>
        <RowStyled className={'mb-lg-5 pb-lg-5'}>
          <WorkeTypography>Talentos</WorkeTypography>

          <Textsbody>
            O FGCoop possui os melhores talentos que colaboram com a transformação de ideias em
            soluções concretas. Se você tem interesse em viver essa história, junte-se a nós. <br />
            <b>Cadastre seu currículo aqui: </b>
            <StyledLink to={{ pathname: 'https://vagasfgcoop.rhgestor.com.br' }} target="_blank">
              Vem ser FGCoop!
            </StyledLink>
          </Textsbody>
        </RowStyled>

        <RowStyled className={'mb-lg-5 pb-lg-5'}>
          <WorkeTypography>Programas</WorkeTypography>

          <Programs>
            <Programsimg src={program01}></Programsimg>
            <Textsbody>
              <b>Programa de Qualidade de Vida – BEM VOCÊ!</b>
              <br />
              <br />
              Aqui acreditamos que quando você se sente bem, você faz bem para o ambiente e para
              todos que estão ao seu redor. Isso reﬂete em todas as áreas da sua vida de forma
              positiva. O nosso programa de qualidade BEM VOCÊ é um compromisso coletivo em criar
              uma cultura que priorize a saúde física e mental, para promover uma atmosfera positiva
              que se reﬂita em nossas atividades diárias.
            </Textsbody>
          </Programs>

          <Programs>
            <Programsimg src={program02}></Programsimg>
            <ProgramsText>
              <b>Programa de Desenvolvimento de Liderança – LIDERA</b>
              <br />
              <br />
              Capacitar as lideranças em competências essenciais, trazendo para a nossa realidade as
              melhores práticas de mercado e as principais tendências de gestão.
              <br />
              <br />O Desenvolvimento da nossa liderança visa gerar um impacto positivo na
              construção de um time forte e competente, porque defendemos que quando capacitamos
              nossos líderes, aperfeiçoamos a nossa equipe.
            </ProgramsText>
          </Programs>

          <Programs>
            <Programsimg src={program03}></Programsimg>
            <ProgramsText>
              <b>Programa de Gestão do Desempenho - PERFORMA</b>
              <br />
              <br />
              O PERFORMA visa promover o alinhamento entre as expectativas, as competências e os
              resultados dos colaboradores com os objetivos estratégicos da organização.
              <br />
              <br />
              Sabemos que quando promovemos a oportunidades claras e objetivas de gerenciamento de
              performance dos colaboradores, damos condições para a evolução contínua na carreira e
              no desenvolvimento.
            </ProgramsText>
          </Programs>
        </RowStyled>

        <RowStyled className={'mb-lg-5 pb-lg-5'}>
          <WorkeTypography>Nossas premiações</WorkeTypography>
          <Textsbody>Também somos reconhecidas como uma ótima empresa para se trabalhar</Textsbody>
          <Certificate src={certificate}></Certificate>
          <p>Great Place To Work – Certificado Brasil</p>
        </RowStyled>
      </WorkContainer>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  }
}

Work.propTypes = {
  //props
  history: PropTypes.object,
};
export default Work;
