import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataGrid, GridOverlay, GridPagination, ptBR } from '@mui/x-data-grid';
import './styles.css';
import { getLabelFromEnum } from '../../utils/helpers';
import { Col, Row } from 'react-bootstrap';

class TableListAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: window.innerWidth < 768,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ mobile: window.innerWidth < 768 });
    });
  }

  render() {
    const {
      data,
      onCellClick,
      loading,
      pageSize,
      checkboxSelection,
      onSelectionModelChange,
      isRowSelectable,
    } = this.props;
    const { columns = [] } = this.props;

    const columnsWeb = [{ field: 'id', headerName: 'ID', minWidth: 10 }];
    if (!columns.length) {
      columns.push({ field: 'name', headerName: 'Name' });
      if ((data[0] && data[0].description) || !data) {
        columns.push({ field: 'description', headerName: 'Descrição' });
      }
    }

    columns.map((e) => {
      columnsWeb.push({
        field: e.field,
        headerName: e.headerName,
        flex: e.flex ? e.flex : 1,
        minWidth: e.width && e.width,
        align: e.align ? e.align : 'left',
        headerAlign: e.headerAlign ? e.headerAlign : 'left',
        ...(e.typeEnum
          ? {
              renderCell: ({ value }) => {
                return <>{getLabelFromEnum(value, e.typeEnum)}</>;
              },
            }
          : {
              renderCell: (cellValues) => <span title={cellValues.value}>{cellValues.value}</span>,
            }),
      });
    });

    const columnMobile = [
      { field: 'id', headerName: 'ID', hide: true },
      { field: 'title', headerName: 'Title', hide: true },
      { field: 'createdAt', headerName: 'CreatedAt', hide: true },
      { field: 'isActive', headerName: 'isActive', hide: true },
      {
        field: '',
        headerName: 'Nome',
        flex: 1,
        valueGetter: (params) => {
          let value = '';
          columnsWeb.map((e) => {
            value += `<span>${e.headerName}: </span> ${
              params.getValue(params.id, e.field) || ''
            }\n`;
          });
          return value;
        },
        renderCell: (cellValues) => {
          return <h5 dangerouslySetInnerHTML={{ __html: cellValues.value }} />;
        },
      },
      {
        field: 'description',
        headerName: 'Descrição',
        hide: true,
      },
    ];

    const rows = [
      { id: 1, name: 'Snow', description: 'Jon' },
      {
        id: 2,
        name: 'Lannister',
        description:
          'Cersei Cersei Cersei Cersei Cersei Cersei Cersei Cersei Cersei Cersei Cersei Cersei Cersei ',
        age: 42,
      },
      { id: 3, name: 'Lannister', description: 'Jaime', title: 'Lannister', link: 'Jaime' },
      { id: 4, name: 'Stark', description: 'Arya', title: 'Stark', link: 'Arya' },
      { id: 5, name: 'Targaryen', description: 'Daenerys', title: 'Targaryen', link: 'Daenerys' },
      { id: 6, name: 'Melisandre', description: null },
      { id: 7, name: 'Clifford', description: 'Ferrara' },
      { id: 8, name: 'Frances', description: 'Rossini' },
      { id: 9, name: 'Roxie', description: 'Harvey' },
    ];

    const components = {
      NoRowsOverlay: () => <GridOverlay height="100%">Nenhum registro encontrado</GridOverlay>,
    };

    return (
      <div style={{ height: 400, width: '100%', marginTop: '25px' }}>
        <DataGrid
          className={'admin'}
          onCellClick={onCellClick}
          onSelectionModelChange={onSelectionModelChange}
          isRowSelectable={isRowSelectable}
          rows={data || rows}
          columns={this.state.mobile ? columnMobile : columnsWeb}
          pageSize={pageSize || 10}
          rowsPerPageOptions={[10]}
          rowHeight={this.state.mobile ? 90 : 52}
          isCellEditable={() => false}
          headerHeight={this.state.mobile ? 0 : 50}
          checkboxSelection={checkboxSelection}
          disableSelectionOnClick={false}
          loading={loading}
          disableExtendRowFullWidth={false}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          components={components}
        />
      </div>
    );
  }
}

TableListAdmin.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  onCellClick: PropTypes.func,
  loading: PropTypes.bool,
  checkboxSelection: PropTypes.bool,
  onSelectionModelChange: PropTypes.func,
  isRowSelectable: PropTypes.func,
  pageSize: PropTypes.number,
  typeEnum: PropTypes.array,
};

export default TableListAdmin;
