const { dataFormParse } = require('../utils/dataFormParse');
const { axiosDefault, handleErrors } = require('../utils/defaults');

const bannersService = {
  admin: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/banners/gestao/${id ? id : ''}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async () => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/banners/gestao`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    save: async (banner) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.post(`/api/banners/gestao`, dataFormParse(banner));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    edit: async (banner) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.put(`/api/banners/gestao/${banner.id}`, dataFormParse(banner));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    delete: async (id) => {
      try {
        const axios = axiosDefault();

        const { data } = await axios.delete(`/api/banners/gestao/${id}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },

  user: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/banners/${id}`);

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async ({ limit = '', search = '', offset = '', random = '' }) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(
          `/api/banners?limit=${limit}&title=${search}&offset=${offset}&random=${random}`,
        );

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },
};

export default bannersService;
