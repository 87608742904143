import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin, device } from '../../../utils/devices';

import bannerIADI from '../../../assets/imgs/banner-iadi.png';

export const Container = styled.div`
  .recipes,
  .guaranteed-deposits {
    @media ${bootstrapMin.xl} {
      max-width: 77%;
    }
  }

  .guaranteed-deposits {
    p {
      @media ${bootstrapMax.lg} {
        margin-bottom: 30px !important;
      }
    }
  }

  & .meet-our-background:first-child {
    & .MuiAccordionSummary-root {
      padding-top: 0 !important;
      margin-top: 5px;

      @media ${bootstrapMax.sm} {
        margin-top: -5px;
      }
    }
  }

  @media ${bootstrapMax.sm} {
    padding: 0 15px;
  }

  @media (max-width: 354px) {
    .adjust-width-mobile {
      width: 100%;
    }
  }

  .btn-iadi {
    @media ${device.tablet} {
      justify-content: center !important;
    }
  }
`;

export const Paragraph = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: ${colors.secondaryTwo};
  border-left: ${({ border }) => (border ? `2px solid ${colors.primaryTwo}` : 'none')};
  padding-left: ${({ border }) => (border ? '10px' : '0')};
  margin-bottom: 30px;

  @media ${bootstrapMin.lg} {
    font-size: 16px;
  }
`;

export const BannerIADI = styled.div`
  background-image: url(${bannerIADI});
  background-repeat: no-repeat;
  background-size: 283px 170px;
  height: 170px;
  width: 283px;

  @media ${bootstrapMax.xs} {
    width: 100%;
    background-size: 100% 170px;
  }

  @media ${bootstrapMin.lg} {
    background-size: 100% 323px;
    align-self: center;
    height: 323px;
    width: 100%;
  }

  @media ${bootstrapMin.xl} {
    align-self: normal;
    margin-top: 25px;
  }
`;

export const Heading3 = styled.h3`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryTwo};
  font-size: 23px;
  line-height: 32px;

  @media ${bootstrapMin.lg} {
    font-size: 26px;
    line-height: 50px;
  }
`;
