import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';

export const Plus = styled.div`
  margin-left: 15px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
	pointer-events: auto;

  &:before {
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: ${colors.primaryTwo};
    transform: rotate(90deg);
  }

  &:after {
    margin-left: -2px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: ${colors.primaryTwo};
    transform: rotate(0deg);
  }
}`;

export const AccordionPlus = MuiStyled(MuiAccordion)`
	font-family: 'Trade Gothic LT Bold';
	color: ${colors.primaryTwo};

  &:before {
      display: none;
  }

	&:last-of-type {
		border-radius: 0px;
	}
`;

export const AccordionSummary = MuiStyled(MuiAccordionSummary)`
	border-bottom: 1px solid ${colors.lightGreyTwo};
	min-height: 40px !important;
  padding: 30px 0 0 !important;
	pointer-events: none;

	& span {
		font-size: 18px !important;
		text-transform: initial;
		line-height: 26px;
		margin: 3px 0;

		@media ${bootstrapMax.lg} {
			font-size: 16px !important;
		}
	}

	&.Mui-expanded {
    padding: 0 0 12px;
	}
	
	&.MuiAccordionSummary-content {
		padding-right: 30px;
	}

	.MuiAccordionSummary-expandIconWrapper.Mui-expanded{
		transform: none;

		& div:before {
			height: 15px;
		}
		& div:after {
			display: none;
		}
	}
`;

export const AccordionDetails = MuiStyled(MuiAccordionDetails)`
	padding: 20px 0 0;

	& p {

		font-family: 'Trade Gothic LT';
		color: ${({ text_color }) => (text_color ? text_color : colors.secondaryTwo)};
    font-size: 16px;
    line-height: 24px;

		ul {
			padding-left: 0;

			& li {
				border-left: 2px solid ${colors.primaryTwo};
				margin-bottom: 10px;
    		padding-left: 12px;

				&::marker{
					content: none;
				}
			}
		}

		@media ${bootstrapMax.lg} {
			font-size: 14px;
			margin-bottom: 0 !important;
		}
	}
`;
