/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';
import { Screen, Text, Input, Label, Button, Checkbox } from '../../../components';
import { Editor } from '@tinymce/tinymce-react';
import cooperativeService from '../../../services/cooperatives';
import quote1 from '../../../assets/icons/admin/quote-left-top.png';
import quote2 from '../../../assets/icons/admin/quote-right-bottom.png';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
} = require('../../../utils/defaults');

class Cooperative extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cooperative: {
        name: '',
        sigla: ''
      },
      ...defaultStates(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });

    const { id } = this.props.match.params;

    if (id !== 'novo') {
      const response = await cooperativeService.admin.get(id);
      if (response.ok && response.data && response.data.cooperative) {
        this.setState({
          cooperative: response.data.cooperative,
          newRegister: false,
        });
      } else if (response.ok) {
        this.props.history.push('/gestao/cooperatives/novo');
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  handleChange(id, event) {
    const { cooperative } = this.state;

    if (id === 'newDescription') {
      cooperative[id] = event.target.getContent();
    } else if (id !== 'cooperative') {
      let value = event.target.value;
      cooperative[id] = value.toUpperCase();
    } else {
      cooperative[id] = event.target.value;
    }
    this.setState({ cooperative });
  }

  async handleSubmit() {
    const { newRegister, cooperative } = this.state;
    cooperative.description = cooperative.newDescription || cooperative.description;
    delete cooperative.newDescription;
    this.setState({ loadingState: true });
    if (newRegister) {
      const response = await cooperativeService.admin.save(cooperative);

      if (response.ok) {
        const alertScreenState = {
          title: 'Cooperativa criada com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/cooperatives',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    } else {
      const response = await cooperativeService.admin.edit(cooperative);

      if (response.ok) {
        const alertScreenState = {
          title: 'Cooperativa alterada com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/cooperatives',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  async handleDelete() {
    this.setState({
      modalState: {
        title: `Remoção de Cooperativa`,
        text: `Tem certeza que deseja excluir essa cooperativa ?`,
        toggle: true,
        onHide: () => this.modalOnHide(),
        confirm: 'Sim',
        onClickConfirm: async () => await _handleDelete(),
        danger: true,
        cancel: 'Não',
        onClickCancel: () => this.modalOnHide(),
      },
    });

    const _handleDelete = async () => {
      const {
        cooperative: { id },
      } = this.state;
      this.setState({ loadingState: true });

      const response = await cooperativeService.admin.delete(id);

      if (response.ok && response.data) {
        const alertScreenState = {
          title: 'Cooperativa Excluida com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/gestao/cooperatives',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }

      this.setState({ loadingState: false });
    };
  }

  body() {
    const { cooperative } = this.state;

    return (
      <>
        <Row align="center">
          <Col>
            <Text title text={'Cooperativa'} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Input
              value={cooperative.name}
              label={'Nome:'}
              placeholder={'Nome'}
              maxLength={200}
              onChange={(e) => this.handleChange('name', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Input
              value={cooperative.sigla}
              label={'Sigla:'}
              placeholder={'Sigla'}
              maxLength={200}
              onChange={(e) => this.handleChange('sigla', e)}
            />
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center">
          {this.state.cooperative.id ? (
            <Col>
              <Button danger text={'Excluir'} onClick={this.handleDelete} />
            </Col>
          ) : (
            <></>
          )}
          <Col>
            <Button text={'Salvar'} onClick={this.handleSubmit} />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          admin
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

Cooperative.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Cooperative;
