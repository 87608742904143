import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';

export const Label = styled.label`
  font-size: 20px;
  height: 28px;
  text-align: left !important;
  margin: 15px 0 0 0;
`;

export const LabelHeader = styled.span`
  text-transform: uppercase;
  font-size: 14px;
  color: ${colors.primaryThree};
  white-space: nowrap;
`;

export const LabelHeaderCustom = styled.span`
  text-transform: uppercase;
  font-size: 14px;
  color: ${colors.primaryThree};
`;

export const LabelLinkInfo = styled.span`
  font-size: 16px;
  font-family: 'Trade Gothic LT', serif !important;
  color: ${colors.secondaryTwo};

  .is-active {
    font-family: 'Trade Gothic LT Bold', serif !important;
    color: ${colors.primaryThree} !important;
  }
`;

export const LabelMenu = styled.span`
  font-size: 14px;
  font-family: 'Trade Gothic LT Bold', serif !important;
  color: ${colors.primaryOne};
  padding-top: 15px;
`;

export const LabelNewsDate = styled.span`
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'Trade Gothic LT Bold', serif !important;
  color: ${colors.secondaryTwo};
  padding-top: 15px;

  @media ${bootstrapMax.md} {
    font-size: 14px;
  }
`;

export const LabelNewsTitle = styled.span`
  text-transform: uppercase;
  font-size: 26px;
  font-family: 'Trade Gothic LT Bold', serif !important;
  color: ${colors.primaryThree};
  padding-top: 15px;
  white-space: break-spaces;
  overflow-wrap: break-word;

  @media ${bootstrapMax.md} {
    font-size: 20px;
  }
`;

export const LabelMarginBottom = styled(Label)`
  margin-bottom: 20px;
`;

export const WhiteLabelComponent = styled(Label)`
  color: ${colors.white};
`;
