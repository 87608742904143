import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  Button,
  CarouselComponent,
  MiniCard,
  Screen,
  SubHeader,
  Text,
  Video,
} from '../../../components';
import newsService from '../../../services/news';
import bannersService from '../../../services/banners';

import PropTypes from 'prop-types';
import {
  TitleHome,
  Content,
  ImageHome,
  ColStyled,
  Background,
  HomeContainer,
  SubHeaderHome,
  FlagYoutube,
} from './styles';
const { defaultStates, defaultModalOnHide, baseURL } = require('../../../utils/defaults');
import { differentials } from '../../../enums';

import bannerHome from '../../../assets/imgs/banner-home_compress.webp';
import bannerHomeMobile from '../../../assets/imgs/banner-home-mobile.webp';
import { bootstrapMax } from '../../../utils/devices';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descOrder: true,
      limit: 3,
      news: [],
      carousel: [],
      ...defaultStates(),
    };

    this.handleMiniCardsForCarousel = this.handleMiniCardsForCarousel.bind(this);
    this.handleBannerForCarousel = this.handleBannerForCarousel.bind(this);
    this.goToWhoWeArePage = this.goToWhoWeArePage.bind(this);
    this.goToNewsPage = this.goToNewsPage.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.header = this.header.bind(this);
    this.body = this.body.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  async componentDidMount() {
    await this.fillAll();
  }

  async fillAll() {
    this.setState({ loadingState: true });
    this.fillNews();
    this.fillBanner();
    this.setState({ loadingState: false });
  }

  async fillNews() {
    const response = await newsService.user.getList({
      home: true,
      descOrder: this.state.descOrder,
      limit: this.state.limit,
    });

    if (response && response.ok && response.data) {
      this.setState({ news: response.data });
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  async fillBanner() {
    const response = await bannersService.user.getList({
      limit: this.state.limit,
      descOrder: this.state.descOrder,
    });
    if (response && response.ok && response.data) {
      this.setState({ carousel: response.data });
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  async goToWhoWeArePage() {
    window.location.href = '/quem-somos';
  }

  async goToNewsPage() {
    window.location.href = '/noticias';
  }

  handleBannerForCarousel = (carousel) => {
    if (carousel.length) {
      return carousel.map((c, i) => {
        const banner = `${baseURL()}/api/file/${c.file?.id}/${c.file?.slug}`;
        let bannerMobile = null;
        if (c.fileMobile && c.fileMobile.id) {
          bannerMobile = `${baseURL()}/api/file/${c.fileMobile?.id}/${c.fileMobile?.slug}`;
        }
        return (
          <SubHeader
            key={i}
            home={'true'}
            carousel
            backgroundImage={banner}
            backgroundImageMobile={bannerMobile ? bannerMobile : banner}
            minHeightSm={503}
            minHeight={312}
            title={c.title}
            content={c.subtitle}
            href={c.link}
            isVisible={c.isVisible}
            length={carousel.length}
          />
        );
      });
    } else {
      return [
        <SubHeader
          key={'banner-home'}
          home={'true'}
          carousel
          backgroundImage={bannerHome}
          backgroundImageMobile={bannerHomeMobile}
          minHeightSm={503}
          minHeight={312}
          title={'NOSSO NEGÓCIO É PROTEÇÃO!'}
          content={`Protegemos depósitos e atuamos preventivamente para manter o cooperativismo de crédito
          sólido, confiável e em plena expansão.`}
          href={'/quem-somos'}
        />,
      ];
    }
  };

  header() {
    const breakPoints = [{ width: 1, itemsToShow: 1 }];
    const { carousel } = this.state;

    return (
      <SubHeaderHome items={carousel}>
        <div>
          <CarouselComponent
            bannerHome
            enableAutoPlay={true}
            items={this.handleBannerForCarousel(carousel)}
            data={carousel}
          />
        </div>
      </SubHeaderHome>
    );
  }

  handleMiniCardsForCarousel = (news) => {
    return news.map((n, i) => {
      return <MiniCard key={i} newsHomePage data={n} />;
    });
  };

  body() {
    const breakPoints = [{ width: 1, itemsToShow: 1 }];
    const { news } = this.state;

    return (
      <HomeContainer>
        <Row>
          <Col lg={5} className={'align-self-center'}>
            <div>
              <TitleHome className={'mb-0 mb-lg-3'}>Prazer, somos o FGCOOP</TitleHome>
              <div className={'mb-3'}>
                <Text
                  heading2
                  noWrap
                  title={{ one: 'GARANTIA DE DEPÓSITOS, SEGURANÇA PARA BONS NEGÓCIOS.' }}
                />
                <div
                  className={'d-flex d-md-none justify-content-center'}
                  style={{ height: '293px' }}>
                  <ImageHome />
                </div>
              </div>
              <Content className={'mb-4'}>
                O Fundo Garantidor do Cooperativismo de Crédito (FGCoop) nasceu em 2014 para
                contribuir com a solidez e confiança do Sistema Nacional de Crédito Cooperativo
                (SNCC), tornando-se parte de uma ampla rede de proteção ao Sistema Financeiro
                Nacional.
              </Content>
              <Content className={'mb-4'}>
                O FGCoop foi criado para proteger pessoas que confiam suas economias às cooperativas
                de crédito e aos bancos cooperativos associados ao Fundo, nas condições do seu
                Regulamento.
              </Content>
              <div className={'pt-lg-2 d-flex justify-content-center justify-content-lg-start'}>
                <Button
                  greenButton
                  height={53}
                  width={178}
                  widthMobile={150}
                  onClick={this.goToWhoWeArePage}
                  text={'Conheça mais'}
                />
              </div>
            </div>
          </Col>
          <ColStyled lg={{ offset: 1, span: 5 }} className={'d-none d-md-flex'}>
            <ImageHome />
          </ColStyled>
        </Row>
        <Row className={'justify-content-center pt-5 pt-lg-3 mb-4 mb-lg-5 pb-lg-3'}>
          <div className={'pb-4 mb-lg-5 pb-lg-3'}>
            <Text heading2 noWrap title={{ one: 'NOSSOS DIFERENCIAIS' }} />
          </div>
          <Row>
            {differentials.map((differential, index) => {
              return (
                <Col
                  xs={12}
                  lg={3}
                  key={index}
                  className={'mb-3 px-5 px-lg-0 d-flex justify-content-center'}>
                  <MiniCard
                    noBox
                    fullColumns
                    center
                    centerMobile
                    flexDirectionColumn
                    justifyContentCenter
                    data={[differential]}
                    fontSizeContent={14}
                    fontSizeTitle={26}
                  />
                </Col>
              );
            })}
          </Row>
        </Row>
        <div>
          <Background />
          <Row className={'mb-3 mb-lg-5 pb-4 pt-5 know-more-about'}>
            <Col lg={4} className={'pt-lg-5'}>
              <TitleHome className={'mb-0 mb-lg-1 text-center'}>Assista ao nosso vídeo</TitleHome>
              <Text
                heading2Video
                noWrap
                textAlign={'center'}
                title={{
                  one: '10 anos do FGCoop',
                  two: 'Uma década de sucesso, cooperação e crescimento. Vem conferir!',
                }}
              />
            </Col>
            <Col lg={8} className={'mb-lg-3 pb-lg-1 pt-lg-5 d-flex justify-content-center'}>
              <Video tenYears={true} />
            </Col>
          </Row>
        </div>
        <div>
          <Background />
          <Row className={'mb-3 mb-lg-5 pb-4 pt-5 know-more-about'}>
            <Col lg={4} className={'pt-lg-5'}>
              <TitleHome className={'mb-0 mb-lg-1 text-center'}>Assista ao nosso vídeo</TitleHome>
              <Text
                heading2
                noWrap
                textAlign={'center'}
                title={{ one: 'CONHEÇA MAIS SOBRE O FGCOOP' }}
              />
            </Col>
            <Col lg={8} className={'mb-lg-3 pb-lg-1 pt-lg-5 d-flex justify-content-center'}>
              <Video whoWeAre={true} />
            </Col>
          </Row>
        </div>
        {news.length ? (
          <>
            <div className={'mb-4 pb-lg-3'}>
              <div className={'mb-3 mb-lg-4 pb-lg-3'}>
                <Text heading2 noWrap textAlign={'center'} title={{ one: 'FIQUE POR DENTRO' }} />
              </div>
              {news && (
                <>
                  <Row className={'d-none d-lg-flex'}>
                    {news.map((n, i) => {
                      return (
                        <Col lg={4} key={i} className={'d-flex justify-content-center'}>
                          <MiniCard newsHomePage data={n} />
                        </Col>
                      );
                    })}
                  </Row>
                  <div className={'d-lg-none'}>
                    <CarouselComponent
                      breakPoints={breakPoints}
                      items={this.handleMiniCardsForCarousel(news)}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={'d-flex justify-content-center button mb-lg-5'}>
              <Button
                greenButton
                height={53}
                width={213}
                widthMobile={178}
                onClick={this.goToNewsPage}
                text={'Veja mais publicações'}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </HomeContainer>
    );
  }

  addIframeYoutube1() {
    let fachada1 = document.getElementById('fachada1');
    let flag1 = document.getElementById('icon_video1');
    flag1.remove();
    fachada1.remove();
    let iframe = document.createElement('iframe');

    if (window.matchMedia(`${bootstrapMax.xs}`).matches) {
      iframe.style = `position:relative;bottom:228px;border-radius: 10px;margin-left:-22px;`;
      iframe.width = '350px';
      iframe.height = '200px';
    } else {
      iframe.style = `position:relative;bottom:646px;border-radius: 10px;margin-left:385px;`;
      iframe.width = '700px';
      iframe.height = '400px';
    }

    iframe.id = 'video1';
    iframe.allow = 'autoplay';
    iframe.src = 'https://www.youtube.com/embed/SuDKgJbfkFI?controls=0&autoplay=1';
    iframe.title = 'FGCoop - Mais segurança para as cooperativas de crédito';
    document.getElementById('video1').appendChild(iframe);
  }

  addIframeYoutube2() {
    let iframe = document.createElement('iframe');

    if (window.matchMedia(`${bootstrapMax.xs}`).matches) {
      iframe.style = `position:relative;left:-25px;top:-235px;border-radius: 10px;`;
      iframe.width = '350px';
      iframe.height = '200px';
    } else {
      iframe.style = `position:relative;margin-left:391px;top:-648px;border-radius: 10px`;
      iframe.width = '700px';
      iframe.height = '400px';
    }

    let fachada2 = document.getElementById('fachada2');
    let flag2 = document.getElementById('icon_video2');
    flag2.remove();
    fachada2.remove();
    iframe.id = 'video2';
    iframe.allow = 'autoplay';
    iframe.src = 'https://www.youtube.com/embed/-Tlhare1-Qg?controls=0&autoplay=1';
    iframe.title = 'FGCoop - Mais segurança para as cooperativas de crédito';
    document.getElementById('video2').appendChild(iframe);
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        home={'true'}
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  }
}

Home.propTypes = {
  //props
  history: PropTypes.object,
};
export default Home;
