import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CloseButton,
  PreviewDoc,
  LogoImg,
  PinImg,
  Banner,
  PreviewImg,
  PreviewImgBanner,
  CustomImg,
  CustomImgBanner,
  Circle,
  ImageGovernanceHierarchy,
  IconArrowLeft,
  IconArrowRight,
  RowStyled,
} from './styles';
import close_maskavo_min from '../../assets/icons/admin/close_maskavo-min@2x.png';
import document from '../../assets/icons/admin/document_maskavo-min.png';
import document2x from '../../assets/icons/admin/document_maskavo-min@2x.png';
import logoSrc from '../../assets/logos/FGCoop.png';
import { Col, Row } from 'react-bootstrap';
import Text from '../Text';

import Pin from '../../assets/icons/map/Pin.png';
import Pin2x from '../../assets/icons/map/Pin@2x.png';
import PinGreen from '../../assets/icons/map/PinGreen.png';
import PinGreen2x from '../../assets/icons/map/PinGreen@2x.png';
import ImgGovernanceHierarchy from '../../assets/imgs/governance-hierarchy.png';
import ImgGovernanceHierarchyMobile from '../../assets/imgs/governance-hierarchy-mobile.png';
import { width } from '../../utils/devices';

class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= width.lg) {
        this.setState({ zoom: false });
      }
    });
  }

  zoomImage = () => {
    this.setState({ zoom: !this.state.zoom });
  };

  render() {
    // props
    const { img, label, onClick, alt, selected, className } = this.props;

    // types
    const { doc, logo, pin, preview, previewBanner, governanceHierarchy } = this.props;

    if (governanceHierarchy) {
      return (
        <>
          <ImageGovernanceHierarchy
            height={'100%'}
            className={'d-none d-lg-flex'}
            src={ImgGovernanceHierarchy}
            alt={'Hierarquia - Governança'}></ImageGovernanceHierarchy>
          <a onClick={this.zoomImage} className={'d-lg-none'}>
            {!this.state.zoom ? (
              <RowStyled className={'px-4 px-lg-0'}>
                <Col xs={2} className={'d-flex justify-content-end'}>
                  <IconArrowLeft />
                </Col>
                <Col xs={2}>
                  <IconArrowRight />
                </Col>
              </RowStyled>
            ) : (
              ''
            )}
            <ImageGovernanceHierarchy
              height={this.state.zoom ? '100%' : 419}
              id={'imgGovernanceHierarchy'}
              src={ImgGovernanceHierarchyMobile}
              zoom={this.state.zoom}
              innerWidth={window.innerWidth}
              className={'px-3 px-lg-0'}></ImageGovernanceHierarchy>
          </a>
        </>
      );
    }

    if (doc) {
      return (
        <PreviewDoc>
          <Row className={'flex-column align-items-center'} style={{ width: '100%' }}>
            <Col align="center">
              <CloseButton onClick={onClick} img={close_maskavo_min} className={'close-button'} />
              <img
                src={img.id ? img.urlS3 : document}
                srcSet={img.id ? img.urlS3 : document2x}
                alt=""
                onClick={() => {
                  onClick;
                }}
                className="mb-2"
              />
            </Col>
            <Col xs={8}>
              <Text subTitle doc text={label} />
            </Col>
          </Row>
        </PreviewDoc>
      );
    }

    if (logo) {
      return <LogoImg src={logoSrc} />;
    }

    if (pin) {
      return (
        <div onClick={onClick} className={'d-flex align-items-center flex-column my-2'}>
          <Circle
            selected={selected}
            className={'d-flex justify-content-center align-items-center mb-3'}>
            <PinImg
              alt={alt}
              selected={selected}
              src={selected ? PinGreen : Pin}
              srcSset={selected ? PinGreen2x : Pin2x}
            />
          </Circle>
          <Text label text={label} />
        </div>
      );
    }

    if (preview) {
      return (
        <PreviewImg className="mb-1 mt-3">
          <CloseButton onClick={onClick} img={close_maskavo_min} />
          <CustomImg src={img} alt="" />
        </PreviewImg>
      );
    }

    if (previewBanner) {
      return (
        <PreviewImgBanner className="mb-1 mt-3">
          <CloseButton onClick={onClick} img={close_maskavo_min} />
          <CustomImgBanner src={img} alt="" />
        </PreviewImgBanner>
      );
    }

    return <Banner src={img} alt={alt} className={className} />;
  }
}

Image.propTypes = {
  // props
  img: PropTypes.string,
  imgSet: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,

  // types
  doc: PropTypes.bool,
  pin: PropTypes.bool,
  alt: PropTypes.any,
  selected: PropTypes.bool,
  logo: PropTypes.bool,
  preview: PropTypes.bool,
  previewBanner: PropTypes.bool,
  governanceHierarchy: PropTypes.bool,
};

export default Image;
