import React, { Component } from 'react';
import { ContributionCalendar, Screen, SubHeader, Text } from '../../../components';

import PropTypes from 'prop-types';
const { defaultStates } = require('../../../utils/defaults');

import bannerMobile from '../../../assets/imgs/banner-contribution-calendar-mobile.png';
import banner from '../../../assets/imgs/banner-contribution-calendar.png';

import { Container, Paragraph } from './styles';
import { Col, Row } from 'react-bootstrap';
class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      innerWidth: window.innerWidth,
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  updateSize = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  header = () => {
    return (
      <>
        <SubHeader
          image
          backgroundImage={banner}
          backgroundImageMobile={bannerMobile}
          minHeightSm={452}
          minHeight={313}
          title={'CALENDÁRIO DE CONTRIBUIÇÃO'}
        />
      </>
    );
  };

  body = () => {
    return (
      <Container className={'pt-2 pt-lg-0 mb-lg-5 pb-lg-5'}>
        <Row className={'justify-content-center pt-lg-3'}>
          <Col xs={9} className={'mb-lg-4 d-none d-xl-block d-xxl-block'}>
            <div className=""></div>
            <Text
              heading2
              noWrap
              textAlign={'center'}
              title={{ one: 'CONFIRA AS DATAS DE CONTRIBUIÇÕES DAS INSTITUIÇÕES ASSOCIADAS' }}
            />
          </Col>
          <Col xs={12} className={'mb-lg-4 d-xl-none d-xxl-none'}>
            <div className=""></div>
            <Text
              heading2
              noWrap
              textAlign={'center'}
              title={{ one: 'CONFIRA AS DATAS DE CONTRIBUIÇÕES DAS INSTITUIÇÕES ASSOCIADAS' }}
            />
          </Col>
          <Col xs={12} className={'mb-lg-4'}>
            <ContributionCalendar />
            <br />
            <hr />
          </Col>
          <Col lg={9} className={'mb-lg-4'}>
            <Paragraph>
              A contribuição mensal ordinária das instruções associadas ao FGCoop é de 0,0125% do
              montante dos saldos das contas correspondentes às obrigações objeto de garantia.
            </Paragraph>
            <Paragraph>
              Conforme circular CMN 127/2021, o recolhimento das contribuições mensais ordinárias
              deverá ser efetivado até o dia 25 do mês seguinte ao da data-base. Assim caso o dia 25
              seja sábado, domingo ou feriado na sede da instituição associada ao FGCoop, o
              recolhimento deverá ser efetuado no dia útil anterior.
            </Paragraph>
          </Col>
        </Row>
      </Container>
    );
  };

  render = () => {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  };
}

Calendar.propTypes = {
  //props
  history: PropTypes.object,
};
export default Calendar;
