import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMin } from '../../../utils/devices';

export const ColStyled = styled(Col)`
  @media ${bootstrapMin.lg} {
    padding: 30px 40px 0 35px;
  }
`;

export const PurposeRow = styled(Row)`
  @media ${bootstrapMin.lg} {
    padding-left: 120px;
  }
`;

export const Background = styled.div`
  z-index: -1;
  min-height: 225px;
  position: absolute;
  width: 100vw;
  margin-left: -50vw;
  left: 49.99%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${colors.supportOne};

  @media ${bootstrapMin.lg} {
    min-height: 427px;
  }
`;

export const KnowOurGovernanceRow = styled(Row)`
  min-height: 225px;
  position: relative;
  width: 100vw;
  margin-left: -50vw;
  left: 49.99%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${colors.supportOne};
`;
