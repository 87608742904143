/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import CustomRoute from './CustomRoute';
import { AuthProvider } from '../../context/authContext';

import Home from '../app/Home';
import WhoWeAre from '../app/WhoWeAre';
import BackgroundFeatures from '../app/BackgroundFeatures';
import Governance from '../app/Governance';
import StrategicPlan from '../app/StrategicPlan';
import Standards from '../app/Standards';
import SNCC from '../app/SNCC';
import IADI from '../app/IADI';
import AssociateCooperatives from '../app/AssociateCooperatives';
import CooperativeBanks from '../app/CooperativeBanks';
import Guarantees from '../app/Guarantees';
import OrdinaryCoverage from '../app/OrdinaryCoverage';
import CommonQuestions from '../app/CommonQuestions';
import Informations from '../app/Informations';
import InformationsDetail from '../app/InformationsDetail';
import News from '../app/News';
import NewsDetail from '../app/NewsDetail';
import Calendar from '../app/Calendar';
import Contact from '../app/Contact';
import Error from '../app/Error';
import PrivacyPolicy from '../app/PrivacyPolicy';
import SearchedResult from '../app/SearchedResult';

import AdminLogin from '../gestao/Login';
import AdminLogout from '../gestao/Logout';
import AdminNews from '../gestao/News';
import AdminNewsList from '../gestao/News/list';
import AdminInfos from '../gestao/Infos';
import AdminInfosList from '../gestao/Infos/list';
import AdminBanners from '../gestao/Banners';
import AdminBannersList from '../gestao/Banners/list';
import AdminGovernances from '../gestao/Governances';
import AdminGovernancesList from '../gestao/Governances/list';
import AdminCooperatives from '../gestao/Cooperatives';
import AdminCooperativesList from '../gestao/Cooperatives/list';
import AdminCooperativesCsv from '../gestao/Cooperatives/csv';
import AdminUsers from '../gestao/Users';
import AdminUsersList from '../gestao/Users/list';
import Migrate from '../gestao/Migrate';
import Work from '../app/Work';

class Router extends Component {
  render() {
    return (
      <AuthProvider>
        <Switch>
          <CustomRoute exact path="/" component={Home} />
          <CustomRoute exact path="/quem-somos" component={WhoWeAre} />
          <CustomRoute exact path="/caracteristicas-fundo" component={BackgroundFeatures} />
          <CustomRoute exact path="/governanca" component={Governance} />
          <CustomRoute exact path="/plano-estrategico" component={StrategicPlan} />
          <CustomRoute exact path="/normas" component={Standards} />
          <CustomRoute exact path="/sncc" component={SNCC} />
          <CustomRoute exact path="/iadi" component={IADI} />
          <CustomRoute exact path="/cooperativas-associadas" component={AssociateCooperatives} />
          <CustomRoute exact path="/bancos-cooperativos" component={CooperativeBanks} />
          <CustomRoute exact path="/garantias" component={Guarantees} />
          <CustomRoute exact path="/cobertura-ordinaria" component={OrdinaryCoverage} />
          <CustomRoute exact path="/perguntas-frequentes" component={CommonQuestions} />
          <CustomRoute exact path="/informacoes" component={Informations} />
          <CustomRoute exact path="/informacoes/:id" component={InformationsDetail} />
          <CustomRoute exact path="/noticias" component={News} />
          <CustomRoute exact path="/noticias/:id" component={NewsDetail} />
          <CustomRoute exact path="/calendario-contribuicao" component={Calendar} />
          <CustomRoute exact path="/contato" component={Contact} />
          <CustomRoute exact path="/trabalheconosco" component={Work} />

          <CustomRoute exact path="/politica-de-privacidade" component={PrivacyPolicy} />
          <CustomRoute exact path="/busca" component={SearchedResult} />

          <CustomRoute exact path="/gestao/login" component={AdminLogin} />
          <CustomRoute exact path="/gestao/logout" component={AdminLogout} />
          <CustomRoute isPrivate exact path="/gestao" component={AdminNewsList} />
          <CustomRoute isPrivate exact path="/gestao/noticias" component={AdminNewsList} />
          <CustomRoute isPrivate exact path="/gestao/noticias/:id" component={AdminNews} />
          <CustomRoute isPrivate exact path="/gestao/informacoes" component={AdminInfosList} />
          <CustomRoute isPrivate exact path="/gestao/informacoes/:id" component={AdminInfos} />
          <CustomRoute isPrivate exact path="/gestao/banners" component={AdminBannersList} />
          <CustomRoute isPrivate exact path="/gestao/banners/:id" component={AdminBanners} />
          <CustomRoute isPrivate exact path="/gestao/governances" component={AdminGovernancesList} />
          <CustomRoute isPrivate exact path="/gestao/governances/:id" component={AdminGovernances} />
          <CustomRoute isPrivate exact path="/gestao/cooperatives" component={AdminCooperativesList} />
          <CustomRoute isPrivate exact path="/gestao/cooperatives/:id" component={AdminCooperatives} />
          <CustomRoute isPrivate exact path="/gestao/csv" component={AdminCooperativesCsv} />
          <CustomRoute isPrivate exact path="/gestao/users" component={AdminUsersList} />
          <CustomRoute isPrivate exact path="/gestao/users/:id" component={AdminUsers} />
          <CustomRoute isPrivate exact path="/gestao/migrate/:id" component={Migrate} />

          <CustomRoute path="/*" component={Error} />
        </Switch>
      </AuthProvider>
    );
  }
}

export default Router;
