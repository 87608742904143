import styled from 'styled-components';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';

export const StandardsContainer = styled.div`
  .MuiBox-root {
    padding-top: 0 !important;

    & .tab-panel {
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }

    @media ${bootstrapMin.lg} {
      margin-top: 16px !important;
    }
  }

  .MuiTabs-scroller {
    & .MuiTabs-flexContainer {
      button {
        font-size: 16px;

        @media ${bootstrapMax.lg} {
          flex: 1 0;
          font-size: 13.5px;
          padding-top: 10px;
        }
      }

      @media ${bootstrapMin.lg} {
        gap: 50px;
      }
    }

    & .MuiTabs-indicator {
      display: none;
    }
  }
`;
