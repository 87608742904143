import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';

export const Container = styled.div`
  .recipes,
  .guaranteed-deposits {
    @media ${bootstrapMin.xl} {
      max-width: 77%;
    }
  }

  .guaranteed-deposits {
    p {
      @media ${bootstrapMax.lg} {
        margin-bottom: 30px !important;
      }
    }
  }

  & .meet-our-background:first-child {
    & .MuiAccordionSummary-root {
      padding-top: 0 !important;
      margin-top: 5px;

      @media ${bootstrapMax.sm} {
        margin-top: -5px;
      }
    }
  }

  @media ${bootstrapMax.sm} {
    padding: 0 15px;
  }

  @media (max-width: 354px) {
    .adjust-width-mobile {
      width: 100%;
    }
  }
`;

export const ColStyled = styled(Col)`
  @media ${bootstrapMin.lg} {
    padding: 30px 40px 0 35px;
  }
`;

export const ColFontStyled = styled(Col)`
  font-size: 12px;
  color: ${colors.gray};
`;

export const LinkStyled = styled.a`
  padding: 0 10px;
  font-size: 12px;
  color: ${colors.gray};
`;

export const Paragraph = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: ${colors.secondaryTwo};
  border-left: ${({ border }) => (border ? `2px solid ${colors.primaryTwo}` : 'none')};
  padding-left: ${({ border }) => (border ? '10px' : '0')};
  margin-bottom: 30px;

  @media ${bootstrapMin.lg} {
    font-size: 16px;
  }
`;

export const Heading3 = styled.h3`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryTwo};
  font-size: 23px;
  line-height: 32px;

  @media ${bootstrapMin.lg} {
    font-size: 26px;
    line-height: 50px;
  }
`;

export const TitleSubText = styled.h4`
  line-height: 42px;
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryTwo};

  @media ${bootstrapMin.xs} {
    font-size: 18px;
  }
`;

export const SubtitleText = styled.h2`
  line-height: 42px;
  margin-bottom: 0;
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryTwo};
`;

export const Background = styled.div`
  z-index: -1;
  position: absolute;
  background-color: ${colors.supportOne};

  @media ${bootstrapMin.lg} {
    min-height: 480px;
  }

  min-height: 651px;
  margin-top: 160px !important;
`;
