import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../utils/devices';
import { Tabs } from '@mui/material';

export const MUITabs = styled(Tabs)`
  .MuiTabs-scroller {
    & .MuiTabs-flexContainer {
      flex-wrap: wrap;

      & button {
        font-family: 'Trade Gothic LT';
        text-transform: none;
        font-size: 16px;
        line-height: 24px;
        max-width: 100%;
        border-bottom: 2px solid ${colors.lightGrey};
        width: 100%;
        text-align: left;
        align-items: normal;
        padding-left: 0;

        &.Mui-selected {
          font-family: 'Trade Gothic LT Bold' !important;
          color: ${colors.primaryTwo} !important;
          border-bottom: 2px solid;
        }

        @media ${bootstrapMax.md} {
          letter-spacing: 0px;
          padding: 0;
        }

        @media ${bootstrapMin.lg} {
          font-size: 20px;
          width: auto;
        }
      }

      @media ${bootstrapMin.lg} {
        gap: 29px;
      }
    }

    & .MuiTabs-indicator {
      background-color: ${colors.primaryTwo};
      display: none;

      @media ${bootstrapMin.lg} {
        display: inline-block;
      }
    }
  }
`;

export const BorderTabs = styled.div`
  display: none;
  width: 100%;
  height: 2px;
  background-color: #d9d9d9;
  position: relative;
  bottom: -50px;

  @media ${bootstrapMin.lg} {
    display: flex;
  }
`;
