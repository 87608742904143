exports.getLabelFromEnum = (value, typeEnum) => {
  let label = '';
  typeEnum.find((k) => {
    if (k.value === value) {
      label = k.label;
    }
  });
  return label;
};

exports.validateEmail = (value) => {
  const email =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/;
  return email.test(String(value));
};

exports.maskPhone = (value) => {
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');

  return value;
};
