import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { ArrowSlider, ArrowSliderStyled, Carousel, CarouselStyled, Objective } from './styles';

class CarouselComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
    this.carouselRef = createRef();
    this.autoPlayInterval = null;
    this.resetTimeout = null;
  }

  componentDidMount() {
    this.startAutoPlay();
  }

  componentWillUnmount() {
    clearInterval(this.autoPlayInterval);
    clearTimeout(this.resetTimeout);
  }

  startAutoPlay = () => {
    const { enableAutoPlay, items } = this.props;
    if (enableAutoPlay && items.length > 1) {
      this.autoPlayInterval = setInterval(() => {
        this.setState((prevState) => ({
          index: (prevState.index + 1) % items.length,
        }), () => {
          this.carouselRef.current.goTo(this.state.index);
        });
      }, 5000);
    }
  }

  resetAutoPlay = () => {
    clearInterval(this.autoPlayInterval);
    this.startAutoPlay();
  }

  onNextEnd = ({ index }) => {
    clearTimeout(this.resetTimeout);
    if (index + 1 === this.props.items.length) {
      this.resetTimeout = setTimeout(() => {
        try {
          this.carouselRef.current.goTo(0);
        } catch (e) {
          console.error(e);
        }
      }, 4000);
    }
  }

  onNextStart = ({ index }) => {
    if (index + 1 === this.props.items.length) {
      try {
        this.carouselRef.current.goTo(0);
      } catch (e) {
        console.error(e);
      }
    }
  }

  onPrevStart = ({ index }) => {
    if (!index) {
      try {
        this.carouselRef.current.goTo(this.props.items.length - 1);
      } catch (e) {
        console.error(e);
      }
    }
  }

  render() {
    const {
      breakPoints,
      items,
      showArrows,
      withLink,
      enableAutoPlay,
      disableArrowsOnEnd,
      home,
      bannerHome,
      snccArrow,
      className,
    } = this.props;

    const myArrow = ({ onClick, isEdge, type }) => (
      <ArrowSlider
        next={type === 'NEXT'}
        disabled={!disableArrowsOnEnd && isEdge}
        onClick={() => {
          onClick();
          this.resetAutoPlay();
        }}
        showArrows={showArrows}>
        <div />
      </ArrowSlider>
    );

    const arrowStyled = ({ onClick, type }) => (
      <ArrowSliderStyled
        next={type === 'NEXT'}
        arrowType={bannerHome ? 'home' : 'sncc'}
        onClick={() => {
          onClick();
          this.resetAutoPlay();
        }}
        showArrows={showArrows}>
        <div />
      </ArrowSliderStyled>
    );

    const commonProps = {
      ref: this.carouselRef,
      showArrows,
      breakPoints,
      className,
      renderArrow: bannerHome || snccArrow ? arrowStyled : myArrow,
      pagination: false,
      isRTL: false,
      onNextEnd: this.onNextEnd,
      onNextStart: this.onNextStart,
      onPrevStart: this.onPrevStart,
    };

    if (withLink) {
      return (
        <Carousel {...commonProps} enableAutoPlay={enableAutoPlay} autoPlaySpeed={5000}>
          {items.map((item, i) => (
            <a key={i} href={item.link} target="_blank" rel="noreferrer">
              <Objective key={i} img={item.img} />
            </a>
          ))}
        </Carousel>
      );
    }

    if (home) {
      return (
        <Carousel {...commonProps} pagination={true} enableAutoPlay={enableAutoPlay} autoPlaySpeed={5000}>
          {items.map((item, i) => (item.type ? item : <Objective key={i} img={item} />))}
        </Carousel>
      );
    }

    if (bannerHome || snccArrow) {
      return (
        <CarouselStyled {...commonProps} enableAutoPlay={enableAutoPlay} autoPlaySpeed={5000}>
          {items.map((item, i) => (item.type ? item : <Objective key={i} img={item} />))}
        </CarouselStyled>
      );
    }

    return (
      <Carousel {...commonProps} pagination={true}>
        {items.map((item, i) => (
          <a key={i} href={item.link} target="_blank" rel="noreferrer">
            <Objective key={i} img={item.img} />
          </a>
        ))}
      </Carousel>
    );
  }
}

CarouselComponent.propTypes = {
  breakPoints: PropTypes.array,
  items: PropTypes.array,
  showArrows: PropTypes.bool,
  withLink: PropTypes.bool,
  enableAutoPlay: PropTypes.bool,
  disableArrowsOnEnd: PropTypes.bool,
  home: PropTypes.bool,
  bannerHome: PropTypes.bool,
  snccArrow: PropTypes.bool,
  className: PropTypes.string,
};

export default CarouselComponent;
