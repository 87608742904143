import React, { Component } from 'react';
import { Accordion, Screen, SubHeader, Text } from '../../../components';
import { commonQuestions } from '../../../enums';

import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
const { defaultStates } = require('../../../utils/defaults');
import { Container } from './styles';
import { colors } from '../../../utils/colors';

class CommonQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      innerWidth: window.innerWidth,
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  updateSize = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  header = () => {
    return (
      <>
        <SubHeader backgroundColor={'none'} title={'Perguntas frequentes'} />
      </>
    );
  };

  body() {
    return (
      <Container className={'mb-4 pb-2 mb-lg-5 pb-lg-5'}>
        {commonQuestions.map((commonQuestion, index) => {
          return (
            <Row key={index}>
              <Col>
                <Accordion
                  textColor={colors.primaryThree}
                  id={index.toString()}
                  summary={
                    <Text labelAcordeonFaq text={`${index + 1}. ${commonQuestion.question}`} />
                  }
                  details={
                    commonQuestion.answerArray ? (
                      <>
                        {commonQuestion.answer && <Text paragraph3 text={commonQuestion.answer} />}
                        <Row>
                          {commonQuestion.answerArray.map((array, index) => {
                            return (
                              <Col key={index} lg={6}>
                                <Text paragraph3 text={array.answer} />
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    ) : (
                      <Text paragraph3 text={commonQuestion.answer} />
                    )
                  }
                />
              </Col>
            </Row>
          );
        })}
      </Container>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  }
}

CommonQuestions.propTypes = {
  //props
  history: PropTypes.object,
};
export default CommonQuestions;
