import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';
import { Col, Row } from 'react-bootstrap';

import banner_limit_value from '../../../assets/imgs/banner-guarantees-limit-value.svg';
import banner_limit_value_mobile from '../../../assets/imgs/banner-guarantees-limit-value-mobile.png';

export const Title = styled.h2`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryThree};
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0;

  @media ${bootstrapMin.lg} {
    font-size: 26px;
    line-height: 40px;
  }
`;

export const TitleProcedures = styled.h2`
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryOne};
  font-size: 25px;
  line-height: 42px;

  @media ${bootstrapMin.lg} {
    font-size: 42px;
    line-height: 40px;
  }
`;

export const TitleWhite = styled.h2`
  font-family: 'Trade Gothic LT Bold';
  font-size: 25px;
  line-height: 30px;
  color: ${colors.white};
  width: 175px;

  @media ${bootstrapMin.lg} {
    font-size: 32px;
    width: 185px;
  }
`;

export const RowFinancial = styled(Row)`
  @media ${bootstrapMin.lg} {
    gap: 8%;
  }
`;
export const ColFinancial = styled(Col)`
  @media ${bootstrapMin.lg} {
    width: 28%;
  }
`;

export const ContainerLimitValue = styled.div`
  height: 255px;

  @media ${bootstrapMin.lg} {
    height: 273px;
  }
`;

export const BannerLimitValue = styled.div`
  background-image: url(${banner_limit_value_mobile});
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-size: 100% 268px;
  position: absolute;
  width: 100vw;
  height: 268px;
  margin-left: -50vw;
  left: 49.99%;
  z-index: -1;

  @media ${bootstrapMin.sm} {
    background-image: url(${banner_limit_value});
    background-size: 1366px 273px;
    height: 273px;
  }
`;

export const GuaranteesContainer = styled.div`
  @media ${bootstrapMax.sm} {
    & .MuiButtonBase-root {
      font-size: 15.5px !important;
    }

    & .container-doubt {
      margin: 0 -20px;

      & .col-doubt {
        padding-right: 0;
      }
    }
  }

  @media ${bootstrapMax.lg} {
    padding: 0 12px;
  }
`;

export const RowStyled = styled(Row)`
  @media ${bootstrapMax.sm} {
    padding: 0 8px;
  }
`;
