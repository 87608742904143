import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { Screen, SubHeader, Text } from '../../../components';
import { TextStyled, ColStyled as Col } from './styles';
import { defaultStates } from '../../../utils/defaults';

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
  }

  header() {
    return (
      <>
        <SubHeader backgroundColor={'none'} title={'Política de privacidade'} />
      </>
    );
  }

  body() {
    return (
      <>
        <Row className={'mb-lg-5 pb-lg-5'}>
          <Col className={'col-12 d-flex flex-column mb-3'}>
            <TextStyled>Início de vigência: 29/01/2022</TextStyled>
            <Text
              paragraph4
              text={`O <b>FUNDO GARANTIDOR DO COOPERATIVISMO DE CRÉDITO</b>, associação civil, inscrito no CNPJ
              sob o n° 19.990.300/0001-93, denominado simplesmente como “<b>FGCoop</b>”, considerando como
              sensíveis e relevantes os registros eletrônicos e a privacidade dos dados dos
              visitantes (“Usuário”) na utilização do site, elaborou a presente Política de
              Privacidade (“Política”).`}
            />
            <Text
              paragraph4
              text={`Art. 1º O objetivo desta Política de Privacidade do site é informar, de forma simples,
              transparente e objetiva, quais são as práticas de coleta, uso e compartilhamento de
              dados pessoais e os direitos dos usuários em relação à privacidade de seus dados. <br> Art.
              2º Esta Política leva em consideração os conceitos e princípios previstos na Lei Geral
              de Proteção de Dados, dentre eles: boa-fé, finalidade, adequação, necessidade, livre
              acesso, qualidade dos dados, transparência, segurança, prevenção, não discriminação,
              responsabilização e prestação de contas. <br> Art. 3º Ao fornecer os dados pessoais, o
              usuário concorda com o tratamento estabelecido nesta Política. <br> Art. 4º A presente
              Política se aplica ao site institucional do <b>FGCoop</b>, no endereço: <b>www.fgcoop.coop.br</b>.`}
            />
            <Text titleApp2 text={'TÍTULO I - DAS DEFINIÇÕES'} />
            <Text
              paragraph4
              text={`Art. 5º Para melhor compreensão da presente Política, importa saber alguns conceitos: <br>
              I. <u>Lei Geral de Proteção de Dados (“LGPD”):</u> a Lei Geral de Proteção de Dados Pessoais (Lei nº
              13.709/18), dispõe sobre o tratamento de dados pessoais, nos meios físicos e digitais, com
              o objetivo de proteger direitos fundamentais de liberdade e de privacidade e o livre
              desenvolvimento da personalidade das pessoas naturais titulares de dados pessoais. <br> II.
              <u>Dados Pessoais:</u> dado pessoal é toda informação que permita a identificação da pessoa
              natural, de forma direta ou associada a outros dados. <br> III. <u>Dados sensíveis:</u> categoria
              especial de dados pessoais referentes à origem racial ou étnica, convicção religiosa,
              opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico
              ou político, referentes à saúde ou à vida sexual e dados genéticos ou biométricos
              relativos à pessoa natural. <br> IV. <u>Dados especiais:</u> dados de crianças e adolescentes, que
              merecem tratamento especial. <br> V. <u>Tratamento de Dados Pessoais:</u> nos termos da LGPD, o
              tratamento é toda operação realizada com dados pessoais coletados pelo <b>FGCoop</b>, por meios
              automatizados ou não automatizados, tais como: coleta, utilização, difusão ou qualquer
              outra forma de disponibilização, compartilhamento, armazenamento, alteração, exclusão,
              eliminação, destruição, etc. <br> VI. <u>Titular dos dados pessoais / Usuário:</u> é a pessoa física a
              quem os dados pessoais se referem. <br> VII. <u>Incidente(s):</u> qualquer evento ou série de eventos
              adversos, indesejados ou inesperados, confirmado ou sob suspeita, relacionado ao
              comprometimento da integridade, disponibilidade ou autenticidade de ativos ou ao bom
              funcionamento do negócio do <b>FGCoop</b>, tais como (mas não limitado a) ataques, uso ou acesso
              não autorizado, vírus, vazamento de informação ou mesmo violação à Política de Segurança. <br>
              VIII. <u>Controlador:</u> agente responsável por tomar as principais decisões referentes ao
              tratamento de dados pessoais e por definir a finalidade desse tratamento. <br> IX. <u>Operador:</u>
              agente responsável por realizar o tratamento de dados segundo as instruções fornecidas
              pelo controlador e no limite das finalidades por este delimitadas. <br> X. <u>Encarregado de
              Proteção de Dados (DPO):</u> pessoa indicada pelo <b>FGCoop</b> para atuar como canal de comunicação
              entre o Fundo e os titulares dos dados e a Autoridade Nacional de Proteção de Dados. <br> XI.
              <u>Autoridade Nacional de Proteção de Dados (ANPD):</u> órgão responsável pela fiscalização das
              disposições da LGPD no território brasileiro.`}
            />
            <Text titleApp2 text={'TÍTULO II - DADOS PESSOAIS'} />
            <Text titleApp3 text={'CAPÍTULO I - DADOS PESSOAIS COLETADOS E UTILIZADOS'} />
            <Text
              paragraph4
              text={`Art. 6º O <b>FGCoop</b> trata apenas os dados pessoais enquanto forem necessários e adequados
              para as finalidades que embasam a coleta e o uso, sempre observando as bases legais do
              tratamento. <br> Art. 7º Durante a navegação no website, o <b>FGCoop</b> coleta dados pessoais
              indiretamente por meio de e outras tecnologias de coleta de dados, sendo algumas
              estritamente necessárias ao funcionamento do website. <br> § 1º Os dados coletados podem
              envolver informações a respeito do dispositivo, registros de acesso, (data, hora, endereço
              de IP, e rede utilizada) e duração do acesso, em atendimento às legislações aplicáveis. <br> §
              2º Quando o Usuário interage com o ambiente virtual, por meio de preenchimento de
              formulários ou acesso ao seu cadastro, o <b>FGCoop</b> pode coletar dados pessoais fornecidos
              diretamente pelo Usuário. <br> § 3º O tratamento de dados pessoais ocorre de duas formas:
              coletados automaticamente ou fornecidos pelo próprio Usuário.`}
            />
            <Text titleApp3 text={'CAPÍTULO II - COMPARTILHAMENTO DE DADOS PESSOAIS'} />
            <Text
              paragraph4
              text={`Art. 8º Os dados pessoais e os registros de atividades poderão ser compartilhados
              internamente, com as áreas que necessitem, bem como com: <br> a) empresas parceiras, envolvidas
              no desenvolvimento das atividades de fins publicitários, que são obrigadas por lei ou
              contrato a proteger as suas informações pessoais e somente utilizá-las de acordo com as
              nossas instruções; <br> b) provedores de serviços de tecnologia, que são obrigados por lei ou
              contrato a proteger as suas informações pessoais e somente utilizá-las de acordo com as
              nossas instruções; e <br> c) autoridades judiciais, administrativas ou governamentais
              competentes, órgãos reguladores, órgãos fiscalizadores e empresas de auditoria, com o
              propósito de cumprimento da legislação, ou sempre que houver determinação legal ou ordem
              judicial para tanto. <br><br> § 1º Os dados pessoais coletados não serão transferidos a terceiros
              ou utilizados para finalidades diversas daquelas que foram informadas nesta Política. <br><br> § 2º
              Caso o Usuário tenha qualquer dúvida sobre o compartilhamento dos dados pessoais, poderá
              entrar em contato por meio dos canais de atendimento disponibilizados nesta Política.`}
            />
            <Text titleApp3 text={'CAPÍTULO III - TRANSFERÊNCIA INTERNACIONAL DE DADOS PESSOAIS'} />
            <Text
              paragraph4
              text={`Art. 9º Os dados pessoais coletados e tratados pelo <b>FGCoop</b> são armazenados em ambiente
              seguro e controlado, localizados em servidores no Brasil, bem como em ambiente conhecido
              como nuvem (<i>cloud computing</i>), o que poderá ocasionar a transferência dos seus dados para
              fora do país. <br> Parágrafo Único. As transferências envolvem apenas empresas que demonstram
              estar adequadas às legislações aplicáveis, mantendo um nível de conformidade semelhante ou
              ainda mais rigoroso do que o previsto na legislação brasileira.`}
            />
            <Text titleApp3 text={'CAPÍTULO IV - DIREITOS DOS TITULARES DE DADOS PESSOAIS'} />
            <Text
              paragraph4
              text={`Art. 10 O Usuário e titular de dados pessoais, pode entrar em contato com o <b>FGCoop</b> para
              exercer seu direito de se autodeterminar sobre seus dados pessoais. O Marco Civil da
              Internet, em seu artigo 7º, garante o direito a informações claras sobre coleta, uso,
              armazenamento e proteção de dados pessoais. <br> § 1º A LGPD garante que o titular de dados
              pessoais tenha, a qualquer momento, e mediante requisição, os seguintes direitos: <br> a)
              Confirmação da existência do tratamento; <br> b) Acesso aos dados; <br> c) Informação sobre
              consentimento e compartilhamento; <br> d) Correção de dados incompletos, inexatos ou
              desatualizados; <br> e) Anonimização, bloqueio ou eliminação de dados desnecessários,
              excessivos ou tratados em desconformidade com a LGPD; <br> f) Portabilidade dos dados a outro
              fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a
              regulamentação da autoridade nacional, observados os segredos comercial e industrial; <br> g)
              Eliminação dos dados pessoais tratados com o consentimento do titular; <br> h) Possibilidade de
              não fornecer consentimento; e <br> i) Revogação do consentimento. <br><br> § 2º Para que o <b>FGCoop</b> possa
              viabilizar o exercício dos direitos, conforme o caso, o Usuário deve identificar-se
              apropriadamente e informar o direito que gostaria de exercer. <br> § 3º As requisições e
              solicitações relacionadas aos dados pessoais poderão ser enviadas ao Encarregado de
              Proteção de Dados Pessoais (“DPO”) através do e-mail: <b>dpo@fgcoop.coop.br</b>. <br> § 4º O <b>FGCoop</b>
              empreenderá todos os esforços razoáveis no menor tempo possível e procederá com
              assertividade e cooperação em relação às requisições enviadas, a fim de resolvê-las
              satisfatoriamente. <br> § 5º O <b>FGCoop</b> não atenderá solicitação de exercício de direitos, caso o
              atendimento viole a propriedade intelectual ou segredo de negócios, bem como quando houver
              obrigação legal ou regulatória para retenção de dados pessoais.`}
            />
            <Text titleApp2 text={'TÍTULO III - DA UTILIZAÇÃO DE COOKIES'} />
            <Text
              paragraph4
              text={`Art. 11 O <b>FGCoop</b> utiliza <i>Cookies</i> (pequenos arquivos digitais em formato de texto que são
              armazenados no seu dispositivo – computador, <i>smartphone</i>, <i>tablet</i> etc. – pelo navegador de
              internet) em seu ambiente. <br> Art. 12 Os <i>cookies</i> têm a função de aprimorar a experiência do
              Usuário, tanto em termos de performance, como em termos de usabilidade, uma vez que os
              conteúdos disponibilizados serão direcionados às necessidades e expectativas do Usuário. <br>
              Art. 13 Os <i>cookies</i> permitem a coleta de dados relacionados à navegação, quando concedidas
              autorizações pelo Usuário, por meio das configurações do dispositivo e podem coletar dados
              para diferentes finalidades, tais como: <br> a) <u>Funcionamento:</u> para garantir o correto acesso e
              funcionamento das aplicações; <br> b) <u>Autenticação:</u> para reconhecer o Usuário, possibilitando o
              seu acesso; <br> c) <u>Segurança:</u> para auxiliar no monitoramento e detecção de atividades não
              autorizadas, bem como na proteção das informações dos Usuários; e <br> d) <u>Pesquisa, análise e
              desempenho:</u> para verificar, medir e analisar o desempenho e a utilização das aplicações
              pelos Usuários. <br><br> Art. 14 O Usuário tem a opção de bloquear os <i>cookies</i> diretamente no seu
              navegador e excluir os já existentes por meio das configurações, todavia, pode ocasionar
              restrições nas funções ou utilização do website.`}
            />
            <Text titleApp2 text={'TÍTULO IV - DO PRAZO DE RETENÇÃO DOS DADOS PESSOAIS'} />
            <Text
              paragraph4
              text={`Art. 15 Os dados pessoais coletados e tratados pelo <b>FGCoop</b> são armazenados até se
              exaurir a finalidade para a qual foram coletados, ou quando houver propósito legítimo
              e obrigações legais ou regulatórias que possibilitem a retenção dos dados pelo <b>FGCoop</b>,
              sempre em atendimento à LGPD. <br> Parágrafo único. Após o prazo de retenção, será
              realizado o descarte seguro dos dados pessoais.`}
            />
            <Text titleApp2 text={'TÍTULO V - DA SEGURANÇA DOS DADOS PESSOAIS'} />
            <Text
              paragraph4
              text={`Art. 16 Para resguardar a privacidade e proteger os dados pessoais do Usuário, o
              <b>FGCoop</b> adota medidas de proteção técnicas, organizacionais e de segurança adequadas,
              inclusive de segurança física, para manter a integridade, a disponibilidade, a
              autenticidade e a confidencialidade das informações e protegê-las de acessos não
              autorizados. <br> Art. 17 Os dados pessoais são acessados somente por profissionais
              devidamente autorizados, respeitando os princípios de proporcionalidade, necessidade e
              relevância, além do compromisso de confidencialidade e preservação da privacidade do
              Usuário, nos termos desta Política.`}
            />
            <Text titleApp2 text={'TÍTULO VI - DAS INFORMAÇÕES ADICIONAIS'} />
            <Text
              paragraph4
              text={`Art. 18 Esta Política pode ser atualizada a qualquer tempo pelo <b>FGCoop</b>, mediante aviso
              no site e/ou por e-mail, caso o Usuário tenha optado por receber dessa forma nossas
              comunicações. <br> Art. 19 Esta Política é interpretada segundo a legislação brasileira, no
              idioma português. <br> Art. 20 Em caso de qualquer dúvida com relação às disposições
              constantes desta Política, inclusive para exercício dos seus direitos, o Usuário pode
              entrar em contato com o Encarregado, pelo e-mail: <b>dpo@fgcoop.coop.br</b>.`}
            />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { loadingState } = this.state;
    return <Screen body={this.body} header={this.header} loadingState={loadingState} />;
  }
}

export default PrivacyPolicy;
