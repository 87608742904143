const { axiosDefault, handleErrors } = require('../utils/defaults');

const newslettersService = {
  get: async (email) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(`/api/newsletters/${email ? email : ''}`);

      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  save: async (newsletters) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.post(`/api/newsletters`, newsletters);

      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  getall: async () => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(`/api/newsletters`);
      return data;

    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default newslettersService;
