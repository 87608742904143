import React from 'react';
import PropTypes from 'prop-types';
import { InputTags } from 'react-bootstrap-tagsinput';
import 'react-bootstrap-tagsinput/dist/index.css';

const InputHashTags = ({ value, onTags, onClick }) => {
  return (
    <div style={{ margin: 10 }}>
      <div className="input-group">
        <InputTags style={{ backgroundColor: 'white' }} values={value} onTags={onTags} />
        <button
          className="btn btn-outline-secondary"
          type="button"
          data-testid="button-clearAll"
          onClick={onClick}>
          Remover
        </button>
      </div>
      <hr />
      <ol>{value && value.map((item, index) => <li key={item + index}>{'#' + item}</li>)}</ol>
    </div>
  );
};

InputHashTags.propTypes = {
  value: PropTypes.any,
  onTags: PropTypes.func,
  onClick: PropTypes.func,
};

export default InputHashTags;
