import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { bootstrapMin } from '../../../utils/devices';

export const ColStyled = styled(Col)`
  gap: 0 60px;

  @media ${bootstrapMin.xl} {
    gap: 0 68px;
  }
`;
