import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';
import { Col } from 'react-bootstrap';
import image_home from '../../../assets/imgs/image-home.webp';

export const SubHeaderHome = styled.div`
  width: 100%;
  height: 348px;

  > div {
    @media ${bootstrapMax.lg} {
      position: absolute;
      width: 100vw;
      margin-left: -50vw;
      left: 49.99%;
    }
  }

  & .rec-item-wrapper {
    height: 312px;

    @media ${bootstrapMin.lg} {
      height: 503px;
    }
  }

  @media ${bootstrapMin.lg} {
    height: 503px;
  }
`;

export const TitleHome = styled.p`
  font-size: 20px;
  line-height: 42px;
  font-family: 'Trade Gothic LT Bold';
  color: ${colors.primaryTwo};

  @media ${bootstrapMin.lg} {
    font-size: 24px;
  }
`;

export const Content = styled.p`
  color: ${colors.secondaryTwo};
  font-size: 14px;

  @media ${bootstrapMin.lg} {
    font-size: 16px;
  }
`;

export const ImageHome = styled.div`
  background-image: url(${image_home});
  background-repeat: no-repeat;
  position: absolute;
  height: 293px;
  width: 280px;
  background-size: 100%;

  @media ${bootstrapMin.md} and ${bootstrapMax.xl} {
    margin-top: 58px;
    height: 470px;
    width: 450px;
  }

  @media ${bootstrapMin.xl} {
    height: 652px;
    width: 578px;
  }
`;

export const ColStyled = styled(Col)`
  height: 293px;

  @media ${bootstrapMax.lg} {
    justify-content: center;
  }

  @media ${bootstrapMin.md} and ${bootstrapMax.xl} {
    height: 527px;
  }

  @media ${bootstrapMin.xl} {
    height: 652px;
  }
`;

export const Background = styled.div`
  z-index: -1;
  min-height: 283px;
  position: absolute;
  width: 100vw;
  margin-left: -50vw;
  left: 49.99%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${colors.supportOne};

  @media ${bootstrapMin.lg} {
    min-height: 427px;
  }
`;

export const HomeContainer = styled.div`
  margin-top: -10px;

  & .know-more-about {
    & h2 {
      @media ${bootstrapMin.lg} {
        text-align: left !important;
        line-height: 50px;
      }
    }

    & p {
      @media ${bootstrapMin.lg} {
        text-align: left !important;
      }
    }
  }

  & .rec-carousel-wrapper {
    & .rec-carousel {
      min-width: 284px;
      width: 100%;
    }
  }

  & .button {
    margin-bottom: 16px;

    @media ${bootstrapMin.lg} {
      margin-bottom: -20px;
    }
  }

  @media ${bootstrapMin.lg} {
    margin-top: 30px;
  }
`;
export const FlagYoutube = styled.div`
  button.ytp-large-play-button.ytp-button.ytp-large-play-button-red-bg {
    background-color: transparent;
    border-color: transparent;
    position: relative;
    left: 580px;
    bottom: 400px;
  }

  @media ${bootstrapMax.xs} {
    button.ytp-large-play-button.ytp-button.ytp-large-play-button-red-bg {
      background-color: transparent;
      border-color: transparent;
      position: relative;
      left: 5px;
      bottom: 400px;
      top: -115px;
    }
  }
`;
