import React, { Component } from 'react';
import { Button, Screen, SubHeader, Text } from '../../../components';

import PropTypes from 'prop-types';
const { defaultStates } = require('../../../utils/defaults');

import bannerMobile from '../../../assets/imgs/banner-page-iadi-mobile.png';
import banner from '../../../assets/imgs/banner-page-iadi.png';

import { Container, Paragraph, BannerIADI, Heading3 } from './styles';
import { Col, Row } from 'react-bootstrap';
class IADI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      innerWidth: window.innerWidth,
    };

    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
    this.updateSize = this.updateSize.bind(this);
    this.principles = this.principles.bind(this);
  }

  updateSize = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  header = () => {
    return (
      <>
        <SubHeader
          image
          backgroundImage={banner}
          backgroundImageMobile={bannerMobile}
          minHeightSm={452}
          minHeight={313}
          title={'IADI - ASSOCIAÇÃO INTERNACIONAL DE SEGURADORES DE DEPÓSITOS'}
        />
      </>
    );
  };

  principles = () => {
    window.open('https://www.iadi.org/en/about-iadi/iadi-members-and-participants/', '_blank');
  };

  principlesPT_BR = () => {
    window.open(
      'https://fgcoop-producao.s3.sa-east-1.amazonaws.com/principios-basicos-iadi-portugues-junho.pdf',
      '_blank',
    );
  };

  body = () => {
    return (
      <Container className={'pt-2 pt-lg-0 mb-lg-5 pb-lg-5'}>
        <Row className={'justify-content-center pt-lg-3'}>
          <Col xs={12} className={'mb-lg-4'}>
            <Text heading2 noWrap textAlign={'center'} title={{ one: 'SAIBA O QUE É A IADI' }} />
          </Col>
          <Col lg={6} className={'pe-lg-5'}>
            <Paragraph>
              O FGCoop é membro da Associação Internacional de Seguradores de Depósitos
              (International Association of Deposit Insurers – IADI) desde 2016. Como membro dessa
              instituição, participa ativamente de fóruns que promovem o intercâmbio de informações
              e a cooperação internacional entre as instituições garantidoras de depósitos, além
              buscar constantemente a adequação e contribuição para as melhores práticas aplicáveis
              aos seguradores de depósitos.
            </Paragraph>
            <Paragraph>
              A IADI foi criada em 6 de maio de 2002, como uma organização sem fins lucrativos
              constituída sob a Lei Suíça. A Associação é uma <b>entidade privada</b> com sede nas
              dependências do Banco Internacional de Liquidações (Bank for International Settlements
              – BIS) na cidade de Basiléia, Suíça.
            </Paragraph>
            <Paragraph>
              Seus membros são entidades que, por lei ou convênios, oferecem{' '}
              <b>
                seguro de depósito, proteção ao depositante, ou esquemas de garantia de depósito.
              </b>
            </Paragraph>
          </Col>
          <Col sm={8} lg={6} className={'d-flex justify-content-center mb-5 pb-2 mb-lg-0 pb-lg-0'}>
            <BannerIADI />
          </Col>
        </Row>
        <div className={'mb-lg-4 pb-lg-2'}>
          <Heading3 className={'mb-3 mb-lg-2'}>OS SEUS OBJETIVOS PRINCIPAIS SÃO:</Heading3>
          <Paragraph border>
            Desenvolver princípios, padrões e orientações para aumentar a eficácia dos sistemas de
            seguro de depósito, além de incentivar a consideração e a aplicação voluntária de seus
            princípios, padrões e diretrizes;
          </Paragraph>
          <Paragraph border>
            Desenvolver metodologias de avaliação do cumprimento de seus princípios, normas e
            diretrizes, facilitando os processos de avaliação;
          </Paragraph>
          <Paragraph border>
            Aprimorar a compreensão de interesses comuns e questões relacionadas ao seguro de
            depósito;
          </Paragraph>
          <Paragraph border>
            Facilitar o compartilhamento e troca de conhecimentos e informações sobre garantias de
            depósitos por meio de programas de treinamento, desenvolvimento e educação, além de
            fornecer aconselhamento sobre o estabelecimento ou aprimoramento de sistemas eficazes de
            seguro de depósito;
          </Paragraph>
          <Paragraph border>
            Realizar pesquisas sobre temas relacionados ao seguro de depósito;
          </Paragraph>
          <Paragraph border>
            Cooperar com outras organizações internacionais, particularmente aquelas envolvidas em
            questões relacionadas com os mercados financeiros e promoção do crescimento financeiro,
            estabilidade e integridade;
          </Paragraph>
          <Paragraph border>
            Criar conscientização entre supervisores e reguladores de instituições financeiras sobre
            o papel fundamental dos sistemas de seguro de depósito na manutenção da estabilidade
            financeira;
          </Paragraph>
          <Paragraph border>
            Como parte da organização da IADI, foram criados Comitês Regionais para a África,
            Ásia-Pacífico, Caribe, Eurásia, Europa, América Latina, Oriente Médio & África do Norte
            e América do Norte com a finalidade de refletir os interesses regionais e as questões
            comuns, através da troca de informações e ideias. Esses comitês promovem eventos em suas
            próprias regiões anualmente.
          </Paragraph>
        </div>
        <Row className={'mb-4 mb-lg-0'} style={{ marginBottom: '-10px' }}>
          <Col className={'d-flex btn-iadi justify-content-end'}>
            <Button
              greenButton
              width={321}
              widthMobile={283}
              text={'CONFIRA A LISTA DE PARTICIPANTES'}
              onClick={this.principles}
            />
          </Col>
          <Col className={'d-flex btn-iadi justify-content-start'}>
            <Button
              greenButton
              width={321}
              widthMobile={283}
              text={'BAIXE OS PRINCÍPIOS DA IADI EM PORTUGUÊS'}
              onClick={this.principlesPT_BR}
            />
          </Col>
        </Row>
      </Container>
    );
  };

  render = () => {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        header={this.header}
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  };
}

IADI.propTypes = {
  //props
  history: PropTypes.object,
};
export default IADI;
